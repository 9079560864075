import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';

import SelectionInput from '../../components/advanced_filtering/SelectionInput';

import {
  ACTION_OPTIONS,
  selectEveryoneAction,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  name: <Translate value="components.advancedFiltering.action" />,
  options: ACTION_OPTIONS,
  selectedIndex:
    state.advanced_filtering.policy_settings.default_action === 'allow' ? 1 : 0,
  helpText: (
    <Translate
      value="components.advancedFiltering.actionHelpText"
      dangerousHTML
    />
  ),
  tooltipTestId: 'rule-action',
});

const mapDispatchToProps = dispatch => ({
  onChange: index => {
    Mixpanel.track(
      'Advanced Filtering / Add Everyone Rule Dialog / Action Dropdown',
      {}
    );
    if (index === 0) {
      Mixpanel.track(
        'Advanced Filtering / Add Everyone Rule Dialog / Action Block',
        {}
      );
    } else {
      Mixpanel.track(
        'Advanced Filtering / Add Everyone Rule Dialog / Action Allow',
        {}
      );
    }
    dispatch(selectEveryoneAction(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionInput);
