export const ACTIVATE_STORE_SSO_TOKEN = 'ACTIVATE_STORE_SSO_TOKEN';
export const LINK_PURCHASE = 'LINK_PURCHASE';
export const LINK_SUCCESS = 'LINK_SUCCESS';
export const LINK_FAILURE = 'LINK_FAILURE';
export const SAVE_INFO = 'ACTIVATE_SAVE_INFO';
export const CLEAR_INFO = 'CLEAR_INFO';
export const LINK_CLEAR_FILLED = 'LINK_CLEAR_FILLED';

export const saveInfo = info => ({
  type: SAVE_INFO,
  info,
});

export const clearInfo = () => ({
  type: CLEAR_INFO,
});

export const linkPurchase = history => ({
  type: LINK_PURCHASE,
  history,
});

export const linkSuccess = result => ({
  type: LINK_SUCCESS,
  result,
});

export const linkFailure = error => ({
  type: LINK_FAILURE,
  error,
});

export const clearFilled = () => ({
  type: LINK_CLEAR_FILLED,
});

export const storeDecodedSSOToken = (dtoken, provisioned_accounts) => ({
  type: ACTIVATE_STORE_SSO_TOKEN,
  dtoken,
  provisioned_accounts,
});
