export const BLOCK_PAGE_SHOW_PREVIEW = 'BLOCK_PAGE_SHOW_PREVIEW';
export const showPreview = () => ({
  type: BLOCK_PAGE_SHOW_PREVIEW,
});

export const BLOCK_PAGE_UPDATE_EDITOR = 'BLOCK_PAGE_UPDATE_EDITOR';
export const updateState = editorState => ({
  type: BLOCK_PAGE_UPDATE_EDITOR,
  editorState,
});

export const BLOCK_PAGE_CHECK_EDITOR_STATE = 'BLOCK_PAGE_CHECK_EDITOR_STATE';

export const BLOCK_PAGE_UPDATE_HEADER = 'BLOCK_PAGE_UPDATE_HEADER';
export const updateHeader = header => ({
  type: BLOCK_PAGE_UPDATE_HEADER,
  header,
});

export const BLOCK_PAGE_FETCH_INFO = 'BLOCK_PAGE_FETCH_INFO';
export const fetchInfo = () => ({
  type: BLOCK_PAGE_FETCH_INFO,
});
export const BLOCK_PAGE_FETCH_INFO_SUCCESS = 'BLOCK_PAGE_FETCH_INFO_SUCCESS';
export const fetchInfoSuccess = data => ({
  type: BLOCK_PAGE_FETCH_INFO_SUCCESS,
  data: {
    ...data,
    template: data.custom === 2 ? 'custom' : 'default',
  },
});
export const BLOCK_PAGE_FETCH_INFO_FAILURE = 'BLOCK_PAGE_FETCH_INFO_FAILURE';
export const fetchInfoFailure = error => ({
  type: BLOCK_PAGE_FETCH_INFO_FAILURE,
  error,
});

export const BLOCK_PAGE_SAVE_SETTINGS = 'BLOCK_PAGE_SAVE_SETTINGS';
export const saveSettings = () => ({
  type: BLOCK_PAGE_SAVE_SETTINGS,
});
export const BLOCK_PAGE_SAVE_SETTINGS_SUCCESS =
  'BLOCK_PAGE_SAVE_SETTINGS_SUCCESS';
export const saveSettingsSuccess = () => ({
  type: BLOCK_PAGE_SAVE_SETTINGS_SUCCESS,
});
export const BLOCK_PAGE_SAVE_SETTINGS_FAILURE =
  'BLOCK_PAGE_SAVE_SETTINGS_FAILURE';
export const saveSettingsFailure = () => ({
  type: BLOCK_PAGE_SAVE_SETTINGS_FAILURE,
});

export const BLOCK_PAGE_RESET_SETTINGS = 'BLOCK_PAGE_RESET_SETTINGS';
export const resetSettings = () => ({
  type: BLOCK_PAGE_RESET_SETTINGS,
});

export const BLOCK_PAGE_RESET_DEFAULT = 'BLOCK_PAGE_RESET_DEFAULT';
export const resetDefault = () => ({
  type: BLOCK_PAGE_RESET_DEFAULT,
});

export const BLOCK_PAGE_CHANGE_TEMPLATE = 'BLOCK_PAGE_CHANGE_TEMPLATE';
export const changeTemplate = template => ({
  type: BLOCK_PAGE_CHANGE_TEMPLATE,
  template,
});
