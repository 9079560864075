import React from 'react';
import { Translate } from 'react-i18nify';
import { connect } from 'react-redux';
import GetAppIcon from '@material-ui/icons/GetApp';
import HeaderButton from '../../components/log_table/HeaderButton';
import { exportCSV } from '../../actions/wf_logs';

const mapStateToProps = state => ({
  loading: state.wfLogs.exporting,
  value: <Translate value="shared.downloadCSV" />,
  icon: <GetAppIcon />,
});

const mapDispatchToProps = dispatch => ({
  clickAction: () => {
    dispatch(exportCSV());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderButton);
