import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import { CudaDialogTitle } from '../CudaDialog';
import DeployAgent from '../DeployAgent';

const DeployModal = ({ classes, title, onClose, loading, ...other }) => (
  <Dialog {...other} fullWidth maxWidth="md">
    <CudaDialogTitle onRequestClose={onClose}>{title}</CudaDialogTitle>
    <DialogContent>
      {loading ? (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      ) : (
        <DeployAgent {...other} />
      )}
    </DialogContent>
  </Dialog>
);

const styles = theme => ({
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '300px',
  },
});

DeployModal.propTypes = {
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  installer: PropTypes.shape({
    version: PropTypes.string,
    download_url: PropTypes.string,
    upload_url: PropTypes.string,
    installer_status: PropTypes.string,
  }),
};

export default withStyles(styles)(DeployModal);
