import { connect } from 'react-redux';
import SMBAccountSettings from '../components/SMBAccountSettings';

import {
  toggleUploadModal,
  uploadLogo,
  toggleSyslogModal,
  openSyslogModal,
  openSyslogModalForDemoAccounts,
  openSyslogConfig,
  toggleSyslogConfig,
  toggleSyslogConfigForDemmoAccounts,
  closeSyslogModal,
  toggleSyslogRemove,
  getAccountInfo,
  updateAccountOption,
  saveSyslogSettings,
  getDomainCategory,
  updateDomain,
  getDomainCategoryList,
  updateRecategorizeDomain,
  recategorizeDomainHandleChange,
  openRecategorizeModal,
  closeRecategorizeModal,
  recategorizeDomainConfirm,
} from '../actions/smb_account_settings';

const mapStateToProps = state => ({
  msp: state.account.msp,
  showUploadModal: state.smbAccountSettings.showUpload,
  showSyslogModal: state.smbAccountSettings.showSyslog,
  showSyslogRemoveModal: state.smbAccountSettings.showSyslogRemove,
  logo: state.smbAccountSettings.logo,
  processing: state.smbAccountSettings.processing,
  saving: state.smbAccountSettings.saving,
  wca: state.account.subscriptions.wca,
  syslogExists: state.smbAccountSettings.syslogExists,
  syslogIp: state.smbAccountSettings.syslogIp,
  syslogSyncGood: state.smbAccountSettings.syslogSyncGood,
  syslogSyncError: state.smbAccountSettings.syslogSyncError,
  syslogSyncWarn: state.smbAccountSettings.syslogSyncWarn,
  syslogLastSync: state.smbAccountSettings.syslogLastSync,
  fields: {
    ...state.smbAccountSettings.fields,
    ...state.smbAccountSettings.changes,
  },
  features: state.account.features,
  domain: state.smbAccountSettings.domain,
  category_name: state.smbAccountSettings.category_name,
  domainVal: state.smbAccountSettings.domainVal,
  categories: state.smbAccountSettings.categories,
  selectedOption: state.smbAccountSettings.selectedOption,
  showRecategorizeDomain: state.smbAccountSettings.showRecategorizeDomain,
  accountId: state.account.selected.toString(),
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(getAccountInfo());
  },
  openUploadModal: () => {
    dispatch(toggleUploadModal(true));
  },
  closeUploadModal: () => {
    dispatch(toggleUploadModal(false));
  },
  uploadLogo: logo => {
    dispatch(uploadLogo(logo));
  },
  openSyslogModal: () => {
    dispatch(toggleSyslogConfig(true));
  },
  openSyslogModalForDemoAccounts: () => {
    dispatch(toggleSyslogConfigForDemmoAccounts(true));
  },
  closeSyslogModal: () => {
    dispatch(toggleSyslogModal(false));
  },
  openSyslogRemoveModal: () => {
    dispatch(toggleSyslogRemove(true));
  },
  closeSyslogRemoveModal: () => {
    dispatch(toggleSyslogRemove(false));
  },
  saveSyslogConfig: () => {
    dispatch(saveSyslogSettings());
  },
  toggleSupport: field => {
    dispatch(
      updateAccountOption({
        support_enabled: field.target.checked,
      })
    );
  },
  updateDomain: field => {
    dispatch(updateDomain({ domain: field.target.value }));
  },
  getDomainCategory: domain => {
    dispatch(getDomainCategory(domain));
  },
  updateRecategorizeDomain: myfield => {
    dispatch(updateRecategorizeDomain({ domainVal: myfield.target.value }));
  },
  getDomainCategoryList: domainVal => {
    dispatch(getDomainCategoryList(domainVal));
  },
  recategorizeDomainHandleChange: value => {
    dispatch(recategorizeDomainHandleChange(value));
  },
  openRecategorizeModal: () => {
    dispatch(openRecategorizeModal(true));
  },
  closeRecategorizeModal: () => {
    dispatch(closeRecategorizeModal(false));
  },
  recategorizeDomainConfirm: () => {
    dispatch(recategorizeDomainConfirm());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SMBAccountSettings);
