import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import { Sector, PieChart, Pie, Cell } from 'recharts';

const styles = theme => ({
  container: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      position: 'relative',
      zIndex: '9',
      '& rect': {
        fill: 'rgba(0,0,0,0.9)',
      },
    },
  },
  centerText: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '& div.number': {
      fontSize: '40px',
      animation: 'fadein 5s',
    },
  },
  legend: {
    position: 'absolute',
    top: '40px',
    right: '40px',
  },
  legendRow: {
    display: 'flex',
    alignItems: 'center',
  },
  legendCircle: {
    height: '14px',
    width: '14px',
    borderRadius: '7px',
  },
  legendKey: {
    marginLeft: '5px',
    animation: 'fadein 5s',
  },
});

const COLORS = ['#D42B1E', '#E8563D', '#EC8062', '#F3A68D', '#F8D0BE'];
const OTHER_COLOR = '#E3EAF3';
let graphData = [];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = outerRadius + (outerRadius - innerRadius) * 0.75;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {graphData[index]['key']}
    </text>
  );
};

const renderActiveShape = props => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const text1 = `${(percent * 100).toFixed(0)}% (${payload.doc_count})`;
  const text2 = `${payload.key}`;

  const textLength = text1.length > text2.length ? text1.length : text2.length;

  const rectX = sx + (cos >= 0 ? 1 : -1) * 12 - 10;
  const rectY = cos >= 0 ? sy - 24 : sy - 34;
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <rect
        x={rectX}
        y={rectY}
        width={textLength <= 8 ? '100' : textLength * 7}
        height={60}
        rx="3"
        ry="3"
        transform={
          cos >= 0 ? '' : `rotate(180, ${sx + (cos >= 0 ? 1 : -1) * 12}, ${sy})`
        }
      />
      <text
        x={sx + (cos >= 0 ? 1 : -1) * 12}
        y={sy}
        textAnchor={textAnchor}
        fill="#FFFFFF"
        fontSize="16"
      >
        {text1}
      </text>
      <text
        x={sx + (cos >= 0 ? 1 : -1) * 12}
        y={sy}
        dy={18}
        textAnchor={textAnchor}
        fill="#FFFFFF"
        fontSize="12"
      >
        {text2}
      </text>
    </g>
  );
};

class ProtocolPieChart extends Component {
  state = {
    activeIndex: -1,
  };

  getInitialState() {
    return {
      activeIndex: 0,
    };
  }

  onPieEnter(data, index) {
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const {
      classes,
      data,
      legend = true,
      legendCount = 3,
      height = 400,
      width = 800,
      index = 0,
      widthDifferential = 30,
      legendTop = '160px',
    } = this.props;

    let total = 0;
    let tmp = { key: 'Other', doc_count: 0 };
    graphData = [];
    let dataPts = ['Non-SSL', 'TLS 1.0', 'TLS 1.2'];
    data.map((entry, index) => {
      total += entry.doc_count;
      if (dataPts.includes(entry.key)) {
        graphData.push(entry);
      } else {
        tmp.doc_count += entry.doc_count;
      }

      return entry;
    });

    if (tmp.doc_count > 0) {
      graphData.push(tmp);
    }

    const legendStyle = {
      top: [legendTop],
    };

    return (
      <div className={classes.container}>
        <PieChart width={width} height={height}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={graphData}
            fill="#8884d8"
            nameKey="key"
            dataKey="doc_count"
            legendType="none"
            label={renderCustomizedLabel}
            outerRadius={width * (13 / 80)}
            innerRadius={width * (13 / 80) - widthDifferential}
            onMouseEnter={(data, index) => {
              this.onPieEnter(data, index);
            }}
            onMouseLeave={(data, index) => {
              this.onPieEnter(data, -1);
            }}
          >
            {graphData.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  entry.key === 'Other'
                    ? OTHER_COLOR
                    : COLORS[index % COLORS.length]
                }
              />
            ))}
          </Pie>
        </PieChart>
        {!total ? (
          <div className={classes.centerText} style={legendStyle}>
            <div className="number">{total}</div>
            <div>{translate('components.dashboard.noTlsDetected')}</div>
          </div>
        ) : null}
        {legend ? (
          <div className={classes.legend}>
            {graphData.map((entry, index) => {
              return (
                <div key={index} className={classes.legendRow}>
                  <div
                    className={classes.legendCircle}
                    style={{
                      backgroundColor:
                        entry.key === 'Other'
                          ? OTHER_COLOR
                          : COLORS[index % COLORS.length],
                    }}
                  />
                  <div className={classes.legendKey}>
                    {entry.key || 'Other'}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

ProtocolPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key: PropTypes.string,
    })
  ).isRequired,
  legend: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default withStyles(styles, { name: 'AreaChart' })(ProtocolPieChart);
