import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';

import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
} from '../CudaDialog';

const DeleteNotificationComponent = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
}) => (
  <Dialog
    data-test-id="delete-dialog"
    open={showModal}
    maxWidth="md"
    onEscapeKeyDown={closeDialog}
    className={classes.dialog}
  >
    <CudaDialogTitle onClose={closeDialog}>
      {translate('components.notifications.delete_notification')}
    </CudaDialogTitle>
    <CudaDialogContent overflow="visible" loadingAnimation={false}>
      <div className={classes.dialogContent}>
        <CudaDialogRegularContent className={classes.dialogContent}>
          {translate('components.notifications.confirm_delete')}
        </CudaDialogRegularContent>
      </div>
    </CudaDialogContent>
    <DialogActions>
      <div>
        <ActionButton
          data-test-id="delete-cancel"
          color="secondary"
          onClick={closeDialog}
        >
          {translate('shared.cancel')}
        </ActionButton>
        <ActionButton
          data-test-id="delete-confirm"
          style={{ background: '#DD1E31' }}
          onClick={() => {
            buttonAction();
            closeDialog();
          }}
        >
          {translate('shared.delete')}
        </ActionButton>
      </div>
    </DialogActions>
  </Dialog>
);

const styles = theme => ({
  dialogContent: {
    width: '600px',
  },
});

export default withStyles(styles, { name: 'DeleteNotificationStyles' })(
  DeleteNotificationComponent
);
