import { connect } from 'react-redux';
import StatusBar from '../components/StatusBar';

const mapStateToProps = (state, ownProps) => {
  const lid =
    state.app.result === 'success'
      ? state.app.success.message
      : state.app.error.message;
  return {
    messageLid: lid,
    result: state.app.result,
    show: state.app.show,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(StatusBar);
