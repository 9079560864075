import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate, Translate } from 'react-i18nify';
import { DragSource, DropTarget } from 'react-dnd';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import TimerIcon from '@material-ui/icons/Timer';
import DeleteIcon from '@material-ui/icons/Delete';
import VerticalAlignTopIcon from '@material-ui/icons/VerticalAlignTop';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { DragVerticalIcon } from '../icons';

const styles = theme => ({
  smallCell: {
    width: '30px',
  },
  hidden: {
    display: 'none',
  },
  clickableIcon: {
    cursor: 'pointer',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  ruleWrapper: {
    padding: 0,
    border: 0,
    '&:last-child': {
      padding: 0,
    },
    verticalAlign: 'top',
  },
  orderInput: {
    width: '30px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  doneIcon: {
    color: theme.statusColors.success,
  },
  blockIcon: {
    color: theme.statusColors.failure,
  },
  actionCell: {
    display: 'flex',
    textTransform: 'uppercase',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
  chip: {
    margin: '4px',
    height: '24px',
    fontSize: '12px',
    maxWidth: '775px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    lineHeight: '24px',
    '& span': {
      display: 'inline-block',
    },
  },
  chipHighlight: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    color: '#FFFFFF',
  },
  partialChipHighlight: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    color: '#FFFFFF',
    padding: '5px 0px',
  },
  defaultRuleChip: {
    textTransform: 'uppercase',
  },
  defaultChip: {
    borderRadius: 0,
  },
  topAlignedCell: {
    verticalAlign: 'top',
    '&>div': {
      height: '32px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  typeCell: {
    verticalAlign: 'top',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  dragHandle: {
    cursor: 'pointer',
    justifyContent: 'flex-end',
    '& > svg': {
      height: '32px',
    },
  },
  menuOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  deleteOption: {
    color: theme.statusColors.failure,
  },
  disabledHandle: {
    pointerEvents: 'none',
  },
  highlighted: {
    backgroundColor: theme.colors.highlightYellow,
  },
  dragging: {
    opacity: 0,
  },
});

class RuleRow extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.moveToTop = this.moveToTop.bind(this);
    this.moveToBottom = this.moveToBottom.bind(this);
  }

  state = {
    anchorEl: null,
    everyoneAnchor: null,
  };

  handleEveryoneClick = event => {
    this.setState({ everyoneAnchor: event.currentTarget });
  };

  handleEveryoneClose = () => {
    this.setState({ everyoneAnchor: null });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.props.submitRuleOrder(this.props.arrIndex, this.props.parentIndex);
    } else if (event.key === 'Escape') {
      this.props.updateOrder(
        this.props.arrIndex,
        this.props.arrIndex,
        this.props.parentIndex
      );
    }
  }

  handleBlur() {
    this.props.submitRuleOrder(this.props.arrIndex, this.props.parentIndex);
  }

  moveToTop() {
    this.props.submitRuleOrder(
      this.props.rule.id,
      this.props.arrIndex,
      this.props.parentIndex,
      0,
      this.props.rule.ruleset_id
    );
  }

  moveToBottom() {
    this.props.submitRuleOrder(
      this.props.rule.id,
      this.props.arrIndex,
      this.props.parentIndex,
      this.props.ruleCount,
      this.props.rule.ruleset_id
    );
  }

  componentDidMount() {
    if (
      this.props.highlighted &&
      this.props.rule.id === this.props.highlighted
    ) {
      this.row.scrollIntoView();
    }
  }

  render() {
    const {
      classes,
      rule,
      arrIndex,
      categories,
      parentIndex,
      updateOrder,
      connectDragSource,
      connectDropTarget,
      connectDragPreview,
      isDragging,
      processing,
      deleteRule,
      openEveryoneEditRule,
      openEditRule,
      openTimeConstraint,
      adMap,
      users,
      groups,
      everyone,
      highlighted,
      search,
      modifiers,
    } = this.props;
    const { anchorEl, everyoneAnchor } = this.state;
    let type;
    let rules;
    if (everyone) {
      type = 'default';
      rules = [];
    } else {
      type = rule.type;
      if (type === 'category') {
        rules = rule.categories;
      } else if (type === 'domain') {
        rules = rule.domains;
      } else if (type === 'url') {
        rules = rule.urls || [];
      } else {
        rules = [];
      }
    }

    let showIcon = rule.id in modifiers;

    // Users and Groups get adMap to populate.
    let selectedAD = [];
    if (users.length > 0) {
      selectedAD = users.map(id =>
        adMap[id]
          ? {
              type: adMap[id]['type'],
              label: adMap[id]['display_name'],
              value: adMap[id]['uuid'],
            }
          : { type: 'user', label: id, value: id }
      );
    } else if (groups.length > 0) {
      selectedAD = groups.map(id =>
        adMap[id]
          ? {
              type: adMap[id]['type'],
              label: adMap[id]['group_name'],
              value: adMap[id]['uuid'],
            }
          : { type: 'group', label: id, value: id }
      );
    } else {
      selectedAD = [{ type: 'everyone', label: 'Everyone', value: 'everyone' }];
    }

    return connectDropTarget(
      connectDragPreview(
        <tr
          data-test-id={`ruleset-row-${arrIndex}`}
          className={classNames({
            [classes.dragging]: isDragging,
            [classes.highlighted]: highlighted === rule.id,
          })}
          ref={elm => {
            this.row = elm;
          }}
        >
          <TableCell className={classes.topAlignedCell}>
            {!everyone &&
              connectDragSource(
                <div
                  data-test-id="row-drag-icon"
                  className={classNames(classes.dragHandle, {
                    [classes.disabledHandle]: processing,
                  })}
                >
                  <DragVerticalIcon />
                </div>
              )}
          </TableCell>
          <TableCell
            className={classNames({ [classes.topAlignedCell]: !everyone })}
          >
            {everyone ? (
              <Chip
                key={translate('components.advancedFiltering.default')}
                label={translate('components.advancedFiltering.default')}
                className={classNames(
                  classes.defaultChip,
                  classes.chip,
                  classes.defaultRuleChip
                )}
              />
            ) : (
              <TextField
                data-test-id="ruleset-row-precedence"
                InputProps={{
                  disabled: true,
                  disableUnderline: true,
                  classes: {
                    root: classes.orderInput,
                  },
                }}
                onChange={event =>
                  updateOrder(arrIndex, event.target.value, parentIndex)
                }
                value={rule.index}
                onKeyUp={this.handleKeyPress}
                onBlur={this.handleBlur}
              />
            )}
          </TableCell>
          <TableCell className={classes.topAlignedCell}>
            <div className={classes.actionCell}>
              {rule.action === 'allow' ? (
                <DoneIcon
                  data-test-id="ruleset-row-allow-icon"
                  color="primary"
                  classes={{ colorPrimary: classes.doneIcon }}
                />
              ) : (
                <BlockIcon
                  data-test-id="ruleset-row-block-icon"
                  color="primary"
                  classes={{ colorPrimary: classes.blockIcon }}
                />
              )}
              {rule.action === 'allow'
                ? translate('components.advancedFiltering.allow')
                : translate('components.advancedFiltering.block')}
            </div>
          </TableCell>
          <TableCell
            data-test-id="ruleset-row-type-cell"
            className={classNames(classes.topAlignedCell, classes.typeCell)}
          >
            <div>
              {showIcon && <TimerIcon />}
              {
                {
                  domain: translate('components.advancedFiltering.domains'),
                  category: translate(
                    'components.advancedFiltering.categories'
                  ),
                  url: translate('components.advancedFiltering.urls'),
                  default: '',
                }[type]
              }
            </div>
          </TableCell>
          <TableCell data-test-id="ruleset-row-data-cell">
            {
              {
                domain: rules.map(value => {
                  let chipStyle = classNames(classes.chip);
                  let displayLabel = value;

                  if (
                    search.query.value !== '' &&
                    search.type === 'domains' &&
                    search.query.value === value
                  ) {
                    chipStyle = classNames(classes.chip, classes.chipHighlight);
                  } else if (
                    search.query.value !== '' &&
                    search.type === 'domains'
                  ) {
                    const regex = new RegExp(search.query.value, 'g');
                    regex.lastIndex = 0;
                    const match = regex.exec(value);
                    if (match && value) {
                      const start = value.slice(0, match.index);
                      const body = value.slice(match.index, regex.lastIndex);
                      const end = value.slice(regex.lastIndex, value.length);
                      displayLabel = (
                        <span>
                          {start}
                          <span className={classes.partialChipHighlight}>
                            {body}
                          </span>
                          {end}
                        </span>
                      );
                    }
                  }

                  return (
                    <Chip
                      data-test-id={`data-${value}`}
                      key={value}
                      label={displayLabel}
                      className={chipStyle}
                    />
                  );
                }),
                category: rules.map(value => {
                  let chipStyle = classNames(classes.chip);
                  if (
                    search.query.value !== '' &&
                    search.type === 'categories' &&
                    search.query.value === value
                  ) {
                    chipStyle = classNames(classes.chip, classes.chipHighlight);
                  }

                  // Hide categories that may be migrated because they are new and not ready to display
                  if (!categories[value]) {
                    return null;
                  }

                  return (
                    <Chip
                      data-test-id={`data-${value}`}
                      key={value}
                      label={categories[value]}
                      className={chipStyle}
                    />
                  );
                }),
                url: rules.map(value => {
                  let chipStyle = classNames(classes.chip);
                  if (
                    search.query.value !== '' &&
                    search.type === 'categories' &&
                    search.query.value === value
                  ) {
                    chipStyle = classNames(classes.chip, classes.chipHighlight);
                  }

                  return (
                    <Chip
                      data-test-id={`data-${value}`}
                      key={value}
                      label={value}
                      className={chipStyle}
                    />
                  );
                }),
                default: (
                  <Chip
                    data-test-id="data-default"
                    key={translate('components.advancedFiltering.allTraffic')}
                    label={translate('components.advancedFiltering.allTraffic')}
                    className={classNames(
                      classes.chip,
                      classes.defaultRuleChip
                    )}
                  />
                ),
              }[type]
            }
          </TableCell>
          <TableCell className={classes.topAlignedCell}>
            <div>
              <IconButton
                data-test-id="ruleset-row-more"
                aria-label="More"
                aria-owns={null}
                aria-haspopup="true"
                onClick={everyone ? this.handleEveryoneClick : this.handleClick}
                disabled={processing}
              >
                <MoreVertIcon />
              </IconButton>
            </div>
            <Menu
              anchorEl={everyoneAnchor}
              open={Boolean(everyoneAnchor)}
              onClose={this.handleEveryoneClose}
            >
              <MenuItem
                data-test-id="ruleset-row-edit-everyone"
                className={classes.menuOptions}
                onClick={() => {
                  this.handleEveryoneClose();
                  openEveryoneEditRule();
                }}
              >
                <EditIcon color="inherit" />
                <Translate value="components.advancedFiltering.edit" />
              </MenuItem>
            </Menu>
            <Menu
              anchorEl={everyoneAnchor}
              open={Boolean(everyoneAnchor)}
              onClose={this.handleEveryoneClose}
            >
              <MenuItem
                data-test-id="ruleset-row-edit-everyone"
                className={classes.menuOptions}
                onClick={() => {
                  this.handleEveryoneClose();
                  openEveryoneEditRule();
                }}
              >
                <EditIcon color="inherit" />
                <Translate value="components.advancedFiltering.edit" />
              </MenuItem>
            </Menu>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <MenuItem
                data-test-id="ruleset-row-edit"
                className={classes.menuOptions}
                onClick={() => {
                  this.handleClose();
                  openEditRule(selectedAD, rule);
                }}
              >
                <EditIcon color="inherit" />
                <Translate value="components.advancedFiltering.edit" />
              </MenuItem>
              <MenuItem
                data-test-id="ruleset-row-edit"
                className={classes.menuOptions}
                onClick={() => {
                  this.handleClose();
                  openTimeConstraint(selectedAD, rule);
                }}
              >
                <TimerIcon color="inherit" />
                <Translate value="components.advancedFiltering.timeConstraint" />
              </MenuItem>
              <MenuItem
                data-test-id="ruleset-row-move-top"
                className={classes.menuOptions}
                onClick={() => {
                  this.moveToTop();
                  this.handleClose();
                }}
              >
                <VerticalAlignTopIcon color="inherit" />
                <Translate value="components.advancedFiltering.moveTop" />
              </MenuItem>
              <MenuItem
                className={classes.menuOptions}
                data-test-id="ruleset-row-move-bottom"
                onClick={() => {
                  this.moveToBottom();
                  this.handleClose();
                }}
              >
                <VerticalAlignBottomIcon color="inherit" />
                <Translate value="components.advancedFiltering.moveBottom" />
              </MenuItem>
              <MenuItem
                data-test-id="ruleset-row-delete-rule"
                className={classNames(
                  classes.menuOptions,
                  classes.deleteOption
                )}
                onClick={() => {
                  this.handleClose();
                  deleteRule(rule.ruleset_id, rule.id);
                }}
              >
                <DeleteIcon color="inherit" />
                <Translate value="components.advancedFiltering.delete" />
              </MenuItem>
            </Menu>
          </TableCell>
        </tr>
      )
    );
  }
}

RuleRow.propTypes = {
  classes: PropTypes.object.isRequired,
  rule: PropTypes.object.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  submitRuleOrder: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  isDragging: PropTypes.bool.isRequired,
  openEditRule: PropTypes.func.isRequired,
  deleteRule: PropTypes.func.isRequired,
  adMap: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  parentId: PropTypes.string,
  ruleCount: PropTypes.number,
  highlighted: PropTypes.string,
  users: PropTypes.array,
  groups: PropTypes.array,
  everyone: PropTypes.bool,
  search: PropTypes.shape({
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
  }),
};

RuleRow.defaultProps = {
  users: [],
  groups: [],
  everyone: false,
  highlighted: '',
  ruleCount: 0,
  parentId: '',
};

const ruleSource = {
  beginDrag(props) {
    return {
      id: props.rule.id,
      index: props.arrIndex,
      originalIndex: props.arrIndex,
      parentIndex: props.parentIndex,
    };
  },
  endDrag(props, monitor, component) {
    const item = monitor.getItem();
    const { id, originalIndex, index } = item;

    if (index !== originalIndex) {
      props.submitReorder(
        id,
        index,
        props.parentIndex,
        originalIndex,
        props.parentId
      );
    }
  },
};

function sourceCollect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
}

const ruleTarget = {
  hover(props, monitor, component) {
    // eslint-disable-line
    if (!component) {
      return null;
    }
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.arrIndex;

    if (dragIndex === hoverIndex) {
      return null;
    }

    const hoverBoundingRect = component.row.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return null;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return null;
    }

    props.reorderRules(dragIndex, hoverIndex, props.parentIndex);
    monitor.getItem().index = hoverIndex; // eslint-disable-line
  },
};

function targetCollect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  };
}

const dragType = props => `RULE-${props.parentIndex}`;

const targeted = DropTarget(dragType, ruleTarget, targetCollect)(RuleRow);
const sourced = DragSource(dragType, ruleSource, sourceCollect)(targeted);
export default withStyles(styles, { name: 'RuleRow' })(sourced);
