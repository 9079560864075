import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import CudaTableTitle from '../CudaTableTitle';
import Paper from '../CudaPaper';
import BarChart from '../charts/Bar';
import PageReport from './PageReport';

const styles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: '300',
    color: 'rgba(0,0,0,0.9)',
  },
  subtitle: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
  graphWrapper: {
    padding: '20px',
    margin: '20px',
  },
  dataTableWrapper: {
    margin: '20px',
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
});

class PerUserReport extends Component {
  render() {
    const { classes, data } = this.props;
    const activity = data.activity;
    const blockedDomains = data.blockedDomains;
    const visitedDomains = data.visitedDomains;

    return (
      <div>
        <PageReport
          headers={['Actions', 'Requests']}
          data={activity}
          title={'Top User Activity'}
        />
        <PageReport
          headers={['Domains', 'Requests']}
          data={visitedDomains}
          title={'Top Domains Visited'}
        />
        <PageReport
          headers={['Domains', 'Requests']}
          data={blockedDomains}
          title={'Top Blocked Domains'}
        />
      </div>
    );
  }
}

PerUserReport.propTypes = {};

export default withStyles(styles)(withRouter(PerUserReport));
