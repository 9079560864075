import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import SectionHeader from './SectionHeader';
import FilterSearchBar from './FilterSearchBar';
import { WebLogIcon } from './icons';
import TimeSelect from '../containers/wf_logs/TimeSelect';

import WFLogTable from '../containers/wf_logs/WFLogTable';
import { DateRangeBox } from '../containers/WebFilteringLogs';
import { DateRangePicker } from '../containers/WebFilteringLogs';

class WebFilteringLogs extends Component {
  state = {};

  componentDidMount = () => {
    this.props.initLogs();
  };

  timeSelectHandler = event => {
    this.props.updateRange(event);
  };

  render() {
    const {
      classes,
      query,
      filterValues,
      updateQuery,
      addRow,
      removeRow,
      queryData,
      queryFields,
      queryField,
      updateField,
      queryFieldValues,
      clearFilter,
    } = this.props;

    let fieldValues = [];
    for (let i = 0; i < filterValues.length; i++) {
      fieldValues.push(
        queryFieldValues[queryFields[filterValues[i].queryField].field]
      );
    }
    return (
      <DocumentTitle title={translate('components.wfLogs.pageTitle')}>
        <div>
          <SectionHeader
            icon={<WebLogIcon />}
            title={translate('components.wfLogs.title')}
          />
          <Grid spacing={2} container>
            <Grid item xs={6}>
              <DateRangeBox size="large" />
              <DateRangePicker />
            </Grid>
          </Grid>
          <Grid spacing={2} container>
            {filterValues.map((n, i) => (
              <>
                <Grid item xs={12} md={7} lg={6}>
                  <FilterSearchBar
                    data-test-id="wfLogsSearch"
                    height="40px"
                    autoFocus={false}
                    disabled={false}
                    fields={queryFields}
                    field={n.queryField}
                    fieldValues={fieldValues[i]}
                    handleMenuItemClick={f => {
                      updateField(f, i);
                    }}
                    value={n.query}
                    onChange={value => {
                      updateQuery(value, i);
                    }}
                  />
                </Grid>
                {filterValues.length !== 1 && (
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={1}
                    className={classes.timeOptionsWrapper}
                  >
                    <Button
                      data-test-id="search-btn"
                      onClick={e => {
                        removeRow(i);
                      }}
                      color="secondary"
                      className={classes.queryButton}
                      variant="contained"
                    >
                      <RemoveIcon />
                    </Button>
                  </Grid>
                )}
                {(filterValues.length === 1 ||
                  i === filterValues.length - 1) && (
                  <Grid
                    item
                    xs={12}
                    md={2}
                    lg={1}
                    className={classes.timeOptionsWrapper}
                  >
                    <Button
                      data-test-id="search-btn"
                      onClick={addRow}
                      color="secondary"
                      className={classes.queryButton}
                      variant="contained"
                    >
                      <AddIcon />
                    </Button>
                  </Grid>
                )}
              </>
            ))}
            <Grid
              item
              xs={12}
              md={12}
              lg={filterValues.length === 1 ? 5 : 4}
              className={classes.timeOptionsWrapper}
            >
              <div>
                <Button
                  data-test-id="search-btn"
                  onClick={queryData}
                  color="primary"
                  className={classes.queryButton}
                  variant="contained"
                >
                  {translate('components.wfLogs.search')}
                </Button>
                <Button
                  data-test-id="search-btn"
                  onClick={clearFilter}
                  color="secondary"
                  variant="contained"
                >
                  {translate('components.wfLogs.clear')}
                </Button>
              </div>
            </Grid>
            <Grid item xs={12}>
              <WFLogTable />
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

WebFilteringLogs.propTypes = {
  addRow: PropTypes.func.isRequired,
  removeRow: PropTypes.func.isRequired,
  initLogs: PropTypes.func.isRequired,
  updateRange: PropTypes.func.isRequired,
  updateQuery: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  filterValues: PropTypes.array.isRequired,
  clearFilter: PropTypes.func.isRequired,
};

const styles = {
  timeOptionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  queryButton: {
    margin: 0,
    height: '40px',
    minWidth: '85px',
  },
};

export default withStyles(styles, { name: 'WebFilteringLogs' })(
  WebFilteringLogs
);
