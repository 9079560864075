import { connect } from 'react-redux';
import GettingStarted from '../../components/basic_filtering/GettingStarted';
import { updateIPType } from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  type: state.basic_filtering.ipType,
});

const mapDispatchToProps = dispatch => ({
  updateIPType: type => {
    dispatch(updateIPType(type));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GettingStarted);
