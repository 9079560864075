import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { capitalizeFirstLetter } from '../../lib/Utils';

const styles = theme => ({
  tooltip: {
    backgroundColor: 'rgba(0,0,0,0.9)',
    boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)',
    color: '#FFFFFF',
    fontSize: '12px',
    lineHeight: '16px',
    height: '32px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    padding: '0px 10px 0px 10px',
  },
});

const CustomizedTooltip = ({ classes, label, payload, units }) => {
  let display = false;
  let unit_label = units;
  if (payload && payload.length > 0) {
    display = true;
    if (!unit_label) {
      unit_label = capitalizeFirstLetter(payload[0]['payload']['name']);
    }
  }
  return (
    <div className={classes.tooltip}>
      {display ? (
        <p className="label">{`${payload[0].value} ${unit_label}`}</p>
      ) : null}
    </div>
  );
};

CustomizedTooltip.propTypes = {
  graphType: PropTypes.string,
};

export default withStyles(styles, { name: 'CustomizedTooltip' })(
  CustomizedTooltip
);
