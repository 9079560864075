import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  link: {
    color: '#0088CF',
    textDecoration: 'none',
  },
  disabled: {
    color: 'rgba(0,0,0,0.4)',
  },
};

const CudaLink = ({ classes, sheet, children, disabled = false, ...other }) => {
  return disabled ? (
    <span className={classes.disabled}>{children}</span>
  ) : (
    <NavLink className={classes.link} {...other}>
      {children}
    </NavLink>
  );
};

export default withStyles(styles, { name: 'CudaLink' })(CudaLink);
