import { connect } from 'react-redux';
import Table from '../../components/basic_filtering/Table';

import {
  openCategory,
  editCategoryPolicy,
  openException,
  editExceptionPolicy,
  openLocation,
  editLocationPolicy,
  removeLocationPolicy,
  checkTraffic,
  setAllTrafficAsNotSeen,
  showDynamicModal,
  hideDynamicModal,
} from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  loading: state.basic_filtering.loading,
  policies: state.basic_filtering.policies,
  query: state.basic_filtering.query,
  timezone: state.account.time_zone,
  locationConfig: state.basic_filtering.locationConfig,
  showDynamicIPModal: state.basic_filtering.showDynamicIPModal,
  highlighted: state.basic_filtering.highlightedLocation,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openCategory: (config, id) => {
    dispatch(openCategory(config, id));
  },
  showDynamicModal: id => {
    dispatch(showDynamicModal(id));
  },
  hideDynamicModal: () => {
    dispatch(hideDynamicModal());
  },
  editCategory: () => {
    dispatch(editCategoryPolicy());
  },
  openException: (config, id) => {
    dispatch(openException(config, id));
  },
  editException: () => {
    dispatch(editExceptionPolicy());
  },
  openLocation: config => {
    dispatch(openLocation(config));
  },
  editLocation: () => {
    dispatch(editLocationPolicy());
  },
  removeLocation: config => {
    dispatch(removeLocationPolicy(config));
  },
  checkTraffic: locationIds => {
    dispatch(checkTraffic(locationIds));
  },
  setAllTrafficAsNotSeen: () => {
    dispatch(setAllTrafficAsNotSeen());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
