import React, { Component } from 'react';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';

import SectionHeader from '../components/SectionHeader';
import CircularProgress from '@material-ui/core/CircularProgress';

import { WarningIcon } from './icons';

const trialStyle = {
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  loadingBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  error: {
    display: 'flex',
  },
};

class ActivateTrialComponent extends Component {
  componentDidMount() {
    const { initData } = this.props;
    initData();
  }

  render() {
    const { classes, success, error, redirect, history } = this.props;

    if (redirect) {
      history.push('/login');
    }

    if (success) {
      history.push('/dashboard');
    }

    return (
      <DocumentTitle title={translate('shared.loading')}>
        <Grid container spacing={2} className={classes.loading}>
          {!error && (
            <div className={classes.loadingBox}>
              <CircularProgress />
              <Translate value="shared.prepare" />
            </div>
          )}
          {error && (
            <div className={classes.error}>
              <WarningIcon width="50px" height="50px" large={true} />
              <Translate value="shared.onboardFail" />
            </div>
          )}
        </Grid>
      </DocumentTitle>
    );
  }
}

export default withStyles(trialStyle, { name: 'MSPDashboard' })(
  ActivateTrialComponent
);
