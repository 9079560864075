import { connect } from 'react-redux';
import EveryoneEditPolicyModal from '../../components/advanced_filtering/EveryoneEditPolicyModal';

import {
  editEveryoneRule,
  closeEveryoneRule,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  return {
    disabled: false,
    loading: state.advanced_filtering.everyoneRuleModal.loading,
    showModal: state.advanced_filtering.everyoneRuleModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    dispatch(editEveryoneRule());
  },
  closeDialog: () => {
    dispatch(closeEveryoneRule());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EveryoneEditPolicyModal);
