import { connect } from 'react-redux';
import TimeSelect from '../../components/dashboard/TimeSelection';
import {
  updateTlsVersionTimeframe,
  updateProtocolTimeframe,
  updateProcessTimeframe,
} from '../../actions/dashboard';

const options = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

const mapStateToProps = (state, ownProps) => ({
  options: options,
  selectedIndex: state.dashboard.fileTypeSelectIndex,
  selectedTlsIndex: state.dashboard.tlsSelectIndex,
  selectedProtocolIndex: state.dashboard.protocolSelectIndex,
  selectedProcessIndex: state.dashboard.processSelectIndex,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onTlsChange: index => {
    dispatch(updateTlsVersionTimeframe(options[index].key, index));
  },
  onProcessChange: index => {
    dispatch(updateProcessTimeframe(options[index].key, index));
  },
  onProtocolChange: index => {
    dispatch(updateProtocolTimeframe(options[index].key, index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSelect);
