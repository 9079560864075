import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../components/ChipInput';
import { updateAccountOption } from '../actions/exempt_policies';
import { getCopyStrNetworkExclusions } from '../selectors/exempt_policies';

const mapStateToProps = (state, ownProps) => ({
  inputValue:
    state.exemptPolicies.changes.networkExclusions ||
    state.exemptPolicies.fields.networkExclusions,
  copyStr: getCopyStrNetworkExclusions(state),
  selectedIndex:
    state.exemptPolicies.changes.networkExclusionsSelected ||
    state.exemptPolicies.fields.networkExclusionsSelected,
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  onSelectIndex: index => {
    dispatch(
      updateAccountOption({
        networkExclusionsSelected: index,
      })
    );
  },
  onChange: value => {
    dispatch(
      updateAccountOption({
        networkExclusions: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
