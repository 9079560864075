import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import classNames from 'classnames';

import { MenuArrowIcon } from './icons';

const styles = theme => ({
  root: {
    background: theme.palette.background.paper,
  },
  label: {
    fontSize: 14,
    paddingRight: 12,
    paddingLeft: 12,
    lineHeight: '18px',
  },
  topLabel: {
    padding: '4px 0',
  },
  menu: {
    paddingRight: '4px',
    paddingLeft: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    border: '1px solid #DDDDDD',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    borderRadius: '2px',
    position: 'relative',
  },
  menuIcon: {
    display: 'inline-block',
    float: 'left',
    marginLeft: '-4px',
    marginRight: '8px',
  },
  disabled: {
    cursor: 'default',
    background: '#DDD',
  },
  labelClass: {
    height: '24px',
    backgroundColor: '#DDDDDD',
    fontSize: '0.75rem',
    lineHeight: '1.5rem',
    color: 'rgba(0, 0, 0, 0.7)',
    padding: '0px 12px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  menuListClass: {
    padding: 0,
    overflow: 'scroll',
  },
});

class CudaMenu extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClickListItem = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    if (index !== this.props.selectedIndex) {
      this.props.onChange(index, this.props.options[index]);
    }
    this.setState({ open: false });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      options,
      customClasses,
      staticLabel,
      icon,
      customStyle,
      selectedIndex,
      disabled,
      height = 32,
      dataTestID = 'cudaMenu',
      ...others
    } = this.props;
    const rootClass = classNames(...customClasses, classes.root);
    const topLabelClass = classNames(classes.label);
    const lineHeight = `${height - 8}px`;

    let menuClasses = {};
    menuClasses[classes.menu] = true;
    menuClasses[classes.disabled] = disabled;
    if (customStyle) {
      menuClasses[customStyle] = true;
    }
    const menuClass = classNames(menuClasses);

    return (
      <div
        data-test-id={dataTestID}
        id="list-select"
        className={rootClass}
        style={{ height: height }}
      >
        <List disablePadding={true}>
          <ListItem
            dense={true}
            button={false}
            aria-haspopup="true"
            aria-controls="time-menu"
            onClick={disabled ? undefined : this.handleClickListItem}
            style={{ height: height }}
            className={menuClass}
          >
            <ListItemText
              disableTypography={true}
              style={{ height: height, lineHeight: lineHeight }}
              className={topLabelClass}
              primary={
                <div className={classes.topLabel}>
                  {icon ||
                    (options[selectedIndex] && options[selectedIndex].icon)}
                  <span>
                    {staticLabel ||
                      (options[selectedIndex] && options[selectedIndex].label)}
                  </span>
                </div>
              }
            />
            <ListItemIcon style={{ minWidth: '0px' }}>
              <MenuArrowIcon />
            </ListItemIcon>
          </ListItem>
        </List>
        <Menu
          {...others}
          data-test-id="time-menu"
          id="time-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          getContentAnchorEl={null}
          MenuListProps={{
            className: classes.menuListClass,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {options.map((option, index) => {
            let response;

            if (option.hide) {
              response = null;
            } else if (option.type && option.type === 'label') {
              response = (
                <div className={classes.labelClass} key={option.key}>
                  {option.label}
                </div>
              );
            } else {
              response = (
                <MenuItem
                  data-test-id="menuItem"
                  id={`menu-item-${option.key}`}
                  key={option.key}
                  className={classes.label}
                  style={{ height }}
                  selected={index === selectedIndex}
                  onClick={event => this.handleMenuItemClick(event, index)}
                >
                  {option.icon}
                  {option.label}
                </MenuItem>
              );
            }

            return response;
          })}
        </Menu>
      </div>
    );
  }
}

CudaMenu.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.array,
  selectedIndex: PropTypes.number.isRequired,
};

CudaMenu.defaultProps = {
  customClasses: [],
};
export default withStyles(styles)(CudaMenu);
