import { connect } from 'react-redux';
import SyslogModal from '../../components/smb_account_settings/SyslogModal';

import {
  getSyslogInfo,
  syslogErrors,
  toggleConnection,
  updateSyslogConfig,
  saving,
  helperTextAddress,
  helperTextPort,
} from '../../actions/smb_account_settings';

const mapStateToProps = state => ({
  syslogConfig: {
    ...state.smbAccountSettings.syslogConfig,
    ...state.smbAccountSettings.syslogChanges,
  },
  saving:
    Boolean(Object.keys(state.smbAccountSettings.syslogChanges).length) &&
    (state.smbAccountSettings.syslogChanges.address ||
      state.smbAccountSettings.syslogConfig.address) &&
    state.smbAccountSettings.syslogChanges.address !== '' &&
    state.smbAccountSettings.syslogChanges.port !== '',
  syslogErrors: state.smbAccountSettings.logoErrors,
  syslogType: state.smbAccountSettings.syslogType,
  syslogConnect: state.smbAccountSettings.syslogConnect,
  syslogExists: state.smbAccountSettings.syslogExists,
  helperTextAddress: state.smbAccountSettings.helperTextAddress,
  helperTextPort: state.smbAccountSettings.helperTextPort,
  processingSyslog: state.smbAccountSettings.processingSyslog,
  accountId: state.account.selected.toString(),
});

const mapDispatchToProps = dispatch => ({
  toggleSyslogEnabled: field => {
    dispatch(updateSyslogConfig({ enabled: field.target.checked }));
  },
  updateSyslogAddress: field => {
    dispatch(updateSyslogConfig({ address: field.target.value }));
  },
  updateSyslogPort: field => {
    dispatch(updateSyslogConfig({ port: field.target.value }));
  },
  selectSyslogType: select => {
    dispatch(updateSyslogConfig({ type: select.value }));
  },
  toggleSyslogConnect: () => {
    dispatch(toggleConnection());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyslogModal);
