import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { ChevronDoubleDownIcon, ChevronDoubleUpIcon } from '../icons';

const ExpandIcon = ({ classes, expanded, toggleExpand, icons, ...others }) => (
  <span className={classes.iconWrapper}>
    {icons}
    {expanded ? (
      <ChevronDoubleUpIcon
        className={classes.toggleIcon}
        onClick={toggleExpand}
        {...others}
      />
    ) : (
      <ChevronDoubleDownIcon
        className={classes.toggleIcon}
        onClick={toggleExpand}
        {...others}
      />
    )}
  </span>
);

ExpandIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  toggleExpand: PropTypes.func.isRequired,
  icons: PropTypes.element,
};

ExpandIcon.defaultProps = {
  icons: null,
};

const styles = theme => ({
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      cursor: 'pointer',
    },
  },
  toggleIcon: {
    color: theme.colors.blueAction,
  },
});

export default withStyles(styles, { name: 'ExpandIcon' })(ExpandIcon);
