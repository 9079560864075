import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../../components/advanced_filtering/ChipInput';

import { onChangeUrl, selectIndex } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  name: translate('components.advancedFiltering.urls'),
  copyStr: state.advanced_filtering.ruleModal.urls.toString(),
  inputValue: state.advanced_filtering.ruleModal.urls,
  selectedIndex: state.advanced_filtering.ruleModal.inputIndex,
  error: state.advanced_filtering.selectedRule.error,
});

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(onChangeUrl(value));
  },
  onSelectIndex: index => {
    dispatch(selectIndex(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
