import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Translate } from 'react-i18nify';

import ADGroupSelection from '../../containers/advanced_filtering/ADGroupSelection';
import SettingsItem from '../settings/SettingsItem';

const UrlLogging = ({
  classes,
  disabledAD,
  toggleUrlLogging,
  enableUrlLogging,
}) => (
  <Grid id="add-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.levelContainer}>
      <ADGroupSelection disabled={disabledAD} />
    </Grid>
    <Grid item xs={12} className={classes.addContainer}>
      <SettingsItem
        text={
          <Translate
            value="components.advancedFiltering.fullUrlLogging"
            className={classes.label}
          />
        }
        toggled={enableUrlLogging}
        onToggle={toggleUrlLogging}
        padding={false}
        disabled={false}
        labels={['components.shared.on', 'components.shared.off']}
      />
    </Grid>
  </Grid>
);

UrlLogging.propTypes = {
  classes: PropTypes.object.isRequired,
  disabledAD: PropTypes.bool,
  toggleUrlLogging: PropTypes.func.isRequired,
};

UrlLogging.defaultProps = {};

const styles = theme => ({
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
});

export default withStyles(styles, { name: 'UrlLogging' })(UrlLogging);
