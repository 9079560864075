import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HelpTooltipIcon from '../HelpTooltipIcon';

const SectionTitle = ({ classes, tooltipText, titleText, tooltipTestId }) => (
  <span className={classes.title}>
    {titleText}
    {tooltipText && (
      <HelpTooltipIcon
        tooltipText={tooltipText}
        className={classes.icon}
        testId={tooltipTestId}
      />
    )}
  </span>
);

SectionTitle.propTypes = {
  classes: PropTypes.object.isRequired,
  titleText: PropTypes.string.isRequired,
  tooltipTestId: PropTypes.string,
};
SectionTitle.defaultProps = {
  tooltipTestId: '',
};

const styles = {
  title: {
    lineHeight: '24px',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    height: '20px',
    marginLeft: '8px',
  },
};

export default withStyles(styles, { name: 'SectionTitle' })(SectionTitle);
