import { connect } from 'react-redux';
import Notifications from '../components/Notifications';
import { get, startCreate, hideModal } from '../actions/notifications';
import { getFilteredOptions } from '../selectors/notifications';

const mapStateToProps = (state, ownProps) => ({
  showCreateModal: state.notifications.openModal === 'create',
  showEditModal: state.notifications.openModal === 'edit',
  options: getFilteredOptions(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  showCreate: () => {
    dispatch(startCreate());
  },
  hideModal: () => {
    dispatch(hideModal());
  },
  initData: () => {
    dispatch(get());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
