import { connect } from 'react-redux';
import DynamicIPUpdater from '../../components/basic_filtering/DynamicIPUpdater';

const mapStateToProps = state => ({
  account_id: state.account.selected,
  locationId: state.basic_filtering.selectedLocation,
  version: state.basic_filtering.catMapping.wcsVersion || '2.0',
  demo: state.account.demo,
});

export default connect(
  mapStateToProps,
  undefined
)(DynamicIPUpdater);
