import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

const ActionLabel = ({ classes, label, subtext }) => (
  <div>
    <div className={classes.label}>{label}</div>
    <div className={classes.subtext}>{subtext}</div>
  </div>
);
ActionLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  subtext: PropTypes.string.isRequired,
};

const styles = {
  label: {
    fontSize: '16px',
    lineHeight: '19px',
    marginBottom: '8px',
    display: 'inline-block',
  },
  subtext: {
    color: '#999999',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    height: '14px',
  },
};

export default withStyles(styles, { name: 'ActionLabel' })(ActionLabel);
