import { connect } from 'react-redux';
import Protocol from '../../components/dashboard/Protocol';
import { timeOptions } from '../../actions/dashboard';

const mapStateToProps = (state, ownProps) => ({
  options: timeOptions,
  selectedIndex: state.dashboard.protocolSelectIndex,
  data: state.dashboard.protocolData,
  loading: state.dashboard.protocolLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Protocol);
