import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import ExpandIcon from './ExpandIcon';

class ExpandableRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  state = {
    expanded: true,
  };

  toggleExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const { classes, header, body, icons, ...others } = this.props;
    const { expanded } = this.state;

    return (
      <div
        className={classNames(classes.wrapper, {
          [classes.expanded]: expanded,
        })}
        {...others}
      >
        <div>
          <div className={classes.header}>
            <div className={classes.smallDiv}>
              <ExpandIcon
                data-test-id="expand-icon"
                expanded={expanded}
                toggleExpand={this.toggleExpand}
                icons={icons}
              />
            </div>
            {header}
          </div>
        </div>
        {expanded && body}
      </div>
    );
  }
}

ExpandableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.element.isRequired,
  body: PropTypes.element.isRequired,
  icons: PropTypes.element,
};

ExpandableRow.defaultProps = {
  icons: null,
};

const styles = {
  wrapper: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
  },
  expanded: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  smallDiv: {
    flex: '0 0 50px',
    justifyContent: 'space-evenly',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    color: 'rgba(0, 0, 0, 0.50)',
    fontSize: '12px',
    fontWeight: 500,
    '& > div': {
      display: 'inline-flex',
      padding: '0 4px',
      '&:last-child': {
        flex: '1 1',
        alignItems: 'center',
        paddingRight: 0,
        textAlign: 'center',
      },
    },
  },
};

export default withStyles(styles, { name: 'ExpandableRow' })(ExpandableRow);
