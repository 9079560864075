import { connect } from 'react-redux';
import EditSharedThreatPolicy from '../../components/shared_policies/EditSharedThreatPolicy';

import { updateSharedOptions } from '../../actions/shared_policies';

const mapStateToProps = (state, ownProps) => {
  return {
    display: false,
    fields: {
      ...state.shared_policies.editFields,
      ...state.shared_policies.changes,
    },
  };
};

const mapDispatchToProps = dispatch => ({
  updateSetting: (name, value) => {
    dispatch(
      updateSharedOptions({
        [name]: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSharedThreatPolicy);
