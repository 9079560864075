import React from 'react';
import PropTypes from 'prop-types';
import './CudaToggle.css';

const CudaToggle = ({ name, text, value, selected, selectRadio, icon }) => {
  let toggleClassName = 'cuda-side-radio-toggle';
  if (selected) {
    toggleClassName += ' selected';
  }

  return (
    <span className="cuda-side-radio-wrapper">
      <span className={toggleClassName}>
        {icon}
        {text || value}
      </span>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={selectRadio}
        checked={selected}
      />
    </span>
  );
};

CudaToggle.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  value: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  selectRadio: PropTypes.func.isRequired,
  icon: PropTypes.element,
};

export default CudaToggle;
