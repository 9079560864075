import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import CudaTableTitle from '../CudaTableTitle';
import DownloadOptions from '../../containers/reports/DownloadOptions';
import EPSTableBody from '../table/TableBody';
import PaginatorComponent from '../Paginator';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
    overflowY: 'hidden',
    margin: 0,
  },
  dateColumn: {
    width: '20%',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pathColumn: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fileColumn: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  tableTitle: {
    '& > th': {
      borderTop: '1px solid #DDDDDD',
    },
  },
  reportTableBody: {
    '& > tr': {
      height: '40px',
    },
  },
});

class TableComponent extends Component {
  render() {
    const {
      classes,
      entries,
      headers,
      entryKeys,
      sortIndex,
      sortDirection,
      selected,
      toggle,
      loading,
    } = this.props;

    const orderBy = entryKeys[sortIndex];
    let data = [...entries];
    const dataDisplay = data.sort((a, b) => {
      return sortDirection[sortIndex] === 'desc'
        ? b[orderBy] < a[orderBy]
          ? -1
          : 1
        : a[orderBy] < b[orderBy]
        ? -1
        : 1;
    });

    return (
      <div className={classes.root}>
        <div>
          <PaginatorComponent
            grayBackground={true}
            showPaginationOptions={false}
            options={<DownloadOptions />}
          />
        </div>
        <Table>
          <TableHead>
            <TableRow className={classes.tableTitle}>
              {headers.map((n, i) => (
                <TableCell key={i}>
                  <CudaTableTitle
                    value={n}
                    sort={true}
                    toggle={sortDirection[i]}
                    onClick={() => {
                      toggle(selected, i);
                    }}
                  />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <EPSTableBody
            loading={loading}
            className={classes.reportTableBody}
            colCount={headers.length}
            rowCount={dataDisplay.length}
          >
            {!loading &&
              dataDisplay.map((n, i) => {
                return (
                  <TableRow key={i}>
                    {entryKeys.map((key, index) => (
                      <TableCell key={`${i}-${index}`}>{n[key]}</TableCell>
                    ))}
                  </TableRow>
                );
              })}
            {!loading && dataDisplay.length === 0 ? (
              <TableRow>
                <TableCell
                  className={classes.noResults}
                  colSpan={headers.length}
                >
                  <Translate value="shared.noResults" />
                </TableCell>
              </TableRow>
            ) : null}
          </EPSTableBody>
        </Table>
      </div>
    );
  }
}

TableComponent.propTypes = {
  entries: PropTypes.array.isRequired,
};

export default withStyles(styles, { name: 'ReportsTableComponent' })(
  TableComponent
);
