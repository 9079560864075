import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ScheduleModal from '../../containers/atp_policy/ScheduleScanModal';
import CudaMenu from '../CudaMenu';
import Paper from '../CudaPaper';

const styles = {
  headers: {
    height: '14px',
    width: '64px',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
  },
  paper: {
    padding: '20px',
    display: 'flex',
    alignItems: 'flex-end',
    flexWrap: 'wrap',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '24px',
    margin: '0 0 0 16px',
    padding: '8px 21px',
    fontWeight: '300',
  },
  scanLabel: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'enter',
    marginTop: '20px',
    textAlign: 'center',
  },
  timeStatus: {
    fontSize: '18px',
    fontWeight: 300,
    lineHeight: '24px',
    textAlign: 'enter',
    marginTop: '20px',
  },
  scanBorder: {
    lineHeight: '64px',
  },
  control: {
    paddingRight: '15px',
    textAlign: 'right',
  },
};

class ScheduleScan extends Component {
  render() {
    const {
      classes,
      timeOptions,
      userOptions,
      locationOptions,
      mainSelectHandler,
      timeSelectHandler,
      executeHandler,
      locationSelectHandler,
      locationSelectIndex,
      userSelectHandler,
      userSelectIndex,
      mainSelectIndex,
      timeSelectIndex,
      openScheduleModal,
      reportSelect,
      timeSelect,
      rows,
      index,
      lastScan,
      scanTime,
      runScan,
      scheduleLabel,
      scanEnabled,
      loading,
      disabled,
      wca,
      features,
    } = this.props;
    return (
      <Paper className={classes.scanBorder} loading={loading}>
        {wca ? (
          <Grid container>
            <Grid item xs={6} md={3} lg={2} className={classes.scanLabel}>
              <Translate
                value="components.atp.scheduleScan"
                className={classes.scanLabel}
              />
              :
            </Grid>
            <Grid item xs={4} md={3} lg={4} className={classes.timeStatus}>
              {!loading ? scheduleLabel : null}
            </Grid>
            <Grid item xs={4} md={6} lg={6} className={classes.control}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  openScheduleModal();
                }}
                className={classes.reportBtn}
                disabled={disabled}
              >
                {scanTime == '' || !scanEnabled ? (
                  <Translate value="shared.schedule" />
                ) : (
                  scanTime
                )}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  runScan();
                }}
                className={classes.reportBtn}
                disabled={disabled}
              >
                <Translate value="shared.runNow" />
              </Button>
            </Grid>
          </Grid>
        ) : null}
        <ScheduleModal />
      </Paper>
    );
  }
}

ScheduleScan.propTypes = {
  classes: PropTypes.object.isRequired,
  init: PropTypes.func.isRequired,
  openScheduleModal: PropTypes.func.isRequired,
};

export default withStyles(styles)(ScheduleScan);
