import { connect } from 'react-redux';
import UserMenu from '../../components/user_authentication/UserMenu';
import { openModal } from '../../actions/user_authentication';

const mapDispatchToProps = dispatch => ({
  editUser: user => {
    dispatch(openModal('editLocalUser', user));
  },
  moveUser: (user, groupID) => {
    dispatch(
      openModal('moveLocalUser', {
        ...user,
        groupID,
      })
    );
  },
  removeUser: (user, group) => {
    dispatch(
      openModal('removeFromGroup', {
        user,
        group,
      })
    );
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(UserMenu);
