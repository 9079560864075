import 'react-day-picker/lib/style.css';
import { ActionButton, LoadingDialogContent } from './CudaDialog';
import DayPicker, { DateUtils } from 'react-day-picker';
import React, { Component } from 'react';

import { ButtonLink } from './CudaLink';
import { CalanderIcon } from '../icons';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import MomentUtils from '@date-io/moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { Typography } from '@material-ui/core';
export const quickRangeOptions = {
  r_1h: {
    variableName: 'r_1h',
    text: translate('components.dateRangePicker.lastHour'),
  },
  r_24h: {
    variableName: 'r_24h',
    text: translate('components.dateRangePicker.last24hours'),
  },
  r_7d: {
    variableName: 'r_7d',
    text: translate('components.dateRangePicker.last7days'),
  },
  r_14d: {
    variableName: 'r_14d',
    text: translate('components.dateRangePicker.last14days'),
  },
  r_30d: {
    variableName: 'r_30d',
    text: translate('components.dateRangePicker.last30days'),
  },
};

const style = {
  title: {
    fontSize: '22px',
    lineHeight: '24px',
    padding: '15px 20px',
  },
  range: {
    '& .DayPicker-Day': {
      borderRadius: '0 !important',
    },
    '& .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside)': {
      backgroundColor: '#f0f8ff!important',
      color: '#4a90e2',
    },
  },
  quickRange: {
    fontSize: '14px',
    alignSelf: 'flex-start',
    marginTop: '20px',
    borderLeft: '1px solid #dddddd',
    '& div': {
      marginLeft: '15px',
    },
  },
  quickRangeTitle: {
    paddingTop: '0px !important',
    color: 'rgba(0,0,0,0.87)',
  },
  quickRangeLastItem: {
    marginBottom: '45px',
  },
  textField: {
    '& input': {
      padding: '0 10px',
    },
    '& input[type="time"]::-webkit-clear-button': {
      display: 'none',
    },
    '& input[type="time"]::-ms-clear': {
      display: 'none',
    },
    marginRight: 10,
  },
  textFieldWrapper: {
    marginLeft: '30px',
    lineHeight: '40px',
  },
  pickerTypography: {
    color: 'red',
  },
};

const START_TIME = moment().set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});
const END_TIME = moment().set({
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0,
});

class DateRangePicker extends Component {
  constructor(props) {
    super(props);
    this.oldestDayLogged = props.fromMonth ? props.fromMonth : props.from; //new Date(Date.now() - 24 * 3600 * 1000 * 45);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.cancel = this.cancel.bind(this);
    this.apply = this.apply.bind(this);
    this.changeFromTime = this.changeFromTime.bind(this);
    this.changeToTime = this.changeToTime.bind(this);
    this.lastMinutes = this.lastMinutes.bind(this);
    this.state = {
      fromMonth: props.fromMonth ? props.fromMonth : props.from,
      from: props.from,
      to: props.to,
      fromTime: props.from ? props.from : START_TIME,
      toTime: props.to ? props.to : END_TIME,
      enteredTo: props.enteredTo,
    };
  }

  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
      quickRange: null,
    };
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  isOutOfAllowedDateRange(day) {
    return (
      DateUtils.isDayBefore(day, this.oldestDayLogged) ||
      DateUtils.isDayAfter(day, new Date(Date.now()))
    );
  }

  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isOutOfAllowedDateRange(day)) return;
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
        quickRange: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
        quickRange: null,
      });
    }
  }

  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  cancel() {
    this.props.close();
  }

  timedDate(date, time) {
    const { timezone } = this.props;
    const t = moment(time, 'hh:mm');

    const d = moment(date).tz(timezone);
    d.set({
      hour: t.hours(),
      minute: t.minutes(),
    });
    return d.utc().toDate();
  }

  apply() {
    const from = this.timedDate(this.state.from, this.state.fromTime);
    let to = this.timedDate(this.state.from, this.state.toTime);
    if (this.state.to !== null) {
      to = this.timedDate(this.state.to, this.state.toTime);
    }
    if (!isNaN(from.getTime()) && !isNaN(to.getTime())) {
      const fromMonth = this.state.fromMonth;
      this.props.setDateRange({
        fromMonth,
        from: from,
        to: to,
        enteredTo: this.state.enteredTo,
      });

      this.props.apply();
    } else {
      //do nothing
    }
  }

  changeFromTime(date) {
    this.setState({ fromTime: date });
  }

  changeToTime(date) {
    this.setState({ toTime: date });
  }

  lastMinutes(minutes) {
    const { timezone } = this.props;
    const now = new Date();
    const from = new Date(now.getTime() - minutes * 60 * 1000);
    let quickRange;
    switch (minutes) {
      case 60:
        quickRange = quickRangeOptions.r_1h.variableName;
        break;
      case 24 * 60:
        quickRange = quickRangeOptions.r_24h.variableName;
        break;
      case 7 * 24 * 60:
        quickRange = quickRangeOptions.r_7d.variableName;
        break;
      case 14 * 24 * 60:
        quickRange = quickRangeOptions.r_14d.variableName;
        break;
      case 30 * 24 * 60:
        quickRange = quickRangeOptions.r_30d.variableName;
        break;
      default:
        quickRange = quickRangeOptions.r_1h.variableName;
    }

    this.setState({
      from: from,
      fromTime: moment(from)
        .tz(timezone)
        .format('HH:mm'),
      to: now,
      toTime: moment(now)
        .tz(timezone)
        .format('HH:mm'),
      enteredTo: now,
      quickRange: quickRange,
    });

    const fromTime = moment(from, 'hh:mm');
    const nowTime = moment(now, 'hh:mm');
    const fromUtc = moment(from).tz(timezone);
    const nowUtc = moment(now).tz(timezone);
    fromUtc.set({
      hour: fromTime.hours(),
      minute: fromTime.minutes(),
    });
    nowUtc.set({
      hour: nowTime.hours(),
      minute: nowTime.minutes(),
    });
    this.props.setDateRange({
      from: fromUtc.utc().toDate(),
      to: nowUtc.utc().toDate(),
      enteredTo: now,
      quickRange: quickRange,
    });
  }

  render() {
    const { classes, open, timezone } = this.props;
    const { fromMonth, from, to, enteredTo, fromTime, toTime } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = {
      before: fromMonth, //new Date(Date.now() - 24 * 3600 * 1000 * 45),
      after: new Date(Date.now()),
    };
    const selectedDays = [from, { from, to: enteredTo }];
    const current = new Date();
    const utcString = `${timezone}`;
    return (
      <Dialog
        open={open}
        transitionDuration={0}
        maxWidth="lg"
        onClose={this.cancel}
      >
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <LoadingDialogContent>
            <Grid container spacing={0} alignItems="center">
              <Grid item xs={12} className={classes.title}>
                {translate('components.dateRangePicker.title')}
              </Grid>
              <Grid item xs={10}>
                <DayPicker
                  className={classes.range}
                  numberOfMonths={3}
                  fromMonth={fromMonth}
                  toMonth={current}
                  selectedDays={selectedDays}
                  disabledDays={disabledDays}
                  modifiers={modifiers}
                  onDayClick={this.handleDayClick}
                  onDayMouseEnter={this.handleDayMouseEnter}
                />
              </Grid>
              <Grid
                item
                xs={2}
                container
                spacing={2}
                className={classes.quickRange}
              >
                <Grid item xs={12} className={classes.quickRangeTitle}>
                  {translate('components.dateRangePicker.quickRange')}
                </Grid>
                <Grid item xs={12}>
                  <ButtonLink onClick={() => this.lastMinutes(60)}>
                    {translate('components.dateRangePicker.lastHour')}
                  </ButtonLink>
                </Grid>
                <Grid item xs={12}>
                  <ButtonLink onClick={() => this.lastMinutes(24 * 60)}>
                    {translate('components.dateRangePicker.last24hours')}
                  </ButtonLink>
                </Grid>
                <Grid item xs={12}>
                  <ButtonLink onClick={() => this.lastMinutes(7 * 24 * 60)}>
                    {translate('components.dateRangePicker.last7days')}
                  </ButtonLink>
                </Grid>
                <Grid item xs={12}>
                  <ButtonLink onClick={() => this.lastMinutes(14 * 24 * 60)}>
                    {translate('components.dateRangePicker.last14days')}
                  </ButtonLink>
                </Grid>
                <Grid item xs={12} className={classes.quickRangeLastItem}>
                  <ButtonLink onClick={() => this.lastMinutes(30 * 24 * 60)}>
                    {translate('components.dateRangePicker.last30days')}
                  </ButtonLink>
                </Grid>
              </Grid>

              <Grid
                item
                xs={4}
                container
                justify="center"
                className={classes.textFieldWrapper}
              >
                <Grid item xs={3}>
                  {translate('components.dateRangePicker.startTime')}:
                </Grid>
                <Grid item xs={3}>
                  <KeyboardTimePicker
                    ampm={false}
                    value={moment(fromTime, 'HH:mm')}
                    onChange={this.changeFromTime}
                  />
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="center"
                  xs={6}
                >
                  <Typography>{utcString}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={3} />
              <Grid
                item
                xs={4}
                container
                justify="center"
                className={classes.textFieldWrapper}
              >
                <Grid item xs={3}>
                  {translate('components.dateRangePicker.endTime')} :
                </Grid>
                <Grid item xs={3}>
                  <KeyboardTimePicker
                    ampm={false}
                    value={moment(toTime, 'HH:mm')}
                    onChange={this.changeToTime}
                  />
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justify="center"
                  xs={6}
                >
                  <Typography>{utcString}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </LoadingDialogContent>
          <DialogActions>
            <ActionButton onClick={this.cancel} size="large">
              {translate('common.cancel')}
            </ActionButton>
            <ActionButton
              color="primary"
              size="large"
              onClick={this.apply}
              disabled={!from || !to || !fromTime || !toTime}
            >
              {translate('common.apply')}
            </ActionButton>
          </DialogActions>
        </MuiPickersUtilsProvider>
      </Dialog>
    );
  }
}

export default withStyles(style, { name: 'DateRangePicker' })(DateRangePicker);

DateRangePicker.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

const dateRangeBoxStyle = {
  containerLabelSpace: {
    marginTop: '16px',
  },
  containerMedium: {
    height: '32px',
  },
  containerLarge: {
    height: '40px',
  },
  container: {
    cursor: 'pointer',
    border: '1px solid #ced4da',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '16px',
    outline: 'none',
    borderRadius: '2px',
  },
  dateDiv: {
    padding: '0 !important',
  },
  hypDiv: {
    padding: '0 !important',
    width: '10px',
  },
  iconDiv: {
    padding: '0 !important',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
    position: 'absolute',
  },
};

let DateRangeBox = ({
  classes,
  from,
  to,
  quickRange,
  togglePicker,
  label,
  size,
  timezone,
}) => {
  const fromString = moment(from)
    .tz(timezone)
    .format('MMM DD, YYYY hh:mm a');
  const toString = moment(to)
    .tz(timezone)
    .format('MMM DD, YYYY hh:mm a');

  const outerClass = classNames({
    [classes.container]: true,
    [classes.containerMedium]: size !== 'large',
    [classes.containerLarge]: size === 'large',
    [classes.containerLabelSpace]: !!label,
  });

  return (
    <Grid container>
      {label && (
        <Grid item className={classes.label}>
          {label}
        </Grid>
      )}
      <Grid
        item
        container
        className={outerClass}
        alignItems="center"
        justify="center"
        onClick={togglePicker}
        onKeyPress={togglePicker}
        tabIndex="0"
        role="button"
      >
        <Grid item xs={1} className={classes.iconDiv}>
          <CalanderIcon />
        </Grid>
        <React.Fragment>
          <Grid item xs={5} className={classes.dateDiv}>
            {from && fromString}
          </Grid>
          <Grid item className={classes.hypDiv}>
            -
          </Grid>
          <Grid item xs={5} className={classes.dateDiv}>
            {to && toString}
          </Grid>
        </React.Fragment>
      </Grid>
    </Grid>
  );
};

DateRangeBox.propTypes = {
  from: PropTypes.object.isRequired,
  to: PropTypes.object.isRequired,
  quickRange: PropTypes.string,
  togglePicker: PropTypes.func,
  label: PropTypes.string,
  size: PropTypes.string,
};

DateRangeBox = withStyles(dateRangeBoxStyle, { name: 'DateRangeBox' })(
  DateRangeBox
);
export { DateRangeBox };
