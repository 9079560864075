import { connect } from 'react-redux';
import DownloadOptions from '../../components/reports/DownloadOptions';

import { downloadPdf, downloadCsv } from '../../actions/reports';

const mapStateToProps = (state, ownProps) => {
  const accounts = state.account.accounts;
  const selected = state.account.selected;

  let name = '';
  for (var i = 0; i < accounts.length; i++) {
    if (accounts[i].id === selected) {
      name = accounts[i].name;
      break;
    }
  }

  return {
    timezone: state.account.time_zone,
    accountName: name,
    accountId: selected,
    range: state.reports.timeSelect,
    reportType: state.reports.reportSelect,
    pdfDownloading: state.reports.pdf.loading,
    csvDownloading: state.reports.csv.loading,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadPdf: (reportType, range, timezone, name, title, rangeText) => {
    dispatch(downloadPdf(reportType, range, timezone, name, title, rangeText));
  },
  downloadCsv: (reportType, range) => {
    dispatch(downloadCsv(reportType, range));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadOptions);
