import { createSelector } from 'reselect';

const getPolicies = state => state.shared_policies.policies;
const getQuery = state => state.shared_policies.atp.applyQuery;
const getAllAccounts = state => state.account.accounts;

const getFilteredPolicies = createSelector(
  [getPolicies, getQuery, getAllAccounts],
  (policies, query, accounts) => {
    const accountNames = {};
    for (let i = 0; i < accounts.length; i++) {
      accountNames[Number(accounts[i]['id'])] = accounts[i]['name'];
    }
    const regex = new RegExp(query, 'ig');
    return policies.filter(p => {
      regex.lastIndex = 0; // Reset search index to beginnin
      let matchAccount = p.accounts.some(a => {
        regex.lastIndex = 0;
        return regex.exec(accountNames[Number(a)]);
      });
      regex.lastIndex = 0; // Reset search index to beginnin
      return regex.exec(p.name) || matchAccount;
    });
  }
);

export { getFilteredPolicies };
