import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input/Input';
import Editor from '../../containers/block_page/Editor';
import { CircleIcon } from '../icons';

const DefaultEditor = ({ classes, header, logo, updateHeader }) => (
  <div className={classes.browser}>
    <div className={classes.browserHeader}>
      <span className={classes.browserIcon} style={{ color: '#E34050' }}>
        <CircleIcon />
      </span>
      <span className={classes.browserIcon} style={{ color: '#5BA71B' }}>
        <CircleIcon />
      </span>
      <div className={classes.browserBar} />
    </div>
    <div className={classes.blockPage}>
      <img
        src={logo}
        style={{
          maxWidth: '240px',
          display: 'flex',
          visibility: logo ? 'visible' : 'hidden',
        }}
        alt="logo"
      />
      <div className={classes.messageBox}>
        <div className={classes.header}>
          <svg
            className={classes.icon}
            focusable="false"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
          </svg>
          <span className={classes.headerText}>
            <Input
              disableUnderline
              value={header}
              onChange={updateHeader}
              className={classes.headerTextValue}
            />
          </span>
        </div>
        <div className={classes.content}>
          <Editor />
        </div>
      </div>
    </div>
  </div>
);

const styles = {
  browser: {
    backgroundColor: '#DDDDDD',
    padding: '8px',
  },
  browserIcon: {
    display: 'flex',
    marginRight: '12px',
    '& > svg': {
      height: '16px',
      width: '16px',
    },
  },
  browserHeader: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px 0 16px 0',
  },
  browserBar: {
    display: 'flex',
    flex: 1,
    height: '20px',
    backgroundColor: '#FFFFFF',
    border: '1px solid rgba(0,0,0,0.3)',
    borderRadius: '15px',
  },
  blockPage: {
    backgroundColor: '#FFFFFF',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'center',
    display: 'flex',
  },
  messageBox: {
    margin: '24px',
    maxWidth: '600px',
    width: '100%',
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
    borderRadius: '2px',
  },
  header: {
    backgroundColor: '#E34050',
    padding: '24px',
    color: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '10px 24px 24px 24px',
  },
  icon: {
    height: '72px',
    width: '72px',
    fill: '#FFFFFF',
    marginRight: '24px',
  },
  headerText: {
    display: 'flex',
    flex: 1,
  },
  headerTextValue: {
    height: '20px',
    fontSize: '16px',
    lineHeight: '20px',
    backgroundColor: '#FFFFFF',
    flex: 1,
    padding: '10px',
    '& > input': {
      padding: 0,
      color: '#000000',
    },
  },
};

DefaultEditor.propTypes = {
  header: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  updateHeader: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'DefaultEditor' })(DefaultEditor);
