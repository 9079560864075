import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

import Timestamp from '../../containers/Timestamp';
const styles = {
  container: {
    height: '80px',
    backgroundColor: '#0088CE',
    color: '#FFFFFF',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
    marginTop: '5px',
  },
  title: {
    fontSize: '32px',
  },
  range: {
    fontSize: '14px',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    marginTop: '5px',
    fontSize: '14px',
    height: '100%',
  },
};

const Header = ({ classes, title, subTitle, accountName }) => {
  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={8}>
        <div className={classes.titleWrapper}>
          <div className={classes.title}>{title}</div>
          <div className={classes.range}>
            <Translate value={'shared.ranges.' + subTitle} />
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className={classes.infoWrapper}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Translate value={'components.dashboard.accountName'} />:
            </Grid>
            <Grid item xs={8}>
              {accountName}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Translate value={'components.reporting.generated'} />:
            </Grid>
            <Grid item xs={8}>
              <Timestamp timestamp={Date.now()} />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
};

export default withStyles(styles)(Header);
