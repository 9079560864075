import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import SplitButton from '../../components/SplitButton';

import { onChangeUrl, selectIndex } from '../../actions/advanced_filtering';

import {
  openAddRule,
  openSafesearch,
  openUrlLogging,
  selectButtonMenu,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  const options = [translate('components.advancedFiltering.create')];

  options.push(translate('components.advancedFiltering.safeSearch'));
  options.push(translate('components.advancedFiltering.fullUrlLogging'));

  return {
    options: options,
    align: 'flex-end',
    selectedIndex: state.advanced_filtering.mainButton.selectIndex,
  };
};

const mapDispatchToProps = dispatch => ({
  onClick: value => {
    dispatch([openAddRule, openSafesearch, openUrlLogging][value]());
  },
  menuClick: index => {
    dispatch(selectButtonMenu(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SplitButton);
