import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';

import RuleRow from './../../containers/advanced_filtering/RuleRow';

const styles = theme => ({
  table: {
    '& th': {
      lineHeight: '12px',
      fontSize: '12px',
      padding: '10px 4px',
      '&:last-child': {
        paddingRight: '0',
        textAlign: 'center',
      },
    },
    '& td': {
      padding: '10px 4px',
      fontSize: '12px',
      '&:last-child': {
        paddingRight: '0',
        textAlign: 'center',
      },
    },
  },
  head: {
    backgroundColor: '#EEEEEE',
    textTransform: 'uppercase',
    '& tr': {
      height: '32px',
    },
  },
  smallCell: {
    width: '50px',
  },
});

const RuleTable = ({
  classes,
  rules,
  index,
  id,
  users,
  groups,
  everyone,
  default_action,
  ...others
}) => (
  <Table className={classes.table} {...others} data-test-id="rule-table">
    <TableHead className={classes.head} data-test-id="rule-table-header">
      <TableRow>
        <TableCell style={{ width: '50px' }} />
        <TableCell style={{ width: '50px' }} data-test-id="rule-table-order">
          {translate('components.advancedFiltering.order')}
        </TableCell>
        <TableCell style={{ width: '90px' }} data-test-id="rule-table-action">
          {translate('components.advancedFiltering.action')}
        </TableCell>
        <TableCell style={{ width: '90px' }} data-test-id="rule-table-type">
          {translate('components.advancedFiltering.type')}
        </TableCell>
        <TableCell>rule</TableCell>
        <TableCell style={{ width: '50px' }} />
      </TableRow>
    </TableHead>
    <TableBody data-test-id="rule-table-body">
      {rules.map((rule, i) => (
        <RuleRow
          key={rule.id}
          users={users}
          groups={groups}
          rule={rule}
          parentId={id}
          parentIndex={index}
          index={rule.index}
          ruleCount={rules.length - 1}
          arrIndex={i}
        />
      ))}
      {everyone && (
        <RuleRow
          data-test-id="everyone-rule-row"
          key="default"
          action="allow"
          everyone
          rule={{
            id: 'default',
            action: default_action,
          }}
        />
      )}
    </TableBody>
  </Table>
);

RuleTable.propTypes = {
  classes: PropTypes.object.isRequired,
  rules: PropTypes.array.isRequired,
  index: PropTypes.number.isRequired,
  users: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  search: PropTypes.shape({
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    type: PropTypes.string,
  }),
  default_action: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'RuleTable' })(RuleTable);
