import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { settings } from '../../actions/atp';
import SettingsItem from '../settings/SettingsItem';

const CpuThrottleComponent = ({ classes, onChange, value, loading }) => (
  <div>
    <SettingsItem
      disabled={false}
      text={
        <Translate value="components.atp.maxCPU" className={classes.label} />
      }
      padding={false}
      value={value}
      onChange={onChange}
      inputIsUpdating={loading}
      disabled={loading}
      inputSuffixText="%"
      customClasses={{
        labelWrapper: classes.labelWrapper,
      }}
    />
  </div>
);

const atdStyles = theme => ({
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  container: {
    paddingTop: '21px',
    paddingBottom: '21px',
  },
});

CpuThrottleComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  errorMsg: PropTypes.string.isRequired,
};

export default withStyles(atdStyles, { name: 'CpuThrottleComponentStyles' })(
  CpuThrottleComponent
);
