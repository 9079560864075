import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Editor from '../../containers/block_page/Editor';

const BlockPageEditor = ({ classes }) => (
  <div className={classes.container}>
    <Editor />
  </div>
);

const styles = {
  container: {
    padding: '8px',
  },
};

BlockPageEditor.propTypes = {};

export default withStyles(styles, { name: 'BlockPageEditor' })(BlockPageEditor);
