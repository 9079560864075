import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import InfoIconOutline from '@material-ui/icons/InfoOutlined';

class HelpTooltipIcon extends Component {
  state = {
    hover: false,
  };

  render() {
    const { classes, tooltipText, testId, ...others } = this.props;

    return (
      <span data-test-id="helpToolTip" {...others}>
        <Tooltip
          title={tooltipText}
          placement="top"
          PopperProps={{
            'data-test-id': testId,
          }}
        >
          <span
            className={classes.icon}
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            {this.state.hover ? <InfoIcon /> : <InfoIconOutline />}
          </span>
        </Tooltip>
      </span>
    );
  }
}

HelpTooltipIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  testId: PropTypes.string.isRequired,
};

const styles = {
  icon: {
    color: '#888888',
  },
};

export default withStyles(styles, { name: 'HelpTooltipIcon' })(HelpTooltipIcon);
