import { connect } from 'react-redux';
import React, { Component } from 'react';
import WebFilteringLogs from '../components/WebFilteringLogs';
import DateRangePickerComponent, {
  DateRangeBox as DateRangeBoxComponent,
} from '../components/common/DateRangePicker';
import { getQueryFieldValues } from '../selectors/wf_logs';
import {
  addSearchRow,
  removeSearchRow,
  initData,
  updateQuery,
  updateRange,
  queryData,
  updateField,
  clearFilter,
  toggleDateRangePicker,
  fetch,
  setDateRange,
} from '../actions/wf_logs';

// DATE RANGE BOX

const mapDateRangeBoxStateToProps = (state, ownProps) => {
  return {
    from: state.wfLogs.dateRange.from,
    to: state.wfLogs.dateRange.to,
    quickRange: state.wfLogs.dateRange.quickRange,
    timezone: state.account.time_zone,
  };
};

const mapDateRangeBoxDispatchToProps = (dispatch, ownProps) => {
  return {
    togglePicker: () => {
      dispatch(toggleDateRangePicker());
    },
  };
};

const DateRangeBox = connect(
  mapDateRangeBoxStateToProps,
  mapDateRangeBoxDispatchToProps
)(DateRangeBoxComponent);
export { DateRangeBox };

// DATE RANGE PICKER

const mapDateRangePickerStateToProps = (state, ownProps) => {
  return {
    open: state.wfLogs.datePickerOpen,
    from: state.wfLogs.dateRange.from,
    fromMonth: state.wfLogs.dateRange.fromMonth,
    to: state.wfLogs.dateRange.to,
    enteredTo: state.wfLogs.dateRange.enteredTo,
    quickRange: state.wfLogs.dateRange.quickRange,
    timezone: state.account.time_zone,
  };
};

const mapDateRangePickerDispatchToProps = (dispatch, ownProps) => {
  return {
    close: params => {
      dispatch(toggleDateRangePicker());
    },
    setDateRange: range => {
      dispatch(setDateRange(range));
    },
    apply: () => {
      dispatch(toggleDateRangePicker());
      dispatch(fetch());
    },
  };
};

const DateRangePicker = connect(
  mapDateRangePickerStateToProps,
  mapDateRangePickerDispatchToProps
)(DateRangePickerComponent);
export { DateRangePicker };

const mapStateToProps = state => ({
  query: state.wfLogs.query,
  filterValues: state.wfLogs.filterSelection,
  queryFields: state.wfLogs.queryFields,
  queryField: state.wfLogs.queryField,
  queryFieldValues: getQueryFieldValues(state),
});

const mapDispatchToProps = dispatch => ({
  addRow: () => {
    dispatch(addSearchRow());
  },
  removeRow: i => {
    dispatch(removeSearchRow(i));
  },
  initLogs: () => {
    dispatch(initData());
  },
  updateQuery: (query, i) => {
    dispatch(updateQuery(query, i));
  },
  clearFilter: () => {
    dispatch(clearFilter());
  },
  queryData: () => {
    dispatch(queryData());
  },
  updateRange: index => {
    dispatch(updateRange(index));
  },
  updateField: (field, index) => {
    dispatch(updateField(field, index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFilteringLogs);
