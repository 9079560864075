import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../components/ChipInput';
import { updateAccountOption } from '../actions/exempt_policies';
import { getCopyStrExemptProcesses } from '../selectors/exempt_policies';

const mapStateToProps = (state, ownProps) => ({
  inputValue:
    state.exemptPolicies.changes.processExclusions ||
    state.exemptPolicies.fields.processExclusions,
  copyStr: getCopyStrExemptProcesses(state),
  selectedIndex:
    state.exemptPolicies.changes.processExclusionsSelected ||
    state.exemptPolicies.fields.processExclusionsSelected,
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
  skipSpace: true,
});

const mapDispatchToProps = dispatch => ({
  filterInput: value => {
    return value.replace(/\/.*/, '');
  },
  onSelectIndex: index => {
    dispatch(
      updateAccountOption({
        processExclusionsSelected: index,
      })
    );
  },
  onChange: value => {
    dispatch(
      updateAccountOption({
        processExclusions: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
