import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import EditEveryonePolicy from '../../containers/advanced_filtering/EditEveryonePolicy';

const EveryoneEditPolicyModal = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
  overflow,
}) => (
  <EditModal
    title={translate('components.advancedFiltering.defaultRule')}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
    customClasses={
      overflow && {
        paper: classes.dialog,
        root: classes.dialog,
      }
    }
  >
    <EditEveryonePolicy />
  </EditModal>
);

EveryoneEditPolicyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

EveryoneEditPolicyModal.defaultProps = {
  disabled: false,
};

const styles = {
  dialog: {
    overflow: 'visible',
  },
};

export default withStyles(styles, { name: 'EveryoneEditPolicyModal' })(
  EveryoneEditPolicyModal
);
