import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import { MenuArrowIcon, ExpandArrowIcon } from './icons';
import ClickOutside from './ClickOutside'; //react-click-outside

const styles = {
  selectedItem: {
    position: 'relative',
    display: 'flex',
    border: '1px solid #dddddd',
    borderRadius: 3,
    padding: '0 10px',
    cursor: 'pointer',
    fontSize: 16,
    height: '28px',
    background: 'linear-gradient(180deg, #FEFEFE 0%, #FAFAFA 100%)',
    alignItems: 'center',
  },
  selectedItemDisabled: {
    extend: 'selectedItem',
    cursor: 'auto',
    color: 'rgba(0,0,0,0.3)',
  },
  dropdownContent: {
    position: 'absolute',
    backgroundColor: '#fff',
    width: '100%',
    top: 28,
    left: -1,
    border: '1px solid #dddddd',
    boxShadow: '0 1px 5px 0px #DDDDDD',
    zIndex: 1,
  },
  dropdownContentFixed: {
    position: 'fixed',
    backgroundColor: '#fff',
    border: '1px solid #dddddd',
    boxShadow: '0 1px 5px 0px #DDDDDD',
    zIndex: 1,
  },
  downArrow: {
    marginTop: 10,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  options: {
    padding: '4px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
};

const CudaDropdown = ({
  classes,
  disabled,
  menuOpen,
  items,
  selectedItemValue,
  toggleMenu,
  closeMenu,
  onSelect,
  withIcons = false,
  fixed = false,
}) => {
  const selectedItem = items.filter(
    item => item.value === selectedItemValue
  )[0];
  return (
    <ClickOutside onClickOutside={closeMenu}>
      <div
        className={
          disabled ? classes.selectedItemDisabled : classes.selectedItem
        }
        onClick={!disabled ? () => toggleMenu() : null}
      >
        {!withIcons && (
          <Grid container align="center" spacing={0}>
            <Grid item xs={11}>
              {selectedItem.text}
            </Grid>
            <Grid item xs={1}>
              <Grid container justify="flex-end">
                <Grid item>
                  <ExpandArrowIcon className={classes.downArrow} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        {withIcons && (
          <Grid container justify="space-between" align="center" spacing={0}>
            <Grid item xs={2} className={classes.iconWrapper}>
              <div className={classes.iconWrapper}>
                {!disabled && selectedItem.icon}
                {disabled && selectedItem.iconDisabled}
              </div>
            </Grid>
            <Grid item xs={9} className={classes.center}>
              {selectedItem.text}
            </Grid>
            <Grid item xs={1}>
              <Grid container justify="flex-end">
                <Grid item className={classes.center}>
                  <MenuArrowIcon />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}

        {menuOpen && (
          <div
            className={
              fixed ? classes.dropdownContentFixed : classes.dropdownContent
            }
          >
            {items.map((item, number) => {
              return (
                <MenuItem
                  key={number}
                  onClick={() => onSelect(item.value)}
                  className={classes.options}
                >
                  {!withIcons && item.text}
                  {withIcons && (
                    <Grid container align="start" spacing={0}>
                      <Grid item xs={3}>
                        {item.icon}
                      </Grid>
                      <Grid item xs={9}>
                        {item.text}
                      </Grid>
                    </Grid>
                  )}
                </MenuItem>
              );
            })}
          </div>
        )}
      </div>
    </ClickOutside>
  );
};

export default withStyles(styles, { name: 'CudaDropdown' })(CudaDropdown);
