import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GroupIcon from '@material-ui/icons/Group';
import { WebIcon } from '../icons';

const styles = {
  wrapper: {
    fontSize: '16px',
    color: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 300,
  },
  count: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  name: {
    marginLeft: '16px',
    marginRight: '8px',
  },
  icon: {
    height: '24px',
    width: '24px',
  },
  highlight: {
    fontWeight: 'bold',
  },
};

const RulesetName = ({ classes, type, name, count, highlight, ...others }) => {
  const nameStyle = highlight
    ? classNames(classes.name, classes.highlight)
    : classNames(classes.name);
  return (
    <span className={classes.wrapper} {...others}>
      {
        {
          user: (
            <PersonOutlineIcon
              className={classes.icon}
              data-test-id="person-icon"
            />
          ),
          group: (
            <GroupIcon className={classes.icon} data-test-id="group-icon" />
          ),
          everyone: (
            <WebIcon className={classes.icon} data-test-id="web-icon" />
          ),
        }[type]
      }
      <span className={nameStyle}>
        {name.map((name, index, arr) => {
          return (
            <React.Fragment>
              {name}
              {index !== arr.length - 1 && ', '}
            </React.Fragment>
          );
        })}
      </span>
      <span className={classes.count}>{`(${count})`}</span>
    </span>
  );
};

RulesetName.defaultProps = {
  highlight: false,
};

RulesetName.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  name: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  highlight: PropTypes.bool,
};

export default withStyles(styles, { name: 'RulesetName' })(RulesetName);
