import { connect } from 'react-redux';
import AgentsModule from '../../components/overview/AgentsModule';

const mapStateToProps = (state, ownProps) => ({
  total: state.account.totalAgents,
  active: state.account.activeAgents,
  loading: state.account.dashboardLoading,
});

export default connect(
  mapStateToProps,
  undefined
)(AgentsModule);
