import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import DefaultEditor from '../../components/block_page/DefaultEditor';
import { updateHeader } from '../../actions/block_page_settings';

const mapStateToProps = state => ({
  header: Object.prototype.hasOwnProperty.call(
    state.blockPageSettings.changes,
    'header'
  )
    ? state.blockPageSettings.changes.header
    : state.blockPageSettings.original.header,
  logo: state.blockPageSettings.logo,
});

const mapDispatchToProps = dispatch => ({
  updateHeader: event => {
    dispatch(updateHeader(event.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefaultEditor));
