import { connect } from 'react-redux';
import StatusBar from '../../components/block_page/StatusBar';
import { getChangedFields } from '../../selectors/exempt_policies';
import { saveSettings, resetSettings } from '../../actions/exempt_policies';

const mapStateToProps = state => {
  const fields = getChangedFields(state);
  return {
    show: Boolean(fields.length),
    saving: state.exemptPolicies.saving,
    error: state.exemptPolicies.errors.join(', '),
  };
};

const mapDispatchToProps = dispatch => ({
  submitAction: () => {
    dispatch(saveSettings());
  },
  cancelAction: () => {
    dispatch(resetSettings());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusBar);
