import { connect } from 'react-redux';
import EditCustomExclusionDialog from '../../components/shared_policies/AddCustomExclusionDialog';

import {
  editCustomExclusion,
  closeExclusionModal,
} from '../../actions/shared_policies';

const mapStateToProps = state => {
  return {
    showCustomExclusion: state.shared_policies.showExclusionModal,
  };
};

const mapDispatchToProps = dispatch => ({
  addCustomExclusion: (type, value) => {
    dispatch(editCustomExclusion(type, value));
  },
  closePopUp: () => {
    dispatch(closeExclusionModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomExclusionDialog);
