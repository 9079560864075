import { createSelector } from 'reselect';
import moment from 'moment-timezone';

const getAccountId = state => state.account.selected;
const getMspSubStats = state => state.account.mspSubStats;
const getADAccounts = state => state.account.activeDirectory;
const getTimezones = () => moment.tz.names();
const getDashboardInfo = state => state.account.dashboardInfo;
const getAllAccounts = state => state.account.accounts;
const getAtpPolicies = state => state.shared_policies.policies;
const getEditAccounts = state =>
  state.shared_policies.editFields.updatedAccountSelections;
const getEditId = state => state.shared_policies.editModal.id;
const getADUserHash = state => state.account.adUserMap;
const getUserList = state => state.account.adUsers;
const getGroupList = state => state.account.adGroups;

const getSubs = createSelector(
  [getMspSubStats],
  mspSubStats => {
    const futureDate = moment()
      .add(30, 'days')
      .calendar();

    let subs = {
      dns: false,
      atp: false,
      wca: false,
      account: false,
      ...mspSubStats.reduce((obj, n) => {
        let state = 'good';
        if (moment(n.expiredate).isBefore()) {
          state = 'expired';
        } else if (moment(n.expiredate).isBefore(futureDate)) {
          state = 'warning';
        }
        const daysLeft = moment(n.expiredate).diff(
          moment({ hours: 0 }),
          'days'
        );

        return {
          ...obj,
          [n['type']]: {
            ...n,
            daysLeft,
            state,
          },
        };
      }, {}),
    };

    return subs;
  }
);

const getActiveAccounts = createSelector(
  [getAllAccounts, getDashboardInfo],
  (accounts, dashboardInfo) => {
    return accounts.filter(a => dashboardInfo[Number(a.id)]);
  }
);

const getActiveAvailableAccounts = createSelector(
  [getAllAccounts, getDashboardInfo, getAtpPolicies],
  (accounts, dashboardInfo, policies) => {
    let usedAccounts = {};
    let mergeAccounts = [];
    for (let i = 0; i < policies.length; i++) {
      mergeAccounts = mergeAccounts.concat(policies[i]['accounts']);
    }
    for (let i = 0; i < mergeAccounts.length; i++) {
      usedAccounts[Number(mergeAccounts[i])] = true;
    }
    return accounts.filter(
      a => dashboardInfo[Number(a.id)] && !usedAccounts[Number(a.id)]
    );
  }
);

const getActiveAvailableEditAccounts = createSelector(
  [
    getAllAccounts,
    getDashboardInfo,
    getAtpPolicies,
    getEditId,
    getEditAccounts,
  ],
  (accounts, dashboardInfo, policies, editId, editAccounts) => {
    let usedAccounts = {};
    let mergeAccounts = [];
    for (let i = 0; i < policies.length; i++) {
      // Do not include accounts that are being modified in selection
      if (policies[i]['id'] !== editId) {
        mergeAccounts = mergeAccounts.concat(policies[i]['accounts']);
      }
    }
    for (let i = 0; i < mergeAccounts.length; i++) {
      usedAccounts[Number(mergeAccounts[i])] = true;
    }
    for (let i = 0; i < editAccounts.length; i++) {
      usedAccounts[Number(editAccounts[i])] = true;
    }
    return accounts.filter(
      a => dashboardInfo[Number(a.id)] && !usedAccounts[Number(a.id)]
    );
  }
);

const getAccountNames = createSelector(
  [getAllAccounts],
  accounts => {
    let accountNames = {};
    for (let i = 0; i < accounts.length; i++) {
      accountNames[Number(accounts[i]['id'])] = accounts[i]['name'];
    }
    return accountNames;
  }
);

const getTimezoneList = createSelector(
  [getTimezones],
  timezones =>
    timezones.map(tz => ({ label: tz, value: tz, 'data-test-id': `${tz}` }))
);

export {
  getADUserHash,
  getAllAccounts,
  getAccountId,
  getActiveAccounts,
  getActiveAvailableAccounts,
  getActiveAvailableEditAccounts,
  getAccountNames,
  getDashboardInfo,
  getSubs,
  getUserList,
  getGroupList,
  getTimezoneList,
};
