import { connect } from 'react-redux';
import NotificationModal from '../../components/notifications/NotificationModal';
import {
  update,
  changeType,
  updateSecondaryOptions,
} from '../../actions/notifications';

const mapStateToProps = state => ({
  typeIndex: state.notifications.typeSelectIndex,
  title: 'edit',
  actionTitle: 'save',
  formInfo: state.notifications.editData,
  processing: state.notifications.isProcessing,
  errors: state.notifications.errors,
});

const mapDispatchToProps = dispatch => ({
  buttonAction: (type, emails, threshold, options = '', formInfo) => {
    const newEmails = emails ? emails.split(/[\s,]+/) : [];

    dispatch(
      update({
        type,
        old_emails: formInfo.email_subscribers,
        new_emails: newEmails,
        threshold,
        options,
        id: formInfo.id,
      })
    );
  },
  handleTypeChange: (index, type) => {
    dispatch(changeType(type.key));
  },
  updateSecondaryOptions: options => {
    dispatch(updateSecondaryOptions(options));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModal);
