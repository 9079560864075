import { connect } from 'react-redux';
import ActivateForm from '../../components/activate/ActivateForm';
import { deleteSession } from '../../actions/account';
import { saveInfo } from '../../actions/activate';

const mapStateToProps = state => ({
  accounts: state.activate.sso.accounts,
  processing: state.activate.processing,
});

const mapDispatchToProps = dispatch => ({
  deleteSession: token => dispatch(deleteSession(token)),
  saveInfo: (accountId, serial, code) => {
    dispatch(
      saveInfo({
        accountId,
        serial,
        code,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateForm);
