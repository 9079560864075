import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Tooltip from '@material-ui/core/Tooltip';

import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import BlockIcon from '@material-ui/icons/Block';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

import EPSTableBody from '../table/TableBody';
import DeleteModal from '../../components/notifications/DeleteModal';
import CudaTableTitle from '../CudaTableTitle';

class NotificationsTableComponent extends Component {
  state = {
    moreAnchorEl: null,
    moreOpen: false,
    showDelete: false,
    selectedNotification: null,
  };

  handleMoreClick = (event, index) => {
    this.setState({ moreOpen: index, moreAnchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ moreOpen: false });
  };

  closeDialog = () => {
    this.setState({ showDelete: false });
  };

  handleEdit = item => {
    this.props.editAction(item);
    this.setState({ moreOpen: false });
  };

  handleSuspend = item => {
    this.props.suspendAction(item);
    this.setState({ moreOpen: false });
  };

  confirmDelete = () => {
    this.props.deleteAction(this.state.selectedNotification);
  };

  handleDelete = item => {
    this.setState({
      selectedNotification: item,
      moreOpen: false,
      showDelete: true,
    });
  };

  render() {
    const { classes, notifications, options, loading } = this.props;
    const deleteClass = classNames(classes.logOptions, classes.deleteOption);

    return (
      <div>
        <Table data-test-id="alerts-table">
          <TableHead>
            <TableRow>
              <TableCell data-test-id="alert-type">
                <CudaTableTitle value="components.notifications.type" />
              </TableCell>
              <TableCell data-test-id="alert-recipients">
                <CudaTableTitle value="components.notifications.recipients" />
              </TableCell>
              <TableCell data-test-id="alert-status">
                <CudaTableTitle value="components.notifications.status" />
              </TableCell>
              <TableCell data-test-id="alert-more">
                <CudaTableTitle value="components.notifications.more" />
              </TableCell>
            </TableRow>
          </TableHead>
          <EPSTableBody
            loading={loading}
            colCount={4}
            rowCount={notifications.length}
            lid="components.notifications.noResults"
          >
            {notifications.map((n, i) => (
              <TableRow key={n.id} data-test-id={`alert-${i}`}>
                <TableCell data-test-id={`alert-type-${i}`}>
                  {options[n.type].label}
                </TableCell>
                <TableCell
                  className={classes.emailCell}
                  data-test-id={`alert-recipients-${i}`}
                >
                  <Tooltip
                    title={n.email_subscribers.join(', ')}
                    placement="top-start"
                    PopperProps={{ style: { pointerEvents: 'none' } }}
                  >
                    <span>{n.email_subscribers.join(', ')}</span>
                  </Tooltip>
                </TableCell>
                <TableCell data-test-id={`alert-status-${i}`}>
                  {n.active ? (
                    <span>
                      <CheckCircleIcon
                        classes={{ colorPrimary: classes.successIcon }}
                        color="primary"
                        className={classes.statusIcon}
                      />
                      <Translate value="shared.active" />
                    </span>
                  ) : (
                    <span>
                      <BlockIcon
                        classes={{ colorDisabled: classes.disabledIcon }}
                        color="disabled"
                        className={classes.statusIcon}
                      />
                      <Translate value="shared.suspended" />
                    </span>
                  )}
                </TableCell>
                <TableCell data-test-id={`alert-more-${i}`}>
                  <IconButton
                    aria-label="More"
                    aria-owns={null}
                    aria-haspopup="true"
                    data-test-id={`alert-more-menu-${i}`}
                    onClick={event => {
                      this.handleMoreClick(event, i);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={this.state.moreAnchorEl}
                    open={this.state.moreOpen === i}
                    onClose={this.handleClose}
                  >
                    <MenuItem
                      key="edit"
                      selected={false}
                      data-test-id={`alert-edit-${i}`}
                      onClick={() => this.handleEdit(n)}
                      className={classes.logOptions}
                    >
                      <EditIcon color="inherit" />
                      <Translate value="shared.edit" />
                    </MenuItem>
                    {n.active ? (
                      <MenuItem
                        key="suspend"
                        selected={false}
                        data-test-id={`alert-suspend-${i}`}
                        onClick={() => this.handleSuspend(n)}
                        className={classes.logOptions}
                      >
                        <PauseIcon color="inherit" />
                        <Translate value="shared.suspend" />
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key="suspend"
                        selected={false}
                        data-test-id={`alert-resume-${i}`}
                        onClick={() => this.handleSuspend(n)}
                        className={classes.logOptions}
                      >
                        <PlayArrowIcon color="inherit" />
                        <Translate value="shared.resume" />
                      </MenuItem>
                    )}
                    <MenuItem
                      key="delete"
                      selected={false}
                      data-test-id={`alert-delete-${i}`}
                      onClick={() => this.handleDelete(n)}
                      className={deleteClass}
                    >
                      <DeleteIcon color="inherit" />
                      <Translate value="shared.delete" />
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </EPSTableBody>
        </Table>
        <DeleteModal
          showModal={this.state.showDelete}
          buttonAction={this.confirmDelete}
          closeDialog={this.closeDialog}
        />
      </div>
    );
  }
}
NotificationsTableComponent.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      recipients: PropTypes.string,
      active: PropTypes.bool,
    })
  ).isRequired,
};

const styles = {
  emailCell: {
    maxWidth: '800px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  logOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  deleteOption: {
    color: '#DD1E31',
  },
  statusIcon: {
    height: '16px',
    width: '16px',
    verticalAlign: 'bottom',
    marginRight: '8px',
  },
  disabledIcon: {
    color: '#5F5F5F',
  },
  successIcon: {
    color: '#5BA71B',
  },
  tableTitle: {
    textTransform: 'uppercase',
  },
  timeLabel: {
    paddingLeft: '20px',
  },
  centered: {
    textAlign: 'center',
  },
  threats: {
    color: '#DD1E31',
  },
  link: {
    color: '#0088CF',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  button: {
    float: 'right',
    top: '-61px',
    marginRight: '20px',
    marginBottom: '-50px',
  },
};

export default withStyles(styles, { name: 'NotificationsStyles' })(
  NotificationsTableComponent
);
