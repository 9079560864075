import React from 'react';
import PropTypes from 'prop-types';

import TableBody from '@material-ui/core/TableBody';

import TableLoading from './TableLoading';
import NoResults from './NoResults';

const EPSTableBody = ({ children, rowCount, colCount, lid, loading }) => (
  <TableBody data-test-id="tableBody">
    {!loading && rowCount > 0 ? children : null}
    {!loading && rowCount === 0 ? (
      <NoResults colSpan={colCount} lid={lid} />
    ) : null}
    {loading ? <TableLoading colSpan={colCount} /> : null}
  </TableBody>
);

EPSTableBody.propTypes = {
  rowCount: PropTypes.number.isRequired,
  colCount: PropTypes.number.isRequired,
  lid: PropTypes.string,
  loading: PropTypes.bool,
};
EPSTableBody.defaultProps = {
  lid: 'shared.noResults',
  loading: false,
};

export default EPSTableBody;
