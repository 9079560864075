import { connect } from 'react-redux';

import SharedPolicies from '../components/SharedPolicies';

import { initData } from '../actions/shared_policies';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: () => {
    dispatch(initData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SharedPolicies);
