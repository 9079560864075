import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import Header from '../containers/reports/Header';
import DataTable from '../containers/ReportDataTable';

const styles = {
  root: {
    flexGrow: 1,
    width: 'calc(100% + 12px)', // Remove the extra 24px from grid
  },
};

const ViewReport = ({ classes }) => (
  <Grid className={classes.root} id="view-report" spacing={2} container>
    <Grid item xs={12}>
      <Header />
    </Grid>
    <Grid item xs={12}>
      <DataTable />
    </Grid>
  </Grid>
);

export default withStyles(styles)(ViewReport);
