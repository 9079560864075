import { connect } from 'react-redux';
import ADGroupSelection from '../../components/advanced_filtering/ADGroupSelection';

import {
  onChangeAD,
  filterADSearchInput,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = (state, ownProps) => {
  let ad = state.account.activeDirectory.map(ad => ({
    type: ad.type,
    label: ad.display_name || ad.group_name,
    value: ad.uuid,
  }));
  ad = ad.concat({ type: 'everyone', label: 'Everyone', value: 'everyone' });

  return {
    inputValue: state.advanced_filtering.ruleModal.adGroup,
    activeDirectoryOptions: ad,
  };
};

const mapDispatchToProps = dispatch => ({
  asyncCallback: (value, callback) => {
    dispatch(filterADSearchInput(value, callback));
  },
  onChange: value => {
    Mixpanel.track(
      'Advanced Filtering / Add Rule Dialog / User Selection Dropdown',
      {}
    );
    dispatch(onChangeAD(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ADGroupSelection);
