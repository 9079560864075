import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { PRIMARY_DNS, SECONDARY_DNS } from '../../lib/Env';

const styles = theme => ({
  wrapper: {
    padding: '25px',
  },
  instructions: {
    marginBottom: '50px',
    lineHeight: '24px',
  },
  nameserverLabel: {
    display: 'inline-block',
    width: '140px',
  },
  nameserverList: {
    marginBottom: '20px',
  },
  nameserver: {
    display: 'inline-block',
    marginBottom: '28px',
    fontWeight: 'bold',
  },
  backupText: {
    lineHeight: '14px',
    fontSize: '12px',
    fontWeight: 500,
    color: '#000000',
  },
  dnsTable: {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: '50px',
  },
  dnsTableRow: {
    border: '1px solid #E0E0E0',
  },
  dnsTableCell: {
    padding: '0 16px',
    fontSize: '12px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    color: 'rgba(0, 0, 0, 0.5)',
  },
  ipCell: {
    fontSize: '14px',
    color: '#000000',
  },
  copyToClipboard: {
    cursor: 'pointer',
    color: theme.colors.link,
  },
  specificInstructions: {
    fontSize: '14px',
    lineHeight: '24px',
  },
  helpLink: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
});

const ConfigureDNS = ({ classes }) => {
  const ipCellClass = classNames(classes.dnsTableCell, classes.ipCell);

  return (
    <div className={classes.wrapper}>
      <Translate
        value="components.basicFiltering.configureInstructions"
        tag="div"
        className={classes.instructions}
      />
      <Translate
        value="components.basicFiltering.dnsInstructions"
        dangerousHTML
        tag="div"
        className={classes.specificInstructions}
        linkClass={classes.helpLink}
      />
      <table className={classes.dnsTable}>
        <tbody>
          <tr data-test-id="dnsWrapper" className={classes.dnsTableRow}>
            <td className={classes.dnsTableCell}>
              {translate('components.basicFiltering.primaryDNSServer')}
            </td>
            <td data-test-id="primaryDNS" className={ipCellClass}>
              {PRIMARY_DNS}
            </td>
            <td className={classes.dnsTableCell}>
              <CopyToClipboard text={PRIMARY_DNS}>
                <span className={classes.copyToClipboard}>
                  {translate('components.basicFiltering.copyToClipboard')}
                </span>
              </CopyToClipboard>
            </td>
          </tr>
          <tr data-test-id="dnsWrapper" className={classes.dnsTableRow}>
            <td className={classes.dnsTableCell}>
              {translate('components.basicFiltering.secondaryDNSServer')}
            </td>
            <td data-test-id="secondaryDNS" className={ipCellClass}>
              {SECONDARY_DNS}
            </td>
            <td className={classes.dnsTableCell}>
              <CopyToClipboard text={SECONDARY_DNS}>
                <span className={classes.copyToClipboard}>
                  {translate('components.basicFiltering.copyToClipboard')}
                </span>
              </CopyToClipboard>
            </td>
          </tr>
        </tbody>
      </table>
      <Translate
        value="components.basicFiltering.dnsBackupText"
        tag="div"
        className={classes.backupText}
      />
    </div>
  );
};

ConfigureDNS.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'ConfigureDNS' })(ConfigureDNS);
