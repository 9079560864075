import { connect } from 'react-redux';
import ActivateModal from '../../components/header/ActivateModal';
import { linkPurchase } from '../../actions/signup';

const mapStateToProps = state => ({
  processing: state.signup.activation.submitting,
  error: state.signup.activation.error,
});

const mapDispatchToProps = dispatch => ({
  submitHandler: (serial, code) => dispatch(linkPurchase(serial, code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateModal);
