import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import BarComponent from '../charts/Bar';
import TimeSelect from './TimeSelection';
import Paper from '../CudaPaper';

const TopThreatChart = ({
  classes,
  selectedIndex,
  onChange,
  data,
  loading,
}) => {
  const options = [
    {
      key: 'day',
      label: translate('shared.ranges.day'),
    },
    {
      key: 'week',
      label: translate('shared.ranges.week'),
    },
    {
      key: 'month',
      label: translate('shared.ranges.month'),
    },
  ];

  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <div className={classes.headerSection}>
            <div className={classes.title}>Threats by Accounts</div>
            <div className={classes.subtitle}>
              {options[selectedIndex].label}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : null}
          {!loading && data.length > 0 ? (
            <div className={classes.barWrapper}>
              <BarComponent
                height={300}
                data={data}
                units={translate('shared.threats')}
                tooltip={true}
              />
            </div>
          ) : null}
          {!loading && data.length === 0 ? (
            <div className={classes.noResults}>
              <Translate value="shared.noResults" />
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12} data-test-id="timeSelectionWrapper">
          <TimeSelect
            options={options}
            selectedIndex={selectedIndex}
            onChange={index => {
              onChange(options[index].key, index);
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  container: {},
  headerSection: {
    margin: '20px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
  barWrapper: {
    paddingLeft: '15px',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textTransform: 'uppercase',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

TopThreatChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key: PropTypes.string,
    })
  ).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(TopThreatChart);
