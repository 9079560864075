import React from 'react';
import { translate } from 'react-i18nify';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input/Input';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const styleSheet = {
  container: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
  },
  icon: {
    flex: '0 0 36px',
  },
  input_wrapper: {
    marginLeft: 0,
    marginRight: 25,
    width: '100%',
    fontSize: 14,
  },
  input: {
    paddingLeft: '8px',
    '&::placeholder': {
      marginLeft: '2px',
      color: '#555555 !important',
      opacity: '0.5',
    },
  },
  shadow: {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
  },
  menu: {
    boxShadow:
      '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 12px 14px 2px rgba(0, 0, 0, 0.12)',
    borderRadius: 0,
    borderTop: '1px solid #DDDDDD',
    '& ul': {
      width: '180px',
    },
    '& li': {
      fontSize: '14px',
      lineHeight: '40px',
      paddingLeft: '36px',
    },
  },
  selectDropdown: {
    width: '100%',
  },
  menuButton: {
    flex: '0 0 180px',
    padding: '0',
    justifyContent: 'left',
    fontSize: '14px',
    textTransform: 'none',
    borderRadius: 0,
    marginRight: '8px',
  },
  menuButtonLabel: {
    borderRight: '1px solid #DDDDDD',
    fontWeight: 400,
    '& span': {
      flex: '1 1',
      textAlign: 'left',
    },
  },
  queryButton: {
    margin: 0,
    height: '40px',
    minWidth: '85px',
  },
};

const customStyles = {
  control: base => ({
    ...base,
    border: 'none',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    fontSize: '14px',
    color: '#555555',
  }),
  placeholder: base => ({
    ...base,
    color: '#555555',
    opacity: '0.5',
    margin: '0',
    cursor: 'text',
  }),
  option: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  singleValue: base => ({
    ...base,
    fontSize: '14px',
    color: '#555555',
  }),
  input: base => ({
    ...base,
    margin: '2px 0',
  }),
};

const NoOptionsMessage = props => {
  return (
    <div data-test-id="no-option">
      <components.NoOptionsMessage {...props} />
    </div>
  );
};

let SelectTimeoutPtr;

class FilterSearchBar extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleItemClick = field => {
    this.props.handleMenuItemClick(field);
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      asyncFields,
      asyncCallback,
      onChange,
      disabled = false,
      height,
      handleMenuItemClick,
      fields = [],
      field,
      fieldValues,
      value,
      ...props
    } = this.props;
    const { anchorEl } = this.state;
    const fieldValue = fields.length ? fields[field].field : '';
    const fieldLabel = fields.length ? fields[field].label : '';
    const fieldType = fields.length ? fields[field].type : undefined;

    return (
      <div
        className={classNames(classes.container, classes.shadow)}
        style={{ height }}
        data-test-id="wflogs-search"
      >
        <Button
          data-test-id="search-option-btn"
          className={classes.menuButton}
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          disableRipple
          classes={{
            label: classes.menuButtonLabel,
          }}
        >
          <SearchIcon className={classes.icon} />
          <span>{fieldLabel}</span>
          <KeyboardArrowDownIcon className={classes.icon} />
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          className={classes.menu}
          disableAutoFocusItem
          transitionDuration={0}
          PopoverClasses={{
            paper: classes.menu,
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {fields.map((n, index) => (
            <MenuItem
              data-test-id={`option-${n.field}`}
              key={n.field}
              onClick={() => this.handleItemClick(index)}
            >
              {n.label}
            </MenuItem>
          ))}
        </Menu>
        {!fieldType && (
          <AsyncSelect
            inputId="selection-wrapper-input"
            isDisabled={disabled}
            styles={customStyles}
            isClearable={false}
            placeholder=<span data-test-id="filter-search-placeholder">
              {translate(`shared.${fieldValue}Placeholder`)}
            </span>
            loadOptions={(input, callback) => {
              clearTimeout(SelectTimeoutPtr);
              SelectTimeoutPtr = setTimeout(() => {
                if (asyncCallback && field in asyncFields) {
                  console.log('calling async function');
                  asyncCallback(input, callback);
                  return;
                }

                callback(
                  fieldValues
                    .filter(a =>
                      a.label.toLowerCase().includes(input.toLowerCase())
                    )
                    .slice(0, 100)
                );
              }, 1000);
            }}
            defaultOptions={fieldValues.slice(0, 100)}
            options={
              fieldValues.length > 100 ? fieldValues.slice(0, 100) : fieldValues
            }
            className={classes.selectDropdown}
            value={value}
            components={{ NoOptionsMessage }}
            getValue={value => {}}
            onChange={selectedOptions => {
              onChange(selectedOptions);
            }}
          />
        )}
        {fieldType === 'input' && (
          <Input
            data-test-id="input_wrapper"
            placeholder={translate(`shared.${fieldValue}Placeholder`)}
            className={classes.input_wrapper}
            onChange={e =>
              onChange({ value: e.target.value, label: e.target.value })
            }
            disableUnderline
            classes={{
              input: classes.input,
            }}
            {...props}
            value={value.value}
          />
        )}
      </div>
    );
  }
}

export default withStyles(styleSheet, { name: 'FilterSearchBar' })(
  FilterSearchBar
);
