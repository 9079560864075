import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import { InfoIcon } from '../icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Modal from '../modal/Modal';
import Timestamp from '../../containers/Timestamp';

const styles = theme => ({
  infoText: {
    paddingTop: '36px',
    width: '430px',
    fontSize: '16px',
    color: theme.colors.primaryText,
  },
  dialogRight: {
    backgroundColor: '#FFFFFF',
    flexGrow: 1,
  },
  dialogRightContent: {
    fontSize: '24px',
  },
  row: {
    display: 'flex',
    lineHeight: '32px',
  },
  rowHeader: {
    width: '140px',
    marginRight: '10px',
  },
  bodyTitle: {
    fontSize: '24px',
    fontWeight: 600,
  },
  bodyText: {
    paddingTop: '14px',
    fontSize: '20px',
  },
  subStatusText: {
    paddingTop: '8px',
    fontSize: '20px',
    paddingLeft: '70px',
  },
  lastStatusText: {
    paddingTop: '4px',
    fontSize: '20px',
  },
  bodyButton: {
    paddingTop: '24px',
  },
  bodyStatus: {
    paddingLeft: '75px',
    fontSize: '16px',
  },
});

class SupportTool extends React.Component {
  constructor() {
    super();
    this.sendSupportCommand = this.sendSupportCommand.bind(this);
  }

  state = {
    syslog: null,
  };

  componentWillUnmount() {
    const { syslog } = this.state;
  }

  sendSupportCommand() {
    this.props.sendSupportCommand(this.state.devices);
  }

  render() {
    const {
      classes,
      show,
      device,
      unknownStatus,
      generate,
      lastStatus,
      lastSync,
      filename,
      loading,
      deviceStatus,
      connected,
      sendSupportCommand,
      closeDialog,
    } = this.props;
    const { supportLogErrors } = this.state;

    return (
      <Modal
        name="support-tool-iot"
        showModal={show}
        title={device}
        closeBtnText={translate('components.shared.close')}
        saveBtnText={translate('components.atpLogs.report')}
        closeDialog={closeDialog}
      >
        <div className={classes.bodyTitle}>
          <Translate value="components.supportTool.title" />
        </div>
        <div className={classes.bodyText}>
          <Translate
            className={classes.bodyText}
            value="components.supportTool.generateFileText"
          />
        </div>
        <div className={classes.bodyButton}>
          <Button
            color="primary"
            variant="contained"
            disabled={
              loading || !connected || generate || deviceStatus === 'Offline'
            }
            className={classes.button}
            onClick={() => {
              sendSupportCommand({ device });
            }}
          >
            <Translate value="components.supportTool.generateFileButton" />
          </Button>
        </div>
        <div className={classes.bodyText}>
          <div className={classes.bodyStatus}>
            <Translate value="components.supportTool.lastRequest" />
            {!loading ? (
              lastSync ? (
                <Timestamp timestamp={lastSync} />
              ) : null
            ) : (
              translate('components.supportTool.statusLoading')
            )}
          </div>
          <div className={classes.bodyStatus}>
            <Translate value="components.supportTool.filename" />
            {!loading
              ? filename
              : translate('components.supportTool.statusLoading')}
          </div>
        </div>
        <div className={classes.bodyText}>
          <Translate value="components.supportTool.statusLabel" />
          {!loading
            ? deviceStatus
            : generate
            ? deviceStatus
            : translate('components.supportTool.gettingDevice')}
        </div>
        {deviceStatus === 'Offline' && lastStatus === 'Generating Logs' && (
          <div className={classes.lastStatusText}>
            <Translate value="components.supportTool.statusLast" />
            <Translate value="components.supportTool.statusGenerate" />
          </div>
        )}
        {generate && deviceStatus === 'Online' && (
          <div className={classes.subStatusText}>
            {translate('components.supportTool.statusGenerate')}
          </div>
        )}
      </Modal>
    );
  }
}

SupportTool.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  sendSupportCommand: PropTypes.func.isRequired,
  removeConfigErrors: PropTypes.array,
};

SupportTool.defaultProps = {
  removeConfigErrors: [],
};

export default withStyles(styles)(SupportTool);
