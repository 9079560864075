import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ActionSelection from '../../containers/advanced_filtering/EveryoneActionSelection';

const EditEveryonePolicy = ({ classes }) => (
  <Grid id="edit-everyone-policy" container spacing={0}>
    <Grid item xs={12} className={classes.addContainer}>
      <ActionSelection />
    </Grid>
  </Grid>
);

EditEveryonePolicy.propTypes = {
  classes: PropTypes.object.isRequired,
};

EditEveryonePolicy.defaultProps = {};

const styles = {
  addContainer: {
    padding: '24px 24px 0 24px',
  },
};

export default withStyles(styles, { name: 'EditEveryonePolicy' })(
  EditEveryonePolicy
);
