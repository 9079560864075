import { connect } from 'react-redux';
import ScheduleScanModal from '../../components/atp_policy/ScheduleScanModal';

import {
  closeScheduleFullScan,
  fullScanTimeOptions,
  frequencyOptions,
  hourOptions,
  dayOptions,
  weekOptions,
  selectScheduleScanTimeframe,
  selectScheduleScanFrequency,
  fullScanWeekOptions,
  selectScheduleScanHour,
  selectScheduleScanDay,
  selectScheduleScanWeek,
  scheduleScan,
  toggleScheduleScanEnable,
} from '../../actions/atp';

const mapStateToProps = (state, ownProps) => ({
  open: state.atp.modal.open,
  timeframeIndex: state.atp.modal.timeframeIndex,
  frequencyIndex: state.atp.modal.frequencyIndex,
  hourIndex: state.atp.modal.hourIndex,
  weekIndex: state.atp.modal.weekIndex,
  dayIndex: state.atp.modal.dayIndex,
  recipients: state.atp.modal.recipients,
  title: 'Schedule Full Scan',
  frequencyOptions: frequencyOptions,
  hourOptions: hourOptions,
  weekOptions: weekOptions,
  dayOptions: dayOptions,
  timezone: state.account.time_zone,
  scheduleScanEnabled: state.atp.modal.enabled,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(closeScheduleFullScan());
  },
  selectScanFrequency: index => {
    dispatch(selectScheduleScanFrequency('modal', index));
  },
  selectScanHour: index => {
    dispatch(selectScheduleScanHour('modal', index));
  },
  selectScanWeek: index => {
    dispatch(selectScheduleScanWeek('modal', index));
  },
  selectScanDay: index => {
    dispatch(selectScheduleScanDay('modal', index));
  },
  onSchedule: () => {
    dispatch(scheduleScan());
  },
  toggleScheduleScan: field => {
    dispatch(toggleScheduleScanEnable({ enabled: field.target.checked }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleScanModal);
