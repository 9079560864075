import { call, put, select, takeEvery, all, delay } from 'redux-saga/effects';
import { translate } from 'react-i18nify';

import { getAccountId } from '../selectors/account';
import * as Types from '../actions/exempt_policies';
import Api from './Api';

import { getExemptPolicies } from '../selectors/exempt_policies';
import { validWildcardHostname, validIpv6Adddress } from '../lib/Utils';

const update = require('immutability-helper');

const hasOwn = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

export default (
  state = {
    processing: false,
    saving: false,
    errors: [],
    fields: {
      o365: true,
      networkExclusions: [],
      networkExclusionsSelected: 0,
      processExclusions: [],
      processExclusionsSelected: 0,
    },
    changes: {},
  },
  action
) => {
  switch (action.type) {
    case Types.EXEMPT_POLICIES_GET_INFO:
      return {
        ...state,
        processing: true,
      };
    case Types.EXEMPT_POLICIES_GET_INFO_SUCCESS: {
      const { o365, exclusions } = action;
      const networks = (exclusions && exclusions['networks']) || [];
      const selectedNetworks = networks.length + 1;

      // Process Exclusions are stored in bcs_agent_settings in the column application_exclusions.
      const processExclusions =
        (exclusions && exclusions['applications']) || [];
      const processExclusionsSelected = processExclusions.length + 1;

      return {
        ...state,
        processing: false,
        fields: {
          ...state.fields,
          o365,
          networkExclusions: networks,
          networkExclusionsSelected: selectedNetworks,
          processExclusions: processExclusions,
          processExclusionsSelected: processExclusionsSelected,
        },
      };
    }
    case Types.EXEMPT_POLICIES_GET_INFO_FAILURE:
      return {
        ...state,
        processing: false,
      };
    case Types.EXEMPT_POLICIES_UPDATE_ACCOUNT_OPTION: {
      const { options } = action;
      const { fields, changes } = state;
      const merged = {
        ...changes,
        ...options,
      };

      Object.keys(options).forEach(key => {
        if (merged[key] === fields[key]) {
          delete merged[key];
        }
      });

      if (merged['processExclusions']) {
        merged['processExclusionsSelected'] =
          merged['processExclusions'].length;
      }
      if (merged['networkExclusions']) {
        merged['networkExclusionsSelected'] =
          merged['networkExclusions'].length;
      }
      return update(state, {
        changes: {
          $set: merged,
        },
        errors: {
          $set: [],
        },
      });
    }
    case Types.EXEMPT_POLICIES_RESET:
      return update(state, {
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.EXEMPT_POLICIES_SAVE:
      return {
        ...state,
        saving: true,
      };
    case Types.EXEMPT_POLICIES_SAVE_SUCCESS:
      return update(state, {
        saving: { $set: false },
        fields: {
          $merge: state.changes,
        },
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.EXEMPT_POLICIES_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        errors: action.errors || ['Failed to update settings'],
      };
    default:
      return state;
  }
};

function* getInfo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.read, accountId);
    yield put(Types.getAccountInfoSuccess(result));
  } catch (e) {
    yield put(Types.getAccountInfoFailure(e));
  }
}

function* saveSetttings(action) {
  try {
    const [accountId, exemptPolicies] = yield all([
      select(getAccountId),
      select(getExemptPolicies),
    ]);
    const { changes, fields } = exemptPolicies;
    const values = {
      ...fields,
      ...changes,
    };
    let updates = {};

    if (hasOwn(changes, 'processExclusions')) {
      updates.processExclusions = changes.processExclusions;
    }

    if (hasOwn(changes, 'networkExclusions')) {
      if (checkNetworkFormat(changes.networkExclusions)) {
        updates.networkExclusions = changes.networkExclusions;
      } else {
        yield put(
          Types.saveSettingsFailure([
            'Improper CIDR notation or hostname detected',
          ])
        );
        return;
      }
    }

    if (hasOwn(changes, 'o365')) {
      updates.o365 = changes.o365;
    }

    const result = yield call(Api.accounts.update, accountId, updates);
    yield put(Types.saveSettingsSuccess(result));
  } catch (e) {
    yield put(Types.saveSettingsFailure([e.error.request.responseText]));
  }
}

function checkNetworkFormat(ips) {
  for (const [index, value] of ips.entries()) {
    if (
      !value.match(
        /^((2[5][0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])\.){3}(2[5][0-5]|2[0-4][0-9]|1[0-9][0-9]|[0-9][0-9]|[0-9])(\/([0-9]|[1-2][0-9]|3[0-2]))?$/
      ) &&
      !validWildcardHostname(value) &&
      !validIpv6Adddress(value)
    ) {
      return false;
    }
  }
  return true;
}

export function* ExemptPoliciesReducerFlow() {
  yield takeEvery(Types.EXEMPT_POLICIES_GET_INFO, getInfo);
  yield takeEvery(Types.EXEMPT_POLICIES_SAVE, saveSetttings);
}
