import React from 'react';
import { translate } from 'react-i18nify';
import { Translate } from 'react-i18nify';
import { connect } from 'react-redux';
import { updateAccountOption } from '../actions/agent_settings';
import CpuThrottle from '../components/smb_account_settings/cpu_throttle';

const mapStateToProps = state => ({
  value:
    'cpuMax' in state.agentSettings.changes
      ? state.agentSettings.changes.cpuMax
      : state.agentSettings.fields.cpuMax,
});

const mapDispatchToProps = dispatch => ({
  onChange: event => {
    dispatch(
      updateAccountOption({
        cpuMax: event.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CpuThrottle);
