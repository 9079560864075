import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import { translate } from 'react-i18nify';

import ScheduleSelection from '../../containers/advanced_filtering/ScheduleSelection';

const EditTimeConstraint = ({
  classes,
  display,
  selectDow,
  dow,
  startTime,
  endTime,
  selectTime,
  showForm,
  startError,
}) => (
  <Grid id="edit-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.levelContainer}>
      <ScheduleSelection />
      {showForm && (
        <>
          <Grid container>
            <Grid item xs={3}>
              Select Day of the week
            </Grid>
            <Grid item xs={9}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Sunday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Sunday"
                    />
                  }
                  label="Sunday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Monday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Monday"
                    />
                  }
                  label="Monday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Tuesday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Tuesday"
                    />
                  }
                  label="Tuesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Wednesday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Wednesday"
                    />
                  }
                  label="Wednesday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Thursday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Thursday"
                    />
                  }
                  label="Thursday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Friday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Friday"
                    />
                  }
                  label="Friday"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dow['Saturday']}
                      onChange={event => {
                        selectDow(event.target.name, event.target.checked);
                      }}
                      name="Saturday"
                    />
                  }
                  label="Saturday"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={3}>
              Time
            </Grid>
            <Grid item xs={5}>
              <form className={classes.container} noValidate>
                <TextField
                  id="time"
                  label="Start Time"
                  error={startError}
                  helperText={
                    startError
                      ? translate('components.advancedFiltering.startError')
                      : null
                  }
                  type="time"
                  value={startTime}
                  onChange={event => {
                    selectTime('startTime', event.target.value);
                  }}
                  defaultValue="12:00"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </form>
            </Grid>
            <Grid item xs={4}>
              <form className={classes.container} noValidate>
                <TextField
                  id="time"
                  label="End Time"
                  value={endTime}
                  onChange={event => {
                    selectTime('endTime', event.target.value);
                  }}
                  type="time"
                  defaultValue="13:00"
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </form>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  </Grid>
);

EditTimeConstraint.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
};

EditTimeConstraint.defaultProps = {};

const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: 200,
  },
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'EditTimeConstraint' })(
  EditTimeConstraint
);
