import { connect } from 'react-redux';
import { getSubs } from '../../selectors/account';
import SubscriptionModule from '../../components/overview/SubscriptionModule';

const mapStateToProps = state => {
  const subs = ['dns', 'atp', 'wca'];
  let mspSubStats = getSubs(state);

  let dates = [];
  let subState = 'good';
  let accountSub = {
    dns: false,
    atp: false,
    wca: false,
  };
  for (var i = 0; i < subs.length; i++) {
    if (subs[i] in mspSubStats && mspSubStats[subs[i]]) {
      accountSub[subs[i]] = true;
      dates.push(mspSubStats[subs[i]]['expiredate']);
      if (mspSubStats[subs[i]]['state'] != 'good') {
        subState = mspSubStats[subs[i]]['state'];
      }
    }
  }

  let minDate;
  if (dates.length) {
    minDate = dates.reduce((a, b) => {
      return a < b ? a : b;
    });

    minDate = Date.parse(minDate) / 1000;
    window.Intercom('update', {
      sub_exp: minDate,
      sub_state: subState,
      sub_dns: accountSub['dns'],
      sub_atp: accountSub['atp'],
      sub_wca: accountSub['wca'],
    });
  }
  return {
    displayCount: false,
    mspSubStats: mspSubStats,
    loading: state.account.dashboardLoading,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(SubscriptionModule);
