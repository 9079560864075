import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import Safesearch from '../../containers/advanced_filtering/Safesearch';

const SafesearchModal = ({
  showModal,
  closeDialog,
  buttonAction,
  disabled,
}) => (
  <EditModal
    title={translate('components.advancedFiltering.safesearch')}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    saveBtnText={translate('shared.save')}
    disabled={disabled}
  >
    <Safesearch />
  </EditModal>
);

SafesearchModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

SafesearchModal.defaultProps = {
  disabled: false,
};

export default SafesearchModal;
