import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import EPSTableBody from '../table/TableBody';
import CudaTableTitle from '../CudaTableTitle';
import { UpArrow, DownArrow } from '../icons';

const CategoryTable = ({
  classes,
  id,
  loading,
  data,
  dispatch,
  firstColumn,
  ...other
}) => (
  <div className={classes.wrapper} {...other} id={id}>
    <Table className={classes.table} data-test-id="categoryTable">
      <TableHead>
        <TableRow className={classes.header}>
          <TableCell
            className={classes.cell}
            data-test-id={`col-${firstColumn}`}
          >
            <CudaTableTitle value={`components.dashboard.${firstColumn}`} />
          </TableCell>
          <TableCell className={classes.cell} numeric data-test-id="countCol">
            <CudaTableTitle value="components.dashboard.count" numeric />
          </TableCell>
          <TableCell className={classes.cell} numeric data-test-id="trendCol">
            <CudaTableTitle value="components.dashboard.trend" numeric />
          </TableCell>
        </TableRow>
      </TableHead>
      <EPSTableBody colCount={3} rowCount={data.length} loading={loading}>
        {data.map(cat => {
          const trendClass = classNames(
            classes.cell,
            cat.trend >= 0 || cat.trend === 'infinite'
              ? classes.positive
              : classes.negative
          );
          return (
            <TableRow
              className={classes.row}
              key={cat.key}
              data-test-id="categoryTableRow"
            >
              <TableCell
                className={classes.cell}
                data-test-id="categoryTableCat"
              >
                {cat[firstColumn]}
              </TableCell>
              <TableCell
                className={classes.cell}
                numeric
                data-test-id="categoryTableCount"
              >
                {cat.count.toLocaleString()}
              </TableCell>
              {cat.trend === '-' ? (
                <TableCell
                  className={classes.cell}
                  numeric
                  data-test-id="categoryTableTrend"
                >
                  {cat.trend}
                </TableCell>
              ) : null}
              {cat.trend === 'infinite' ? (
                <TableCell
                  className={trendClass}
                  numeric
                  data-test-id="categoryTableInfinite"
                >
                  <span className={classes.trendCell}>
                    <UpArrow className={classes.icon} />
                    {Math.abs(cat.delta)}
                  </span>
                </TableCell>
              ) : null}
              {cat.trend !== '-' && cat.trend !== 'infinite' ? (
                <TableCell
                  className={trendClass}
                  numeric
                  data-test-id="categoryTableTrendNumber"
                >
                  <span className={classes.trendCell}>
                    {cat.trend >= 0 ? (
                      <UpArrow
                        data-test-id="upArrow"
                        className={classes.icon}
                      />
                    ) : (
                      <DownArrow
                        data-test-id="downArrow"
                        className={classes.icon}
                      />
                    )}
                    {Math.abs(cat.delta)} &nbsp;
                    {Math.abs(cat.trend).toFixed(2)}
                    {'%'}
                  </span>
                </TableCell>
              ) : null}
            </TableRow>
          );
        })}
      </EPSTableBody>
    </Table>
  </div>
);
CategoryTable.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  firstColumn: PropTypes.string.isRequired,
  id: PropTypes.string,
};

CategoryTable.defaultProps = {
  id: 'categoryTable',
};

const styles = theme => ({
  cell: {
    borderBottom: 0,
    fontSize: '12px',
    lineHeight: '24px',
    padding: '4px 10px',
    '&:last-child': {
      paddingRight: '18px',
    },
    '&:first-child': {
      paddingLeft: '18px',
    },
  },
  trendCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  row: {
    height: '32px',
  },
  header: {
    height: '32px',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  icon: {
    height: '10px',
    width: '8px',
  },
  positive: {
    color: theme.statusColors.failure,
    '& path': {
      fill: theme.statusColors.failure,
    },
  },
  negative: {
    color: theme.statusColors.success,
  },
  wrapper: {
    borderTop: '1px solid rgba(224, 224, 224, 1)',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

export default withStyles(styles, { name: 'CategoryTable' })(CategoryTable);
