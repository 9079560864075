import { connect } from 'react-redux';
import ExemptPolicies from '../components/ExemptPolicies';

import {
  getAccountInfo,
  updateAccountOption,
} from '../actions/exempt_policies';

const mapStateToProps = state => ({
  msp: state.account.msp,
  processing: state.exemptPolicies.processing,
  saving: state.exemptPolicies.saving,
  wca: state.account.subscriptions.wca,
  fields: {
    ...state.exemptPolicies.fields,
    ...state.exemptPolicies.changes,
  },
  features: state.account.features,
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(getAccountInfo());
  },
  toggleField: (field, value) => {
    dispatch(
      updateAccountOption({
        [field]: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExemptPolicies);
