import { connect } from 'react-redux';
import PieChart from '../components/charts/Pie';

const mapStateToProps = (state, ownProps) => ({
  data: state.reports.graphData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PieChart);
