import { connect } from 'react-redux';
import Support from '../components/settings/Support';

import { mspToggleSupportTunnel } from '../actions/account';

const mapStateToProps = (state, ownProps) => ({
  query: state.quarantine.query,
  processing: state.account.supportAccessLoading,
  support_enabled: state.account.supportAccessEnabled,
  saving: state.account.supportAccessSaving,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSupport: field => {
    dispatch(mspToggleSupportTunnel(field.target.checked));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Support);
