import * as Types from '../actions/atp_shared';

export default (
  state = {
    timeSelectIndex: 1,
    range: 'week',
  },
  action
) => {
  switch (action.type) {
    case Types.UPDATE_SHARED_ATP_TIMEFRAME:
      return {
        ...state,
        timeSelectIndex: action.index,
        range: action.range,
      };
    default:
      return state;
  }
};
