import { connect } from 'react-redux';
import BasicFilter from '../components/BasicFilter';

import {
  updateSearch,
  getAllLocationPolicy,
  createLocationPolicy,
  resetConfig,
  openAddNetworkModal,
  closeAddNetworkModal,
  setHighlightedRule,
} from '../actions/basic_filtering';

const mapStateToProps = (state, ownProps) => ({
  query: state.basic_filtering.query,
  locationConfig: state.basic_filtering.locationConfig,
  loading: state.basic_filtering.loading,
  modalOpen: state.basic_filtering.showAddModal,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  openModal: () => {
    dispatch(openAddNetworkModal());
  },
  closeModal: () => {
    dispatch(closeAddNetworkModal());
  },
  updateSearch: value => {
    dispatch(updateSearch(value));
  },
  initData: rule => {
    dispatch(setHighlightedRule(rule));
    dispatch(getAllLocationPolicy());
  },
  createPolicy: params => {
    dispatch(createLocationPolicy(params));
  },
  resetConfig: () => {
    dispatch(resetConfig());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicFilter);
