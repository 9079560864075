import { connect } from 'react-redux';
import Activate from '../components/activate/Activate';
import Auth from '../lib/Auth';

const mapStateToProps = state => ({
  sso: Auth.sso_token,
  filled: state.activate.filled,
});

export default connect(
  mapStateToProps,
  undefined
)(Activate);
