import { connect } from 'react-redux';
import GroupMenu from '../../components/user_authentication/GroupMenu';
import { openModal } from '../../actions/user_authentication';

const mapDispatchToProps = dispatch => ({
  editGroup: group => dispatch(openModal('editLocalGroup', group)),
  deleteGroup: group => dispatch(openModal('deleteLocalGroup', group)),
});

export default connect(
  undefined,
  mapDispatchToProps
)(GroupMenu);
