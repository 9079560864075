import { connect } from 'react-redux';
import ActivateLogin from '../../components/activate/ActivateLogin';
import { activate_login } from '../../actions/login';

const mapStateToProps = state => ({
  accounts: state.account.accounts,
  processing: state.login.isProcessing,
});

const mapDispatchToProps = dispatch => ({
  handleLogin: (username, password) => {
    dispatch(
      activate_login({
        username,
        password,
        activate_only: true,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateLogin);
