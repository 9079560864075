import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18nify';
import RadioSelector from '../../components/common/RadioSelector';

import { openModal } from '../../actions/user_authentication';

import { Mixpanel } from '../../lib/Mixpanel';

const track_event = {
  directory: 'Users / Directory Services',
  local: 'Users / Manually Configure Users',
};

const mapStateToProps = state => {
  let options = [
    {
      value: 'directory',
      label: translate('userAuthentication.directoryOption'),
    },
    {
      value: 'local',
      label: translate('userAuthentication.localOption'),
    },
  ];

  options.push({
    value: 'synctool',
    label: translate('userAuthentication.directorySync'),
  });

  return {
    value: state.userAuthentication.userType,
    label: translate('userAuthentication.configureUsers'),
    options: options,
  };
};

const mapDispatchToProps = dispatch => ({
  handleChange: e => {
    Mixpanel.track(track_event[e.target.value], {});
    dispatch(
      openModal('confirmTypeChange', {
        type: e.target.value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RadioSelector));
