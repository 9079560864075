import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Translate, translate } from 'react-i18nify';
import Input from '@material-ui/core/Input/Input';

import { FileIcon, FolderIcon } from '../icons';

import CudaDropdown from '../CudaDropdown';

import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
  ActionCancelButton,
} from '../CudaDialog';

const dialogStyleSheet = {
  dialog: {
    overflow: 'visible',
  },
  defaultText: {
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
    marginLeft: 15,
  },
  nameLabel: {
    extend: 'label',
    marginLeft: 25,
    marginBottom: 5,
  },
  domainsLabel: {
    extend: 'label',
    marginLeft: -5,
  },
  singlelineTextDiv: {
    fontSize: 13,
    '& > div > input': {
      border: '1px solid #DDDDDD',
      borderRadius: 3,
      fontSize: 14,
      paddingLeft: 5,
    },
    '& > div > input:focus': {
      border: '1px solid #a9a9a9',
    },
  },
  singlelineTextDivError: {
    extend: 'singlelineTextDiv',
    '& > div > input': {
      borderColor: '#DD1E31',
    },
    '& > div > input:focus': {
      borderColor: '#DD1E31',
    },
  },
  singlelineInput: {
    width: '100%',
  },
  multilineWrapper: {
    padding: '0 20px',
  },
  errorLabel: {
    fontSize: 12,
    margin: '0 0 25px 25px',
    color: '#DD1E31',
    height: 10,
  },
  infoLabel: {
    extend: 'errorLabel',
    color: 'rgba(0,0,0,0.3)',
    marginBottom: 10,
  },
};

class AddCustomExclusionDialogComponent extends Component {
  state = {
    selectedType: 'file',
    inputValue: '',
    openMenu: false,
  };

  resetState = () => {
    this.setState({ selectedType: 'file', inputValue: '' });
  };

  handleChange = value => {
    this.setState({ selectedType: value });
  };

  toggleMenu() {
    this.setState(prevState => {
      return {
        ...prevState,
        openMenu: !prevState.openMenu,
      };
    });
  }

  handleClose() {
    this.setState({ openMenu: false });
  }

  render() {
    const {
      classes,
      showCustomExclusion,
      closePopUp,
      addCustomExclusion,
    } = this.props;
    const items = [
      {
        text: <Translate value="components.atp.file" />,
        value: 'file',
        icon: <FileIcon color="#5F5F5F" />,
      },
      {
        text: <Translate value="components.atp.path" />,
        value: 'path',
        icon: <FolderIcon color="#5F5F5F" />,
      },
    ];

    const track_selection = {
      file: 'Threat Policy / Add Exception Dialog / Exception Type File',
      path: 'Threat Policy / Add Exception Dialog / Exception Type Path',
    };

    return (
      <Dialog
        open={showCustomExclusion}
        maxWidth="md"
        onEscapeKeyDown={closePopUp}
      >
        <CudaDialogTitle
          onRequestClose={() => {
            closePopUp();
          }}
        >
          <Translate value="components.atp.addExclusion" />
        </CudaDialogTitle>
        <CudaDialogContent overflow="visible" loadingAnimation={false}>
          <CudaDialogRegularContent>
            <div className={classes.defaultText}>
              <Translate
                value="components.atp.customExclusionText"
                dangerousHTML
              />
            </div>
            <Grid
              container
              className={classes.container}
              spacing={2}
              justify="space-between"
            >
              <Grid item xs={3}>
                Exception Type
              </Grid>
              <Grid item xs={9}>
                Value
              </Grid>
              <Grid item xs={3}>
                <CudaDropdown
                  menuOpen={this.state.openMenu}
                  items={items}
                  selectedItemValue={this.state.selectedType}
                  toggleMenu={e => {
                    this.toggleMenu();
                  }}
                  closeMenu={() => {
                    this.handleClose();
                  }}
                  onSelect={value => {
                    return this.handleChange(value);
                  }}
                  withIcons={true}
                />
              </Grid>
              <Grid item xs={9}>
                <div className={classes.singlelineTextDiv}>
                  <Input
                    autoFocus
                    className={classes.singlelineInput}
                    disableUnderline
                    value={this.state.inputValue}
                    onChange={e => {
                      this.setState({ inputValue: e.target.value });
                    }}
                    onBlur={() => {}}
                    placeholder="example: [drive letter]:\path\ or [path_macro]\path\ "
                  />
                </div>
              </Grid>
            </Grid>
          </CudaDialogRegularContent>
        </CudaDialogContent>
        <DialogActions>
          <div>
            <ActionCancelButton
              onClick={() => {
                closePopUp();
              }}
            >
              <Translate value="settings.webscraping.dialog.cancel" />
            </ActionCancelButton>
            <ActionButton
              color="primary"
              onClick={() => {
                addCustomExclusion(
                  this.state.selectedType,
                  this.state.inputValue
                );
                this.resetState();
                closePopUp();
              }}
            >
              <Translate value="shared.add" />
            </ActionButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(dialogStyleSheet, {
  name: 'AllowedBotsDialogStylesheet',
})(AddCustomExclusionDialogComponent);
