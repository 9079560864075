import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';
import Dropdown from '../common/Dropdown';

const MoveLocalUser = ({ classes, data, groups, changeGroup, saving }) => (
  <React.Fragment>
    <Translate
      value="userAuthentication.local.moveUserText"
      user={`${data.name} (${data.email})`}
      className={classes.text}
      tag="div"
    />
    <Dropdown
      data-test-id="agent-update-type"
      value={groups.find(option => option.value === data.groupID)}
      options={groups}
      isDisabled={saving}
      onChange={changeGroup}
      placeholder=""
      className={classes.groupList}
    />
    <Translate
      value="userAuthentication.local.moveUserSubText"
      className={classes.subText}
      tag="div"
    />
  </React.Fragment>
);

const styles = theme => ({
  text: {
    marginBottom: '8px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  groupList: {
    marginBottom: '24px',
  },
  subText: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '12px',
    lineHeight: '16px',
  },
});

export default withStyles(styles, { name: 'MoveLocalUser' })(MoveLocalUser);
