// API Calls
export const AUDIT_LOG_INIT_DATA = 'AUDIT_LOG_INIT_DATA';
export const AUDIT_LOG_FETCH_DATA = 'AUDIT_LOG_FETCH_DATA';
export const GET_AUDIT_LOG_SUCCESS = 'GET_AUDIT_LOG_SUCCESS';
export const GET_AUDIT_LOG_FAILURE = 'GET_AUDIT_LOG_FAILURE';

export const AUDIT_LOG_SORT_DATA = 'AUDIT_LOG_SORT_DATA';

export const initData = (params, smb) => ({
  type: AUDIT_LOG_INIT_DATA,
  params,
  smb,
});

export const fetchData = (params, smb = false) => ({
  type: AUDIT_LOG_FETCH_DATA,
  params,
  smb,
});

export const sortData = (orderBy, order, specialMap, smb = false) => ({
  type: AUDIT_LOG_SORT_DATA,
  orderBy,
  order,
  specialMap,
  smb,
});

export const getAuditLogSuccess = result => ({
  type: GET_AUDIT_LOG_SUCCESS,
  result,
});

export const getAuditLogFailure = error => ({
  type: GET_AUDIT_LOG_FAILURE,
  error,
});
