import { connect } from 'react-redux';
import AddSharedThreatPolicyModal from '../../components/shared_policies/AddSharedThreatPolicyModal';

import { Mixpanel } from '../../lib/Mixpanel';
import {
  addSharedPolicies,
  closeAddThreatModal,
} from '../../actions/shared_policies';

import { getChangedFields } from '../../selectors/smb_account_settings';

const mapStateToProps = state => {
  let disabled = true;

  return {
    disabled:
      !Boolean(Object.keys(state.shared_policies.changes).length) ||
      !Boolean(state.shared_policies.accountSelections.length),
    loading: state.shared_policies.sharedThreatPolicy.loading,
    showModal: state.shared_policies.sharedThreatPolicy.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    //Mixpanel.track('Advanced Filtering / Add Rule Dialog / Create', {});
    dispatch(addSharedPolicies());
  },
  closeDialog: () => {
    //Mixpanel.track('Advanced Filtering / Add Rule Dialog / Cancel', {});
    dispatch(closeAddThreatModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSharedThreatPolicyModal);
