import React from 'react';
import { Translate } from 'react-i18nify';
import { connect } from 'react-redux';
import RefreshIcon from '@material-ui/icons/Refresh';
import HeaderButton from '../../components/log_table/HeaderButton';
import { getData } from '../../actions/wf_logs';

const mapStateToProps = state => ({
  loading: state.wfLogs.refreshing,
  value: <Translate value="shared.refreshLogs" />,
  icon: <RefreshIcon />,
});

const mapDispatchToProps = dispatch => ({
  clickAction: () => {
    dispatch(getData());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderButton);
