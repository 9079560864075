import { connect } from 'react-redux';
import EditPolicy from '../../components/advanced_filtering/EditPolicy';

import { TYPE_OPTIONS } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  const typeIndex = state.advanced_filtering.ruleModal.selectedTypeIndex;

  const display =
    state.advanced_filtering.selectedRule.display ||
    TYPE_OPTIONS[typeIndex].key;

  return {
    display,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(EditPolicy);
