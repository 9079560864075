import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';
import logo from '../barracuda_logo_dark.svg';
import {
  REACT_APP_ONETRUST_SCRIPT_DOMAIN,
  REACT_APP_ONETRUST_SCRIPT_URL,
} from '../lib/Env';

const oneTrustEnvVarsSet =
  REACT_APP_ONETRUST_SCRIPT_URL.length > 0 &&
  REACT_APP_ONETRUST_SCRIPT_DOMAIN.length > 0;

const FooterComponent = ({ classes }) => (
  <div className={classes.container}>
    <Typography type="caption" className={classes.footerText}>
      <Translate
        value="components.footer.copyright"
        year={new Date().getFullYear()}
        className={classes.copyright}
      />
      |
      <a
        href={'https://www.barracuda.com/company/legal/privacy'}
        className={classes.privacy}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Translate value="components.footer.privacy" />
      </a>
      |
      <a
        href={'https://www.barracuda.com/legal/terms'}
        className={classes.terms}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Translate value="components.footer.terms" />
      </a>
      |
      <span
        id="ot-sdk-btn"
        className={`ot-sdk-show-settings ${classes.cookie}`}
      >
        <Translate value="components.footer.cookies" />
      </span>
    </Typography>
    <img src={logo} className={classes.logo} alt="logo" />
  </div>
);

const styles = {
  container: {
    padding: '0px 15px 0px 15px',
  },
  footerText: {
    fontSize: '12px',
    lineHeight: '14px',
    paddingTop: '11px',
    display: 'inline-block',
  },
  copyright: {
    marginRight: '5px',
  },
  privacy: {
    color: '#0088CF',
    textDecoration: 'none',
    marginLeft: '5px',
    marginRight: '5px',
  },
  terms: {
    color: '#0088CF',
    textDecoration: 'none',
    marginLeft: '5px',
    marginRight: '5px',
  },
  cookie: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    border: '0 !important',
    color: '#0088CF !important',
    padding: '0 !important',
    fontSize: '12px !important',
    cursor: 'pointer',
    textDecoration: 'none',
    marginLeft: '5px',
    marginRight: '5px',
  },
  logo: {
    float: 'right',
  },
};

export default withStyles(styles, { name: 'FooterStyles' })(FooterComponent);
