import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import HelpTooltipIcon from '../HelpTooltipIcon';
import CudaMenu from '../CudaMenu';

const SelectionInput = ({
  classes,
  name,
  options,
  selectedIndex,
  onChange,
  helpText,
  tooltipTestId,
  disabled,
  ...others
}) => (
  <Grid container spacing={0} className={classes.wrapper} {...others}>
    <Grid item xs={3}>
      {name}
    </Grid>
    <Grid item xs={3}>
      <CudaMenu
        customClasses={[classes.menu]}
        options={options}
        selectedIndex={selectedIndex}
        onChange={onChange}
        disabled={disabled}
      />
    </Grid>
    <Grid item xs={6}>
      {helpText && (
        <HelpTooltipIcon
          tooltipText={helpText}
          className={classes.icon}
          testId={tooltipTestId}
        />
      )}
    </Grid>
  </Grid>
);

SelectionInput.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  help: PropTypes.string,
  tooltipTestId: PropTypes.string,
  disabled: PropTypes.bool,
};
SelectionInput.defaultProps = {
  helpText: '',
  disabled: false,
};

const styles = {
  icon: {
    height: '20px',
    marginLeft: '8px',
  },
  menu: {
    marginTop: '4px',
  },
  wrapper: {
    alignItems: 'center',
    paddingBottom: '15px',
  },
};

export default withStyles(styles, { name: 'SelectionInput' })(SelectionInput);
