import { connect } from 'react-redux';
import CategoryTable from '../../components/dashboard/CategoryTable';

const mapStateToProps = state => ({
  data: state.dashboard.categories.blockedSupercategories,
  firstColumn: 'supercategory',
  loading: state.dashboard.blocked_infoLoading,
  id: 'superCategoryTable',
});

export default connect(
  mapStateToProps,
  undefined
)(CategoryTable);
