import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from './modal/Modal';

const SessionModal = ({
  classes,
  title,
  showModal,
  closeDialog,
  buttonAction,
  content,
  ...others
}) => (
  <Modal
    title={title}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    contentClass={classes.shortModal}
    {...others}
  >
    <div className={classes.content}>{content}</div>
  </Modal>
);

SessionModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
};

const styles = {
  content: {
    padding: '24px 30px 24px 24px',
  },
  shortModal: {
    width: '500px',
  },
};

export default withStyles(styles, { name: 'SessionModal' })(SessionModal);
