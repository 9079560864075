import React from 'react';
import { connect } from 'react-redux';
import WizardModal from '../../components/basic_filtering/WizardModal';

import NetworkPolicy from '../../components/basic_filtering/NetworkPolicy';
import CategoryPolicy from './CategoryPolicy';
import ExceptionPolicy from './ExceptionPolicy';
import ConfigureDNS from '../../components/basic_filtering/ConfigureDNS';
import GettingStarted from './GettingStarted';

const mapStateToProps = (state, ownProps) => {
  const type = state.basic_filtering.ipType;

  const steps =
    type === 'dynamic'
      ? [
          'components.basicFiltering.categories',
          'components.basicFiltering.exceptions',
          'components.basicFiltering.configureDNS',
        ]
      : [
          'components.basicFiltering.outboundAddress',
          'components.basicFiltering.categories',
          'components.basicFiltering.exceptions',
          'components.basicFiltering.configureDNS',
        ];
  const views =
    type === 'dynamic'
      ? [<CategoryPolicy />, <ExceptionPolicy />, <ConfigureDNS />]
      : [
          <NetworkPolicy hideName />,
          <CategoryPolicy />,
          <ExceptionPolicy />,
          <ConfigureDNS />,
        ];
  const disableNext =
    type === 'dynamic'
      ? []
      : [() => ownProps.locationConfig.addresses.length <= 0];

  return {
    steps,
    views,
    disableNext,
    startPage: true,
    startView: <GettingStarted />,
    error: state.app.error,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(WizardModal);
