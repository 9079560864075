import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import PaginatorComponent from '../Paginator';
import CudaTableTitle from '../CudaTableTitle';

import Timestamp from '../../containers/Timestamp';

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  dateColumn: {
    width: '20%',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  pathColumn: {
    maxWidth: '250px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  fileColumn: {
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tableTitle: {
    '& > th': {
      borderTop: '1px solid #DDDDDD',
    },
  },
  reportTableBody: {
    '& > tr': {
      height: '40px',
    },
  },
});

class BarTableComponent extends Component {
  state = {
    anchorEl: null,
    open: false,
    moreAnchorEl: null,
    icons: ['desc', 'none', 'none', 'none', 'none'],
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRequestClose = option => {
    this.setState({ open: false });
    this.props.updateRows(option);
  };

  render() {
    const { classes, entries, total, filters, nextPage, prevPage } = this.props;

    const options = [10, 20, 30];

    return (
      <div className={classes.root}>
        <Table>
          <TableHead>
            <TableRow className={classes.tableTitle}>
              <TableCell>
                <CudaTableTitle value="shared.date" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.endpointName" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.agentVersion" />
              </TableCell>
              <TableCell>
                <CudaTableTitle value="shared.os" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.reportTableBody}>
            {entries.map((n, i) => {
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Timestamp timestamp={n['_source'].timestamp} />
                  </TableCell>
                  <TableCell>{n['_source']['device_name']}</TableCell>
                  <TableCell>
                    {n['_source']['device_info']['agent_version']}
                  </TableCell>
                  <TableCell>
                    {n['_source']['device_info']['os_version']}
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell colSpan="6">
                <PaginatorComponent
                  text="Rows per page: "
                  itemsTotal={total}
                  pageNumber={filters.index}
                  onPaginateClick={this.handleClick}
                  onNextClick={nextPage}
                  onPreviousClick={prevPage}
                  itemsPerPage={filters.rows}
                />
                <Menu
                  anchorEl={this.state.anchorEl}
                  open={this.state.open}
                  onRequestClose={this.handleClose}
                >
                  {options.map(option => (
                    <MenuItem
                      key={option}
                      onClick={() => {
                        this.handleRequestClose(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </Menu>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

BarTableComponent.propTypes = {
  entries: PropTypes.array.isRequired,
};

export default withStyles(styles, { name: 'ReportsBarTableComponent' })(
  BarTableComponent
);
