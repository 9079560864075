import { connect } from 'react-redux';
import RemoveFromGroup from '../../components/user_authentication/RemoveFromGroup';

const mapStateToProps = state => ({
  data: state.userAuthentication.modal.data,
});

export default connect(
  mapStateToProps,
  undefined
)(RemoveFromGroup);
