import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import PieComponent from '../charts/TwoLevelPie';
import TimeSelect from '../../containers/overview/TimeSelect';
import Paper from '../CudaPaper';

const ThreatPieChart = ({ classes, options, selectedIndex, data, loading }) => {
  return (
    <Paper>
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <div className={classes.headerSection}>
            <div className={classes.title}>Threats by File Type</div>
            <div className={classes.subtitle}>
              {options[selectedIndex].label}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <PieComponent
              data={data}
              legend={false}
              width={600}
              height={300}
              widthDifferential={25}
              legendTop="115px"
            />
          )}
        </Grid>
        <Grid item xs={12} data-test-id="threatPieSelectionWrapper">
          <TimeSelect />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  container: {},
  headerSection: {
    margin: '20px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
});

ThreatPieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key: PropTypes.string,
    })
  ).isRequired,
  options: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ThreatPieChart);
