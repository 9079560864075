import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import LogDetails from './LogDetails';
import RuleLink from './RuleLink';
import ActionCell from './ActionCell';
import ExpandIcon from '../expandable_table/ExpandIcon';

import Timestamp from '../../containers/Timestamp';

class ExpandableRow extends React.Component {
  constructor(props) {
    super(props);
    this.toggleExpand = this.toggleExpand.bind(this);
  }

  state = {
    expanded: false,
  };

  toggleExpand() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    const {
      classes,
      columns,
      n,
      index,
      cats,
      supercats,
      locations,
      users,
      colCount,
    } = this.props;
    const { expanded } = this.state;

    return [
      expanded && (
        <TableRow
          key={`${n.id}-spacer-1`}
          style={{ height: '4px' }}
          className={classes.spacerRow}
        >
          <TableCell
            style={{ backgroundColor: '#F7F7F7', border: 'none' }}
            colSpan={colCount}
          />
        </TableRow>
      ),
      <TableRow
        data-test-id={`row-${index}`}
        key={n.id}
        className={classNames(classes.row, { [classes.expanded]: expanded })}
      >
        <TableCell
          style={{
            width: '20px',
            padding: '0 0 0 24px',
          }}
        >
          <ExpandIcon
            data-test-id="expand-icon"
            expanded={expanded}
            toggleExpand={this.toggleExpand}
          />
        </TableCell>
        {columns.date.selected && (
          <TableCell>
            <Timestamp data-test-id="timestamp" timestamp={n.timestamp} />
          </TableCell>
        )}
        {columns.action.selected && (
          <TableCell>
            <ActionCell
              data-test-id="action-cell"
              action={n.action.toLowerCase()}
            />
          </TableCell>
        )}
        {columns.user.selected && (
          <TableCell data-test-id="location-cell">
            {n.username ? n.username : locations[n.location_id]}
          </TableCell>
        )}
        {columns.rule.selected && (
          <TableCell data-test-id="rule-link-cell">
            {!n.action_reason ||
            (n.action_reason && !n.action_reason.includes('Security')) ? (
              <RuleLink
                rule={n.type === 'wca' ? n.rule_id : n.location_id}
                type={n.type}
              />
            ) : (
              <Translate value="shared.security" />
            )}
          </TableCell>
        )}
        {columns.supercategory.selected &&
          (n.action_reason && n.action_reason.includes('Security') ? (
            <TableCell data-test-id="supercategory-cell">
              <Translate value="shared.security" />
            </TableCell>
          ) : (
            <TableCell data-test-id="supercategory-cell">
              {n.supercategories &&
                n.supercategories
                  .map(supercat => supercats[supercat])
                  .join(',')}
            </TableCell>
          ))}
        {columns.category.selected &&
          (n.action_reason && n.action_reason.includes('Security') ? (
            <TableCell data-test-id="category-cell">
              <Translate value="shared.security" />
            </TableCell>
          ) : (
            <TableCell data-test-id="category-cell">
              {n.categories &&
                n.categories
                  .map(cat => (cat in cats ? cats[cat].display : 'Unknown'))
                  .join(',')}
            </TableCell>
          ))}
        {columns.url.selected && (
          <TableCell data-test-id="domain-cell">{n.domain}</TableCell>
        )}
      </TableRow>,
      expanded && (
        <TableRow
          data-test-id={`expanded-row-${index}`}
          key={`${n.id}-expanded`}
          className={classNames(classes.details, classes.row, {
            [classes.expanded]: expanded,
          })}
        >
          <TableCell
            colSpan={colCount}
            style={{
              width: '20px',
              padding: '0',
            }}
          >
            <LogDetails
              log={{
                ...n,
                source: n.username ? n.username : locations[n.location_id],
                categories: n.categories
                  ? n.categories
                      .map(cat => (cat in cats ? cats[cat].display : 'Unknown'))
                      .join(',')
                  : null,
                date: <Timestamp timestamp={n.timestamp} />,
              }}
            />
          </TableCell>
        </TableRow>
      ),
      expanded && (
        <TableRow
          key={`${n.id}-spacer-2`}
          style={{ height: '4px' }}
          className={classes.spacerRow}
        >
          <TableCell
            style={{ backgroundColor: '#F7F7F7', border: 'none' }}
            colSpan={colCount}
          />
        </TableRow>
      ),
    ];
  }
}

ExpandableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  index: PropTypes.number,
};

const styles = theme => ({
  details: {
    border: '2px solid #EEEEEE',
    borderTop: '1px solid #EEEEEE',
  },
  spacerRow: {
    '& + &': {
      display: 'none',
    },
  },
  row: {
    '& td': {
      borderTop: `1px solid ${theme.colors.tableBorder}`,
      borderBottom: `1px solid ${theme.colors.tableBorder}`,
    },
  },
  expanded: {
    '& td:first-child > span': {
      padding: 0,
    },
    '& td': {
      borderBottom: 'none',
      padding: '0 24px',
      '&> span': {
        display: 'flex',
        height: '32px',
        lineHeight: '20px',
        padding: '15px 0',
        boxSizing: 'border-box',
      },
    },
  },
});

export default withStyles(styles, { name: 'ExpandableRow' })(ExpandableRow);
