import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import SelectionInput from '../../components/advanced_filtering/SelectionInput';

import { TYPE_OPTIONS, selectType } from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = (state, ownProps) => ({
  name: translate('components.advancedFiltering.type'),
  options: TYPE_OPTIONS,
  selectedIndex: state.advanced_filtering.ruleModal.selectedTypeIndex,
  helpText: translate('components.advancedFiltering.typeHelpText'),
  tooltipTestId: 'type-selection',
});

const mapDispatchToProps = dispatch => ({
  onChange: index => {
    Mixpanel.track('Advanced Filtering / Add Rule Dialog / Type Dropdown', {});
    if (index === 0) {
      Mixpanel.track(
        'Advanced Filtering / Add Rule Dialog / Type Categories',
        {}
      );
    } else if (index === 1) {
      Mixpanel.track('Advanced Filtering / Add Rule Dialog / Type Domains', {});
    } else {
      Mixpanel.track('Advanced Filtering / Add Rule Dialog / Type URLs', {});
    }
    dispatch(selectType(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionInput);
