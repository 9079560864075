import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment-timezone';

const Timestamp = ({ timestamp, format, timezone, ...others }) => (
  <span {...others}>{moment.tz(timestamp, timezone).format(format)}</span>
);

Timestamp.propTypes = {
  format: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  timezone: PropTypes.string,
};
Timestamp.defaultProps = {
  format: 'MMM DD, YYYY HH:mm:ss',
  timezone: 'America/Los_Angeles',
};

export default Timestamp;
