import { connect } from 'react-redux';
import ReportDataTable from '../components/reports/OverviewReport';

const mapStateToProps = (state, ownProps) => ({
  data: state.reports.overviewReport,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDataTable);
