import { connect } from 'react-redux';
import ReportModal from '../../components/reports/ReportModal';
import {
  getFilteredTypes,
  getUserOptions,
  getGroupOptions,
  getLocationOptions,
} from '../../selectors/reports';

import {
  closeEditReportModal,
  timeOptions,
  frequencyOptions,
  hourOptions,
  dayOptions,
  weekOptions,
  selectScheduleReport,
  selectScheduleReportTimeframe,
  selectScheduleReportFrequency,
  selectHour,
  selectDay,
  selectWeek,
  selectUser,
  selectGroup,
  selectLocation,
  onChangeRecipients,
  editReport,
} from '../../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  open: state.reports.editModal.open,
  reportIndex: state.reports.editModal.reportIndex,
  timeframeIndex: state.reports.editModal.timeframeIndex,
  frequencyIndex: state.reports.editModal.frequencyIndex,
  hourIndex: state.reports.editModal.hourIndex,
  weekIndex: state.reports.editModal.weekIndex,
  dayIndex: state.reports.editModal.dayIndex,
  recipients: state.reports.editModal.recipients,
  title: 'Schedule Report',
  reportOptions: getFilteredTypes(state),
  timeOptions: timeOptions,
  frequencyOptions: frequencyOptions,
  hourOptions: hourOptions,
  weekOptions: weekOptions,
  dayOptions: dayOptions,
  locationOptions: getLocationOptions(state),
  locationSelectIndex: state.reports.editModal.locationSelectIndex,
  userOptions: getUserOptions(state),
  groupOptions: getGroupOptions(state),
  userSelectIndex: state.reports.editModal.userSelectIndex,
  groupSelectIndex: state.reports.editModal.groupSelectIndex,
  timezone: state.account.time_zone,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClose: () => {
    dispatch(closeEditReportModal());
  },
  selectReport: (index, options) => {
    dispatch(selectScheduleReport('editModal', index, options));
  },
  selectTimeframe: index => {
    dispatch(selectScheduleReportTimeframe('editModal', index));
  },
  selectFrequency: index => {
    dispatch(selectScheduleReportFrequency('editModal', index));
  },
  onChangeRecipients: value => {
    dispatch(onChangeRecipients('editModal', value));
  },
  selectHour: index => {
    dispatch(selectHour('editModal', index));
  },
  selectWeek: index => {
    dispatch(selectWeek('editModal', index));
  },
  selectDay: index => {
    dispatch(selectDay('editModal', index));
  },
  onSave: () => {
    dispatch(editReport());
  },
  userSelectHandler: index => {
    dispatch(selectUser('editModal', index));
  },
  groupSelectHandler: index => {
    dispatch(selectGroup('editModal', index));
  },
  locationSelectHandler: index => {
    dispatch(selectLocation('editModal', index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportModal);
