import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

const LocalUserPrompt = ({ classes }) => (
  <React.Fragment>
    <div className={classes.text}>
      <Translate value="userAuthentication.localUserPrompt" />
    </div>
    <div className={classes.text}>
      <Translate value="userAuthentication.switchLocalTool" />
    </div>
  </React.Fragment>
);

const styles = theme => ({
  text: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
});

export default withStyles(styles, { name: 'LocalUserPrompt' })(LocalUserPrompt);
