import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import EditPolicy from '../../containers/advanced_filtering/EditPolicy';

const EditPolicyModal = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
  overflow,
}) => (
  <EditModal
    title={
      overflow
        ? translate('components.advancedFiltering.editUsersGroups')
        : translate('components.advancedFiltering.editRule')
    }
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
    customClasses={
      overflow && {
        paper: classes.dialog,
        root: classes.dialog,
      }
    }
  >
    <EditPolicy />
  </EditModal>
);

EditPolicyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

EditPolicyModal.defaultProps = {
  disabled: false,
};

const styles = {
  dialog: {
    overflow: 'visible',
  },
};

export default withStyles(styles, { name: 'EditPolicyModal' })(EditPolicyModal);
