import { connect } from 'react-redux';
import EditTimeConstraint from '../../components/advanced_filtering/EditTimeConstraint';

import {
  TYPE_OPTIONS,
  selectDow,
  selectTime,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  return {
    dow: state.advanced_filtering.editTimeConstraintModal.dow,
    startTime: state.advanced_filtering.editTimeConstraintModal.startTime,
    endTime: state.advanced_filtering.editTimeConstraintModal.endTime,
    showForm: state.advanced_filtering.editTimeConstraintModal.selectIndex > 0,
    startError: state.advanced_filtering.editTimeConstraintModal.startError,
  };
};

const mapDispatchToProps = dispatch => ({
  selectDow: (name, value) => {
    dispatch(selectDow(name, value));
  },
  selectTime: (name, value) => {
    dispatch(selectTime(name, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTimeConstraint);
