import { connect } from 'react-redux';
import EditPolicyModal from '../../components/advanced_filtering/EditPolicyModal';

import {
  // TYPE_OPTIONS,
  editRule,
  closeRule,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  const cats = state.advanced_filtering.ruleModal.categories.toString();
  const oCats = state.advanced_filtering.ruleModal.origCategories;
  const domains = state.advanced_filtering.ruleModal.domains.toString();
  const oDomains = state.advanced_filtering.ruleModal.origDomains;
  const urls = state.advanced_filtering.ruleModal.urls.toString();
  const oUrls = state.advanced_filtering.ruleModal.origUrls;

  return {
    // Before edit this is an array, after it's an object
    disabled:
      Array.isArray(state.advanced_filtering.ruleModal.adGroup) &&
      cats === oCats &&
      domains === oDomains &&
      urls === oUrls,
    loading: state.advanced_filtering.selectedRule.loading,
    overflow: Boolean(state.advanced_filtering.selectedRule.display),
    showModal: state.advanced_filtering.selectedRule.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: rule => {
    dispatch(editRule(rule));
  },
  closeDialog: () => {
    dispatch(closeRule());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPolicyModal);
