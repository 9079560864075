import { connect } from 'react-redux';
import UrlLogging from '../../components/advanced_filtering/UrlLogging';

import { toggleUrlLogging } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  disabledAD: state.advanced_filtering.urlLoggingModal.disabledAD,
  enableUrlLogging: state.advanced_filtering.urlLoggingModal.enabled,
});

const mapDispatchToProps = dispatch => ({
  toggleUrlLogging: value => {
    dispatch(toggleUrlLogging());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UrlLogging);
