import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { translate } from 'react-i18nify';

import InstallerHeaderRow from './InstallerHeaderRow';
import InstallerRows from './InstallerRows';

class InstallerTable extends React.Component {
  state = {
    currentExpanded: true,
    olderExpanded: false,
  };

  toggleCurrent = () =>
    this.setState(prevState => ({
      ...prevState,
      currentExpanded: !prevState.currentExpanded,
    }));

  toggleOlder = () =>
    this.setState(prevState => ({
      ...prevState,
      olderExpanded: !prevState.olderExpanded,
    }));

  render() {
    const { latest, older, demo } = this.props;
    const { currentExpanded, olderExpanded } = this.state;
    const { toggleCurrent, toggleOlder } = this;

    return (
      <Table>
        <TableBody>
          <InstallerHeaderRow
            data-test-id="current-agents-header"
            handleToggle={toggleCurrent}
            expanded={currentExpanded}
            label={translate('components.deploy.currentVersion')}
          />
          {currentExpanded && (
            <InstallerRows
              dataTestId="current-agents"
              installers={latest}
              demo={demo}
            />
          )}
          <InstallerHeaderRow
            data-test-id="older-agents-header"
            handleToggle={toggleOlder}
            expanded={olderExpanded}
            label={translate('components.deploy.olderVersions', {
              count: older.length,
            })}
          />
          {olderExpanded && (
            <InstallerRows
              dataTestId="older-agents"
              installers={older}
              demo={demo}
            />
          )}
        </TableBody>
      </Table>
    );
  }
}

InstallerTable.propTypes = {
  latest: PropTypes.array.isRequired,
  older: PropTypes.array.isRequired,
};

export default InstallerTable;
