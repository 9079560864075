import { connect } from 'react-redux';
import MoreOption from '../../components/devices/MoreOption';

import { openSupportToolModal } from '../../actions/devices';

const mapStateToProps = state => ({
  generate: true,
});

const mapDispatchToProps = dispatch => ({
  openSupportToolModal: device => {
    dispatch(openSupportToolModal(device));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreOption);
