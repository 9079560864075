import React, { Component } from 'react';
import { Translate, translate } from 'react-i18nify';
import DocumentTitle from 'react-document-title';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';

import SearchBar from '../components/SearchBar';

import CudaTableTitle from '../components/CudaTableTitle';
import Paper from './CudaPaper';

import Navigator from '../containers/shared_policies/Navigator';
import SharedTable from '../containers/shared_policies/Table';
import AddSharedThreatPolicyModal from '../containers/shared_policies/AddSharedThreatPolicyModal';
import AddCustomExclusionDialog from '../containers/shared_policies/AddCustomExclusionDialog';
import EditSharedThreatPolicyModal from '../containers/shared_policies/EditSharedThreatPolicyModal';
import EditCustomExclusionDialog from '../containers/shared_policies/EditCustomExclusionDialog';
import CopyAccountModal from '../containers/shared_policies/CopyAccountModal';
import DeleteSharedPolicyModal from '../containers/shared_policies/DeleteSharedPolicyModal';

const style = theme => ({
  content: {
    paddingTop: '18px !important',
  },
  thTitle: {
    textAlign: 'center',
  },
  tableTitle: {
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
  },
  manageButton: {
    height: '32px',
    paddingTop: '8px',
    paddingBottom: '8px',
    margin: 0,
  },
  nameColumn: {
    width: '75%',
  },
  deploy: {
    position: 'absolute',
    color: theme.colors.barracudaBlue,
    textDecoration: 'none',
    marginLeft: '8px',
    maxWidth: '125px',
  },
  numericCell: {
    textAlign: 'center',
  },
  actionColumnHeader: {
    width: '25%',
  },
  actionColumn: {
    width: '25%',
    textAlign: 'right',
  },
  deployButton: {
    height: '32px',
    lineHeight: '16px',
    padding: '8px 15px',
    fontWeight: 300,
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  placeholder: {
    height: '194px',
    width: '100%',
    backgroundColor: '#DDDDDD',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& div.title': {
      fontSize: '18px',
      lineHeight: '21px',
      color: 'rgba(0,0,0,0.7)',
    },
    '& div.subTitle': {
      fontSize: '14px',
      lineHeight: '16px',
      color: 'rgba(0,0,0,0.5)',
    },
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    overflowX: 'auto',
    width: '100%',
  },
  statsColumn: {
    display: 'flex',
    flexDirection: 'column',
    '& >div': {
      marginBottom: '24px',
    },
  },
  accounts: {},
  headerSection: {
    margin: '10px 0px 0px 20px',
  },
  searchWrapper: {
    margin: '14px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  licenses: {
    color: 'rgba(0,0,0,0.5)',
  },
  link: {
    color: theme.colors.barracudaBlue,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    textTransform: 'none',
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  buttonWrappers: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

class SharedPoliciesComponent extends Component {
  componentDidMount() {
    this.props.initData();
  }

  render() {
    const { classes } = this.props;

    return (
      <DocumentTitle title={translate('components.dashboard.page_title')}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={2} lg={2}>
            <Navigator />
          </Grid>
          <Grid item xs={12} md={10} lg={10} className={classes.content}>
            <Paper elevation={0}>
              <Grid container>
                <Grid item xs={12} sm={12} lg={12}>
                  <div className={classes.headerSection}>
                    <div className={classes.title}>
                      <Translate
                        value="shared.sharedThreatPolicies"
                        toggle="none"
                      />
                    </div>
                    <div className={classes.subtitle}>Some help text here</div>
                  </div>
                </Grid>
              </Grid>
            </Paper>
            <SharedTable />
          </Grid>
          <AddSharedThreatPolicyModal />
          <AddCustomExclusionDialog />
          <EditSharedThreatPolicyModal />
          <EditCustomExclusionDialog />
          <CopyAccountModal />
          <DeleteSharedPolicyModal />
        </Grid>
      </DocumentTitle>
    );
  }
}

export default withStyles(style, { name: 'SharedPolicies' })(
  SharedPoliciesComponent
);
