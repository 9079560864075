import { connect } from 'react-redux';
import ReportTable from '../components/reports/Table';

import {
  updateRows,
  firstPage,
  nextPage,
  prevPage,
  lastPage,
} from '../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  entries: state.reports.tableData,
  total: state.reports.total,
  filters: state.reports.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateRows: option => {
    dispatch(updateRows(option));
  },
  firstPage: () => {
    dispatch(firstPage());
  },
  nextPage: () => {
    dispatch(nextPage());
  },
  prevPage: () => {
    dispatch(prevPage());
  },
  lastPage: () => {
    dispatch(lastPage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportTable);
