import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js';
import './index.css';
import 'draft-js/dist/Draft.css';
import App from './App';
import { unregister } from './registerServiceWorker';
import configureStore from './store/configureStore';
import { translate, setTranslations, setLocale } from 'react-i18nify';
import translationsObject from './translate';
import createSagaMiddleware from 'redux-saga';
import { compose } from 'redux';
import saga from './reducers/sagas';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import { setupAxiosHandler } from './reducers/Api.js';
import { STAGE } from './lib/Env';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-113857650-1');

const sagaMiddleware = createSagaMiddleware();
const history = createBrowserHistory({
  basename: STAGE,
});
const router = routerMiddleware(history);

setTranslations(translationsObject);
setLocale('en');

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = configureStore(composeEnhancer, [router, sagaMiddleware]);

setupAxiosHandler(store, history);
sagaMiddleware.run(saga);

ReactDOM.render(
  <App store={store} history={history} />,
  document.getElementById('root')
);
unregister();
