import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import EditTimeConstraint from '../../containers/advanced_filtering/EditTimeConstraint';

const EditTimeConstraintModal = ({
  classes,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
}) => (
  <EditModal
    title={translate('components.advancedFiltering.timeConstraint')}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
  >
    <EditTimeConstraint />
  </EditModal>
);

EditTimeConstraintModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EditTimeConstraintModal.defaultProps = {
  disabled: false,
};

const styles = {
  dialog: {
    overflow: 'visible',
  },
};

export default withStyles(styles, { name: 'EditTimeConstraintModal' })(
  EditTimeConstraintModal
);
