import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import TextField from '../TextField';

const FormField = ({
  classes,
  field,
  name,
  disabled,
  padding,
  icon,
  ...other
}) => (
  <FormControl fullWidth error={Boolean(field.error)} disabled={disabled}>
    <TextField
      disableUnderline
      value={field.value}
      wrapperClass={classNames(classes.wrapper, { [classes.padding]: padding })}
      icon={icon}
      name={name}
      data-test-id={`${name}-input`}
      {...other}
    />
    <FormHelperText
      className={classNames(classes.helperText, {
        [classes.paddedHelper]: icon,
      })}
      data-test-id={`${name}-error`}
    >
      {field.error}
    </FormHelperText>
  </FormControl>
);

const styles = {
  padding: {
    marginTop: '16px',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  helperText: {
    marginTop: '4px',
  },
  paddedHelper: {
    marginLeft: '30px',
  },
};

FormField.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  padding: PropTypes.bool,
  field: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
};

FormField.defaultProps = {
  disabled: false,
  padding: false,
};

export default withStyles(styles, { name: 'FormField' })(FormField);
