import { createSelector } from 'reselect';

const getChanges = state => state.smbAccountSettings.changes;
const getSMBSettings = state => state.smbAccountSettings;

const getChangedFields = createSelector(
  [getChanges],
  changes => Object.keys(changes)
);

export { getChangedFields, getSMBSettings };
