import { connect } from 'react-redux';
import ReportPieTable from '../components/reports/PieTable';

const mapStateToProps = (state, ownProps) => ({
  entries: state.reports.graphData,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportPieTable);
