import React from 'react';
import { Translate, translate } from 'react-i18nify';
import { call, select, put, takeEvery, all, delay } from 'redux-saga/effects';
import * as Types from '../actions/wf_logs';
import { storeLoadedCats } from '../actions/basic_filtering';
import { getActiveDirectorySuccess } from '../actions/account';
import { getQueryLabels, getWfLogs } from '../selectors/wf_logs';
import Api from './Api';
import Auth from '../lib/Auth';
import * as AppTypes from '../actions/app';
import moment from 'moment-timezone';

import { getAccountId } from '../selectors/account';

const update = require('immutability-helper');
const getDateRangeState = state => state.dateRange;

const timeOptions = [
  {
    key: 'day',
    label: <Translate value="shared.ranges.day" />,
  },
  {
    key: 'week',
    label: <Translate value="shared.ranges.week" />,
  },
  {
    key: 'month',
    label: <Translate value="shared.ranges.month" />,
  },
];

const defaults = {
  filters: {
    query: '',
    queryField: 0,
  },
};
const now = new Date();

export default (
  state = {
    logs: [],
    page: 0,
    rowsPerPage: 20,
    logCount: 0,
    loading: false,
    sort: {
      field: 'date',
      direction: 'desc',
    },
    timeOptions,
    timeSelectedIndex: 1,
    range: 'week',
    locations: {},
    exporting: false,
    refreshing: false,
    filterSelection: [
      {
        ...defaults['filters'],
      },
    ],
    query: '',
    queryField: 0,
    queryFields: [
      {
        label: translate('components.wfLogs.categories'),
        field: 'categories',
      },
      {
        label: translate('components.wfLogs.supercategories'),
        field: 'supercategories',
      },
      {
        label: translate('components.wfLogs.location'),
        field: 'locations',
      },
      {
        label: translate('components.wfLogs.fullName'),
        field: 'users',
      },
      {
        label: translate('components.wfLogs.userId'),
        field: 'name',
        type: 'input',
      },
      {
        label: translate('components.wfLogs.action'),
        field: 'actions',
      },
      {
        label: translate('components.wfLogs.domain'),
        field: 'urls',
        type: 'input',
      },
    ],
    columnTypes: [
      'date',
      'action',
      'user',
      'rule',
      'supercategory',
      'category',
      'url',
    ],
    columns: {
      date: {
        selected: true,
        label: translate('shared.date'),
      },
      action: {
        selected: true,
        label: translate('components.wfLogs.action'),
      },
      user: {
        selected: true,
        label: translate('components.wfLogs.userId'),
      },
      rule: {
        selected: true,
        label: translate('components.wfLogs.rule'),
      },
      category: {
        selected: true,
        label: translate('components.wfLogs.categories'),
      },
      supercategory: {
        selected: false,
        label: translate('components.wfLogs.supercategories'),
      },
      location: {
        selected: false,
        label: 'Location',
      },
      url: {
        selected: true,
        label: translate('components.wfLogs.url'),
      },
    },
    datePickerOpen: false,
    dateRange: {
      fromMonth: new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000), // 365 days ago
      from: new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000), // 7 days ago
      to: now,
      enteredTo: now,
      quickRange: 'r_7d',
    },
  },
  action
) => {
  switch (action.type) {
    case Types.WF_LOGS_SEARCH_UPDATE:
      let settings = action.settings
        ? action.settings
        : [{ ...defaults.filters }];

      return {
        ...state,
        filterSelection: settings,
      };
    case Types.WF_LOGS_ADD_SEARCH_ROW:
      let filterSelection = [...state.filterSelection];
      filterSelection.push({
        ...defaults['filters'],
      });

      return {
        ...state,
        filterSelection: filterSelection,
      };
    case Types.WF_LOGS_REMOVE_SEARCH_ROW:
      filterSelection = [...state.filterSelection];
      filterSelection.splice(action.index, 1);

      return {
        ...state,
        filterSelection: filterSelection,
      };
    case Types.WF_LOGS_CLEAR_FILTER:
      return {
        ...state,
        filterSelection: [
          {
            ...defaults['filters'],
          },
        ],
      };
    case Types.WF_LOGS_GET_DATA:
    case Types.WF_LOGS_GET_MORE_LOGS:
      return {
        ...state,
        loading: true,
      };
    case Types.WF_LOGS_INIT_DATA:
      return {
        ...state,
        loading: true,
        query: '',
      };
    case Types.WF_LOGS_EXPORT_CSV:
      return {
        ...state,
        exporting: true,
      };
    case Types.WF_LOGS_EXPORT_CSV_SUCCESS:
    case Types.WF_LOGS_EXPORT_CSV_FAILURE:
      return {
        ...state,
        exporting: false,
      };
    case Types.WF_LOGS_QUERY_DATA:
      return {
        ...state,
        page: 0,
        loading: true,
      };
    case Types.WF_LOGS_UPDATE_FIELD:
      filterSelection = [...state.filterSelection];
      filterSelection[action.index].query = '';
      filterSelection[action.index].queryField = action.field;
      return {
        ...state,
        filterSelection: filterSelection,
        query: '',
        queryField: action.field,
      };
    case Types.WF_LOGS_UPDATE_QUERY:
      filterSelection = [...state.filterSelection];
      filterSelection[action.params.index].query = action.params.query;
      return {
        ...state,
        filterSelection: filterSelection,
        query: action.params.query,
      };
    case Types.WF_LOGS_GET_MORE_SUCCESS:
      return {
        ...state,
        loading: false,
        logs: [...state.logs, ...action.logs],
      };
    case Types.WF_LOGS_GET_SUCCESS:
      return {
        ...state,
        page: 0,
        logs: action.logs,
        logCount: action.logCount,
        loading: false,
      };
    case Types.WF_LOGS_GET_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case Types.WF_LOGS_CHANGE_PAGE:
      return {
        ...state,
        page: action.page,
        rowsPerPage: action.rowsPerPage,
      };
    case Types.WF_LOGS_UPDATE_SORT:
      return {
        ...state,
        loading: true,
        sort: action.sort,
      };
    case Types.WF_LOGS_STORE_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
      };
    case Types.WF_LOGS_TOGGLE_COLUMN:
      return {
        ...state,
        columns: update(state.columns, {
          [action.column]: {
            selected: {
              $set: action.checked,
            },
          },
        }),
      };
    case Types.WF_LOGS_UPDATE_RANGE:
      return {
        ...state,
        page: 0,
        loading: true,
        timeSelectedIndex: action.index,
        range: timeOptions[action.index].key,
      };
    case Types.TOGGLE_DATE_RANGE_PICKER:
      return {
        ...state,
        datePickerOpen: !state.datePickerOpen,
      };
    case Types.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: {
          ...state.dateRange,
          ...action.range,
        },
      };
    default:
      return state;
  }
};

function generateParams(store, actionParams = {}) {
  const filterSelections = store.wfLogs.filterSelection;
  let filterValues = {
    categories: [],
    supercategories: [],
    locations: [],
    actions: [],
    name: [],
    users: [],
    urls: [],
  };

  const params = {
    ...actionParams,
    order: store.wfLogs.sort.direction,
    range: store.wfLogs.range,
    account_id: store.selected,
    from: store.wfLogs.dateRange.from,
    to: store.wfLogs.dateRange.to,
    page: 'wf_logs',
  };

  for (let i = 0; i < filterSelections.length; i++) {
    let query = filterSelections[i].query;
    let field = store.wfLogs.queryFields[filterSelections[i].queryField].field;
    if (query) {
      switch (field) {
        case 'categories':
          if (query.value || query.value === 0) {
            if ('cats' in params) {
              params.cats = params.cats + ',' + [query.value].join(',');
            } else {
              params.cats = [query.value].join(',');
            }
          }
          break;
        case 'supercategories':
          if (query.value) {
            if ('supercats' in params) {
              params.supercats =
                params.supercats + ',' + [query.value].join(',');
            } else {
              params.supercats = [query.value].join(',');
            }
          }
          break;
        case 'locations':
          if (query.value) {
            if ('locations' in params) {
              params.locations =
                params.locations + ',' + [query.value].join(',');
            } else {
              params.locations = [query.value].join(',');
            }
          }
          break;
        case 'actions':
          if (query.value) {
            if ('actions' in params) {
              params.actions = params.actions + ',' + [query.value].join(',');
            } else {
              params.actions = [query.value].join(',');
            }
          }
          break;
        case 'name':
          if (query.value) {
            if ('username' in params) {
              params.username = params.username + ',' + [query.value].join(',');
            } else {
              params.username = [query.value].join(',');
            }
          }
          break;
        case 'users':
          if (query.value) {
            if ('users' in params) {
              params.users = params.users + ',' + [query.value].join(',');
            } else {
              params.users = [query.value].join(',');
            }
          }
          break;
        case 'urls':
          if (query.value) {
            if ('url' in params) {
              params.url = params.url + ',' + query.value;
            } else {
              params.url = query.value;
            }
          }
          break;
        default:
          break;
      }
    }
  }

  return params;
}

function* _fetchLogs(params) {
  let result = yield call(Api.getData, params);
  let count = 0;
  // Wait for 3 minutes max
  const MAX_TIME = 36;
  while ('cache_timestamp' in result && count < MAX_TIME) {
    yield delay(5000);
    result = yield call(Api.getData, {
      ...params,
      cache_timestamp: result['cache_timestamp'],
    });
    count += 1;
  }

  if (count >= MAX_TIME) {
    return -1;
  }

  return result;
}

function* updateFetchLogs(action) {
  try {
    const store = yield select();
    yield call(fetchLogs, action);
    const result = yield call(Api.accounts.update, store.account.selected, {
      wf_search: store.wfLogs.filterSelection,
    });
  } catch (e) {
    console.log('the error: ', e);
  }
}

function* fetchLogs(action) {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, action.params);
    let result = yield call(_fetchLogs, params);
    if (result === -1) {
      yield put(Types.getFailure());
      yield put(AppTypes.error(translate('errors.timeout')));
    } else {
      yield put(Types.getSuccess(result));
    }
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

function* fetchMoreLogs(action) {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, action.params);

    let result = yield call(_fetchLogs, params);
    if (result === -1) {
      yield put(Types.getFailure());
      yield put(AppTypes.error(translate('errors.timeout')));
    } else {
      yield put(Types.getMoreSuccess(result));
    }
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

function* changePage() {
  const store = yield select(getWfLogs);
  const { page, rowsPerPage, logs } = store.wfLogs;

  if (page * rowsPerPage + 1 > logs.length) {
    yield put(Types.getMoreLogs(logs[logs.length - 1].sort));
  }
}

function* exportCSV() {
  try {
    const store = yield select(getQueryLabels);
    const params = generateParams(store, {
      timezone: store.timezone,
      job_type: 'wf_logs_csv',
    });
    const accountID = yield select(getAccountId);
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(
          Api.csv.download,
          result.presignedURL,
          'BCS Web Filtering Logs.csv'
        );
        break;
      }
    }

    yield put(Types.exportCSVSuccess());
  } catch (e) {
    yield put(Types.exportCSVFailure(e));
    yield put(AppTypes.error(translate('errors.downloadFailed')));
  }
}

function* initData(action) {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;
    const account = store.account;
    const timezone = account.time_zone;

    const { activeDirectory } = store.account;
    const now = new Date();
    yield put(
      Types.setDateRange({
        fromMonth: moment(new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000))
          .tz(timezone)
          .utc()
          .toDate(),
        from: moment(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000))
          .tz(timezone)
          .utc()
          .toDate(),
        to: moment(now)
          .tz(timezone)
          .utc()
          .toDate(),
        enteredTo: moment(now)
          .tz(timezone)
          .utc()
          .toDate(),
      })
    );

    const cats = yield call(catMap.loaded ? () => {} : Api.getData, {
      page: 'get_basic_policies',
    });

    yield all([
      ...(!catMap.loaded
        ? [
            put(
              storeLoadedCats(
                cats.categories,
                cats.supercategories,
                cats.catsv3,
                cats.wcs_version
              )
            ),
          ]
        : []),
    ]);

    let [locations, adUsers, adGroups, settings] = yield all([
      call(Api.locationPolicy.read, {}),
      call(activeDirectory.length ? () => {} : Api.directory.readUsers, {
        account_id: store.account.selected,
      }),
      call(activeDirectory.length ? () => {} : Api.directory.readGroups, {
        account_id: store.account.selected,
      }),
      call(Api.accounts.getSettings, store.account.selected),
    ]);

    const ad = {
      users: adUsers['users'],
      groups: adGroups['groups'],
    };

    if (settings['wf_search']) {
      yield put(Types.updateSearch(settings['wf_search']));
    }

    const updatedStore = yield select();
    const params = generateParams(updatedStore, action.params);

    const locationMap = locations.reduce(
      (obj, location) => ({
        ...obj,
        ...location.locations.reduce(
          (o, loc) => ({
            [loc.id]: loc.name,
          }),
          {}
        ),
      }),
      {}
    );

    let result = yield call(_fetchLogs, params);

    if (result === -1) {
      yield put(Types.getFailure());
      yield put(AppTypes.error(translate('errors.timeout')));
    } else {
      yield put(Types.getSuccess(result));
    }

    yield delay(2000);
    yield all([
      put(Types.storeLocations(locationMap)),
      ...(!activeDirectory.length ? [put(getActiveDirectorySuccess(ad))] : []),
    ]);
  } catch (e) {
    yield put(Types.getFailure(e));
  }
}

export function* wfLogsReducerFlow() {
  yield takeEvery(Types.WF_LOGS_INIT_DATA, initData);
  yield takeEvery(Types.WF_LOGS_GET_DATA, fetchLogs);
  yield takeEvery(Types.WF_LOGS_UPDATE_RANGE, fetchLogs);
  yield takeEvery(Types.WF_LOGS_UPDATE_SORT, fetchLogs);
  yield takeEvery(Types.WF_LOGS_GET_MORE_LOGS, fetchMoreLogs);
  yield takeEvery(Types.WF_LOGS_CHANGE_PAGE, changePage);
  yield takeEvery(Types.WF_LOGS_EXPORT_CSV, exportCSV);
  yield takeEvery(Types.WF_LOGS_QUERY_DATA, updateFetchLogs);
  yield takeEvery(Types.WF_LOGS_FETCH, fetchLogs);
}
