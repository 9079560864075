import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-i18nify';

import SelectionInput from '../../components/advanced_filtering/SelectionInput';

import {
  SCHEDULE_TIME_OPTIONS,
  selectTimeAction,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  name: <Translate value="shared.schedule" />,
  options: SCHEDULE_TIME_OPTIONS,
  selectedIndex: state.advanced_filtering.editTimeConstraintModal.selectIndex,
  tooltipTestId: 'schedule-time-action',
});

const mapDispatchToProps = dispatch => ({
  onChange: index => {
    dispatch(selectTimeAction(index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectionInput);
