import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Table from '../../components/shared_policies/Table';

import { getAccountNames } from '../../selectors/account';
import { getFilteredPolicies } from '../../selectors/shared_policies';
import { openModal } from '../../actions/shared_policies';

const mapStateToProps = state => {
  return {
    policies: getFilteredPolicies(state),
    accounts: getAccountNames(state),
    loading: state.shared_policies.sharedThreatPolicy.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  openModal: (type, id) => {
    dispatch(openModal(type, id));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Table)
);
