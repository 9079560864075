import { connect } from 'react-redux';
import BasicFilterTable from '../../components/reports/BasicFilterTable';

import { categoryHeaders, basicFilterToggle } from '../../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  entries: state.reports.tableData,
  loading: state.reports.reportProgress,
  headers: categoryHeaders[state.reports.reportSelect],
  entryKeys: state.reports.basicWF[state.reports.reportSelect].entryKeys,
  sortIndex: state.reports.basicWF[state.reports.reportSelect].sortIndex,
  sortDirection:
    state.reports.basicWF[state.reports.reportSelect].sortDirection,
  selected: state.reports.reportSelect,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggle: (report, i) => {
    dispatch(basicFilterToggle(report, i));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicFilterTable);
