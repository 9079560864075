import { connect } from 'react-redux';
import ReportFalseThreatModal from '../../components/atp_logs/ReportFalseThreatModal';

import {
  reportFalseThreat,
  closeReportThreatModal,
} from '../../actions/atplogs';

const mapStateToProps = state => ({
  show: state.atplogs.show,
  file: state.atplogs.reportFile,
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => {
    dispatch(closeReportThreatModal());
  },
  reportFalseThreat: () => {
    dispatch(reportFalseThreat());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportFalseThreatModal);
