import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Translate } from 'react-i18nify';

import ADGroupSelection from '../../containers/advanced_filtering/ADGroupSelection';
import SettingsItem from '../settings/SettingsItem';

const Safesearch = ({
  classes,
  disabledAD,
  toggleSafesearch,
  enableSafesearch,
}) => (
  <Grid id="add-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.levelContainer}>
      <ADGroupSelection disabled={disabledAD} />
    </Grid>
    <Grid item xs={12} className={classes.addContainer}>
      <div className={classes.help}>
        <Translate value="components.advancedFiltering.safesearchHelp" />
      </div>
    </Grid>
    <Grid item xs={12} className={classes.addContainer}>
      <SettingsItem
        text={
          <Translate
            value="components.advancedFiltering.safesearch"
            className={classes.label}
          />
        }
        toggled={enableSafesearch}
        onToggle={toggleSafesearch}
        padding={false}
        disabled={false}
        labels={['components.shared.on', 'components.shared.off']}
      />
    </Grid>
  </Grid>
);

Safesearch.propTypes = {
  classes: PropTypes.object.isRequired,
  disabledAD: PropTypes.bool,
  toggleSafesearch: PropTypes.func.isRequired,
};

Safesearch.defaultProps = {};

const styles = theme => ({
  help: {
    color: 'rgba(0,0,0,0.9)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '24px',
  },
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
});

export default withStyles(styles, { name: 'Safesearch' })(Safesearch);
