import React, { Component } from 'react';
import ReactGA from 'react-ga';
import DocumentTitle from 'react-document-title';
import escapeRegExp from 'escape-string-regexp';
import SectionHeader from './components/SectionHeader';
import { Translate, translate } from 'react-i18nify';
import Input from '@material-ui/core/Input/Input';
import Paper from '@material-ui/core/Paper';
import {
  SelectAllIcon,
  FolderIcon,
  FileIcon,
  SettingsIcon,
} from './components/icons';
import SettingsItem from './components/settings/SettingsItem';
import CudaPaper from './components/CudaPaper';
import { connect } from 'react-redux';
import CudaToggle from './components/CudaToggle';
import ATPDisableNotice from './components/shared_policies/ATPDisableNotice';
import {
  AddIcon,
  MenuIcon,
  EditIcon,
  DeleteIcon,
  ATPPolicyIcon,
  SearchIcon,
} from './components/icons';
import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
  ActionCancelButton,
} from './components/CudaDialog';
import CudaDropdown from './components/CudaDropdown';
import CudaTableTitle from './components/CudaTableTitle';

import HelpText from './components/HelpText';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import SettingsRadio from './components/settings/SettingsRadio';

import ATPToggle from './components/atp_policy/main_toggle';
import ScanDeliver from './components/atp_policy/scan_deliver_toggle';
import ScheduleScan from './containers/atp_policy/ScheduleScan';

import { Mixpanel } from './lib/Mixpanel';

import {
  initATP,
  toggleSetting,
  settings,
  toggleCustomExclusion,
  addCustomExclusion,
  ADD_CUST_FILE_EXCLUSION,
  ADD_CUST_PATH_EXCLUSION,
  editCustomExclusion,
  EDIT_CUST_EXCLUSION,
  removeCustomExclusion,
  EXCLUSION_TYPE_FILE,
  EXCLUSION_TYPE_PATH,
} from './actions/atp';

const ScanType = ({ atp, toggleSetting }) => {
  return (
    <CudaPaper
      style={{ minHeight: '393px' }}
      padding
      title={translate('components.atp.scanTypeHeader')}
    >
      <HelpText>
        <Translate value="components.atp.scanTypeHelp" dangerousHTML />
      </HelpText>
      <SettingsRadio
        disabled={atp.shared_policy}
        text={translate('components.atp.autoRemediate')}
        tooltipText={<Translate value="components.atp.autoRemediateHelpIcon" />}
        value={atp.autoRemediate.value}
        radioValues={['false', 'true']}
        handleChange={() => toggleSetting(settings.autoRemediate)}
        radioIsUpdating={atp.autoRemediate.isUpdating}
        radioUpdateError={atp.autoRemediate.updateError}
        labels={['components.atp.quarantine', 'components.shared.allow']}
      />
      <div
        style={{
          color: 'rgba(0,0,0,0.7)',
          marginTop: '15px',
          fontSize: '22px',
          fontWeight: '300',
          marginBottom: '10px',
        }}
      >
        <Translate value="components.atp.fileTypes" dangerousHTML />
      </div>
      <SettingsItem
        disabled={atp.shared_policy}
        text={translate('components.atp.scanMsofficeFile')}
        tooltipText={<Translate value="components.atp.msofficeFileHelpIcon" />}
        toggled={atp.scanMSOffice.value}
        onToggle={() => toggleSetting(settings.scanMSOffice)}
        toggleIsUpdating={atp.scanMSOffice.isUpdating}
        toggleUpdateError={atp.scanMSOffice.updateError}
        labels={['components.shared.yes', 'components.shared.no']}
      />
      <SettingsItem
        disabled={atp.shared_policy}
        text={translate('components.atp.scanExecutables')}
        tooltipText={translate('components.atp.executablesHelpIcon')}
        toggled={atp.executables.value}
        onToggle={() => toggleSetting(settings.executables)}
        toggleIsUpdating={atp.executables.isUpdating}
        toggleUpdateError={atp.executables.updateError}
        labels={['components.shared.yes', 'components.shared.no']}
      />
      <SettingsItem
        disabled={atp.shared_policy}
        text={translate('components.atp.scanPdfFiles')}
        tooltipText={<Translate value="components.atp.pdfFilesHelpIcon" />}
        toggled={atp.pdfDocuments.value}
        onToggle={() => toggleSetting(settings.pdfDocuments)}
        toggleIsUpdating={atp.pdfDocuments.isUpdating}
        toggleUpdateError={atp.pdfDocuments.updateError}
        labels={['components.shared.yes', 'components.shared.no']}
      />
      <SettingsItem
        disabled={atp.shared_policy}
        text={translate('components.atp.scanCompressFile')}
        tooltipText={<Translate value="components.atp.compressFileHelpIcon" />}
        toggled={atp.archiveFiles.value}
        onToggle={() => toggleSetting(settings.archiveFiles)}
        toggleIsUpdating={atp.archiveFiles.isUpdating}
        toggleUpdateError={atp.archiveFiles.updateError}
        labels={['components.shared.yes', 'components.shared.no']}
      />
    </CudaPaper>
  );
};

const PasswordProtection = ({ atp, toggleSetting }) => {
  return (
    <CudaPaper
      padding
      title={translate('components.atp.encryptedAndProtectedFiles')}
    >
      <HelpText>
        <Translate
          value="components.atp.encryptedAndProtectedFilesHelp"
          dangerousHTML
        />
      </HelpText>
      <SettingsRadio
        disabled={atp.shared_policy}
        text={translate('components.atp.pwProtectedFile')}
        tooltipText={translate('components.atp.pwProtectedFileHelpIcon')}
        value={atp.blockedPwProtectedOfficeDoc.value}
        handleChange={() => toggleSetting(settings.blockedPwProtectedOfficeDoc)}
        radioIsUpdating={atp.blockedPwProtectedOfficeDoc.isUpdating}
        radioUpdateError={atp.blockedPwProtectedOfficeDoc.updateError}
        labels={['components.atp.quarantine', 'components.shared.allow']}
      />
      <SettingsRadio
        disabled={atp.shared_policy}
        text={translate('components.atp.encryptedFiles')}
        tooltipText={translate('components.atp.encryptedFilesHelpIcon')}
        value={atp.blockedPwProtectedArchiveFile.value}
        handleChange={() =>
          toggleSetting(settings.blockedPwProtectedArchiveFile)
        }
        radioIsUpdating={atp.blockedPwProtectedArchiveFile.isUpdating}
        radioUpdateError={atp.blockedPwProtectedArchiveFile.updateError}
        labels={['components.atp.quarantine', 'components.shared.allow']}
      />
    </CudaPaper>
  );
};

const scanExclustionTableStyles = {
  typeItem: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px',
    },
  },
  menuItem: {
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  menuItemRemove: {
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '22px',
      marginLeft: '-4px',
    },
    color: '#DD1E31',
  },
  contentRow: {
    width: '100%',
  },
};

class ScanExclusionTableRowComponent extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  render() {
    const {
      classes,
      type,
      name,
      removeAction,
      removeCustomExclusion,
      showEditPopUp,
      disabled,
    } = this.props;

    return (
      <TableRow>
        <TableCell>
          <span className={classes.typeItem}>
            {type === 'FILE' && <FileIcon color="#5F5F5F" />}
            {type === 'PATH' && <FolderIcon color="#5F5F5F" />}
            {type}
          </span>
        </TableCell>
        <TableCell className={classes.contentRow}>{name}</TableCell>
        <TableCell>
          <MenuIcon
            onClick={e => {
              !disabled &&
                this.setState({ open: true, anchorEl: e.currentTarget });
            }}
          />
          <Menu
            open={this.state.open}
            transitionDuration={50}
            anchorEl={this.state.anchorEl}
            onRequestClose={() => {
              this.setState({ open: false, anchorEl: null });
            }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                showEditPopUp(removeAction, name);
                this.setState({ open: false, anchorEl: null });
              }}
            >
              <EditIcon />
              <Translate value="shared.edit" />
            </MenuItem>
            <MenuItem
              className={classes.menuItemRemove}
              onClick={() => {
                removeCustomExclusion(removeAction, name);
                this.setState({ open: false, anchorEl: null });
              }}
            >
              <DeleteIcon color="#DD1E31" />
              <Translate value="components.basicFiltering.remove" />
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  }
}

const ScanExclusionTableRow = withStyles(scanExclustionTableStyles, {
  name: 'ScanExclusionTableRow',
})(ScanExclusionTableRowComponent);

const scanExclusionStyles = {
  title: {
    paddingTop: '20px',
    fontSize: '22px',
    fontWeight: 300,
    color: 'rgba(0,0,0,0.7)',
    marginBottom: '10px',
  },
  filterContainer: {
    padding: '0 20px',
  },
  addBotBtn: {
    marginRight: 0,
    padding: '8px 15px',
    '& span': {
      fontWeight: 200,
    },
  },
  addIcon: {
    marginRight: '10px',
    width: '14px',
  },
  emptyTableRow: {
    textAlign: 'center',
    color: 'rgba(0,0,0,0.5)',
    fontSize: '14px',
    padding: '50px',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: 2,
    border: '1px solid #DDDDDD',
    backgroundColor: '#FFFFFF',
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2);',
    height: '40px',
    width: '100%',
    '& input': {
      padding: 0,
      'font-size': '14px',
      'line-height': '16px',
    },
  },
  icon: {
    margin: '2px 11px 0 11px',
  },
  valueColumn: {
    width: '100%',
  },
  padding: {
    padding: '0 20px 0 20px',
  },
  exclusionHelp: {
    paddingBottom: '20px',
  },
};

const fields = ['type', 'value'];

class ScanExclusionContainer extends Component {
  state = {
    query: '',
    menuOpen: false,
    icons: ['asc', 'none'],
    index: 0,
  };

  toggle(index) {
    let value = this.state.icons[index];
    let icons = ['none', 'none'];
    if (value === 'asc') {
      value = 'desc';
    } else {
      value = 'asc';
    }

    icons[index] = value;
    this.setState({ icons: icons, index: index });
  }

  componentDidMount() {
    this.timer = null;
  }

  updateQuery = value => {
    this.setState({ query: value });
  };

  render() {
    const {
      classes,
      atp,
      toggleCustomExclusion,
      showPopUp,
      removeCustomExclusion,
      showEditPopUp,
    } = this.props;
    const removeActionMap = {
      FILE: EXCLUSION_TYPE_FILE,
      PATH: EXCLUSION_TYPE_PATH,
    };
    let exclusionList = [];
    let tmpList = [];

    const showFiles = Boolean(
      (atp.customExclusion === 'ALL' || atp.customExclusion === 'FILE') &&
        atp.fileExclusion.length
    );
    const showPath = Boolean(
      (atp.customExclusion === 'ALL' || atp.customExclusion === 'PATH') &&
        atp.pathExclusion.length
    );

    const match = new RegExp(escapeRegExp(this.state.query), 'i');

    if (showFiles) {
      tmpList = tmpList.concat(
        atp.fileExclusion
          .filter(file => {
            return this.state.query === '' || match.test(file);
          })
          .map(file => {
            return { type: 'FILE', value: file };
          })
      );
    }
    if (showPath) {
      tmpList = tmpList.concat(
        atp.pathExclusion
          .filter(path => {
            return this.state.query === '' || match.test(path);
          })
          .map(path => {
            return { type: 'PATH', value: path };
          })
      );
    }

    const orderBy = fields[this.state.index];
    exclusionList = tmpList.sort((a, b) => {
      return this.state.icons[this.state.index] === 'desc'
        ? b[orderBy].toLowerCase() < a[orderBy].toLowerCase()
          ? -1
          : 1
        : a[orderBy].toLowerCase() < b[orderBy].toLowerCase()
        ? -1
        : 1;
    });
    return (
      <Paper>
        <div className={classes.padding}>
          <div className={classes.title}>
            <Translate value="components.atp.customExclusions" />
          </div>
          <HelpText customClasses={[classes.exclusionHelp]}>
            <Translate
              value="components.atp.customExclusionHelp"
              dangerousHTML
            />
          </HelpText>
        </div>
        <Grid
          container
          className={classes.filterContainer}
          spacing={2}
          justify="space-between"
        >
          <Grid item xs={8}>
            <div className="custom-exclusion-controls">
              <CudaToggle
                name="custom-exclusion"
                value="ALL"
                selected={atp.customExclusion === 'ALL'}
                selectRadio={e => toggleCustomExclusion(e.target.value)}
                icon={
                  <SelectAllIcon
                    color={
                      atp.customExclusion === 'ALL' ? '#FFFFFF' : '#5F5F5F'
                    }
                  />
                }
              />
              <CudaToggle
                name="custom-exclusion"
                value="FILE"
                selected={atp.customExclusion === 'FILE'}
                selectRadio={e => toggleCustomExclusion(e.target.value)}
                icon={
                  <FileIcon
                    color={
                      atp.customExclusion === 'FILE' ? '#FFFFFF' : '#5F5F5F'
                    }
                  />
                }
              />
              <CudaToggle
                name="custom-exclusion"
                value="PATH"
                selected={atp.customExclusion === 'PATH'}
                selectRadio={e => toggleCustomExclusion(e.target.value)}
                icon={
                  <FolderIcon
                    color={
                      atp.customExclusion === 'PATH' ? '#FFFFFF' : '#5F5F5F'
                    }
                  />
                }
              />
            </div>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              onClick={showPopUp}
              className={classes.addBotBtn}
              disabled={atp.shared_policy}
            >
              <AddIcon className={classes.addIcon} />
              <Translate value="components.atp.customExclusion" />
            </Button>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.inputWrapper}>
              <SearchIcon className={classes.icon} />
              <Input
                style={{ width: '100%' }}
                value={this.state.query}
                onChange={e => {
                  this.updateQuery(e.target.value);
                }}
                placeholder={translate('settings.webscraping.search')}
                disableUnderline={true}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.container}
          spacing={2}
          justify="space-between"
        >
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <CudaTableTitle
                      value="components.atp.type"
                      sort={true}
                      toggle={this.state.icons[0]}
                      onClick={() => {
                        this.toggle(0);
                      }}
                    />
                  </TableCell>
                  <TableCell className={classes.valueColumn}>
                    <CudaTableTitle
                      value="components.atp.value"
                      sort={true}
                      toggle={this.state.icons[1]}
                      onClick={() => {
                        this.toggle(1);
                      }}
                    />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {exclusionList.length > 0 ? (
                  exclusionList.map((obj, i) => (
                    <ScanExclusionTableRow
                      disabled={atp.shared_policy}
                      key={i}
                      type={obj.type}
                      name={obj.value}
                      removeAction={removeActionMap[obj.type]}
                      removeCustomExclusion={removeCustomExclusion}
                      showEditPopUp={() =>
                        showEditPopUp(removeActionMap[obj.type], obj.value)
                      }
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan="3" className={classes.emptyTableRow}>
                      <Translate value="components.atp.noCustomExclusion" />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const ScanExclusion = withStyles(scanExclusionStyles, {
  name: 'ScanExclusion',
})(ScanExclusionContainer);

const ScanRemovableDrive = ({ atp, toggleSetting }) => {
  return (
    <CudaPaper padding title={translate('components.atp.removableDrive')}>
      <HelpText>
        <Translate value="components.atp.scanRemovableHelp" dangerousHTML />
      </HelpText>
      <SettingsItem
        disabled={atp.shared_policy}
        text={translate('components.atp.scanRemovableDrive')}
        toggled={atp.scanExclusion.value}
        onToggle={() => toggleSetting(settings.scanExclusion)}
        toggleIsUpdating={atp.scanExclusion.isUpdating}
        toggleUpdateError={atp.scanExclusion.updateError}
        labels={['components.shared.yes', 'components.shared.no']}
      />
    </CudaPaper>
  );
};

const dialogStyleSheet = {
  dialog: {
    overflow: 'visible',
  },
  defaultText: {
    marginBottom: 20,
  },
  label: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
    marginLeft: 15,
  },
  nameLabel: {
    extend: 'label',
    marginLeft: 25,
    marginBottom: 5,
  },
  domainsLabel: {
    extend: 'label',
    marginLeft: -5,
  },
  singlelineTextDiv: {
    fontSize: 13,
    '& > div > input': {
      border: '1px solid #DDDDDD',
      borderRadius: 3,
      fontSize: 14,
      paddingLeft: 5,
    },
    '& > div > input:focus': {
      border: '1px solid #a9a9a9',
    },
  },
  singlelineTextDivError: {
    extend: 'singlelineTextDiv',
    '& > div > input': {
      borderColor: '#DD1E31',
    },
    '& > div > input:focus': {
      borderColor: '#DD1E31',
    },
  },
  singlelineInput: {
    width: '100%',
  },
  multilineWrapper: {
    padding: '0 20px',
  },
  errorLabel: {
    fontSize: 12,
    margin: '0 0 25px 25px',
    color: '#DD1E31',
    height: 10,
  },
  infoLabel: {
    extend: 'errorLabel',
    color: 'rgba(0,0,0,0.3)',
    marginBottom: 10,
  },
};

class AddCustomExclusionDialogComponent extends Component {
  state = {
    selectedType: ADD_CUST_FILE_EXCLUSION,
    inputValue: '',
    openMenu: false,
  };

  resetState = () => {
    this.setState({ selectedType: ADD_CUST_FILE_EXCLUSION, inputValue: '' });
  };

  handleChange = value => {
    this.setState({ selectedType: value });
  };

  toggleMenu() {
    this.setState(prevState => {
      return {
        ...prevState,
        openMenu: !prevState.openMenu,
      };
    });
  }

  handleClose() {
    this.setState({ openMenu: false });
  }

  render() {
    const {
      classes,
      showCustomExclusion,
      closePopUp,
      addCustomExclusion,
    } = this.props;
    const items = [
      {
        text: <Translate value="components.atp.file" />,
        value: ADD_CUST_FILE_EXCLUSION,
        icon: <FileIcon color="#5F5F5F" />,
      },
      {
        text: <Translate value="components.atp.path" />,
        value: ADD_CUST_PATH_EXCLUSION,
        icon: <FolderIcon color="#5F5F5F" />,
      },
    ];

    const track_selection = {
      ADD_CUST_FILE_EXCLUSION:
        'Threat Policy / Add Exception Dialog / Exception Type File',
      ADD_CUST_PATH_EXCLUSION:
        'Threat Policy / Add Exception Dialog / Exception Type Path',
    };

    return (
      <Dialog
        open={showCustomExclusion}
        maxWidth="md"
        onEscapeKeyDown={closePopUp}
      >
        <CudaDialogTitle
          onRequestClose={() => {
            Mixpanel.track('Threat Policy / Add Exception Dialog / Close', {});
            closePopUp();
          }}
        >
          <Translate value="components.atp.addExclusion" />
        </CudaDialogTitle>
        <CudaDialogContent overflow="visible" loadingAnimation={false}>
          <CudaDialogRegularContent>
            <div className={classes.defaultText}>
              <Translate
                value="components.atp.customExclusionText"
                dangerousHTML
              />
            </div>
            <Grid
              container
              className={classes.container}
              spacing={2}
              justify="space-between"
            >
              <Grid item xs={3}>
                Exception Type
              </Grid>
              <Grid item xs={9}>
                Value
              </Grid>
              <Grid item xs={3}>
                <CudaDropdown
                  menuOpen={this.state.openMenu}
                  items={items}
                  selectedItemValue={this.state.selectedType}
                  toggleMenu={e => {
                    this.toggleMenu();
                  }}
                  closeMenu={() => {
                    Mixpanel.track(
                      'Threat Policy / Add Exception Dialog / Exception Type Dropdown',
                      {}
                    );
                    this.handleClose();
                  }}
                  onSelect={value => {
                    Mixpanel.track(track_selection[value], {});
                    return this.handleChange(value);
                  }}
                  withIcons={true}
                />
              </Grid>
              <Grid item xs={9}>
                <div className={classes.singlelineTextDiv}>
                  <Input
                    autoFocus
                    className={classes.singlelineInput}
                    disableUnderline
                    value={this.state.inputValue}
                    onChange={e => {
                      this.setState({ inputValue: e.target.value });
                    }}
                    onBlur={() => {}}
                    placeholder="example: [drive letter]:\path\ or [path_macro]\path\ "
                  />
                </div>
              </Grid>
            </Grid>
          </CudaDialogRegularContent>
        </CudaDialogContent>
        <DialogActions>
          <div>
            <ActionCancelButton
              onClick={() => {
                Mixpanel.track(
                  'Threat Policy / Add Exception Dialog / Cancel',
                  {}
                );
                closePopUp();
              }}
            >
              <Translate value="settings.webscraping.dialog.cancel" />
            </ActionCancelButton>
            <ActionButton
              color="primary"
              onClick={() => {
                Mixpanel.track(
                  'Threat Policy / Add Exception Dialog / Save',
                  {}
                );
                addCustomExclusion(
                  this.state.selectedType,
                  this.state.inputValue
                );
                this.resetState();
                closePopUp();
              }}
            >
              <Translate value="settings.webscraping.dialog.save" />
            </ActionButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const AddCustomExclusionDialog = withStyles(dialogStyleSheet, {
  name: 'AllowedBotsDialogStylesheet',
})(AddCustomExclusionDialogComponent);

class EditCustomExclusionDialogComponent extends Component {
  state = {
    openMenu: false,
  };

  toggleMenu() {
    this.setState(prevState => {
      return {
        ...prevState,
        openMenu: !prevState.openMenu,
      };
    });
  }

  handleClose() {
    this.setState({ openMenu: false });
  }

  render() {
    const {
      classes,
      showCustomExclusion,
      closePopUp,
      editCustomExclusion,
      editData,
      handleEditTypeChange,
      handleEditValueChange,
    } = this.props;
    const items = [
      {
        text: <Translate value="components.atp.file" />,
        value: EXCLUSION_TYPE_FILE,
        icon: <FileIcon color="#5F5F5F" />,
      },
      {
        text: <Translate value="components.atp.path" />,
        value: EXCLUSION_TYPE_PATH,
        icon: <FolderIcon color="#5F5F5F" />,
      },
    ];

    return (
      <Dialog
        className={classes.dialog}
        open={showCustomExclusion}
        maxWidth="md"
        onEscapeKeyDown={closePopUp}
      >
        <CudaDialogTitle onRequestClose={closePopUp}>
          <Translate value="components.atp.customExclusion" />
        </CudaDialogTitle>
        <CudaDialogContent overflow="visible" loadingAnimation={false}>
          <CudaDialogRegularContent>
            <div className={classes.defaultText}>
              <Translate
                value="components.atp.customExclusionText"
                dangerousHTML
              />
            </div>
            <Grid
              container
              className={classes.container}
              spacing={2}
              justify="space-between"
            >
              <Grid item xs={3}>
                Exception Type
              </Grid>
              <Grid item xs={9}>
                Value
              </Grid>
              <Grid item xs={3}>
                <CudaDropdown
                  menuOpen={this.state.openMenu}
                  items={items}
                  selectedItemValue={editData.type}
                  toggleMenu={e => {
                    this.toggleMenu();
                  }}
                  closeMenu={() => {
                    this.handleClose();
                  }}
                  onSelect={value => {
                    return handleEditTypeChange(value);
                  }}
                  withIcons={true}
                />
              </Grid>
              <Grid item xs={9}>
                <div className={classes.singlelineTextDiv}>
                  <Input
                    autoFocus
                    className={classes.singlelineInput}
                    disableUnderline
                    value={editData.value}
                    onChange={e => {
                      handleEditValueChange(e.target.value);
                    }}
                    onBlur={() => {}}
                    placeholder="example: [drive letter]:\path\ or [path_macro]\path\ "
                  />
                </div>
              </Grid>
            </Grid>
          </CudaDialogRegularContent>
        </CudaDialogContent>
        <DialogActions>
          <div>
            <ActionCancelButton onClick={closePopUp}>
              <Translate value="settings.webscraping.dialog.cancel" />
            </ActionCancelButton>
            <ActionButton
              color="primary"
              onClick={() => {
                editCustomExclusion(
                  EDIT_CUST_EXCLUSION,
                  editData.prevType,
                  editData.prevValue,
                  editData.type,
                  editData.value
                );
                closePopUp();
              }}
            >
              <Translate value="settings.webscraping.dialog.save" />
            </ActionButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const EditCustomExclusionDialog = withStyles(dialogStyleSheet, {
  name: 'AllowedBotsDialogStylesheet',
})(EditCustomExclusionDialogComponent);

class ATPSettings extends Component {
  state = {
    showCustomExclusion: false,
    showEditCustomExclusion: false,
    query: '',
    edit: {
      prevType: '',
      prevValue: '',
      type: '',
      value: '',
    },
  };

  componentDidMount() {
    this.props.initATP();
  }

  showPopUp = () => {
    Mixpanel.track('Threat Policy / Add Custom Exclusion', {});
    this.setState({ showCustomExclusion: true });
  };

  closePopUp = () => {
    this.setState({ showCustomExclusion: false });
  };

  showEditPopUp = (type, value) => {
    this.setState({
      showEditCustomExclusion: true,
      edit: {
        type,
        value,
        prevType: type,
        prevValue: value,
      },
    });
  };

  handleEditTypeChange = type => {
    this.setState(prevState => ({
      ...prevState,
      edit: {
        ...prevState.edit,
        type,
      },
    }));
  };

  handleEditValueChange = value => {
    this.setState(prevState => ({
      ...prevState,
      edit: {
        ...prevState.edit,
        value,
      },
    }));
  };

  closeEditPopUp = () => {
    this.setState({ showEditCustomExclusion: false });
  };

  render() {
    const { atp } = this.props;
    return (
      <DocumentTitle title="Content Shield | Threat Protection Policy">
        <div>
          <SectionHeader
            icon={<ATPPolicyIcon />}
            title={translate('components.atp.title')}
            tooltipText="Tooltip Text"
            children
          >
            <Translate value="components.atp.headerText" />
          </SectionHeader>
          <Grid container spacing={2} justify="space-between">
            {atp.shared_policy && (
              <Grid item xs={12}>
                <ATPDisableNotice />
              </Grid>
            )}
            <Grid item xs={12}>
              <ATPToggle {...this.props} />
            </Grid>
            <Grid item xs={12}>
              <ScanDeliver {...this.props} />
            </Grid>
            <Grid item xs={12}>
              <ScheduleScan />
            </Grid>
            <Grid item xs={12} lg={6}>
              <ScanType {...this.props} />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Grid
                container
                spacing={2}
                justify="space-between"
                direction="column"
              >
                <Grid item xs={12}>
                  <PasswordProtection {...this.props} />
                </Grid>
                <Grid item xs={12}>
                  <ScanRemovableDrive {...this.props} />
                </Grid>
              </Grid>
            </Grid>
            <br />
            <Grid item xs={12}>
              <AddCustomExclusionDialog
                {...this.props}
                showCustomExclusion={this.state.showCustomExclusion}
                closePopUp={this.closePopUp}
              />
              <EditCustomExclusionDialog
                {...this.props}
                showCustomExclusion={this.state.showEditCustomExclusion}
                closePopUp={this.closeEditPopUp}
                editData={this.state.edit}
                handleEditTypeChange={this.handleEditTypeChange}
                handleEditValueChange={this.handleEditValueChange}
              />
              <ScanExclusion
                {...this.props}
                showPopUp={this.showPopUp}
                showEditPopUp={this.showEditPopUp}
              />
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  atp: state.atp,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  toggleSetting: setting => {
    ReactGA.event({
      category: 'ATP Settings',
      action: 'Toggle ' + setting,
    });
    dispatch(toggleSetting(setting));
  },
  toggleCustomExclusion: value => {
    dispatch(toggleCustomExclusion(value));
  },
  addCustomExclusion: (type, value) => {
    dispatch(addCustomExclusion(type, value));
  },
  editCustomExclusion: (type, prevType, prevValue, cType, cValue) => {
    dispatch(editCustomExclusion(type, prevType, prevValue, cType, cValue));
  },
  removeCustomExclusion: (type, value) => {
    dispatch(removeCustomExclusion(type, value));
  },
  initATP: () => {
    dispatch(initATP());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ATPSettings);
