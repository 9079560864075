import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

import CudaTooltip from '../CudaTooltip';

import moment from 'moment';

import Paper from '../CudaPaper';

class LicenseModule extends Component {
  componentDidMount() {
    if (this.props.initData) {
      this.props.initData();
    }
  }

  render() {
    const { classes, loading, elevation, wca, dashboardInfo } = this.props;

    return (
      <Paper className={classes.paper} elevation={elevation}>
        <Grid spacing={0} container>
          <Grid item xs={12}>
            <div className={classes.headerSection}>
              <div className={classes.title}>
                <Translate value="shared.licenses" />
              </div>
            </div>
          </Grid>
          {loading ? (
            <Grid item xs={12}>
              <div className={classes.loading}>
                <CircularProgress />
              </div>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            {!loading ? (
              <div className={classes.infoWrapper}>
                <div className={classes.number}>
                  <div data-tip data-for="license-id">
                    {wca
                      ? dashboardInfo && dashboardInfo.user_total
                      : dashboardInfo && dashboardInfo.total}
                  </div>
                  <CudaTooltip id="agents-id">
                    <span>
                      {' '}
                      {translate(
                        'components.dashboard.licensesUsedTooltip'
                      )}{' '}
                    </span>
                  </CudaTooltip>
                </div>
                <div className={classes.text}>
                  <Translate value="components.dashboard.licensesUsed" />
                </div>
              </div>
            ) : null}
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

const styles = theme => ({
  paper: {
    border: '1px solid #DDDDDD',
  },
  number: {
    fontSize: '28px',
    lineHeight: '24px',
  },
  infoWrapper: {
    textAlign: 'center',
    margin: '25px',
  },
  headerSection: {
    padding: '20px 20px 5px 14px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
});

LicenseModule.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  wca: PropTypes.bool.isRequired,
  elevation: PropTypes.number,
  dashboardInfo: PropTypes.object.isRequired,
};

LicenseModule.defaultProps = {
  elevation: 0,
};

export default withStyles(styles)(LicenseModule);
