import { connect } from 'react-redux';
import RecategorizeDomainModal from '../../components/smb_account_settings/RecategorizeDomainModal';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecategorizeDomainModal);
