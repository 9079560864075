import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DefaultEditor from '../../containers/block_page/DefaultEditor';
import CustomEditor from './CustomEditor';

const BlockPageEditor = ({ classes, template }) =>
  template === 'custom' ? <CustomEditor /> : <DefaultEditor />;

const styles = {};

BlockPageEditor.propTypes = {};

export default withStyles(styles, { name: 'BlockPageEditor' })(BlockPageEditor);
