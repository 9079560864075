import { createSelector } from 'reselect';

const getChanges = state => state.exemptPolicies.changes;
const getExemptPolicies = state => state.exemptPolicies;

const getChangedFields = createSelector(
  [getChanges],
  changes => Object.keys(changes)
);

const getCopyStrExemptProcesses = createSelector(
  [getExemptPolicies],
  exemptions => {
    if (exemptions.changes.processExclusions) {
      return exemptions.changes.processExclusions.join(',').toString();
    }

    return exemptions.fields.processExclusions.join(',').toString();
  }
);

const getCopyStrNetworkExclusions = createSelector(
  [getExemptPolicies],
  exemptions => {
    if (exemptions.changes.networkExclusions) {
      return exemptions.changes.networkExclusions.join(',').toString();
    }

    return exemptions.fields.networkExclusions.join(',').toString();
  }
);

export {
  getChangedFields,
  getExemptPolicies,
  getCopyStrExemptProcesses,
  getCopyStrNetworkExclusions,
};
