import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-i18nify';
import { ReportIcon } from './icons';
import Grid from '@material-ui/core/Grid';

import SectionHeader from './SectionHeader';
import ReportSelector from '../containers/ReportSelector';
import ScheduledTable from '../containers/reports/ScheduledTable';

const styles = {};

const Reports = ({ classes, loading, report }) => {
  return (
    <DocumentTitle title={translate('components.reporting.pageTitle')}>
      <div>
        <SectionHeader
          icon={<ReportIcon />}
          title={translate('components.reporting.title')}
        />
        <Grid id="report-screen" spacing={2} container>
          <Grid item xs={12}>
            <ReportSelector />
          </Grid>
          <Grid item xs={12}>
            <ScheduledTable />
          </Grid>
        </Grid>
      </div>
    </DocumentTitle>
  );
};

Reports.propTypes = {
  loading: PropTypes.bool.isRequired,
  report: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Reports);
