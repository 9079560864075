import { connect } from 'react-redux';
import TopBlockedAccounts from '../../components/overview/TopBlockedAccounts';
import { getTopBlockedAccounts } from '../../actions/mspdashboard';

const mapDispatchToProps = dispatch => ({
  onChange: range => {
    dispatch(getTopBlockedAccounts(range));
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(TopBlockedAccounts);
