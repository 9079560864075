import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { translate } from 'react-i18nify';
import Api from './Api';
import * as Types from '../actions/shared_policies';
import * as AppTypes from '../actions/app';
import * as AccountTypes from '../actions/account';

import {
  ADD_CUST_FILE_EXCLUSION,
  ADD_CUST_PATH_EXCLUSION,
} from '../actions/atp';

const update = require('immutability-helper');

const defaults = {};

export const shared_policies = (
  state = {
    policies: [],
    atp: {
      query: '',
      applyQuery: '',
    },
    editModal: {
      show: false,
      loading: false,
      id: '',
    },
    copyEditModal: {
      show: false,
      loading: false,
      accounts: {},
      id: '',
      cancel: false,
    },
    deleteModal: {
      show: false,
      loading: false,
      id: '',
    },
    sharedThreatPolicy: {
      show: false,
      loading: false,
      disabled: false,
    },
    customExclusion: 'ALL',
    accountSelections: [],
    exclusionList: {
      file: [],
      path: [],
    },
    showExclusionModal: false,
    editFields: {
      accountSelections: [],
      updatedAccountSelections: [],
      name: '',
      autoRemediate: false,
      cpaEnabled: true,
      scanMSOffice: true,
      scanExecutables: true,
      scanPdfDocuments: true,
      scanArchiveFiles: true,
      blockedPwProtectedOfficeDoc: true,
      blockedPwProtectedArchiveFile: true,
      scanExclusion: true,
      exclusionList: {
        file: [],
        path: [],
      },
    },
    fields: {
      name: '',
      autoRemediate: false,
      cpaEnabled: true,
      scanMSOffice: true,
      scanExecutables: true,
      scanPdfDocuments: true,
      scanArchiveFiles: true,
      blockedPwProtectedOfficeDoc: true,
      blockedPwProtectedArchiveFile: true,
      scanExclusion: true,
    },
    changes: {},
  },
  action
) => {
  switch (action.type) {
    case Types.SHARED_COPY_CONFIG_ACCOUNTS:
      let accounts = action.accounts || [];
      let accountStatus = {};
      for (let i = 0; i < accounts.length; i++) {
        accountStatus[accounts[i]] = { status: 'wait' };
      }
      return {
        ...state,
        copyEditModal: {
          ...state.copyEditModal,
          show: true,
          accounts: accountStatus,
          cancel: false,
        },
        editModal: {
          ...state.editModal,
          show: false,
        },
      };
    case Types.CLOSE_COPY_MODAL:
      return {
        ...state,
        copyEditModal: {
          ...state.copyEditModal,
          show: false,
          accounts: action.accounts,
          cancel: true,
        },
        editModal: {
          ...state.editModal,
          show: true,
        },
      };
    case Types.SHARED_THREAT_LOADING_PAGE:
      return {
        ...state,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          loading: true,
        },
      };
    case Types.SHARED_THREAT_QUERY_CHANGE:
      return {
        ...state,
        atp: {
          ...state.atp,
          query: action.value,
        },
      };
    case Types.SHARED_THREAT_QUERY_APPLY:
      return {
        ...state,
        atp: {
          ...state.atp,
          applyQuery: action.value,
        },
      };
    case Types.SHARED_THREAT_CHANGE_ACCOUNT_SELECTION:
      return {
        ...state,
        accountSelections: action.value || [],
      };
    case Types.SHARED_THREAT_CHANGE_EDIT_ACCOUNT_SELECTION:
      return {
        ...state,
        editFields: {
          ...state.editFields,
          updatedAccountSelections: action.value || [],
        },
      };
    case Types.SHARED_THREAT_POLICY_SAVE_EDIT:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          loading: true,
        },
      };
    case Types.SHARED_THREAT_POLICY_GET_POLICY_SUCCESS:
      return {
        ...state,
        editFields: {
          ...state.editFields,
          accountSelections: action.result.accounts,
          updatedAccountSelections: action.result.accounts,
          name: action.result.data.name,
          autoRemediate: action.result.data.atp_policy.auto_remediate,
          cpaEnabled: action.result.data.atp_policy.cpa_enabled,
          scanMSOffice: action.result.data.atp_policy.scan_ms_office,
          scanExecutables: action.result.data.atp_policy.scan_executables,
          scanPdfDocuments: action.result.data.atp_policy.scan_pdf_documents,
          scanArchiveFiles: action.result.data.atp_policy.scan_archive_files,
          blockedPwProtectedOfficeDoc:
            action.result.data.atp_policy.block_password_protected_doc,
          blockedPwProtectedArchiveFile:
            action.result.data.atp_policy.block_pw_doc,
          scanExclusion: action.result.data.atp_policy.scan_removable_drive,
          exclusionList: {
            ...state.editFields.exclusionList,
            file: action.result.data.atp_policy.filenames.exclude,
            path: action.result.data.atp_policy.paths.exclude,
          },
        },
      };
    case Types.SHARED_THREAT_EDIT_SUCCESS:
      return {
        ...state,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: false,
          loading: false,
        },
        changes: {},
        exclusionList: {
          ...state.exclusionList,
          file: [],
          path: [],
        },
        accountSelections: [],
        editModal: {
          ...state.editModal,
          show: false,
        },
      };
    case Types.SHARED_THREAT_CREATE_SUCCESS:
      return {
        ...state,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: false,
          loading: false,
        },
        changes: {},
        exclusionList: {
          ...state.exclusionList,
          file: [],
          path: [],
        },
        accountSelections: [],
      };
    case Types.SHARED_THREAT_OPEN_MODAL:
      return {
        ...state,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: true,
        },
      };
    case Types.SHARED_THREAT_EDIT_MODAL_OPEN:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          show: true,
          id: action.id,
        },
      };
    case Types.SHARED_THREAT_DELETE_MODAL_OPEN:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          show: true,
          id: action.id,
        },
      };
    case Types.SHARED_THREAT_POLICY_DELETE:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          loading: true,
        },
      };
    case Types.SHARED_THREAT_DELETE_MODAL_CLOSE:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          show: false,
          id: '',
        },
      };
    case Types.SHARED_THREAT_CLOSE_MODAL:
      return {
        ...state,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: false,
        },
      };
    case Types.SHARED_THREAT_CLOSE_EDIT_MODAL:
      return {
        ...state,
        editModal: {
          ...state.editModal,
          show: false,
        },
      };
    case Types.SHARED_POLICIES_UPDATE:
      const { options } = action;
      const { fields, changes } = state;
      const merged = {
        ...changes,
        ...options,
      };

      return update(state, {
        changes: {
          $set: merged,
        },
        errors: {
          $set: [],
        },
      });
    case Types.SHARED_THREAT_FILTER_EXCLUSION:
      return {
        ...state,
        customExclusion: action.selection,
      };
    case Types.SHARED_THREAT_SHOW_EXCLUSION_MODAL:
      return {
        ...state,
        showExclusionModal: true,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: false,
        },
      };
    case Types.SHARED_THREAT_SHOW_EDIT_EXCLUSION_MODAL:
      return {
        ...state,
        showExclusionModal: true,
        editModal: {
          ...state.editModal,
          show: false,
        },
      };
    case Types.SHARED_THREAT_CLOSE_EXCLUSION_MODAL:
      return {
        ...state,
        showExclusionModal: false,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: true,
        },
      };
    case Types.SHARED_THREAT_ADD_CUSTOM_EXCLUSION:
      return {
        ...state,
        showExclusionModal: false,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: true,
        },
        exclusionList: {
          ...state.exclusionList,
          [action.custom_type]: [
            ...state.exclusionList[action.custom_type],
            action.value,
          ],
        },
      };
    case Types.SHARED_THREAT_EDIT_CUSTOM_EXCLUSION:
      return {
        ...state,
        showExclusionModal: false,
        editModal: {
          ...state.editModal,
          show: true,
        },
        editFields: {
          ...state.editFields,
          exclusionList: {
            ...state.editFields.exclusionList,
            [action.custom_type]: [
              ...state.editFields.exclusionList[action.custom_type],
              action.value,
            ],
          },
        },
      };
    case Types.SHARED_THREAT_SUCCESS:
      return {
        ...state,
        policies: action.result.policies,
        sharedThreatPolicy: {
          ...state.sharedThreatPolicy,
          show: false,
          loading: false,
        },
        changes: {},
        exclusionList: {
          ...state.exclusionList,
          file: [],
          path: [],
        },
        accountSelections: [],
      };
    default:
      return state;
  }
};

function* getSharedPolicies() {
  try {
    const store = yield select();
    yield put(Types.loadingPage());
    const result = yield call(Api.getData, {
      page: 'account_dashboard',
      range: 'month',
      fileTypeTime: store.mspDashboard.fileTypeRange,
    });
    yield put(AccountTypes.getAccountDataSuccess(result));
    const accountResult = yield call(Api.accounts.sharedPolicies.read);
    yield put(Types.getSharedPolicies(accountResult.data));
  } catch (error) {
    //  yield put(AppTypes.error(error.message));
    //  yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* getSharedPolicy() {
  try {
    const store = yield select();
    const accounts = store.account.accounts;
    const shared_policies = store.shared_policies;
    const result = yield call(
      Api.accounts.sharedPolicies.get,
      store.shared_policies.editModal.id
    );

    let selectedAccounts = [];
    for (let i = 0; i < shared_policies.policies.length; i++) {
      if (
        store.shared_policies.editModal.id === shared_policies.policies[i].id
      ) {
        selectedAccounts = shared_policies.policies[i].accounts;
        break;
      }
    }

    let mapAccountNames = {};
    for (let i = 0; i < accounts.length; i++) {
      mapAccountNames[String(accounts[i].id)] = accounts[i].name;
    }

    let processSelectedAccounts = [];
    for (let i = 0; i < selectedAccounts.length; i++) {
      processSelectedAccounts.push({
        type: 'smb_account',
        label: mapAccountNames[String(selectedAccounts[i])],
        value: String(selectedAccounts[i]),
      });
    }

    yield put(
      Types.getSharedPolicy({ ...result, accounts: processSelectedAccounts })
    );
  } catch (error) {
    //  yield put(AppTypes.error(error.message));
    //  yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* addSharedPolicies() {
  try {
    const store = yield select();
    const shared_policies = store.shared_policies;
    const params = {
      accounts: shared_policies.accountSelections.map(x => Number(x.value)),
      exclusions: shared_policies.exclusionList,
      fields: {
        ...shared_policies.fields,
        ...shared_policies.changes,
      },
    };
    yield call(Api.accounts.sharedPolicies.create, params);
    yield put(Types.createSuccess());
    const result = yield call(Api.accounts.sharedPolicies.read);
    yield put(Types.getSharedPolicies(result.data));
    yield put(AppTypes.success(translate('shared.configurationUpdated')));
  } catch (error) {
    yield put(AppTypes.error(translate('shared.failedConfiguration')));
  }
}

function* editSharedPolicy() {
  try {
    const store = yield select();
    const shared_policies = store.shared_policies;
    const updatedAccountSelection =
      shared_policies.editFields.updatedAccountSelections;
    const origSelection = shared_policies.editFields.accountSelections;
    const _id = shared_policies.editModal.id;
    const policies = shared_policies.policies;

    let deleteAccounts = [];
    let updateAccountMap = {};
    for (let i = 0; i < updatedAccountSelection.length; i++) {
      updateAccountMap[String(updatedAccountSelection[i]['value'])] = true;
    }
    for (let i = 0; i < origSelection.length; i++) {
      if (!updateAccountMap[String(origSelection[i]['value'])]) {
        deleteAccounts.push(origSelection[i]['value']);
      }
    }
    if (deleteAccounts.length > 0) {
      yield put(Types.copyConfigAccounts(deleteAccounts));
      return;
      for (let i = 0; i < deleteAccounts.length; i++) {
        yield call(
          Api.accounts.sharedPolicies.removeAccount,
          shared_policies.editModal.id,
          deleteAccounts[i]
        );
      }
    }

    let atp_policy_id;
    for (let i = 0; i < policies.length; i++) {
      if (_id == policies[i]['id']) {
        atp_policy_id = policies[i]['atp_policy_id'];
        break;
      }
    }

    // Update accounts
    const params = {
      accounts: shared_policies.editFields.updatedAccountSelections.map(x =>
        Number(x.value)
      ),
      exclusions: shared_policies.editFields.exclusionList,
      atp_policy_id,
      fields: {
        ...shared_policies.editFields,
        ...shared_policies.changes,
      },
    };

    yield call(
      Api.accounts.sharedPolicies.update,
      shared_policies.editModal.id,
      params
    );
    const result = yield call(Api.accounts.sharedPolicies.read);
    yield put(Types.getSharedPolicies(result.data));
    yield put(Types.editSuccess());
    yield put(AppTypes.success(translate('shared.configurationUpdated')));
  } catch (error) {
    yield put(AppTypes.error(translate('shared.failedConfiguration')));
  }
}

function* deleteSharedPolicy() {
  try {
    const store = yield select();
    const shared_policies = store.shared_policies;
    yield call(
      Api.accounts.sharedPolicies.remove,
      store.shared_policies.deleteModal.id
    );
    yield put(Types.closeModal(Types.SHARED_THREAT_DELETE_MODAL_CLOSE));
    const result = yield call(Api.accounts.sharedPolicies.read);
    yield put(Types.getSharedPolicies(result.data));
    yield put(AppTypes.success(translate('shared.configurationUpdated')));
  } catch (error) {
    yield put(Types.closeModal(Types.SHARED_THREAT_DELETE_MODAL_CLOSE));
    yield put(AppTypes.error(translate('shared.failedConfiguration')));
  }
}

function* applyAtpQuery() {
  try {
    const store = yield select();
    const atp = store.shared_policies.atp;
    yield delay(300);
    yield put(Types.applyAtpQuery(atp.query));
  } catch (error) {
    console.log('Error: ', error);
  }
}

export function* SharedPoliciesReducerFlow() {
  yield takeEvery(Types.SHARED_THREAT_POLICY_ADD, addSharedPolicies);
  yield takeEvery(Types.SHARED_THREAT_INIT_DATA, getSharedPolicies);
  yield takeEvery(Types.SHARED_THREAT_POLICY_DELETE, deleteSharedPolicy);
  yield takeLatest(Types.SHARED_THREAT_QUERY_CHANGE, applyAtpQuery);
  yield takeLatest(Types.SHARED_THREAT_EDIT_MODAL_OPEN, getSharedPolicy);
  yield takeLatest(Types.SHARED_THREAT_POLICY_SAVE_EDIT, editSharedPolicy);
}
