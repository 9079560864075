import { connect } from 'react-redux';
import Status from '../../components/dashboard/Status';
import { updateModule } from '../../actions/dashboard';

const mapStateToProps = state => ({
  threatCount: state.dashboard.threatCount,
  color: state.dashboard.threatCount ? '#DD1E31' : '#5BA71B',
  textColor: state.dashboard.threatCount ? '#DD1E31' : 'rgba(0, 0, 0, 0.54)',
  timeLabel: state.dashboard.timeLabel,
  loading: state.dashboard.threatCountLoading,
});

const mapDispatchToProps = dispatch => ({
  changeTimerange: timeRange => {
    dispatch(updateModule('threatCount', timeRange));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Status);
