import { connect } from 'react-redux';
import ATPDashboard from '../components/ATPDashboard';
import { initData } from '../actions/dashboard';

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(initData());
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(ATPDashboard);
