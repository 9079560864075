export const APP_ERROR = 'APP_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const APP_SUCCESS = 'APP_SUCCESS';

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const success = message => ({
  type: APP_SUCCESS,
  message,
});

export const error = message => ({
  type: APP_ERROR,
  message,
});
