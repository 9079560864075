import { createSelector } from 'reselect';

const getCategories = state => state.basic_filtering.catMapping.categories;
const getSupercategories = state =>
  state.basic_filtering.catMapping.supercategories;

const getCategoryList = createSelector(
  [getCategories],
  categories => {
    const cats = [];
    Object.keys(categories).forEach(key => {
      cats.push({
        value: parseInt(key, 10),
        label: categories[key].display,
      });
    });

    return cats.sort((a, b) => (a.label || '').localeCompare(b.label));
  }
);

const getSupercategoryList = createSelector(
  [getSupercategories],
  supercategories => {
    const supercats = [];
    Object.keys(supercategories).forEach(supercat => {
      supercats.push({
        value: parseInt(supercat, 10),
        label: supercategories[supercat],
      });
    });

    return supercats.sort((a, b) => (a.label || '').localeCompare(b.label));
  }
);

export { getCategoryList, getSupercategoryList };
