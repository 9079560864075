import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import StatusBar from '../../containers/activate/StatusBar';

import Background from '../../login-bg-img.jpg';
import ActivateForm from '../../containers/activate/ActivateForm';
import ActivateLogin from '../../containers/activate/ActivateLogin';
import ActivateAgreement from '../../containers/activate/ActivateAgreement';

const styles = {
  container: {
    height: '100vh',
    flexGrow: 1,
    backgroundColor: '#555555',
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
  },
  formContainer: {
    width: '540px',
    height: '400px',
    padding: 20,
    backgroundColor: '#ffffff',
    margin: 0,
    textAlign: 'center',
  },
};

const Activate = ({ classes, sso, filled }) => (
  <DocumentTitle title={translate('components.activate.pageTitle')}>
    <Grid
      container
      className={classes.container}
      alignItems="center"
      justify="center"
      spacing={2}
    >
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={classes.formContainer}
        direction="column"
      >
        {(() => {
          const screen = !sso ? 'login' : filled ? 'agreement' : 'activate';

          return {
            login: <ActivateLogin />,
            activate: <ActivateForm />,
            agreement: <ActivateAgreement />,
          }[screen];
        })()}
      </Grid>
      <Grid item xs={12}>
        <StatusBar />
      </Grid>
    </Grid>
  </DocumentTitle>
);

Activate.propTypes = {
  classes: PropTypes.object.isRequired,
  sso: PropTypes.bool.isRequired,
  filled: PropTypes.bool.isRequired,
};

export default withStyles(styles, { name: 'Activate' })(Activate);
