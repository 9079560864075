export const SMB_ACCOUNT_TOGGLE_UPLOAD = 'SMB_ACCOUNT_TOGGLE_UPLOAD';
export const SMB_ACCOUNT_UPLOAD_LOGO = 'SMB_ACCOUNT_UPLOAD_LOGO';
export const SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS =
  'SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS';
export const SMB_ACCOUNT_UPLOAD_LOGO_FAILURE =
  'SMB_ACCOUNT_UPLOAD_LOGO_FAILURE';
export const SMB_ACCOUNT_TOGGLE_SYSLOG = 'SMB_ACCOUNT_TOGGLE_SYSLOG';
export const SMB_ACCOUNT_GET_INFO = 'SMB_ACCOUNT_GET_INFO';
export const SMB_ACCOUNT_GET_INFO_SUCCESS = 'SMB_ACCOUNT_GET_INFO_SUCCESS';
export const SMB_ACCOUNT_GET_INFO_FAILURE = 'SMB_ACCOUNT_GET_INFO_FAILURE';
export const SMB_ACCOUNT_GET_SYSLOG = 'SMB_ACCOUNT_GET_SYSLOG';
export const SMB_ACCOUNT_GET_SYSLOG_SUCCESS = 'SMB_ACCOUNT_GET_SYSLOG_SUCCESS';
export const SMB_ACCOUNT_DEMO_ACCOUNT_GET_SYSLOG_SUCCESS =
  'SMB_ACCOUNT_DEMO_ACCOUNT_GET_SYSLOG_SUCCESS';
export const SMB_ACCOUNT_GET_SYSLOG_FAILURE = 'SMB_ACCOUNT_GET_SYSLOG_FAILURE';
export const SMB_ACCOUNT_CLOSE_SYSLOG_CONFIG =
  'SMB_ACCOUNT_CLOSE_SYSLOG_CONFIG';
export const SMB_ACCOUNT_TOGGLE_SYSLOG_CONFIG =
  'SMB_ACCOUNT_TOGGLE_SYSLOG_CONFIG';
export const SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG =
  'SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG';
export const SMB_ACCOUNT_TOGGLE_PREVIEW = 'SMB_ACCOUNT_TOGGLE_PREVIEW';
export const SMB_ACCOUNT_CHANGE_LOGO = 'SMB_ACCOUNT_CHANGE_LOGO';
export const SMB_ACCOUNT_CHANGE_LOGO_SUCCESS =
  'SMB_ACCOUNT_CHANGE_LOGO_SUCCESS';
export const SMB_ACCOUNT_SYSLOG_GET_INFO = 'SMB_ACCOUNT_SYSLOG_GET_INFO';
export const SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS =
  'SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS';
export const SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_SUCCESS =
  'SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_SUCCESS';
export const SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_FAILURE =
  'SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_FAILURE';
export const SMB_ACCOUNT_UPDATE_SYSLOG_OPTIONS =
  'SMB_ACCOUNT_SMB_ACCOUNT_UPDATE_SYSLOG_OPTIONS';
export const SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION =
  'SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION';
export const SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_SUCCESS =
  'SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_SUCCESS';
export const SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_FAILURE =
  'SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_FAILURE';
export const SMB_ACCOUNT_SYSLOG_REMOVE = 'SMB_ACCOUNT_SYSLOG_REMOVE';
export const SMB_ACCOUNT_SYSLOG_REMOVE_SUCCESS =
  'SMB_ACCOUNT_SYSLOG_REMOVE_SUCCESS';
export const SMB_ACCOUNT_SYSLOG_REMOVE_FAILURE =
  'SMB_ACCOUNT_SYSLOG_REMOVE_FAILURE';
export const SMB_ACCOUNT_TOGGLE_SYSLOG_REMOVE =
  'SMB_ACCOUNT_TOGGLE_SYSLOG_REMOVE';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY_SUCCESS =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY_SUCCESS';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY_FAILURE =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY_FAILURE';

export const SMB_ACCOUNT_UPDATE_SYSLOG_CONFIG =
  'SMB_ACCOUNT_UPDATE_SYSLOG_CONFIG';
export const SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION =
  'SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION';
export const SMB_ACCOUNT_UPDATE_DOMAIN = 'SMB_ACCOUNT_UPDATE_DOMAIN';
export const SMB_ACCOUNT_RESET = 'SMB_ACCOUNT_RESET';
export const SMB_ACCOUNT_SAVE = 'SMB_ACCOUNT_SAVE';
export const SMB_ACCOUNT_SAVE_SUCCESS = 'SMB_ACCOUNT_SAVE_SUCCESS';
export const SMB_ACCOUNT_SAVE_FAILURE = 'SMB_ACCOUNT_SAVE_FAILURE';
export const SMB_ACCOUNT_LOGO_ERROR = 'SMB_ACCOUNT_LOGO_ERROR';
export const SMB_ACCOUNT_SYSLOG_ERROR = 'SMB_ACCOUNT_SYSLOG_ERROR';
export const SMB_ACCOUNT_UPDATE_RECATEGORIZE_DOMAIN =
  'SMB_ACCOUNT_UPDATE_RECATEGORIZE_DOMAIN';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_SUCCESS =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_SUCCESS';
export const SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_FAILURE =
  'SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_FAILURE';

export const SMB_ACCOUNT_RECATEGORIZE_DOMAIN_HANDLE_CHANGE =
  'SMB_ACCOUNT_RECATEGORIZE_DOMAIN_HANDLE_CHANGE';

export const SMB_ACCOUNT_RECATEGORIZE_DOMAIN_MODAL =
  'SMB_ACCOUNT_RECATEGORIZE_DOMAIN_MODAL';

export const SMB_ACCOUNT_RECATEGORIZE_DOMAIN_CONFIRM =
  'SMB_ACCOUNT_RECATEGORIZE_DOMAIN_CONFIRM';

export const SMB_ACCOUNT_RECATEGORIZE_DOMAIN_SUCCESS =
  'SMB_ACCOUNT_RECATEGORIZE_DOMAIN_SUCCESS';

export const SMB_ACCOUNT_RECATEGORIZE_DOMAIN_FAILURE =
  'SMB_ACCOUNT_RECATEGORIZE_DOMAIN_FAILURE';

export const toggleUploadModal = show => ({
  type: SMB_ACCOUNT_TOGGLE_UPLOAD,
  show,
});

export const uploadLogo = logo => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO,
  logo,
});

export const uploadLogoSuccess = logo => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS,
  logo,
});

export const uploadLogoFailure = error => ({
  type: SMB_ACCOUNT_UPLOAD_LOGO_FAILURE,
  error,
});

export const toggleSyslogModal = show => ({
  type: SMB_ACCOUNT_TOGGLE_SYSLOG,
  show,
});

export const getSyslogConfig = () => ({
  type: SMB_ACCOUNT_SYSLOG_GET_INFO,
});

export const toggleSyslogConfig = data => ({
  type: SMB_ACCOUNT_TOGGLE_SYSLOG_CONFIG,
  ...data,
});

export const toggleSyslogConfigForDemmoAccounts = data => ({
  type: SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG,
  ...data,
});

export const closeSyslogModal = () => ({
  type: SMB_ACCOUNT_CLOSE_SYSLOG_CONFIG,
});

export const toggleSyslogRemove = show => ({
  type: SMB_ACCOUNT_TOGGLE_SYSLOG_REMOVE,
  show,
});

export const removeSyslog = () => ({
  type: SMB_ACCOUNT_SYSLOG_REMOVE,
});

export const removeSyslogSuccess = () => ({
  type: SMB_ACCOUNT_SYSLOG_REMOVE_SUCCESS,
});

export const removeSyslogFailure = () => ({
  type: SMB_ACCOUNT_SYSLOG_REMOVE_FAILURE,
});

export const getAccountInfo = () => ({
  type: SMB_ACCOUNT_GET_INFO,
});

export const getAccountInfoSuccess = data => ({
  type: SMB_ACCOUNT_GET_INFO_SUCCESS,
  ...data,
});

export const getAccountInfoFailure = error => ({
  type: SMB_ACCOUNT_GET_INFO_FAILURE,
  error,
});

export const getSyslogInfo = () => ({
  type: SMB_ACCOUNT_GET_SYSLOG,
});

export const getSyslogInfoSuccess = data => ({
  type: SMB_ACCOUNT_GET_SYSLOG_SUCCESS,
  ...data,
});

export const getSyslogInfoForDemoAccountSuccess = () => ({
  type: SMB_ACCOUNT_DEMO_ACCOUNT_GET_SYSLOG_SUCCESS,
});

export const getSyslogInfoFailure = error => ({
  type: SMB_ACCOUNT_GET_SYSLOG_FAILURE,
  ...error,
});

export const togglePreview = show => ({
  type: SMB_ACCOUNT_TOGGLE_PREVIEW,
  show,
});

export const changeLogo = etag => ({
  type: SMB_ACCOUNT_CHANGE_LOGO,
  etag,
});

export const changeLogoSuccess = () => ({
  type: SMB_ACCOUNT_CHANGE_LOGO_SUCCESS,
});

export const saveSyslogSettings = () => ({
  type: SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS,
});

export const saveSyslogSettingsSuccess = result => ({
  type: SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_SUCCESS,
  result,
});

export const saveSyslogSettingsFailure = error => ({
  type: SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_FAILURE,
  ...error,
});

export const updateSyslogConfig = options => ({
  type: SMB_ACCOUNT_UPDATE_SYSLOG_CONFIG,
  options,
});

export const updateAccountOption = options => ({
  type: SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION,
  options,
});

export const resetSettings = () => ({
  type: SMB_ACCOUNT_RESET,
});

export const saveSettings = () => ({
  type: SMB_ACCOUNT_SAVE,
});
export const saveSettingsSuccess = result => ({
  type: SMB_ACCOUNT_SAVE_SUCCESS,
  result,
});
export const saveSettingsFailure = errors => ({
  type: SMB_ACCOUNT_SAVE_FAILURE,
  errors,
});

export const logoErrors = errors => ({
  type: SMB_ACCOUNT_LOGO_ERROR,
  errors,
});

export const syslogErrors = errors => ({
  type: SMB_ACCOUNT_SYSLOG_ERROR,
  errors,
});

export const toggleConnection = setting => ({
  type: SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION,
  setting,
});

export const toggleConnectionSuccess = setting => ({
  type: SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_SUCCESS,
  setting,
});

export const toggleConnectionFailure = setting => ({
  type: SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION_FAILURE,
  setting,
});

export const getDomainCategory = domain => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY,
  domain,
});

export const updateDomain = field => ({
  type: SMB_ACCOUNT_UPDATE_DOMAIN,
  field,
});

export const getDomainCategorySuccess = category_name => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY_SUCCESS,
  category_name,
});

export const getDomainCategoryFailure = () => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY_FAILURE,
});

export const updateRecategorizeDomain = myfield => ({
  type: SMB_ACCOUNT_UPDATE_RECATEGORIZE_DOMAIN,
  myfield,
});
export const getDomainCategoryList = domainVal => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST,
  domainVal,
});
export const getDomainCategoryListSuccess = categories => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_SUCCESS,
  categories,
});

export const getDomainCategoryListFailure = () => ({
  type: SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_FAILURE,
});

export const recategorizeDomainHandleChange = value => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_HANDLE_CHANGE,
  value,
});

export const openRecategorizeModal = show => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_MODAL,
  show,
});

export const closeRecategorizeModal = show => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_MODAL,
  show,
});

export const recategorizeDomainConfirm = () => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_CONFIRM,
});

export const recategorizeDomainSuccess = result => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_SUCCESS,
  result,
});

export const recategorizeDomainFailure = error => ({
  type: SMB_ACCOUNT_RECATEGORIZE_DOMAIN_FAILURE,
  error,
});
