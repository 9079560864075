import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ActionBar from '../../components/shared_policies/ActionBar';

import { openAddModal, onQueryChange } from '../../actions/shared_policies';

const mapStateToProps = state => ({
  value: state.shared_policies.atp.query,
  loading: state.shared_policies.sharedThreatPolicy.loading,
});

const mapDispatchToProps = dispatch => ({
  openModal: e => {
    dispatch(openAddModal());
  },
  onQueryChange: value => {
    dispatch(onQueryChange(value));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActionBar)
);
