import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ADGroupSelection from '../../containers/advanced_filtering/ADGroupSelection';
import ActionSelection from '../../containers/advanced_filtering/ActionSelection';
import TypeSelection from '../../containers/advanced_filtering/TypeSelection';
import CategoryPolicy from '../../containers/advanced_filtering/CategoryPolicy';
import DomainInput from '../../containers/advanced_filtering/DomainInput';
import UrlInput from '../../containers/advanced_filtering/UrlInput';

const EditPolicy = ({ classes, display }) => (
  <Grid id="edit-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.levelContainer}>
      <ADGroupSelection
        disabled={display !== 'ruleset'}
        multi={display !== 'ruleset'}
      />
    </Grid>
    {display !== 'ruleset' && (
      <Grid item xs={12} className={classes.addContainer}>
        <ActionSelection />
        <TypeSelection disabled />
        {display === 'categories' && <CategoryPolicy />}
        {display === 'domains' && <DomainInput />}
        {display === 'url' && <UrlInput />}
      </Grid>
    )}
  </Grid>
);

EditPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
};

EditPolicy.defaultProps = {};

const styles = {
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'EditPolicy' })(EditPolicy);
