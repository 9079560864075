import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CopyToClipboard from 'react-copy-to-clipboard';
import { translate, Translate } from 'react-i18nify';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';

class ChipInput extends Component {
  state = {
    open: false,
  };

  onKeyDown = event => {
    const {
      inputValue,
      copyStr,
      selectedIndex,
      onSelectIndex,
      filterInput,
    } = this.props;
    const keyPressed = event.key;

    if (
      keyPressed === 'Enter' ||
      keyPressed === ' ' ||
      (keyPressed === 'Tab' && event.target.value)
    ) {
      event.preventDefault();
      this.updateChips(event, filterInput);
    } else if (keyPressed === 'Backspace') {
      let chips = inputValue;

      if (!event.target.value && chips.length) {
        this.deleteChip(chips, selectedIndex);
      }
    } else if (keyPressed === 'ArrowLeft' && selectedIndex > 0) {
      onSelectIndex(selectedIndex - 1);
    } else if (
      keyPressed === 'ArrowRight' &&
      selectedIndex < inputValue.length
    ) {
      onSelectIndex(selectedIndex + 1);
    }
  };

  updateChips = (event, filterInput) => {
    const { inputValue, onChange } = this.props;
    let value = event.target.value;

    if (!value) return;

    let chip = value.trim().replace(/https?:\/\//, '');

    if (filterInput) {
      chip = filterInput(chip);
    }

    if (chip && inputValue.indexOf(chip) < 0) {
      if (chip.match(/[,]/g)) onChange([...inputValue, ...chip.split(/,\s*/g)]);
      else if (chip.match(/\s+/))
        onChange([...inputValue, ...chip.split(/\s+/g)]);
      else {
        onChange([...inputValue, chip]);
      }
    }

    event.target.value = '';
  };

  deleteChip = (chip, index) => {
    const { onChange } = this.props;

    if (index === chip.length) {
      onChange(chip.slice(0, chip.length - 1));
    } else if (index >= 0) {
      onChange([
        ...chip.slice(0, index),
        ...chip.slice(index + 1, chip.length),
      ]);
    }
  };

  focusInput = event => {
    let children = event.target.children;

    if (children.length) children[children.length - 1].focus();
  };

  render() {
    const {
      classes,
      name,
      inputValue,
      copyStr,
      ruleType,
      selectedIndex,
      onSelectIndex,
      error,
      helpText,
    } = this.props;

    let chips = inputValue.map((chip, index) => {
      let style = classNames(classes.chip);
      if (index === selectedIndex) {
        style = classNames(classes.chip, classes.highlight);
      }

      return (
        <Chip
          key={index}
          label={chip}
          className={style}
          style={{ marginRight: '5px' }}
          onKeyDown={this.onKeyDown}
          onClick={() => {
            onSelectIndex(index);
          }}
        />
      );
    });

    const handleTooltipOpen = () => {
      this.setState({ open: true });
      setTimeout(() => {
        this.setState({ open: false });
      }, 300);
    };

    return (
      <Grid
        container
        spacing={16}
        alignItems="flex-end"
        className={classes.wrapper}
      >
        <Grid item xs={3} data-test-id="chip-text">
          {name}
        </Grid>
        <Grid
          item
          xs={9}
          data-test-id="chip-textinput"
          className={classes.chipInputGrid}
        >
          <div
            className={classNames(
              classes.chipInputWrapper,
              error ? classes.error : null
            )}
            onClick={this.focusInput}
          >
            {chips}
            <input
              type="text"
              className={classes.chipsInput}
              onKeyDown={this.onKeyDown}
              onClick={() => {
                onSelectIndex(inputValue.length);
              }}
            />
          </div>
          <div>
            <Tooltip title={translate('shared.copied')} open={this.state.open}>
              <span>
                <CopyToClipboard text={copyStr}>
                  <div
                    className={classes.copyToClipboard}
                    onClick={() => handleTooltipOpen()}
                  >
                    <FileCopyIcon
                      className={classes.copyIcon}
                      sx={{ '&:hover': { color: 'green' } }}
                    />
                  </div>
                </CopyToClipboard>
              </span>
            </Tooltip>
          </div>
        </Grid>
        <Grid item xs={3} />
        <Grid
          item
          xs={9}
          data-test-id="chip-input-help"
          className={classes.helpText}
        >
          {helpText ? helpText : null}
        </Grid>
        <Grid
          item
          xs={9}
          data-test-id="chip-input-error"
          className={classes.errorText}
        >
          {error ? error : null}
        </Grid>
      </Grid>
    );
  }
}

ChipInput.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  inputValue: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  copyStr: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelectIndex: PropTypes.func.isRequired,
  filterInput: PropTypes.func,
  error: PropTypes.string,
};

const styles = theme => ({
  wrapper: {
    paddingBottom: '15px',
  },
  chipInputGrid: {
    padding: '0px !important',
    position: 'relative',
    minHeight: '25px',
    display: 'flex',
  },
  chip: {
    height: '24px',
    fontSize: '12px',
    lineHeight: '24px',
    display: 'inline-block',
    maxWidth: '500px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    marginBottom: '2px',
    '& span': {
      display: 'inline-block',
    },
  },
  chipInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    flexWrap: 'wrap',
    border: '1px solid #A8A8A8',
    borderRadius: '2px',
    padding: '4px 8px',
    marginLeft: '3px',
    minHeight: '25px',
  },
  chipsInput: {
    display: 'inline-block',
    border: 0,
    outline: 'none',
    fontSize: '12px',
    height: '24px',
    padding: 0,
    flexGrow: 1,
  },
  highlight: {
    backgroundColor: 'rgba(0,0,0,0.7) !important',
    color: '#FFFFFF',
  },
  error: {
    borderColor: `${theme.statusColors.failure} !important`,
  },
  helpText: {
    color: '#7F7F7F',
    fontSize: '12px',
    fontWeight: 'normal',
    lineHeight: '16px',
    marginBottom: '10px',
    paddingLeft: '5px',
  },
  errorText: {
    color: '#DD1E31',
    fontSize: '13px',
  },
  copyToClipboard: {
    color: '#7F7F7F',
    cursor: 'pointer',
    text: 'Copy',
    margin: 0,
    padding: 0,
  },
  copyIcon: {
    marginRight: '10px',
    height: 18,
  },
});

export default withStyles(styles, { name: 'ChipInput' })(ChipInput);
