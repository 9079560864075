import mixpanel from 'mixpanel-browser';
mixpanel.init('cc94f3a468934680e6354d39670ebf0c');

let ENV_STAGE = process.env.REACT_APP_STAGE
  ? process.env.REACT_APP_STAGE
  : process.env.PUBLIC_URL;
let env_check =
  process.env.REACT_APP_ENV === 'production' ||
  (ENV_STAGE === '' && process.env.NODE_ENV === 'production') ||
  process.env.REACT_APP_ENV === 'eu-west-1-production';

let actions = {
  identify: id => {
    if (env_check) mixpanel.identify(id);
  },
  alias: id => {
    if (env_check) mixpanel.alias(id);
  },
  track: (name, props) => {
    if (env_check) mixpanel.track(name, props);
  },
  people: {
    set: props => {
      if (env_check) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
