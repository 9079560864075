export const SHARED_THREAT_OPEN_MODAL = 'SHARED_THREAT_OPEN_MODAL';
export const SHARED_THREAT_CLOSE_MODAL = 'SHARED_THREAT_CLOSE_MODAL';
export const SHARED_THREAT_CHANGE_ACCOUNT_SELECTION =
  'SHARED_THREAT_CHANGE_ACCOUNT_SELECTION';
export const SHARED_THREAT_POLICY_ADD = 'SHARED_THREAT_POLICY_ADD';
export const SHARED_THREAT_EDIT_MODAL_OPEN = 'SHARED_THREAT_EDIT_MODAL_OPEN';

export const openAddModal = () => ({
  type: SHARED_THREAT_OPEN_MODAL,
});

export const addSharedPolicies = () => ({
  type: SHARED_THREAT_POLICY_ADD,
});

export const SHARED_THREAT_POLICY_SAVE_EDIT = 'SHARED_THREAT_POLICY_SAVE_EDIT';
export const saveEditSharedPolicies = () => ({
  type: SHARED_THREAT_POLICY_SAVE_EDIT,
});

export const closeAddThreatModal = () => ({
  type: SHARED_THREAT_CLOSE_MODAL,
});

export const SHARED_THREAT_CLOSE_EDIT_MODAL = 'SHARED_THREAT_CLOSE_EDIT_MODAL';
export const closeEditThreatModal = () => ({
  type: SHARED_THREAT_CLOSE_EDIT_MODAL,
});

export const SHARED_POLICIES_UPDATE = 'SHARED_POLICIES_UPDATE';
export const updateSharedOptions = options => ({
  type: SHARED_POLICIES_UPDATE,
  options,
});

export const SHARED_THREAT_FILTER_EXCLUSION = 'SHARED_THREAT_FILTER_EXCLUSION';
export const filterExclusions = selection => ({
  type: SHARED_THREAT_FILTER_EXCLUSION,
  selection,
});

export const SHARED_THREAT_SHOW_EXCLUSION_MODAL =
  'SHARED_THREAT_SHOW_EXCLUSION_MODAL';
export const showExclusionsModal = () => ({
  type: SHARED_THREAT_SHOW_EXCLUSION_MODAL,
});

export const SHARED_THREAT_SHOW_EDIT_EXCLUSION_MODAL =
  'SHARED_THREAT_SHOW_EDIT_EXCLUSION_MODAL';
export const showEditExclusionsModal = () => ({
  type: SHARED_THREAT_SHOW_EDIT_EXCLUSION_MODAL,
});

export const SHARED_THREAT_CLOSE_EXCLUSION_MODAL =
  'SHARED_THREAT_CLOSE_EXCLUSION_MODAL';
export const closeExclusionModal = () => ({
  type: SHARED_THREAT_CLOSE_EXCLUSION_MODAL,
});

export const SHARED_THREAT_ADD_CUSTOM_EXCLUSION =
  'SHARED_THREAT_ADD_CUSTOM_EXCLUSION';
export const addCustomExclusion = (type, value) => ({
  type: SHARED_THREAT_ADD_CUSTOM_EXCLUSION,
  custom_type: type,
  value,
});

export const SHARED_THREAT_EDIT_CUSTOM_EXCLUSION =
  'SHARED_THREAT_EDIT_CUSTOM_EXCLUSION';
export const editCustomExclusion = (type, value) => ({
  type: SHARED_THREAT_EDIT_CUSTOM_EXCLUSION,
  custom_type: type,
  value,
});

export const onChangeAccountSelection = value => ({
  type: SHARED_THREAT_CHANGE_ACCOUNT_SELECTION,
  value,
});

export const SHARED_THREAT_CHANGE_EDIT_ACCOUNT_SELECTION =
  'SHARED_THREAT_CHANGE_EDIT_ACCOUNT_SELECTION';
export const onChangeEditAccountSelection = value => ({
  type: SHARED_THREAT_CHANGE_EDIT_ACCOUNT_SELECTION,
  value,
});

export const SHARED_THREAT_INIT_DATA = 'SHARED_THREAT_INIT_DATA';
export const initData = () => ({
  type: SHARED_THREAT_INIT_DATA,
});

export const SHARED_THREAT_SUCCESS = 'SHARED_THREAT_SUCCESS';
export const getSharedPolicies = result => ({
  type: SHARED_THREAT_SUCCESS,
  result,
});

export const SHARED_THREAT_DELETE_MODAL_OPEN =
  'SHARED_THREAT_DELETE_MODAL_OPEN';
export const openModal = (type, id) => ({
  type,
  id,
});

export const SHARED_THREAT_POLICY_DELETE = 'SHARED_THREAT_POLICY_DELETE';
export const deletePolicy = () => ({
  type: SHARED_THREAT_POLICY_DELETE,
});

export const SHARED_THREAT_DELETE_MODAL_CLOSE =
  'SHARED_THREAT_DELETE_MODAL_CLOSE';
export const closeModal = type => ({
  type,
});

export const SHARED_THREAT_CREATE_SUCCESS = 'SHARED_THREAT_CREATE_SUCCESS';
export const createSuccess = () => ({
  type: SHARED_THREAT_CREATE_SUCCESS,
});

export const SHARED_THREAT_EDIT_SUCCESS = 'SHARED_THREAT_EDIT_SUCCESS';
export const editSuccess = () => ({
  type: SHARED_THREAT_EDIT_SUCCESS,
});

export const SHARED_THREAT_QUERY_CHANGE = 'SHARED_THREAT_QUERY_CHANGE';
export const onQueryChange = value => ({
  type: SHARED_THREAT_QUERY_CHANGE,
  value,
});

export const SHARED_THREAT_QUERY_APPLY = 'SHARED_THREAT_QUERY_APPLY';
export const applyAtpQuery = value => ({
  type: SHARED_THREAT_QUERY_APPLY,
  value,
});

export const SHARED_THREAT_LOADING_PAGE = 'SHARED_THREAT_LOADING_PAGE';
export const loadingPage = () => ({
  type: SHARED_THREAT_LOADING_PAGE,
});

export const SHARED_COPY_CONFIG_ACCOUNTS = 'SHARED_COPY_CONFIG_ACCOUNTS';
export const copyConfigAccounts = accounts => ({
  type: SHARED_COPY_CONFIG_ACCOUNTS,
  accounts,
});

export const CLOSE_COPY_MODAL = 'CLOSE_COPY_MODAL';
export const closeCopyModal = () => ({
  type: CLOSE_COPY_MODAL,
});

export const SHARED_THREAT_POLICY_GET_POLICY_SUCCESS =
  'SHARED_THREAT_POLICY_GET_POLICY_SUCCESS';
export const getSharedPolicy = result => ({
  type: SHARED_THREAT_POLICY_GET_POLICY_SUCCESS,
  result,
});
