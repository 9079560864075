import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import EPSTableBody from '../table/TableBody';
import CudaTableTitle from '../CudaTableTitle';
import GroupRow from './GroupRow';

const SyncToolTable = ({
  classes,
  groups,
  sort,
  handleToggle,
  handleSort,
  expanded,
}) => (
  <Table
    data-test-id="sync-user-table"
    className={classes.table}
    padding="none"
  >
    <TableHead>
      <TableRow>
        <TableCell style={{ width: '24px' }} />
        <TableCell
          data-test-id="name-column"
          style={{ paddingLeft: 0, width: '30%' }}
        >
          <CudaTableTitle
            value="userAuthentication.syncTool.name"
            sort
            toggle={sort.field === 'name' ? sort.direction : 'none'}
            onClick={() => {
              handleSort('name');
            }}
          />
        </TableCell>
        <TableCell data-test-id="user-id-column" style={{ width: '20%' }}>
          <CudaTableTitle value="userAuthentication.syncTool.userID" />
        </TableCell>
        <TableCell data-test-id="ip-column" style={{ width: '20%' }}>
          <CudaTableTitle value="userAuthentication.syncTool.ipAddress" />
        </TableCell>
        <TableCell data-test-id="last-seen-column" style={{ width: '20%' }}>
          <CudaTableTitle value="userAuthentication.syncTool.lastSeen" />
        </TableCell>
        <TableCell />
      </TableRow>
    </TableHead>
    <EPSTableBody
      data-test-id="sync-user-table-body"
      loading={false}
      colCount={6}
      rowCount={groups.length}
      lid="userAuthentication.syncTool.noSyncToolUsers"
    >
      {groups.map((group, i) => (
        <GroupRow
          key={group.name}
          index={i}
          group={group}
          hideMenu={false}
          expanded={expanded[group.id]}
          handleToggle={handleToggle}
        />
      ))}
    </EPSTableBody>
  </Table>
);

SyncToolTable.propTypes = {
  sort: PropTypes.shape({
    field: PropTypes.string,
    direction: PropTypes.oneOf(['asc', 'desc']),
  }).isRequired,
  handleToggle: PropTypes.func.isRequired,
  handleSort: PropTypes.func.isRequired,
  expanded: PropTypes.object.isRequired,
};

const styles = {
  table: {
    '& td': {
      padding: '4px 12px',
    },
    '& th': {
      padding: '4px 12px',
    },
  },
};

export default withStyles(styles, { name: 'SyncToolTable' })(SyncToolTable);
