import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { SearchIcon } from './icons';
import Input from '@material-ui/core/Input/Input';
import classNames from 'classnames';

const styleSheet = {
  container: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: '#FFFFFF',
  },

  container_disabled: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: 2,
    backgroundColor: '#f2f2f2',
  },

  icon: {
    margin: '2px 25px 0 19px',
  },
  input_wrapper: {
    marginLeft: 0,
    marginRight: 25,
    width: '100%',
    fontSize: 14,
  },
  noShadow: {
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
  },
  shadow: {
    boxShadow: '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
  },
  invertShadow: {
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
  },
};

const SearchBar = ({
  classes,
  onChange,
  placeholder = 'Search',
  disabled = false,
  autoFocus = true,
  height = '48px',
  invert = false,
  noShadow = false,
  ...props
}) => {
  let containerClass;

  if (noShadow) {
    containerClass = classNames(classes.container, classes.noShadow);
  } else if (invert) {
    containerClass = classNames(classes.container, classes.invertShadow);
  } else {
    containerClass = classNames(classes.container, classes.shadow);
  }

  return (
    <div
      className={disabled ? classes.container_disabled : containerClass}
      style={{ height: height }}
    >
      <SearchIcon className={classes.icon} />
      <Input
        autoFocus={autoFocus}
        placeholder={placeholder}
        className={classes.input_wrapper}
        onChange={e => onChange(e.target.value)}
        disabled={disabled}
        disableUnderline={true}
        {...props}
      />
    </div>
  );
};

export default withStyles(styleSheet, { name: 'SearchBar' })(SearchBar);
