import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import UserTable from '../../components/user_authentication/UserTable';

import {
  toggleGroupExpand,
  handleSort,
} from '../../actions/user_authentication';
import { getFilteredGroups } from '../../selectors/user_authentication';

const mapStateToProps = state => ({
  groups: getFilteredGroups(state),
  sort: state.userAuthentication.sort,
  expanded: state.userAuthentication.expanded,
});

const mapDispatchToProps = dispatch => ({
  handleToggle: id => dispatch(toggleGroupExpand(id)),
  handleSort: field => dispatch(handleSort(field)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserTable));
