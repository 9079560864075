import { connect } from 'react-redux';
import SafesearchModal from '../../components/advanced_filtering/SafesearchModal';

import {
  addSafesearch,
  closeSafesearch,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => {
  const ad = state.advanced_filtering.ruleModal.adGroup.length;
  let disabled = true;

  const origEnabled = state.advanced_filtering.safesearchModal.fields.enabled;
  const newEnabled = state.advanced_filtering.safesearchModal.enabled;
  disabled = origEnabled === newEnabled || !ad;

  return {
    disabled,
    loading: state.advanced_filtering.safesearchModal.loading,
    showModal: state.advanced_filtering.safesearchModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    dispatch(addSafesearch());
  },
  closeDialog: () => {
    dispatch(closeSafesearch());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafesearchModal);
