import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import CudaTableTitle from '../CudaTableTitle';
import Paper from '../CudaPaper';
import BarChart from '../charts/Bar';

const styles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: '300',
    color: 'rgba(0,0,0,0.9)',
  },
  subtitle: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
  graphWrapper: {
    padding: '20px',
    margin: '20px',
  },
  dataTableWrapper: {
    margin: '20px',
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
  title: {
    margin: '0px',
  },
});

const customReport = {
  agent_version: true,
  'blocked-categories': true,
  'blocked-supercategories': true,
};

class PageReport extends Component {
  render() {
    const { classes, title, data, headers } = this.props;

    return (
      <Paper className={classes.graphWrapper}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12}>
            <h2 className={classes.title}>{title}</h2>
          </Grid>
          <Grid item xs={12}>
            <BarChart data={data} width={'99%'} textWidth={250} />
          </Grid>
          <Grid item xs={12} className={classes.tableWrapper}>
            <Table>
              <TableHead>
                <TableRow className={classes.tableTitle}>
                  {headers.map((n, i) => (
                    <TableCell key={i}>
                      <CudaTableTitle value={n} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((n, i) => (
                  <TableRow key={i}>
                    <TableCell key={`${i}-name`}>{n['name']}</TableCell>
                    <TableCell key={`${i}-count`}>{n['count']}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Paper>
    );
  }
}

PageReport.propTypes = {};

export default withStyles(styles)(withRouter(PageReport));
