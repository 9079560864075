export const INIT_DATA = 'INIT_MSP_GRAPH';
export const CHANGE_TIME = 'CHANGE_MSP_TIME';
export const GET_MSP_DATA_SUCCESS = 'GET_MSP_DATA_SUCCESS';
export const GET_MSP_DATA_FAILURE = 'GET_MSP_DATA_FAILURE';
export const MSP_LOADING = 'MSP_LOADING';
export const UPDATE_MSP_FILE_TYPES_TIMEFRAME =
  'UPDATE_MSP_FILE_TYPES_TIMEFRAME';
export const UPDATE_THREAT_DETECTED_GRAPH = 'UPDATE_THREAT_DETECTED_GRAPH';
export const GET_MSP_THREAT_TYPE_DATA_SUCCESS =
  'GET_MSP_THREAT_TYPE_DATA_SUCCESS';
export const GET_MSP_THREAT_TYPE_DATA_FAILURE =
  'GET_MSP_THREAT_TYPE_DATA_FAILURE';
export const MSP_THREAT_TYPE_LOADING = 'MSP_THREAT_TYPE_LOADING';
export const GET_MSP_THREAT_DETECTED_DATA_SUCCESS =
  'GET_MSP_THREAT_DETECTED_DATA_SUCCESS';
export const GET_MSP_THREAT_DETECTED_DATA_FAILURE =
  'GET_MSP_THREAT_DETECTED_DATA_FAILURE';
export const MSP_THREAT_DETECTED_TYPE_CHANGE =
  'MSP_THREAT_DETECTED_TYPE_CHANGE';
export const MSP_THREAT_DETECTED_LOADING = 'MSP_THREAT_DETECTED_LOADING';
export const TOP_THREAT_GRAPH_LOADING = 'TOP_THREAT_GRAPH_LOADING';
export const UPDATE_TOP_THREAT_GRAPH = 'UPDATE_TOP_THREAT_GRAPH';
export const GET_MSP_TOP_THREAT_DATA_SUCCESS =
  'GET_MSP_TOP_THREAT_DATA_SUCCESS';
export const GET_MSP_TOP_THREAT_DATA_FAILURE =
  'GET_MSP_TOP_THREAT_DATA_FAILURE';

export const MSP_TOP_BLOCKED_ACCOUNTS_LOADING =
  'MSP_TOP_BLOCKED_ACCOUNTS_LOADING';
export const MSP_GET_TOP_BLOCKED_ACCOUNTS = 'MSP_GET_TOP_BLOCKED_ACCOUNTS';
export const MSP_GET_TOP_BLOCKED_ACCOUNTS_SUCCESS =
  'MSP_GET_TOP_BLOCKED_ACCOUNTS_SUCCESS';
export const MSP_GET_TOP_BLOCKED_ACCOUNTS_FAILURE =
  'MSP_GET_TOP_BLOCKED_ACCOUNTS_FAILURE';

export const threatDetectedTypes = ['summary', 'user', 'device'];

export const timeOptions = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

export const initData = () => ({
  type: INIT_DATA,
});

export const mspLoading = () => ({
  type: MSP_LOADING,
});

export const threatTypeLoading = () => ({
  type: MSP_THREAT_TYPE_LOADING,
});

export const threatDetectedLoading = () => ({
  type: MSP_THREAT_DETECTED_LOADING,
});

export const changeTimeframe = (time, index) => ({
  type: CHANGE_TIME,
  time,
  index,
});

const _formatTopThreatsAccounts = (buckets, accounts) => {
  let index = {};
  let result = [];
  accounts.forEach(a => {
    index[a.id] = a;
  });
  buckets.forEach(item => {
    result.push({
      name: index[item['key']] ? index[item['key']]['name'] : item['key'],
      count: item['doc_count'],
    });
  });

  return result;
};

const formatTopBlockedAccounts = (result, accounts) => {
  const lastBlocked = result.current;
  const prevBlocked = result.prev;

  return lastBlocked.map(account => {
    const prev = prevBlocked.find(
      prevAccount => prevAccount.key === account.key
    );
    const accountInfo = accounts.find(
      acc => parseInt(acc.account_id, 10) === account.key
    );
    let trend = 'infinite';
    if (prev) {
      trend = ((account.doc_count - prev.doc_count) / prev.doc_count) * 100;
    } else if (account.doc_count === 0) {
      trend = 0;
    }

    return {
      account: accountInfo ? accountInfo.account_name : '',
      count: account.doc_count,
      key: account.key,
      trend: trend,
      delta: prev ? account.doc_count - prev.doc_count : account.doc_count,
    };
  });
};

export const getMSPDataSuccess = (result, accounts) => {
  const topThreats = _formatTopThreatsAccounts(
    result['top_threats_accounts']['aggregations']['topThreatAccount'][
      'buckets'
    ],
    accounts
  );
  const blockedAccounts = formatTopBlockedAccounts(
    result.top_blocked_accounts,
    accounts
  );

  return {
    type: GET_MSP_DATA_SUCCESS,
    result,
    topThreats,
    blockedAccounts,
  };
};

export const getMSPDataFailure = result => ({
  type: GET_MSP_DATA_FAILURE,
  result,
});

export const getThreatTypeDataSuccess = result => ({
  type: GET_MSP_THREAT_TYPE_DATA_SUCCESS,
  result,
});

export const getThreatTypeDataFailure = result => ({
  type: GET_MSP_THREAT_TYPE_DATA_FAILURE,
  result,
});

export const updateFileTypesTimeframe = (range, index) => ({
  type: UPDATE_MSP_FILE_TYPES_TIMEFRAME,
  range,
  index,
});

export const onThreatChange = (range, index) => ({
  type: UPDATE_THREAT_DETECTED_GRAPH,
  range,
  index,
});

export const getThreatDetectedDataSuccess = result => ({
  type: GET_MSP_THREAT_DETECTED_DATA_SUCCESS,
  result,
});

export const getThreatDetectedDataFailure = result => ({
  type: GET_MSP_THREAT_DETECTED_DATA_FAILURE,
  result,
});

export const onThreatTypeChange = (threat, index) => ({
  type: MSP_THREAT_DETECTED_TYPE_CHANGE,
  threat,
  index,
});

export const topThreatTypeLoading = () => ({
  type: TOP_THREAT_GRAPH_LOADING,
});

export const onTopThreatChange = (range, index) => ({
  type: UPDATE_TOP_THREAT_GRAPH,
  range,
  index,
});

export const getTopThreatDataSuccess = (result, accounts) => {
  const topThreats = _formatTopThreatsAccounts(
    result['aggregations']['topThreatAccount']['buckets'],
    accounts
  );
  return {
    type: GET_MSP_TOP_THREAT_DATA_SUCCESS,
    result: topThreats,
  };
};

export const getTopThreatDataFailure = result => ({
  type: GET_MSP_TOP_THREAT_DATA_FAILURE,
  result,
});

export const getTopBlockedAccounts = range => ({
  type: MSP_GET_TOP_BLOCKED_ACCOUNTS,
  range,
});

export const topBlockedAccountsLoading = () => ({
  type: MSP_TOP_BLOCKED_ACCOUNTS_LOADING,
});

export const getTopBlockedAccountsSuccess = (result, accounts) => {
  const topBlocked = formatTopBlockedAccounts(result, accounts);

  return {
    type: MSP_GET_TOP_BLOCKED_ACCOUNTS_SUCCESS,
    topBlocked,
  };
};

export const getTopBlockedAccountsFailure = result => ({
  type: MSP_GET_TOP_BLOCKED_ACCOUNTS_FAILURE,
  result,
});
