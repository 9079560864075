import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input/Input';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import escapeRegExp from 'escape-string-regexp';

import SettingsItem from '../settings/SettingsItem';
import SettingsRadio from '../settings/SettingsRadio';
import CudaToggle from '../CudaToggle';
import CudaTableTitle from '../CudaTableTitle';
import ScanExclusionTableRow from './ScanExclusionTableRow';

import { SelectAllIcon, FolderIcon, FileIcon, SearchIcon } from '../icons';

class ThreatPolicy extends Component {
  render() {
    const {
      classes,
      fields,
      saving,
      customExclusion,
      exclusionList,
      updateSetting,
      filterSelection,
      showExclusionsModal,
      query,
      index,
    } = this.props;

    let displayExclusionList = [];
    let tmpList = [];
    const cols = ['type', 'value'];
    const icons = ['asc', 'none'];

    const showFiles = Boolean(
      (customExclusion === 'ALL' || customExclusion === 'FILE') &&
        exclusionList.file.length
    );
    const showPath = Boolean(
      (customExclusion === 'ALL' || customExclusion === 'PATH') &&
        exclusionList.path.length
    );

    const match = new RegExp(escapeRegExp(query), 'i');

    if (showFiles) {
      tmpList = tmpList.concat(
        exclusionList.file
          .filter(file => {
            return query === '' || match.test(file);
          })
          .map(file => {
            return { type: 'FILE', value: file };
          })
      );
    }
    if (showPath) {
      tmpList = tmpList.concat(
        exclusionList.path
          .filter(path => {
            return query === '' || match.test(path);
          })
          .map(path => {
            return { type: 'PATH', value: path };
          })
      );
    }

    const orderBy = cols[index];
    displayExclusionList = tmpList.sort((a, b) => {
      return icons[index] === 'desc'
        ? b[orderBy].toLowerCase() < a[orderBy].toLowerCase()
          ? -1
          : 1
        : a[orderBy].toLowerCase() < b[orderBy].toLowerCase()
        ? -1
        : 1;
    });

    return (
      <>
        <div className={classes.module}>
          <SettingsItem
            text={
              <Translate
                value="components.dashboard.malwarePrevention"
                className={classes.label}
              />
            }
            toggled={fields.cpaEnabled}
            onToggle={e => {
              updateSetting('cpaEnabled', e.target.checked);
            }}
            padding={false}
            disabled={saving}
            labels={['components.shared.on', 'components.shared.off']}
          />
        </div>
        <div className={classes.module}>
          <div className={classes.title}>
            <Translate
              value="components.atp.scanTypeHeader"
              className={classes.label}
            />
          </div>
          <div className={classes.subtitle}>
            <Translate
              value="components.atp.scanTypeHelp"
              className={classes.label}
            />
          </div>
          <SettingsRadio
            disabled={false}
            text={translate('components.atp.autoRemediate')}
            tooltipText={
              <Translate value="components.atp.autoRemediateHelpIcon" />
            }
            value={fields.autoRemediate}
            radioValues={['false', 'true']}
            handleChange={e => {
              updateSetting('autoRemediate', !fields.autoRemediate);
            }}
            radioIsUpdating={false}
            radioUpdateError={false}
            labels={['components.atp.quarantine', 'components.shared.allow']}
          />
          <div className={classes.title}>
            <Translate
              value="components.atp.fileTypes"
              className={classes.label}
            />
          </div>
          <SettingsItem
            disabled={false}
            text={translate('components.atp.scanMsofficeFile')}
            tooltipText={
              <Translate value="components.atp.msofficeFileHelpIcon" />
            }
            toggled={fields.scanMSOffice}
            onToggle={() => {
              updateSetting('scanMSOffice', !fields.scanMSOffice);
            }}
            toggleIsUpdating={false}
            toggleUpdateError={false}
            labels={['components.shared.yes', 'components.shared.no']}
          />
          <SettingsItem
            disabled={false}
            text={translate('components.atp.scanExecutables')}
            tooltipText={translate('components.atp.executablesHelpIcon')}
            toggled={fields.scanExecutables}
            onToggle={() => {
              updateSetting('scanExecutables', !fields.scanExecutables);
            }}
            toggleIsUpdating={false}
            toggleUpdateError={false}
            labels={['components.shared.yes', 'components.shared.no']}
          />
          <SettingsItem
            disabled={false}
            text={translate('components.atp.scanPdfFiles')}
            tooltipText={<Translate value="components.atp.pdfFilesHelpIcon" />}
            toggled={fields.scanPdfDocuments}
            onToggle={() => {
              updateSetting('scanPdfDocuments', !fields.scanPdfDocuments);
            }}
            toggleIsUpdating={false}
            toggleUpdateError={false}
            labels={['components.shared.yes', 'components.shared.no']}
          />
          <SettingsItem
            disabled={false}
            text={translate('components.atp.scanCompressFile')}
            tooltipText={
              <Translate value="components.atp.compressFileHelpIcon" />
            }
            toggled={fields.scanArchiveFiles}
            onToggle={() => {
              updateSetting('scanArchiveFiles', !fields.scanArchiveFiles);
            }}
            toggleIsUpdating={false}
            toggleUpdateError={false}
            labels={['components.shared.yes', 'components.shared.no']}
          />
        </div>
        <div className={classes.module}>
          <div className={classes.title}>
            <Translate
              value="components.atp.encryptedAndProtectedFiles"
              className={classes.label}
            />
          </div>
          <div className={classes.subtitle}>
            <Translate
              value="components.atp.encryptedAndProtectedFilesHelp"
              className={classes.label}
            />
          </div>
          <SettingsRadio
            disabled={false}
            text={translate('components.atp.pwProtectedFile')}
            tooltipText={translate('components.atp.pwProtectedFileHelpIcon')}
            value={fields.blockedPwProtectedOfficeDoc}
            handleChange={() => {
              updateSetting(
                'blockedPwProtectedOfficeDoc',
                !fields.blockedPwProtectedOfficeDoc
              );
            }}
            radioIsUpdating={false}
            radioUpdateError={false}
            labels={['components.atp.quarantine', 'components.shared.allow']}
          />
          <SettingsRadio
            disabled={false}
            text={translate('components.atp.encryptedFiles')}
            tooltipText={translate('components.atp.encryptedFilesHelpIcon')}
            value={fields.blockedPwProtectedArchiveFile}
            handleChange={() => {
              updateSetting(
                'blockedPwProtectedArchiveFile',
                !fields.blockedPwProtectedArchiveFile
              );
            }}
            radioIsUpdating={false}
            radioUpdateError={false}
            labels={['components.atp.quarantine', 'components.shared.allow']}
          />
        </div>
        <div className={classes.module}>
          <div className={classes.title}>
            <Translate
              value="components.atp.removableDrive"
              className={classes.label}
            />
          </div>
          <div className={classes.subtitle}>
            <Translate
              value="components.atp.scanRemovableHelp"
              className={classes.label}
            />
          </div>
          <SettingsItem
            disabled={false}
            text={translate('components.atp.scanRemovableDrive')}
            toggled={fields.scanExclusion}
            onToggle={() => {
              updateSetting('scanExclusion', !fields.scanExclusion);
            }}
            toggleIsUpdating={false}
            toggleUpdateError={false}
            labels={['components.shared.yes', 'components.shared.no']}
          />
        </div>
        <div className={classes.module}>
          <div className={classes.title}>
            <Translate
              value="components.atp.customExclusions"
              className={classes.label}
            />
          </div>
          <div className={classes.subtitle}>
            <Translate
              value="components.atp.customExclusionHelp"
              className={classes.label}
            />
          </div>
          <div className="custom-exclusion-controls">
            <CudaToggle
              name="custom-exclusion"
              value="ALL"
              selected={customExclusion === 'ALL'}
              selectRadio={e => {
                filterSelection('ALL');
              }}
              icon={
                <SelectAllIcon
                  color={customExclusion === 'ALL' ? '#FFFFFF' : '#5F5F5F'}
                />
              }
            />
            <CudaToggle
              name="custom-exclusion"
              value="FILE"
              selected={customExclusion === 'FILE'}
              selectRadio={e => {
                filterSelection('FILE');
              }}
              icon={
                <FileIcon
                  color={customExclusion === 'FILE' ? '#FFFFFF' : '#5F5F5F'}
                />
              }
            />
            <CudaToggle
              name="custom-exclusion"
              value="PATH"
              selected={customExclusion === 'PATH'}
              selectRadio={e => {
                filterSelection('PATH');
              }}
              icon={
                <FolderIcon
                  color={customExclusion === 'PATH' ? '#FFFFFF' : '#5F5F5F'}
                />
              }
            />
          </div>
        </div>
        <div className={classes.custom_bar}>
          <div className={classes.search_bar_wrapper}>
            <div className={classes.search_bar}>
              <Input
                placeholder="Search"
                className={classes.input_wrapper}
                onChange={e => {}}
                disabled={false}
                disableUnderline={true}
              />
            </div>
            <div className={classes.btn}>
              <SearchIcon />
            </div>
          </div>
          <div>
            <Button
              variant="contained"
              className={classes.sharedBtn}
              onClick={() => {
                showExclusionsModal();
              }}
            >
              <Translate value="components.atp.addExclusion" />
            </Button>
          </div>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <CudaTableTitle
                  value={translate('shared.type')}
                  sort={true}
                  toggle={0}
                  onClick={() => {}}
                />
              </TableCell>
              <TableCell style={{ width: '450px' }}>
                <CudaTableTitle
                  value={translate('components.atp.value')}
                  sort={true}
                  toggle={0}
                  onClick={() => {}}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayExclusionList.length > 0 ? (
              displayExclusionList.map((obj, i) => (
                <ScanExclusionTableRow
                  key={i}
                  type={obj.type}
                  name={obj.value}
                  removeAction={() => {}}
                  removeCustomExclusion={() => {}}
                  showEditPopUp={() => {}}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={2}>
                  <div className={classes.empty_wrapper}>
                    <div>
                      <Translate value="components.atp.noCustomExclusion" />
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </>
    );
  }
}

ThreatPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  disabledAD: PropTypes.bool,
};

ThreatPolicy.defaultProps = {};

const styles = {
  module: {
    padding: '24px 24px 0 24px',
    borderBottom: '1px solid #DDDDDD',
  },
  title: {
    height: '25px',
    color: 'rgba(0,0,0,0.7)',
    fontFamily: 'Roboto',
    fontSize: '22px',
    fontWeight: '300',
    letterSpacing: '0',
    lineHeight: '25px',
    marginBottom: '15px',
  },
  subtitle: {
    width: '474px',
    color: '#000006',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0',
    lineHeight: '20px',
  },
  custom_bar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '30px',
    backgroundColor: '#F7F7F7',
    boxShadow: 'inset 0 -1px 0 0 #DDDDDD, inset 0 1px 0 0 #DDDDDD',
    padding: '10px 15px 10px 15px',
  },
  search_bar_wrapper: {
    display: 'flex',
    width: '336px',
  },
  search_bar: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: '2px 0 0 2px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    width: '490px',
    paddingLeft: '10px',
  },
  input_wrapper: {
    marginLeft: 0,
    marginRight: 25,
    width: '100%',
    fontSize: 14,
  },
  btn: {
    '&:hover': {
      opacity: '0.80',
    },
    '&:active': {
      opacity: '1',
    },
    cursor: 'pointer',
    backgroundColor: '#7F7F7F',
    display: 'flex',
    alignItems: 'center',
    width: '44px',
    justifyContent: 'center',
    borderRadius: '0px 2px 2px 0px',
    '& svg > path': {
      fill: 'white',
    },
  },
  sharedBtn: {
    height: '33px',
    margin: 0,
    backgroundColor: '#7F7F7F',
    color: '#FFFFFF',
  },
  empty_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};

export default withStyles(styles, { name: 'ThreatPolicy' })(ThreatPolicy);
