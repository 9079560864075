import { connect } from 'react-redux';
import MoreOption from '../../components/atp_logs/MoreOption';

import { downloadPdf, openReportThreatModal } from '../../actions/atplogs';

const mapStateToProps = state => ({
  wca: state.account.subscriptions.wca,
  features: state.account.features,
});

const mapDispatchToProps = dispatch => ({
  downloadPdf: cuid => {
    dispatch(downloadPdf(cuid.cuid));
  },
  openReportThreatModal: (file, cuid) => {
    dispatch(openReportThreatModal(file, cuid));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoreOption);
