import { connect } from 'react-redux';
import DeleteSharedPolicyModal from '../../components/shared_policies/DeleteSharedPolicyModal';

import { Mixpanel } from '../../lib/Mixpanel';
import {
  addSharedPolicies,
  closeAddThreatModal,
  deletePolicy,
  closeModal,
} from '../../actions/shared_policies';

import { getChangedFields } from '../../selectors/smb_account_settings';

const mapStateToProps = state => {
  return {
    disabled: false,
    loading: state.shared_policies.deleteModal.loading,
    showModal: state.shared_policies.deleteModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    //Mixpanel.track('Advanced Filtering / Add Rule Dialog / Create', {});
    dispatch(deletePolicy());
  },
  closeDialog: type => {
    //Mixpanel.track('Advanced Filtering / Add Rule Dialog / Cancel', {});
    dispatch(closeModal(type));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteSharedPolicyModal);
