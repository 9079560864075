import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import { settings } from '../../actions/atp';
import SettingsItem from '../settings/SettingsItem';

const AdvancedThreatDetectionComponent = ({ classes, atp, toggleSetting }) => (
  <Paper>
    <SettingsItem
      disabled={atp.shared_policy}
      text={
        <Translate
          value="components.atp.advancedThreatDetection"
          className={classes.label}
        />
      }
      tooltipText={translate('components.atp.atdTooltip')}
      toggled={atp.cpaEnabled.value}
      onToggle={() => toggleSetting(settings.cpaEnabled)}
      toggleIsUpdating={atp.cpaEnabled.isUpdating}
      toggleUpdateError={atp.cpaEnabled.updateError}
      labels={['components.shared.enabled', 'components.shared.disabled']}
      customClasses={{
        labelWrapper: classes.labelWrapper,
        container: classes.container,
      }}
    />
  </Paper>
);

const atdStyles = {
  label: {
    fontSize: '24px',
    fontWeight: 300,
    lineHeight: '24px',
  },
  labelWrapper: {
    maxWidth: 'none',
    flexBasis: 'unset',
    marginRight: '30px',
  },
  container: {
    paddingTop: '21px',
    paddingBottom: '21px',
  },
};

AdvancedThreatDetectionComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  atp: PropTypes.object.isRequired,
  toggleSetting: PropTypes.func.isRequired,
};

export default withStyles(atdStyles, { name: 'ATDStyles' })(
  AdvancedThreatDetectionComponent
);
