import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

class TableLoadingComponent extends Component {
  render() {
    const { classes, colSpan } = this.props;

    return (
      <TableRow data-test-id="loadingRow">
        <TableCell
          data-test-id="loadingCell"
          className={classes.loadingIcon}
          colSpan={colSpan}
        >
          <CircularProgress data-test-id="loadingSpinner" />
        </TableCell>
      </TableRow>
    );
  }
}
TableLoadingComponent.propTypes = {
  colSpan: PropTypes.number.isRequired,
};

const styles = {
  loadingIcon: {
    textAlign: 'center',
  },
};

export default withStyles(styles, { name: 'TableLoadingStyles' })(
  TableLoadingComponent
);
