import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CudaTableTitle from '../CudaTableTitle';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CircularProgress from '@material-ui/core/CircularProgress';

import AccountSelection from '../../containers/shared_policies/EditAccountSelection';
import ThreatPolicy from '../../containers/shared_policies/EditThreatPolicy';

const CopyAccount = ({ classes, display, accounts, accountsMap }) => (
  <Grid id="add-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.addContainer}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <CudaTableTitle
                value={translate('components.notifications.status')}
                sort={true}
                toggle={0}
                onClick={() => {}}
              />
            </TableCell>
            <TableCell style={{ width: '450px' }}>
              <CudaTableTitle
                value={translate('components.dashboard.accounts')}
                sort={true}
                toggle={0}
                onClick={() => {}}
              />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(accounts).map(a => (
            <TableRow>
              <TableCell>
                <CheckCircleOutlineIcon />
              </TableCell>
              <TableCell>{accountsMap[a]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Grid>
  </Grid>
);

CopyAccount.propTypes = {
  classes: PropTypes.object.isRequired,
};

CopyAccount.defaultProps = {};

const styles = {
  addContainer: {},
  header: {
    color: 'rgb(0,0,0,0.9)',
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '24px',
  },
  input_wrapper: {
    marginLeft: 0,
    marginRight: 25,
    width: '100%',
    fontSize: 14,
    border: '1px solid #B2B2B2',
    paddingLeft: '10px',
    borderRadius: '2px',
    background: '#FFFFFF',
    height: '32px !important',
    boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
    marginBottom: '10px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'CopyAccount' })(CopyAccount);
