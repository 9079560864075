import React from 'react';
import { Translate } from 'react-i18nify';
import { connect } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import ColumnChooser from '../../components/log_table/ColumnChooser';
import { toggleColumn } from '../../actions/wf_logs';

const mapStateToProps = state => ({
  value: <Translate value="shared.columns" />,
  icon: <SettingsIcon />,
  columns: state.wfLogs.columns,
  columnTypes: state.wfLogs.columnTypes,
});

const mapDispatchToProps = dispatch => ({
  toggleColumn: (column, checked) => {
    dispatch(toggleColumn(column, checked));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ColumnChooser);
