import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const Box = ({ classes, children, className, title, subTitle, ...other }) => (
  <Grid
    item
    container
    justify="center"
    alignItems="center"
    direction="column"
    className={classNames(classes.wrapper, className)}
    {...other}
  >
    {title && (
      <Grid item data-test-id="box-title" xs={12} className={classes.title}>
        {title}
      </Grid>
    )}
    {subTitle && (
      <Grid
        item
        xs={12}
        data-test-id="box-subtitle"
        className={classes.subTitle}
      >
        {subTitle}
      </Grid>
    )}
    {children}
  </Grid>
);

const styles = {
  wrapper: {
    maxWidth: '540px',
    padding: 20,
    backgroundColor: '#ffffff',
    margin: 0,
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    marginBottom: '15px',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '300',
  },
};

Box.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

Box.defaultProps = {
  title: '',
  subTitle: '',
};

export default withStyles(styles, { name: 'Box' })(Box);
