import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { SortAZIcon, SortZAIcon, NoSortIcon } from './icons';

const styles = theme => ({});

class CudaSort extends React.Component {
  state = {
    icon: 'none',
  };

  render() {
    const { toggle } = this.props;

    return (
      <span>
        {toggle === 'none' ? <NoSortIcon data-test-id="sort-none" /> : ''}
        {toggle === 'asc' ? <SortAZIcon data-test-id="sort-asc" /> : ''}
        {toggle === 'desc' ? <SortZAIcon data-test-id="sort-desc" /> : ''}
      </span>
    );
  }
}

CudaSort.propTypes = {
  toggle: PropTypes.string,
};

CudaSort.defaultProps = {
  customClasses: [],
};
export default withStyles(styles)(CudaSort);
