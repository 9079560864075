import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import CircularProgress from '@material-ui/core/CircularProgress';

import PieComponent from './charts/ProtocolPie';
import BarChart from './charts/Bar';
import TimeSelect from '../../containers/dashboard/TimeSelect';
import Paper from '../CudaPaper';

const Protocol = ({ classes, options, selectedIndex, data, loading }) => {
  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid spacing={0} container>
        <Grid item xs={12}>
          <div className={classes.headerSection}>
            <div className={classes.title}>
              {translate('components.dashboard.encryptTrafficHeader')}
            </div>
            <div className={classes.subtitle}>
              {options[selectedIndex].label}
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          ) : (
            <BarChart
              data={data}
              width={'99%'}
              textWidth={250}
              xKey="doc_count"
              yKey="key"
              barSize={32}
              dashboard={true}
              dashGraph="protocol"
              barLeftMargin={35}
              units={translate('shared.requests')}
            />
          )}
        </Grid>
        <Grid item xs={12} data-test-id="encryptTraffic/Wrapper">
          <TimeSelect isProtocol={true} />
        </Grid>
      </Grid>
    </Paper>
  );
};

const styles = theme => ({
  paper: {
    // height: 270,
    paddingBottom: 0,
    border: '1px solid #DDDDDD',
  },
  container: {},
  headerSection: {
    margin: '20px 20px 0px 20px',
  },
  title: {
    fontSize: '24px',
    fontWeight: 300,
  },
  subtitle: {
    fontSize: '12px',
    color: 'rgba(0,0,0,0.5)',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '300px',
  },
});

Protocol.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key: PropTypes.string,
    })
  ).isRequired,
  options: PropTypes.array.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default withStyles(styles)(Protocol);
