import { connect } from 'react-redux';
import UrlLoggingModal from '../../components/advanced_filtering/UrlLoggingModal';

import {
  addUrlLogging,
  closeUrlLogging,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => {
  const ad = state.advanced_filtering.ruleModal.adGroup.length;
  let disabled = true;

  const origEnabled = state.advanced_filtering.urlLoggingModal.fields.enabled;
  const newEnabled = state.advanced_filtering.urlLoggingModal.enabled;
  disabled = origEnabled === newEnabled || !ad;

  return {
    disabled,
    loading: state.advanced_filtering.urlLoggingModal.loading,
    showModal: state.advanced_filtering.urlLoggingModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    dispatch(addUrlLogging());
  },
  closeDialog: () => {
    dispatch(closeUrlLogging());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UrlLoggingModal);
