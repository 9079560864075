import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const styles = theme => ({
  container: {
    position: 'relative',
  },
  centerText: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    '& div.number': {
      fontSize: '40px',
      animation: 'fadein 5s',
    },
  },
  legend: {
    position: 'absolute',
    top: '40px',
    right: '40px',
  },
  legendRow: {
    display: 'flex',
    alignItems: 'center',
  },
  legendCircle: {
    height: '14px',
    width: '14px',
    borderRadius: '7px',
  },
  legendKey: {
    marginLeft: '5px',
    animation: 'fadein 5s',
  },
});

const COLORS = ['#D42B1E', '#E8563D', '#EC8062', '#F3A68D', '#F8D0BE'];
const OTHER_COLOR = '#E3EAF3';
let graphData = [];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = outerRadius + (outerRadius - innerRadius) * 0.75;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {graphData[index]['key'] || 'ANON'}
    </text>
  );
};

const PieComponent = ({
  classes,
  data,
  legend = true,
  height = 400,
  width = 800,
  pdf = false,
  widthDifferential = 30,
  legendTop = '160px',
}) => {
  let total = 0;
  let tmp = { key: 'other', doc_count: 0 };
  graphData = [];
  data.map((entry, index) => {
    total += entry.doc_count;
    if (index < 3) {
      graphData.push(entry);
    } else {
      tmp.doc_count += entry.doc_count;
    }

    return entry;
  });
  graphData.push(tmp);

  const legendStyle = {
    top: [legendTop],
  };

  const chartJSX = (
    <PieChart width={width} height={height}>
      <Pie
        data={graphData}
        fill="#8884d8"
        nameKey="key"
        dataKey="doc_count"
        legendType="rect"
        label={renderCustomizedLabel}
        outerRadius={width * (13 / 80)}
        innerRadius={width * (13 / 80) - widthDifferential}
      >
        {graphData.map((entry, index) => (
          <Cell
            key={index}
            fill={
              entry.key === 'other'
                ? OTHER_COLOR
                : COLORS[index % COLORS.length]
            }
          />
        ))}
      </Pie>
    </PieChart>
  );

  return (
    <div className={classes.container}>
      {pdf ? (
        <div height={height}>{chartJSX}</div>
      ) : (
        <ResponsiveContainer height={height}>{chartJSX}</ResponsiveContainer>
      )}
      <div className={classes.centerText} style={legendStyle}>
        <div className="number">{total}</div>
        <div>Total Threats</div>
      </div>
      {legend ? (
        <div className={classes.legend}>
          {graphData.map((entry, index) => {
            return (
              <div key={index} className={classes.legendRow}>
                <div
                  className={classes.legendCircle}
                  style={{
                    backgroundColor:
                      entry.key === 'other'
                        ? OTHER_COLOR
                        : COLORS[index % COLORS.length],
                  }}
                />
                <div className={classes.legendKey}>{entry.key || 'ANON'}</div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

PieComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      doc_count: PropTypes.number,
      key: PropTypes.string,
    })
  ).isRequired,
  legend: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
};

export default withStyles(styles, { name: 'AreaChart' })(PieComponent);
