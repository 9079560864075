import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import DownloadOptions from '../../containers/reports/DownloadOptions';

import CudaTableTitle from '../CudaTableTitle';
import Paper from '../CudaPaper';
import BarChart from '../charts/Bar';
import PageReportWithOutPaper from './PageReportWithOutPaper';
import Pie from '../charts/Pie';
import PaginatorComponent from '../Paginator';
import { PieChart } from 'recharts';

const styles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: '300',
    color: 'rgba(0,0,0,0.9)',
  },
  subtitle: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
  graphWrapper: {
    padding: '20px',
    margin: '20px',
  },
  dataTableWrapper: {
    margin: '20px',
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
  itemrow: {
    width: '100%',
  },
  itemcolumn: {
    width: '50%',
    justify: 'top',
  },
  itemcolumnfull: {
    width: '100%',
    justify: 'top',
  },
});

const customReport = {
  agent_version: true,
  'blocked-categories': true,
  'blocked-supercategories': true,
};

class OverviewReport extends Component {
  render() {
    const { classes, data } = this.props;
    const security_denied_category = data.security_denied_category;
    const security_allowed_category = data.security_allowed_category;
    const productivity_denied_category = data.productivity_denied_category;
    const productivity_allowed_category = data.productivity_allowed_category;
    const compliance_denied_category = data.compliance_denied_category;
    const compliance_allowed_category = data.compliance_allowed_category;
    const blocked_activity = data.blocked_activity;
    const blocked_vulnerabilities = data.blocked_vulnerabilities;
    const blocked_endpoints = data.blocked_endpoints;

    return (
      <div>
        <div>
          <PaginatorComponent
            grayBackground={true}
            showPaginationOptions={false}
            options={<DownloadOptions />}
          />
        </div>
        <Paper className={classes.graphWrapper}>
          <Grid container spacing={24} justify="space-between" align="top">
            <Grid item xs={6}>
              <Pie data={blocked_vulnerabilities} title={'Threats'} />
            </Grid>
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={blocked_endpoints}
                title={'User Report - Top 10 Devices'}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.graphWrapper}>
          <Grid container spacing={24} justify="space-between" align="top">
            <Grid item xs={12}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={blocked_activity}
                title={'User Report - Blocked Users'}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.graphWrapper}>
          <Grid container spacing={24} justify="space-between" align="top">
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={security_denied_category}
                title={'Security - Denied Categories'}
              />
            </Grid>
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={security_allowed_category}
                title={'Security - Allowed Categories'}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.graphWrapper}>
          <Grid container spacing={24} justify="space-between" align="top">
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={productivity_denied_category}
                title={'Productivity - Denied Categories'}
              />
            </Grid>
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={productivity_allowed_category}
                title={'Productivity - Allowed Categories'}
              />
            </Grid>
          </Grid>
        </Paper>
        <Paper className={classes.graphWrapper}>
          <Grid container spacing={24} justify="space-between" align="top">
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={compliance_denied_category}
                title={'Compliance - Denied Categories'}
              />
            </Grid>
            <Grid item xs={6}>
              <PageReportWithOutPaper
                headers={['Name', 'Requests']}
                data={compliance_allowed_category}
                title={'Compliance - Allowed Categories'}
              />
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

OverviewReport.propTypes = {};

export default withStyles(styles)(withRouter(OverviewReport));
