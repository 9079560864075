import { connect } from 'react-redux';
import SupportToolModal from '../../components/devices/SupportToolModal';

import {
  sendSupportCommand,
  closeSupportToolModal,
} from '../../actions/devices';

const mapStateToProps = state => ({
  show: state.devices.show,
  filename: state.devices.filename,
  loading: state.devices.statusLoading,
  connected: state.devices.connected,
  device: state.devices.supportToolDevice,
  generate: state.devices.generate,
  lastStatus: state.devices.lastStatus,
  lastSync: state.devices.lastSync,
  unknownStatus: state.devices.unknownStatus,
  deviceStatus: state.devices.deviceStatus,
});

const mapDispatchToProps = dispatch => ({
  closeDialog: () => {
    dispatch(closeSupportToolModal());
  },
  sendSupportCommand: device => {
    dispatch(sendSupportCommand(device));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupportToolModal);
