import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import Selector from '../components/reports/Selector';
import { getFilteredTypes, getLocationOptions } from '../selectors/reports';
import { getUserList, getGroupList } from '../selectors/account';

import {
  reportTypes,
  timeOptions,
  selectTimeFilter,
  selectReportType,
  selectStartCustomDate,
  selectEndCustomDate,
  openScheduleReportModal,
  selectUserFilter,
  selectGroupFilter,
  selectLocationFilter,
  selectOverviewFilter,
} from '../actions/reports';

import { filterADSearchInput } from '../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  mainOptions: getFilteredTypes(state),
  timeOptions: timeOptions,
  userOptions: getUserList(state),
  groupOptions: getGroupList(state),
  locationOptions: getLocationOptions(state),
  startCustom: state.reports.startCustom,
  endCustom: state.reports.endCustom,
  mainSelectIndex: state.reports.reportSelectIndex,
  timeSelectIndex: state.reports.timeSelectIndex,
  userSelectIndex: state.reports.userSelectIndex,
  groupSelectIndex: state.reports.groupSelectIndex,
  locationSelectIndex: state.reports.locationSelectIndex,
  account_id: state.account.selected,
  timezone: state.account.time_zone,
  reportSelect: state.reports.reportSelect,
  timeSelect: state.reports.timeSelect,
  rows: state.reports.filters.rows,
  index: state.reports.filters.index,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: mainOptions => {
    dispatch(selectReportType(1, mainOptions));
  },
  mainSelectHandler: (value, mainOptions) => {
    ReactGA.event({
      category: 'Reports',
      action: 'Report selected: ' + reportTypes[value]['key'],
    });
    dispatch(selectReportType(value, mainOptions));
  },
  timeSelectHandler: value => {
    ReactGA.event({
      category: 'Reports',
      action: 'Time selected: ' + timeOptions[value]['key'],
    });
    dispatch(selectTimeFilter(value));
  },
  userSelectHandler: index => {
    dispatch(selectUserFilter(index));
  },
  userGroupAsyncCallback: (value, callback) => {
    dispatch(filterADSearchInput(value, callback));
  },
  groupSelectHandler: index => {
    dispatch(selectGroupFilter(index));
  },
  locationSelectHandler: index => {
    dispatch(selectLocationFilter(index));
  },
  startCustomSelectHandler: date => {
    dispatch(selectStartCustomDate(date));
  },
  endCustomSelectHandler: date => {
    dispatch(selectEndCustomDate(date));
  },
  executeHandler: (type, range, rows, index, id) => {
    ReactGA.event({
      category: 'Reports',
      action: 'Ran report',
    });
    let params = 0;
    if (id) {
      params = id;
    }
    const url =
      '/view_report/' +
      type +
      '/' +
      range +
      '/' +
      rows +
      '/' +
      index +
      '/' +
      params;
    window.open(url, '_blank');
  },
  openModal: mainOptions => {
    dispatch(openScheduleReportModal(mainOptions));
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Selector)
);
