import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CudaSort from '../components/CudaSort';

const styles = theme => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  sort: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  tableTitle: {
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
  },
  numeric: {
    '& > span': {
      width: '100%',
    },
  },
  centered: {
    textAlign: 'center',
    '& > span': {
      width: '100%',
    },
  },
});

class CudaTableTitle extends React.Component {
  state = {};

  render() {
    const {
      classes,
      value,
      sort,
      toggle,
      onClick,
      center,
      numeric,
    } = this.props;

    let wrapperClasses = {};
    wrapperClasses[classes.wrapper] = true;
    wrapperClasses[classes.sort] = sort ? true : false;
    wrapperClasses[classes.centered] = center ? true : false;
    wrapperClasses[classes.numeric] = numeric ? true : false;

    let wrapperClass = classNames(wrapperClasses);

    return (
      <div className={wrapperClass} onClick={onClick}>
        <Translate className={classes.tableTitle} value={value} />
        {sort ? <CudaSort toggle={toggle} /> : ''}
      </div>
    );
  }
}

CudaTableTitle.propTypes = {
  value: PropTypes.string.isRequired,
  sort: PropTypes.bool,
  toggle: PropTypes.string,
  onClick: PropTypes.func,
};

CudaTableTitle.defaultProps = {
  customClasses: [],
};
export default withStyles(styles)(CudaTableTitle);
