import { connect } from 'react-redux';
import DeployModal from '../../components/overview/DeployModal';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  installer: state.account.latestInstaller,
  loading: state.account.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeployModal);
