import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import BlockIcon from '@material-ui/icons/Block';
import DoneIcon from '@material-ui/icons/Done';

const ActionCell = ({ classes, action, ...others }) => (
  <span className={classes.actionCell} {...others}>
    {action === 'allowed' ? (
      <DoneIcon color="primary" classes={{ colorPrimary: classes.doneIcon }} />
    ) : (
      <BlockIcon
        color="primary"
        classes={{ colorPrimary: classes.blockIcon }}
      />
    )}
    {translate(`components.wfLogs.${action}`)}
  </span>
);

ActionCell.propTypes = {
  classes: PropTypes.object.isRequired,
  action: PropTypes.oneOf(['allowed', 'denied']).isRequired,
};

const styles = theme => ({
  doneIcon: {
    color: theme.statusColors.success,
  },
  blockIcon: {
    color: theme.statusColors.failure,
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
});

export default withStyles(styles, { name: 'ActionCell' })(ActionCell);
