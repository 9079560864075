import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';

import Paper from './CudaPaper';

import Status from '../containers/dashboard/CustomerStatus';
import ThreatTable from '../containers/dashboard/ThreatTable';
import ThreatGraph from '../containers/dashboard/ThreatGraph';

const styles = theme => ({
  paper: {
    paddingBottom: 0,
    border: '1px solid #DDDDDD',
  },
  paperTitle: {
    padding: '20px 20px 0 20px',
  },
});

class ATPDashboard extends Component {
  componentDidMount() {
    this.props.initData();
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={9}>
            <Paper
              elevation={0}
              titleClasses={[classes.paperTitle]}
              className={classes.paper}
              title={translate('components.dashboard.threatGraphHeader')}
            >
              <ThreatGraph />
            </Paper>
          </Grid>
          <Grid item xs={12} md={12} lg={3}>
            <Paper
              elevation={0}
              titleClasses={[classes.paperTitle]}
              className={classes.paper}
              title={translate('components.dashboard.threatSummaryHeader')}
            >
              <Status />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              titleClasses={[classes.paperTitle]}
              className={classes.paper}
              title={
                <span>
                  <Translate value="components.dashboard.recentThreatGraphHeader" />
                </span>
              }
            >
              <ThreatTable />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ATPDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'WFDashboard' })(ATPDashboard);
