import { connect } from 'react-redux';
import Safesearch from '../../components/advanced_filtering/Safesearch';

import { toggleSafesearch } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  disabledAD: state.advanced_filtering.safesearchModal.disabledAD,
  enableSafesearch: state.advanced_filtering.safesearchModal.enabled,
});

const mapDispatchToProps = dispatch => ({
  toggleSafesearch: value => {
    dispatch(toggleSafesearch());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Safesearch);
