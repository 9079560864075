import { connect } from 'react-redux';
import FillDetails from '../../components/signup/FillDetails';
import {
  submitUserInfo,
  updateFormFields,
  validateFormField,
} from '../../actions/signup';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  form: state.signup.userForm,
  page: state.signup.page,
  email: state.signup.email,
  details: state.signup.details,
  validForm: state.signup.validDetails,
});

const mapDispatchToProps = dispatch => ({
  handleSubmit: event => {
    Mixpanel.track('Trial / Sign Up Form-Submit', {});
    event.preventDefault();
    dispatch(submitUserInfo());
  },
  updateFields: fields => {
    dispatch(updateFormFields('userForm', fields));
  },
  updateField: event => {
    dispatch(
      updateFormFields('userForm', { [event.target.name]: event.target.value })
    );
  },
  validateField: event => {
    dispatch(
      validateFormField('userForm', event.target.name, event.target.value)
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FillDetails);
