import { connect } from 'react-redux';
import LicenseModule from '../../components/overview/LicenseModule';

const mapStateToProps = state => ({
  loading: state.account.dashboardLoading,
  dashboardInfo: state.account.dashboardInfo[state.account.id],
  wca: state.account.subscriptions.wca,
});

export default connect(
  mapStateToProps,
  undefined
)(LicenseModule);
