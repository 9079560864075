import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/index';

const configureStore = (composeEnhancer, middleware) =>
  createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk, ...middleware))
  );

export default configureStore;
