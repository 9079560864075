import React, { Component } from 'react';
import { Translate, translate } from 'react-i18nify';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import logo from '../logo-barracuda-dark.svg';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import DocumentTitle from 'react-document-title';
import PasswordInput from './PasswordInput';
import { withRouter } from 'react-router';

class DemoComponent extends Component {
  componentDidMount() {
    const { history } = this.props;
    this.props.initData(history);
  }

  render() {
    const { classes } = this.props;
    return (
      <DocumentTitle title={translate('shared.demo')}>
        <Grid
          spacing={0}
          className={classes.container}
          id="demo-loading"
          container
        >
          <Grid item />
        </Grid>
      </DocumentTitle>
    );
  }
}

const styles = theme => ({
  container: {},
});

export default withStyles(styles)(withRouter(DemoComponent));
