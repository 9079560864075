import { connect } from 'react-redux';
import AdvancedFilter from '../components/AdvancedFilter';

import { getAllLocationPolicy } from '../actions/basic_filtering';

import {
  getRulesets,
  setHighlightedRule,
  applySearchFilter,
  resetSearchFilter,
} from '../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: rule => {
    dispatch(resetSearchFilter());
    dispatch(setHighlightedRule(rule));
    dispatch(getAllLocationPolicy());
    dispatch(getRulesets());
  },
  applySearch: () => {
    dispatch(applySearchFilter());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedFilter);
