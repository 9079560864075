import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import classNames from 'classnames';
import { MenuArrowIcon } from '../icons';

const styles = theme => ({
  root: {
    background: theme.palette.background.paper,
  },
  label: {
    fontSize: 14,
    paddingRight: 12,
    paddingLeft: 12,
    lineHeight: '18px',
  },
  menu: {
    paddingRight: '4px',
    paddingLeft: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: '1px solid #DDDDDD',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  menuIcon: {
    display: 'inline-block',
    float: 'left',
    marginLeft: '-4px',
    marginRight: '8px',
  },
  disabled: {
    cursor: 'default',
    background: '#DDD',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

class TimeSelect extends React.Component {
  state = {
    AnchorEl: null,
    open: false,
  };

  handleTlsClickListItem = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleTlsMenuItemClick = (event, index) => {
    if (index !== this.props.selectedTlsIndex) {
      this.props.onTlsChange(index);
    }
    this.setState({ open: false });
  };

  handleTlsRequestClose = () => {
    this.setState({ open: false });
  };

  handleProcessClickListItem = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleProcessMenuItemClick = (event, index) => {
    if (index !== this.props.selectedProcessIndex) {
      this.props.onProcessChange(index);
    }
    this.setState({ open: false });
  };

  handleProcessRequestClose = () => {
    this.setState({ open: false });
  };

  handleProtocolClickListItem = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleProtocolMenuItemClick = (event, index) => {
    if (index !== this.props.selectedProtocolIndex) {
      this.props.onProtocolChange(index);
    }
    this.setState({ open: false });
  };

  handleProtocolRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      options,
      isTls,
      isProcess,
      isProtocol,
      customClasses,
      staticLabel,
      icon,
      customStyle,
      selectedIndex,
      selectedTlsIndex,
      selectedProcessIndex,
      selectedProtocolIndex,
      disabled,
      height = '32px',
    } = this.props;
    const rootClass = classNames(...customClasses, classes.root);
    const menuClass = disabled
      ? classNames(classes.menu, classes.disabled)
      : classNames(classes.menu);
    const alignArrow = classNames(classes.menuRow, customStyle);

    return (
      <div className={rootClass} style={{ height: height }}>
        {isTls ? (
          <List disablePadding={true}>
            <ListItem
              dense={true}
              button={false}
              aria-haspopup="true"
              aria-controls="time-menu"
              onClick={this.handleTlsClickListItem}
              style={{ height: height }}
              className={menuClass}
            >
              <ListItemText
                disableTypography={true}
                style={{ height: height, lineHeight: height }}
                className={classes.label}
                primary={
                  <span className={alignArrow}>
                    <span className={classes.menuIcon}>{icon}</span>
                    {staticLabel || options[selectedTlsIndex].label}
                    <MenuArrowIcon />
                  </span>
                }
              />
            </ListItem>
          </List>
        ) : null}
        {isTls ? (
          <Menu
            data-test-id="timeSelection"
            id="time-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleTlsRequestClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                data-test-id={`option-${index}`}
                key={option.key}
                className={classes.label}
                style={{ height: height }}
                selected={index === selectedTlsIndex}
                onClick={event => this.handleTlsMenuItemClick(event, index)}
              >
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
        {isProcess ? (
          <List disablePadding={true}>
            <ListItem
              dense={true}
              button={false}
              aria-haspopup="true"
              aria-controls="time-menu"
              onClick={this.handleProcessClickListItem}
              style={{ height: height }}
              className={menuClass}
            >
              <ListItemText
                disableTypography={true}
                style={{ height: height, lineHeight: height }}
                className={classes.label}
                primary={
                  <span className={alignArrow}>
                    <span className={classes.menuIcon}>{icon}</span>
                    {staticLabel || options[selectedProcessIndex].label}
                    <MenuArrowIcon />
                  </span>
                }
              />
            </ListItem>
          </List>
        ) : null}
        {isProcess ? (
          <Menu
            data-test-id="timeSelection"
            id="time-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleProcessRequestClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                data-test-id={`option-${index}`}
                key={option.key}
                className={classes.label}
                style={{ height: height }}
                selected={index === selectedProcessIndex}
                onClick={event => this.handleProcessMenuItemClick(event, index)}
              >
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
        {isProtocol ? (
          <List disablePadding={true}>
            <ListItem
              dense={true}
              button={false}
              aria-haspopup="true"
              aria-controls="time-menu"
              onClick={this.handleProtocolClickListItem}
              style={{ height: height }}
              className={menuClass}
            >
              <ListItemText
                disableTypography={true}
                style={{ height: height, lineHeight: height }}
                className={classes.label}
                primary={
                  <span className={alignArrow}>
                    <span className={classes.menuIcon}>{icon}</span>
                    {staticLabel || options[selectedProtocolIndex].label}
                    <MenuArrowIcon />
                  </span>
                }
              />
            </ListItem>
          </List>
        ) : null}
        {isProtocol ? (
          <Menu
            data-test-id="timeSelection"
            id="time-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleProtocolRequestClose}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                data-test-id={`option-${index}`}
                key={option.key}
                className={classes.label}
                style={{ height: height }}
                selected={index === selectedProtocolIndex}
                onClick={event =>
                  this.handleProtocolMenuItemClick(event, index)
                }
              >
                {option.icon}
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        ) : null}
      </div>
    );
  }
}

TimeSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onTlsChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.array,
  selectedTlsIndex: PropTypes.number.isRequired,
  selectedProcessIndex: PropTypes.number.isRequired,
  selectedProtocolIndex: PropTypes.number.isRequired,
};

TimeSelect.defaultProps = {
  customClasses: [],
};
export default withStyles(styles)(TimeSelect);
