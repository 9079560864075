import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate, Translate } from 'react-i18nify';

import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import LaunchIcon from '@material-ui/icons/Launch';
import WarningIcon from '@material-ui/icons/Warning';
import GoodIcon from '@material-ui/icons/CheckCircle';
import { DownloadIcon } from '../icons';

import Paper from '../CudaPaper';
import SyncToolTable from '../../containers/user_authentication/SyncToolTable';
import SearchBar from '../../containers/user_authentication/SearchBar';

import { AD_CAMPUS_URL } from '../../lib/Env';

import { Mixpanel } from '../../lib/Mixpanel';

const SyncTool = ({ classes, configured, loading, synctool }) => (
  <Grid id="basic-filtering" spacing={2} container>
    <Grid item xs={12}>
      <Paper
        title={translate('userAuthentication.syncTool.title')}
        padding
        titleClasses={[classes.title]}
        className={classes.paper}
        loading={loading}
      >
        <Translate value="userAuthentication.syncTool.subTitle" />
        <List component="ol" disablePadding className={classes.list}>
          {[
            'downloadTool',
            'downloadKey',
            'installTool',
            'launchTool',
            'configureTool',
          ].map((lid, i) => (
            <li key={lid}>
              <span className={classes.stepNumber}>{`${i + 1}.`}</span>
              <Translate value={`userAuthentication.syncTool.${lid}`} />
            </li>
          ))}
        </List>
        <Button
          data-test-id="agent-configuration-download"
          color="primary"
          className={classes.downloadButton}
          type="text"
          disabled={false}
          onClick={() => {
            window.open(synctool.exe, '_blank');
          }}
        >
          <DownloadIcon className={classes.downloadIcon} color="action" />
          <Translate value="userAuthentication.syncTool.installerExe" />
        </Button>
        <Button
          data-test-id="agent-configuration-download"
          color="primary"
          className={classes.downloadButton}
          type="text"
          disabled={false}
          onClick={() => {
            window.open(synctool.msi, '_blank');
          }}
        >
          <DownloadIcon className={classes.downloadIcon} color="action" />
          <Translate value="userAuthentication.syncTool.installerMsi" />
        </Button>
      </Paper>
    </Grid>
    <Grid item xs={12} md={8} lg={6}>
      <SearchBar
        data-test-id="local-user-search"
        height="40px"
        autoFocus={false}
        disabled={loading}
        placeholder={translate('userAuthentication.local.searchPlaceholder')}
      />
    </Grid>
    <Grid item xs={12}>
      <Paper loading={loading}>
        <SyncToolTable />
      </Paper>
    </Grid>
  </Grid>
);

const styles = theme => ({
  title: {
    marginBottom: '24px',
  },
  paper: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  configure: {
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: '8px',
    },
  },
  list: {
    marginBottom: '16px',
  },
  stepNumber: {
    display: 'inline-block',
    width: '25px',
  },
  warningIcon: {
    color: theme.statusColors.warning,
  },
  successIcon: {
    color: theme.statusColors.success,
  },
  downloadIcon: {
    marginRight: '10px',
    height: 18,
  },
  configureBtn: {
    paddingLeft: 0,
    paddingRight: 0,
    marginRight: '50px',
    '& svg': {
      height: '18px',
      width: '18px',
      marginLeft: '6px',
    },
  },
});

export default withStyles(styles, { name: 'SyncTool' })(SyncTool);
