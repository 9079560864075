import { connect } from 'react-redux';
import Table from '../../components/notifications/Table';
import * as Types from '../../actions/notifications';
import { getOptionsHash } from '../../selectors/notifications';

const mapStateToProps = state => ({
  options: getOptionsHash(state),
  notifications: state.notifications.notifications,
  loading: state.notifications.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  editAction: notification => {
    ownProps.editAction();
    dispatch(Types.edit(notification));
  },
  suspendAction: notification => {
    dispatch(Types.suspend(notification));
  },
  deleteAction: notification => {
    dispatch(Types.remove(notification));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
