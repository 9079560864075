import { connect } from 'react-redux';
import ThreatDetectedChart from '../../components/overview/ThreatDetectedChart';
import {
  onThreatTypeChange,
  timeOptions,
  threatDetectedTypes,
  onThreatChange,
} from '../../actions/mspdashboard';

const mapStateToProps = (state, ownProps) => ({
  options: timeOptions,
  selectedIndex: state.mspDashboard.fileTypeSelectIndex,
  data: state.mspDashboard.areaData,
  selectedThreats: state.mspDashboard.threatDetectedSelect,
  selectedThreatType: state.mspDashboard.threatDetectedTypeSelect,
  loading: state.mspDashboard.fileDetectedLoading,
  range: state.mspDashboard.fileDetectedRange,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onThreatChange: index => {
    dispatch(onThreatChange(timeOptions[index].key, index));
  },
  onThreatTypeChange: index => {
    dispatch(onThreatTypeChange(threatDetectedTypes[index], index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatDetectedChart);
