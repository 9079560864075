import { connect } from 'react-redux';
import MoveLocalUser from '../../components/user_authentication/MoveLocalUser';
import { updateModalData } from '../../actions/user_authentication';

import {
  getGroupList,
  getModalData,
} from '../../selectors/user_authentication';

const mapStateToProps = state => ({
  groups: getGroupList(state),
  data: getModalData(state),
  saving: state.userAuthentication.modal.processing,
});

const mapDispatchToProps = dispatch => ({
  changeGroup: group => dispatch(updateModalData('groupID', group.value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MoveLocalUser);
