import { connect } from 'react-redux';
import Quarantine from '../components/Quarantine';
import { initData, filterData, get } from '../actions/quarantine';
import { updateSharedTimeframe } from '../actions/atp_shared';

const mapStateToProps = (state, ownProps) => ({
  query: state.quarantine.query,
  selectedIndex: state.atp_shared.timeSelectIndex,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: () => {
    dispatch(initData());
  },
  handleQuery: query => {
    dispatch(filterData(query));
  },
  updateRange: (range, index) => {
    dispatch(updateSharedTimeframe(range, index));
    dispatch(get());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Quarantine);
