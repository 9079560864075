import { createSelector } from 'reselect';
import { reportTypes } from '../actions/reports';

const getFeatures = state => state.account.features;
const getSubscriptions = state => state.account.subscriptions;
const getTypes = () => reportTypes;
const getActiveDirectory = state => state.account.activeDirectory;
const getLocations = state => state.basic_filtering.policies;
const getUserOptions = state => state.account.adUsers;
const getGroupOptions = state => state.account.adGroups;

const getFilteredTypes = createSelector(
  [getTypes, getSubscriptions, getFeatures],
  (options, subscriptions, features) => {
    const groupedOptions = [];

    options.forEach(option => {
      if (option.type === 'label') {
        groupedOptions[groupedOptions.length] = [option];
      } else if (option.subscriptions.some(sub => subscriptions[sub])) {
        if ('features' in option) {
          if (option.features.some(f => features.includes(f))) {
            groupedOptions[groupedOptions.length - 1].push(option);
          }
        } else {
          groupedOptions[groupedOptions.length - 1].push(option);
        }
      }
    });
    return [].concat(...groupedOptions.filter(group => group.length > 1));
  }
);

const getLocationOptions = createSelector(
  [getLocations],
  loc => {
    const locations = loc;
    return locations.map(l => ({
      type: l.id,
      label: l.name,
      key: l.id,
      value: l.name,
    }));
  }
);

export {
  getFilteredTypes,
  getUserOptions,
  getGroupOptions,
  getLocationOptions,
};
