import { connect } from 'react-redux';
import AreaChart from '../components/charts/Area';

const mapStateToProps = (state, ownProps) => ({
  data: state.reports.graphData,
  graphType: state.reports.timeLabel,
  timezone: state.account.time_zone,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaChart);
