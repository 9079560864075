import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../../components/advanced_filtering/ChipInput';

import { onChangeDomain, selectIndex } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => ({
  name: translate('components.advancedFiltering.domains'),
  inputValue: state.advanced_filtering.ruleModal.domains,
  copyStr: state.advanced_filtering.ruleModal.domains.toString(),
  selectedIndex: state.advanced_filtering.ruleModal.inputIndex,
  error: state.advanced_filtering.selectedRule.error,
  helpText: translate('components.advancedFiltering.domainsHelpText'),
});

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(onChangeDomain(value));
  },
  onSelectIndex: index => {
    dispatch(selectIndex(index));
  },
  filterInput: value => {
    let v1 = value.replace(/\/.*/, '');
    v1 = v1.replace(/\*\.*/, '');
    v1 = v1.replace(
      /^\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b$/,
      ''
    );
    return v1;
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
