import React from 'react';
import { Translate } from 'react-i18nify';

export const TOGGLE_SCAN = 'TOGGLE_SCAN';
export const TOGGLE_SCAN_SUCCESS = 'TOGGLE_SCAN_SUCCESS';
export const TOGGLE_SCAN_FAILURE = 'TOGGLE_SCAN_FAILURE';
export const TOGGLE_CUSTOM_EXCLUSION = 'TOGGLE_CUSTOM_EXCLUSION';
export const ADD_CUST_FILE_EXCLUSION = 'ADD_CUST_FILE_EXCLUSION';
export const ADD_CUST_FILE_EXCLUSION_SUCCESS =
  'ADD_CUST_FILE_EXCLUSION_SUCCESS';
export const ADD_CUST_FILE_EXCLUSION_FAILURE =
  'ADD_CUST_FILE_EXCLUSION_FAILURE';
export const ADD_CUST_PATH_EXCLUSION = 'ADD_CUST_PATH_EXCLUSION';
export const ADD_CUST_PATH_EXCLUSION_SUCCESS =
  'ADD_CUST_PATH_EXCLUSION_SUCCESS';
export const ADD_CUST_PATH_EXCLUSION_FAILURE =
  'ADD_CUST_PATH_EXCLUSION_FAILURE';
export const EDIT_CUST_EXCLUSION = 'EDIT_CUST_EXCLUSION';
export const EDIT_CUST_EXCLUSION_SUCCESS = 'EDIT_CUST_EXCLUSION_SUCCESS';
export const EDIT_CUST_EXCLUSION_FAILURE = 'EDIT_CUST_EXCLUSION_FAILURE';
export const REMOVE_CUST_EXCLUSION = 'REMOVE_CUST_EXCLUSION';
export const REMOVE_CUST_EXCLUSION_SUCCESS = 'REMOVE_CUST_EXCLUSION_SUCCESS';
export const REMOVE_CUST_EXCLUSION_FAILURE = 'REMOVE_CUST_EXCLUSION_FAILURE';
export const REMOVE_CUST_FILE_EXCLUSION = 'REMOVE_CUST_FILE_EXCLUSION';
export const REMOVE_CUST_PATH_EXCLUSION = 'REMOVE_CUST_PATH_EXCLUSION';
export const EXCLUSION_TYPE_FILE = 'FILE';
export const EXCLUSION_TYPE_PATH = 'PATH';
export const ATP_SETTINGS_INIT = 'ATP_SETTINGS_INIT';
export const ATP_GET_SUCCESS = 'ATP_GET_SUCCESS';
export const ATP_GET_FAILURE = 'ATP_GET_FAILURE';

export const ATP_OPEN_SCHEDULE_FULL_SCAN = 'ATP_OPEN_SCHEDULE_FULL_SCAN';
export const ATP_CLOSE_SCHEDULE_FULL_SCAN = 'ATP_CLOSE_SCHEDULE_FULL_SCAN';
export const SAVE_SCHEDULE_FULL_SCAN = 'SAVE_SCHEDULE_FULL_SCAN';
export const SAVE_SCHEDULE_FULL_SCAN_SUCCESS =
  'SAVE_SCHEDULE_FULL_SCAN_SUCCESS';
export const SAVE_SCHEDULE_FULL_SCAN_FAILURE =
  'SAVE_SCHEDULE_FULL_SCAN_FAILURE';
export const ATP_RUN_FULL_SCAN = 'ATP_RUN_FULL_SCAN';
export const ATP_RUN_FULL_SCAN_SUCCESS = 'ATP_RUN_FULL_SCAN_SUCCESS';
export const ATP_RUN_FULL_SCAN_FAILURE = 'ATP_RUN_FULL_SCAN_FAILURE';

export const SELECT_SCHEDULE_SCAN_FREQUENCY = 'SELECT_SCHEDULE_SCAN_FREQUENCY';
export const SELECT_SCHEDULE_SCAN_HOUR = 'SELECT_SCHEDULE_SCAN_HOUR';
export const SELECT_SCHEDULE_SCAN_WEEK = 'SELECT_SCHEDULE_SCAN_WEEK';
export const SELECT_SCHEDULE_SCAN_DAY = 'SELECT_SCHEDULE_SCAN_DAY';
export const SCHEDULE_SCAN = 'SCHEDULE_SCAN';

export const TOGGLE_SCHEDULE_SCAN = 'TOGGLE_SCHEDULE_SCAN';

export const toggleScheduleScanEnable = options => ({
  type: TOGGLE_SCHEDULE_SCAN,
  options,
});

export const runFullScan = () => ({
  type: ATP_RUN_FULL_SCAN,
});

export const runFullScanSuccess = info => ({
  type: ATP_RUN_FULL_SCAN_SUCCESS,
  timestamp: info.timestamp,
});

export const runFullScanFailure = () => ({
  type: ATP_RUN_FULL_SCAN_FAILURE,
});

export const openScheduleFullScan = mainOptions => ({
  type: ATP_OPEN_SCHEDULE_FULL_SCAN,
  options: mainOptions,
});

export const closeScheduleFullScan = () => ({
  type: ATP_CLOSE_SCHEDULE_FULL_SCAN,
});

export const selectScheduleScanFrequency = (key, index) => ({
  type: SELECT_SCHEDULE_SCAN_FREQUENCY,
  key,
  index,
});

export const selectScheduleScanHour = (key, index) => ({
  type: SELECT_SCHEDULE_SCAN_HOUR,
  key,
  index,
});

export const selectScheduleScanWeek = (key, index) => ({
  type: SELECT_SCHEDULE_SCAN_WEEK,
  key,
  index,
});

export const selectScheduleScanDay = (key, index) => ({
  type: SELECT_SCHEDULE_SCAN_DAY,
  key,
  index,
});

export const scheduleScan = () => ({
  type: SCHEDULE_SCAN,
});

export const saveScheduleFullScan = () => ({
  type: SAVE_SCHEDULE_FULL_SCAN,
});

export const saveScheduleFullScanSuccess = setting => ({
  type: SAVE_SCHEDULE_FULL_SCAN_SUCCESS,
  setting,
});

export const saveScheduleFullScanFailure = () => ({
  type: SAVE_SCHEDULE_FULL_SCAN_FAILURE,
});

export const settings = {
  scanMSOffice: 'scanMSOffice',
  executables: 'executables',
  pdfDocuments: 'pdfDocuments',
  archiveFiles: 'archiveFiles',
  autoRemediate: 'autoRemediate',
  blockedPwProtectedOfficeDoc: 'blockedPwProtectedOfficeDoc',
  blockedPwProtectedArchiveFile: 'blockedPwProtectedArchiveFile',
  scanExclusion: 'scanExclusion',
  cpaEnabled: 'cpaEnabled',
  scanDeliver: 'scanDeliver',
};

export const toggleSetting = setting => ({
  type: TOGGLE_SCAN,
  setting,
});

export const toggleSettingSuccess = setting => ({
  type: TOGGLE_SCAN_SUCCESS,
  setting,
});

export const toggleSettingFailure = setting => ({
  type: TOGGLE_SCAN_FAILURE,
  setting,
});

export const toggleCustomExclusion = value => ({
  type: TOGGLE_CUSTOM_EXCLUSION,
  value,
});

export const addCustomExclusion = (type, value) => ({
  type,
  value,
});

export const addFileCustomExclusionSuccess = value => ({
  type: ADD_CUST_FILE_EXCLUSION_SUCCESS,
  value,
});

export const addFileCustomExclusionFailure = () => ({
  type: ADD_CUST_FILE_EXCLUSION_FAILURE,
});

export const addPathCustomExclusionSuccess = value => ({
  type: ADD_CUST_PATH_EXCLUSION_SUCCESS,
  value,
});

export const addPathCustomExclusionFailure = () => ({
  type: ADD_CUST_PATH_EXCLUSION_FAILURE,
});

export const editCustomExclusion = (
  type,
  prevType,
  prevValue,
  cType,
  cValue
) => ({
  type,
  prevType,
  prevValue,
  cType,
  cValue,
});

export const editCustomExclusionSuccess = atp => ({
  type: EDIT_CUST_EXCLUSION_SUCCESS,
  atp,
});

export const editCustomExclusionFailure = () => ({
  type: EDIT_CUST_EXCLUSION_FAILURE,
});

export const removeCustomExclusion = (fileType, value) => ({
  type: REMOVE_CUST_EXCLUSION,
  fileType,
  value,
});

export const removeCustomExclusionSuccess = atp => ({
  type: REMOVE_CUST_EXCLUSION_SUCCESS,
  atp,
});

export const removeCustomExclusionFailure = () => ({
  type: REMOVE_CUST_EXCLUSION_FAILURE,
});

export const initATP = () => ({
  type: ATP_SETTINGS_INIT,
});

export const getATPSuccess = result => ({
  type: ATP_GET_SUCCESS,
  result,
});

export const getATPFailure = error => ({
  type: ATP_GET_FAILURE,
  error,
});

export const frequencyOptions = [
  {
    key: 'daily',
    label: <Translate value="shared.frequency.daily" />,
  },
  {
    key: 'weekly',
    label: <Translate value="shared.frequency.weekly" />,
  },
  {
    key: 'biweekly',
    label: <Translate value="shared.frequency.biweekly" />,
  },
  {
    key: 'monthly',
    label: <Translate value="shared.frequency.monthly" />,
  },
];

export const weekOptions = [
  {
    key: 7,
    label: <Translate value="shared.sunday" />,
  },
  {
    key: 1,
    label: <Translate value="shared.monday" />,
  },
  {
    key: 2,
    label: <Translate value="shared.tuesday" />,
  },
  {
    key: 3,
    label: <Translate value="shared.wednesday" />,
  },
  {
    key: 4,
    label: <Translate value="shared.thursday" />,
  },
  {
    key: 5,
    label: <Translate value="shared.friday" />,
  },
  {
    key: 6,
    label: <Translate value="shared.saturday" />,
  },
];

export const hourOptions = [
  {
    key: 1,
    label: '1:00',
  },
  {
    key: 2,
    label: '2:00',
  },
  {
    key: 3,
    label: '3:00',
  },
  {
    key: 4,
    label: '4:00',
  },
  {
    key: 5,
    label: '5:00',
  },
  {
    key: 6,
    label: '6:00',
  },
  {
    key: 7,
    label: '7:00',
  },
  {
    key: 8,
    label: '8:00',
  },
  {
    key: 9,
    label: '9:00',
  },
  {
    key: 10,
    label: '10:00',
  },
  {
    key: 11,
    label: '11:00',
  },
  {
    key: 12,
    label: '12:00',
  },
  {
    key: 13,
    label: '13:00',
  },
  {
    key: 14,
    label: '14:00',
  },
  {
    key: 15,
    label: '15:00',
  },
  {
    key: 16,
    label: '16:00',
  },
  {
    key: 17,
    label: '17:00',
  },
  {
    key: 18,
    label: '18:00',
  },
  {
    key: 19,
    label: '19:00',
  },
  {
    key: 20,
    label: '20:00',
  },
  {
    key: 21,
    label: '21:00',
  },
  {
    key: 22,
    label: '22:00',
  },
  {
    key: 23,
    label: '23:00',
  },
  {
    key: 0,
    label: '24:00',
  },
];

export const dayOptions = [
  {
    key: 1,
    label: '1',
  },
  {
    key: 2,
    label: '2',
  },
  {
    key: 3,
    label: '3',
  },
  {
    key: 4,
    label: '4',
  },
  {
    key: 5,
    label: '5',
  },
  {
    key: 6,
    label: '6',
  },
  {
    key: 7,
    label: '7',
  },
  {
    key: 8,
    label: '8',
  },
  {
    key: 9,
    label: '9',
  },
  {
    key: 10,
    label: '10',
  },
  {
    key: 11,
    label: '11',
  },
  {
    key: 12,
    label: '12',
  },
  {
    key: 13,
    label: '13',
  },
  {
    key: 14,
    label: '14',
  },
  {
    key: 15,
    label: '15',
  },
  {
    key: 16,
    label: '16',
  },
  {
    key: 17,
    label: '17',
  },
  {
    key: 18,
    label: '18',
  },
  {
    key: 19,
    label: '19',
  },
  {
    key: 20,
    label: '20',
  },
  {
    key: 21,
    label: '21',
  },
  {
    key: 22,
    label: '22',
  },
  {
    key: 23,
    label: '23',
  },
  {
    key: 24,
    label: '24',
  },
  {
    key: 25,
    label: '25',
  },
  {
    key: 26,
    label: '26',
  },
  {
    key: 27,
    label: '27',
  },
  {
    key: 28,
    label: '28',
  },
  {
    key: 29,
    label: '29',
  },
  {
    key: 30,
    label: '30',
  },
  {
    key: 31,
    label: '31',
  },
];
