import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import SectionHeader from './SectionHeader';
import { PeopleIcon, WarningIcon } from './icons';

import UserTypeSelector from '../containers/user_authentication/UserTypeSelector';
import LocalUsers from '../containers/user_authentication/LocalUsers';
import DirectoryServices from './user_authentication/DirectoryServices';
import SyncTool from './user_authentication/SyncTool';

import LocalUserPrompt from './user_authentication/LocalUserPrompt';

import Modal from './modal/Modal';

import { Mixpanel } from '../lib/Mixpanel';

class UserAuthentication extends React.Component {
  componentDidMount = () => {
    const { initData } = this.props;
    initData();
  };

  render() {
    const {
      classes,
      userType,
      loading,
      synctool,
      closePrompt,
      confirmPrompt,
      configured,
      modal,
      accountStatus,
    } = this.props;

    return (
      <DocumentTitle title={translate('userAuthentication.pageTitle')}>
        <React.Fragment>
          <SectionHeader
            icon={<PeopleIcon secondaryColor="#0088CE" />}
            title={translate('userAuthentication.title')}
          >
            <Translate value="userAuthentication.subheader" />
          </SectionHeader>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <UserTypeSelector disabled={loading} />
              <Divider className={classes.divider} />
            </Grid>
            <Grid item xs={12}>
              {(userType === 'local' || userType === 'synctool') &&
                accountStatus.pending && (
                  <div className={classes.warning}>
                    <WarningIcon />
                    <Translate value="userAuthentication.progress" />
                  </div>
                )}
              {(userType === 'local' || userType === 'synctool') &&
                accountStatus.error && (
                  <div className={classes.warning}>
                    <WarningIcon />
                    <Translate value="userAuthentication.error" />
                  </div>
                )}
            </Grid>
            <Grid item xs={12}>
              {(() =>
                ({
                  directory: (
                    <DirectoryServices
                      configured={configured}
                      loading={loading}
                    />
                  ),
                  local: <LocalUsers loading={loading} />,
                  synctool: <SyncTool loading={loading} synctool={synctool} />,
                }[userType]))()}
            </Grid>
          </Grid>
          <Modal
            showModal={modal.show && modal.name === 'confirmTypeChange'}
            title={translate('userAuthentication.configureUsers')}
            saveBtnText={translate('userAuthentication.continue')}
            actionBtnClass={classes.deleteBtn}
            contentClass={classes.shortModal}
            closeDialog={() => {
              Mixpanel.track(
                'Users / Configure Users Warning Dialog / Cancel',
                {}
              );
              closePrompt();
            }}
            processing={modal.processing}
            buttonAction={() => {
              Mixpanel.track(
                'Users / Configure Users Warning Dialog / Continue Anyway',
                {}
              );
              confirmPrompt();
            }}
          >
            <LocalUserPrompt />
          </Modal>
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

const styles = {
  divider: {
    marginTop: '8px',
  },
  deleteBtn: {
    backgroundColor: '#DD1E31',
    '&:hover': {
      backgroundColor: '#B71C1C',
    },
  },
  shortModal: {
    width: '500px',
  },
  warning: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '15px',
    },
  },
};

UserAuthentication.propTypes = {};

export default withStyles(styles, { name: 'UserAuthentication' })(
  UserAuthentication
);
