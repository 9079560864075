import { connect } from 'react-redux';
import ReportBarTable from '../components/reports/BarTable';

import { updateRows, nextPage, prevPage } from '../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  entries: state.reports.tableData,
  total: state.reports.total,
  filters: state.reports.filters,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateRows: option => {
    dispatch(updateRows(option));
  },
  nextPage: () => {
    dispatch(nextPage());
  },
  prevPage: () => {
    dispatch(prevPage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportBarTable);
