import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import BarChart from '../charts/Bar';

const styles = theme => ({
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
});

const BasicFilterChart = ({
  classes,
  loading,
  data,
  width,
  textWidth,
  selected,
}) => {
  let max = 10;
  if (selected === 'blocked-supercategories') {
    max = 15;
  }
  const displayData = data.slice(0, max);
  return (
    <div>
      {!loading ? (
        <BarChart data={displayData} width={width} textWidth={textWidth} />
      ) : (
        <div className={classes.center}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

BasicFilterChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  loading: PropTypes.bool,
  width: PropTypes.string,
  textWidth: PropTypes.number,
  selected: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'BasicFilterChart' })(
  BasicFilterChart
);
