import { connect } from 'react-redux';
import InstallerTable from '../../components/downloads/InstallerTable';

const mapStateToProps = state => ({
  latest: state.account.latestInstallers,
  older: state.account.installers,
  demo: state.account.demo,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstallerTable);
