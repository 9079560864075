import Button from '@material-ui/core/Button/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { CloseIcon } from '../icons';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Input from '@material-ui/core/Input';
import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// Structure:
// <Dialog>
//     <CudaDialogTitle/>
//     <DialogContent>
//         <CudaDialogWarningContent>
//         <CudaDialogRegularContent>
//              <DialogInput>
//              <InputErrorLabel>
//         </CudaDialogRegularContent>
//     </DialogContent>
//     <DialogActions>
//          <div>
//              <ActionCancelButton/>
//              <ActionButton/>
//          </div>
//     </DialogActions>
// </Dialog>

const CudaDialogContentStyleSheet = {
  container: {
    position: 'relative',
  },
  content: {
    overflowY: 'auto',
    maxHeight: '500px',
  },
  overlay: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    opacity: 0.7,
    zIndex: 1,
  },
  loadingAnimation: {
    alignSelf: 'center',
  },
};

const CudaDialogContentComponent = ({
  classes,
  children,
  loadingAnimation,
  loadingAnimationSize = 40,
  ...other
}) => {
  return (
    <div className={classes.container}>
      {loadingAnimation && (
        <div className={classes.overlay}>
          <div className={classes.loadingAnimation}>
            <CircularProgress size={loadingAnimationSize} />
          </div>
        </div>
      )}
      <DialogContent className={classes.content}>{children}</DialogContent>
    </div>
  );
};

export const CudaDialogContent = withStyles(CudaDialogContentStyleSheet, {
  name: 'CudaDialogContent',
})(CudaDialogContentComponent);

const loadingDialogContentStyleSheet = {
  content: {
    padding: 27,
    fontSize: '16px',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    height: 'calc(100% - (95px + 68px))',
    width: '100%',
    top: '95px',
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    // backgroundColor: 'purple',
    opacity: 0.7,
    zIndex: 1,
  },
  loadingAnimation: {
    alignSelf: 'center',
  },
};

const LoadingDialogContentComponent = ({
  classes,
  children,
  loading,
  loadingAnimationSize = 40,
  ...other
}) => {
  const allclasses = classNames({
    [classes.overlay]: true,
    [other.overlay]: other.overlay,
  });
  // loading = true;
  return (
    <React.Fragment>
      {loading && (
        <div className={allclasses}>
          <div className={classes.loadingAnimation}>
            <CircularProgress size={loadingAnimationSize} />
          </div>
        </div>
      )}
      <DialogContent classes={{ root: classes.content }} {...other}>
        {children}
      </DialogContent>
    </React.Fragment>
  );
};

LoadingDialogContentComponent.propTypes = {
  loading: PropTypes.bool,
  loadingAnimationSize: PropTypes.number,
};

export const LoadingDialogContent = withStyles(loadingDialogContentStyleSheet, {
  name: 'LoadingDialogContentComponent',
})(LoadingDialogContentComponent);

// Title
const styleSheetCudaDialogTitle = theme => ({
  titleFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'Space-between',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: 32,
    fontWeight: 400,
  },
  rootError: {
    backgroundColor: theme.palette.error.main,
  },
});

const CudaDialogTitleComponent = ({
  classes,
  className,
  children,
  onRequestClose,
  error,
}) => {
  const root = classNames({
    [className]: true,
    [classes.rootError]: error,
  });
  return (
    <DialogTitle className={root}>
      <div className={classes.titleFlex}>
        <div>{children}</div>
        {onRequestClose && (
          <div onClick={onRequestClose}>
            <CloseIcon title="Close" />
          </div>
        )}
      </div>
    </DialogTitle>
  );
};

CudaDialogTitleComponent.propTypes = {
  onRequestClose: PropTypes.func,
};

export const CudaDialogTitle = withStyles(styleSheetCudaDialogTitle, {
  name: 'CudaDialogTitle',
})(CudaDialogTitleComponent);

// Warning Content
const styleSheetDialogWarningContent = {
  warningContent: {
    backgroundColor: '#eeeeee',
    borderBottom: '1px solid #d6d6d6',
  },
  innerContent: {
    fontFamily: '"Roboto", sans-serif',
    marginLeft: 23,
    marginRight: 23,
    padding: '17px 7px 12px 7px',
    fontSize: 14,
    lineHeight: 1.5,
    color: '#000000',
  },
};

const CudaDialogWarningContentComponent = ({ classes, children }) => {
  return (
    <div className={classes.warningContent}>
      <div className={classes.innerContent}>{children}</div>
    </div>
  );
};
export const CudaDialogWarningContent = withStyles(
  styleSheetDialogWarningContent,
  { name: 'CudaDialogWarningContent' }
)(CudaDialogWarningContentComponent);

// Regular Content
const styleSheetDialogRegularContent = {
  regularContent: {
    backgroundColor: '#ffffff',
  },
  innerContent: {
    fontFamily: '"Roboto", sans-serif',
    marginLeft: 23,
    marginRight: 23,
    padding: '17px 7px 20px 7px',
    fontSize: 14,
    lineHeight: 1.5,
    color: '#000000',
  },
};

const CudaDialogRegularContentComponent = ({ classes, children }) => {
  return (
    <div className={classes.regularContent}>
      <div className={classes.innerContent}>{children}</div>
    </div>
  );
};
export const CudaDialogRegularContent = withStyles(
  styleSheetDialogRegularContent,
  { name: 'CudaDialogRegularContent' }
)(CudaDialogRegularContentComponent);

// Input field. props: Error (boolean)
export const inputStyleSheet = {
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > input': {
      height: 32,
      border: '1px solid #DDDDDD',
      borderRadius: 2,
      fontSize: 14,
      padding: '0 9px 0 9px',
    },
    '& > input:focus': {
      border: '1px solid #a9a9a9',
    },
  },

  errorInputWrapper: {
    extend: 'inputWrapper',
    '& > input': {
      borderColor: '#DD1E31',
    },
    '& > input:focus': {
      borderColor: '#DD1E31',
    },
  },
  input: {
    '&::-webkit-input-placeholder': {
      /* Chrome/Opera/Safari */
      color: '#BBBBBB',
    },
    '&::-moz-placeholder': {
      /* Firefox 19+ */
      color: '#BBBBBB',
    },
    '&:-ms-input-placeholder': {
      /* IE 10+ */
      color: '#BBBBBB',
    },
    '&:-moz-placeholder': {
      /* Firefox 18- */
      color: '#BBBBBB',
    },
  },
  errorInput: {
    extend: 'input',
    color: '#DD1E31',
  },

  errorLabel: {
    fontSize: 12,
    color: '#DD1E31',
    padding: '3px 0px 0px 3px',
    minHeight: '18px',
  },
  label: {
    color: 'rgba(0,0,0,0.4)',
    fontSize: '12px',
    lineHeight: '20px',
    minHeight: '18px',
  },
  small: {
    width: '40px',
  },
};

const CudaInputComponent = ({
  classes,
  className,
  label,
  error,
  errorLabel,
  field,
  small = false,
  ...props
}) => {
  if (field) {
    props.value = field.value;
    errorLabel = error = field.error;
  }
  const inputClass = classNames({
    [classes.inputWrapper]: !error,
    [classes.errorInputWrapper]: error,
    [classes.small]: small,
    [className]: className,
  });
  return (
    <div>
      {label && <div className={classes.label}>{label}</div>}
      <Input className={inputClass} disableUnderline={true} {...props} />
      <div className={classes.errorLabel}>{errorLabel}</div>
    </div>
  );
};

export const DialogInput = withStyles(inputStyleSheet, {
  name: 'CudaInputComponent',
})(CudaInputComponent);

const multilineInputStylesheet = {
  multilineTextDiv: {
    extend: 'singlelineTextDiv',
    margin: '0 0 5px',
    padding: 0,
    overflowY: 'hidden',
    '& textarea': {
      border: '1px solid #DDDDDD',
      borderRadius: 3,
      height: 140,
      fontSize: 14,
      padding: 5,
      whiteSpace: 'nowrap',
      overflow: 'auto',
      boxSizing: 'border-box',
    },
    '& textarea:focus': {
      borderColor: '#a9a9a9',
    },
    '& > div': {
      display: 'block',
    },
  },
  multilineTextDivError: {
    extend: 'multilineTextDiv',
    '& textarea': {
      borderColor: '#DD1E31',
    },
    '& textarea:focus': {
      borderColor: '#DD1E31',
    },
  },
  multilineInput: {
    // width: '95%',
  },
  errorLabel: {
    fontSize: 12,
    margin: 0,
    color: '#DD1E31',
    height: 10,
  },
  infoLabel: {
    extend: 'errorLabel',
    color: 'rgba(0,0,0,0.3)',
    marginBottom: 10,
  },
};

const MultilineDialogInputComponent = ({
  classes,
  fieldName,
  value,
  infoLabel,
  errorMessage,
  onChange,
  onBlur,
  isUpdating,
  ...props
}) => {
  const handleInputChange = (value, setting) => {
    // only updates the value if it is not currently updating
    if (!isUpdating) {
      onChange(value, setting);
    }
  };

  const listToText = list => {
    return list.join('\n');
  };

  const textToList = text => {
    return text.split('\n');
  };

  const error = errorMessage !== '';

  return (
    <div>
      <div
        className={
          error ? classes.multilineTextDivError : classes.multilineTextDiv
        }
      >
        <Input
          disableUnderline
          multiline={true}
          className={classes.multilineInput}
          value={listToText(value)}
          onChange={e =>
            handleInputChange(textToList(e.target.value), fieldName)
          }
          onBlur={() => onBlur(fieldName)}
          {...props}
        />
      </div>
      <div className={classes.infoLabel}>{infoLabel}</div>
      <div className={classes.errorLabel}>{errorMessage}</div>
    </div>
  );
};

export const MultilineDialogInput = withStyles(multilineInputStylesheet, {
  name: 'MultilineDialogInput',
})(MultilineDialogInputComponent);

// Dialog Action Buttons
const styleSheet = {
  // button: {
  //     // width: 104,
  //     // height: 40,
  // },
  cancelBtn: {
    // extend: 'button',
    marginRight: 20,
  },
};

const ActionButtonComponent = ({ classes, children, ...props }) => {
  return (
    <Button variant="contained" size="large" {...props}>
      {children}
    </Button>
  );
};

export const ActionButton = withStyles(styleSheet, {
  name: 'CudaDialogActionButtons',
})(ActionButtonComponent);

const ActionCancelButtonComponent = ({ classes, children, ...props }) => {
  delete props.className;
  return (
    <Button
      size="large"
      color="secondary"
      variant="contained"
      className={classes.cancelBtn}
      {...props}
    >
      {children}
    </Button>
  );
};

export const ActionCancelButton = withStyles(styleSheet, {
  name: 'CudaDialogActionButtons',
})(ActionCancelButtonComponent);

export const handleKeyPress = (event, saveForm) => {
  if (event.key === 'Enter' && saveForm) {
    saveForm();
  }
};
