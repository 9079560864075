import { connect } from 'react-redux';
import Timestamp from '../components/Timestamp';

const mapStateToProps = state => ({
  timezone: state.account.time_zone,
});

export default connect(
  mapStateToProps,
  undefined
)(Timestamp);
