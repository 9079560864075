import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { onboard_trial } from '../actions/account';

import ActivateTrial from '../components/ActivateTrial';

const mapStateToProps = (state, ownProps) => ({
  redirect: state.account.onboard.redirect,
  error: state.account.onboard.error,
  success: state.account.onboard.success,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initData: () => {
    dispatch(onboard_trial());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivateTrial));
