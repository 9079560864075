import { connect } from 'react-redux';
import NetworkTable from '../../components/basic_filtering/NetworkTable';
import { sortNetworks, updateNetworks } from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  data: state.basic_filtering.locationConfig.addresses,
});

const mapDispatchToProps = dispatch => ({
  sortHandler: (orderBy, order) => {
    dispatch(sortNetworks(orderBy, order));
  },
  deleteHandler: network => {
    dispatch(updateNetworks([], [network]));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkTable);
