import { connect } from 'react-redux';
import CategoryPolicy from '../../components/web_filtering/CategoryPolicy';
import { updatedSelectedCategories } from '../../actions/advanced_filtering';

const mapStateToProps = state => ({
  superCategories: state.basic_filtering.superCategories,
  selectedCategories: state.advanced_filtering.ruleModal.categories,
  error: state.advanced_filtering.selectedRule.error,
  wcsVersion: state.basic_filtering.catMapping.wcsVersion,
});

const mapDispatchToProps = dispatch => ({
  updateCategories: (selected, added, removed) => {
    dispatch(updatedSelectedCategories(selected, added, removed));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPolicy);
