import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import * as Types from '../actions/dashboard';
import * as BasicFilterTypes from '../actions/basic_filtering';
import * as AppTypes from '../actions/app';
import { translate } from 'react-i18nify';
import Api from './Api';

export const dashboard = (
  state = {
    endpoints: {
      active: 0,
      inactive: 0,
      licensed: 0,
    },
    threatCount: 0,
    threatCountLoading: false,
    threatCounts: [],
    threatCountsLoading: false,
    threatLogs: [],
    threatLogsLoading: false,
    blocked_infoLoading: false,
    categories: {
      blockedRequests: 0,
      prevBlockedRequests: 0,
      blockedCategories: [],
      blockedSupercategories: [],
    },
    dayCategories: {
      blockedRequests: 0,
      prevBlockedRequests: 0,
      blockedCategories: [],
      blockedSupercategories: [],
    },
    csv: {
      loading: false,
      range: '',
    },
    tlsLoading: false,
    tlsSelectIndex: 1,
    tlsRange: 'week',
    tlsData: [],
    protocolLoading: false,
    protocolSelectIndex: 1,
    protocolRange: 'week',
    protocolData: [],
    processLoading: false,
    processSelectIndex: 1,
    processRange: 'week',
    processData: [],
  },
  action
) => {
  switch (action.type) {
    case Types.DASHBOARD_DOWNLOAD_CSV:
      return {
        ...state,
        csv: {
          loading: true,
          range: action.range,
        },
      };
    case Types.DASHBOARD_DOWNLOAD_CSV_SUCCESS:
    case Types.DASHBOARD_DOWNLOAD_CSV_FAILURE:
      return {
        ...state,
        csv: {
          ...state.csv,
          loading: false,
          range: '',
        },
      };
    case Types.DASHBOARD_LOADING:
    case Types.INIT_DATA:
      return {
        ...state,
        threatCountLoading: true,
        threatCountsLoading: true,
        threatLogsLoading: true,
        tlsLoading: true,
        processLoading: true,
        protocolLoading: true,
        protocolLoading: true,
        blocked_infoLoading: true,
      };
    case Types.DASHBOARD_UPDATE_MODULE:
      return {
        ...state,
        [`${action.module}Loading`]: true,
      };
    case Types.DASHBOARD_UPDATE_MODULE_DONE:
      return {
        ...state,
        [`${action.module}Loading`]: false,
      };
    case Types.SORT_LOGS:
      let orderBy = action.property;
      let data;

      if (orderBy === 'name' || orderBy === 'user') {
        data =
          action.order === 'desc'
            ? state.threatLogs.sort((a, b) =>
                b[orderBy].toLowerCase() < a[orderBy].toLowerCase() ? -1 : 1
              )
            : state.threatLogs.sort((a, b) =>
                a[orderBy].toLowerCase() < b[orderBy].toLowerCase() ? -1 : 1
              );
      } else {
        data =
          action.order === 'desc'
            ? state.threatLogs.sort((a, b) =>
                b[orderBy] < a[orderBy] ? -1 : 1
              )
            : state.threatLogs.sort((a, b) =>
                a[orderBy] < b[orderBy] ? -1 : 1
              );
      }

      return {
        ...state,
        threatLogs: data,
      };
    case Types.GET_ATP_DATA_SUCCESS:
      return {
        ...state,
        ...action.formattedResult,
        threatCountLoading: false,
        threatCountsLoading: false,
        threatLogsLoading: false,
        blocked_infoLoading: false,
      };
    case Types.DASHBOARD_TLS_VERSION_LOADING:
      return {
        ...state,
        tlsLoading: true,
      };
    case Types.DASHBOARD_UPDATE_TLS_VERSION_TIMEFRAME:
      return {
        ...state,
        tlsSelectIndex: action.index,
        tlsRange: action.range,
      };
    case Types.GET_DASHBOARD_TLS_VERSION_DATA_SUCCESS:
      return {
        ...state,
        tlsLoading: false,
        tlsData: action.result.tls_count,
      };
    case Types.GET_DASHBOARD_TLS_VERSION_DATA_FAILURE:
      return {
        ...state,
        tlsLoading: false,
        tlsData: [],
      };
    case Types.DASHBOARD_PROTOCOL_LOADING:
      return {
        ...state,
        protocolLoading: true,
      };
    case Types.DASHBOARD_UPDATE_PROTOCOL_TIMEFRAME:
      return {
        ...state,
        protocolSelectIndex: action.index,
        protocolRange: action.range,
      };
    case Types.GET_DASHBOARD_PROTOCOL_DATA_SUCCESS:
      return {
        ...state,
        protocolLoading: false,
        protocolData: action.result.protocol_count,
      };
    case Types.GET_DASHBOARD_PROTOCOL_DATA_FAILURE:
      return {
        ...state,
        protocolLoading: false,
        protocolData: [],
      };
    case Types.DASHBOARD_PROCESS_LOADING:
      return {
        ...state,
        processLoading: true,
      };
    case Types.DASHBOARD_UPDATE_PROCESS_TIMEFRAME:
      return {
        ...state,
        processSelectIndex: action.index,
        processRange: action.range,
      };
    case Types.GET_DASHBOARD_PROCESS_DATA_SUCCESS:
      return {
        ...state,
        processLoading: false,
        processData: action.result.process_count,
      };
    case Types.GET_DASHBOARD_PROCESS_DATA_FAILURE:
      return {
        ...state,
        processLoading: false,
        processData: [],
      };
    default:
      return state;
  }
};

function* fetchLogs(module = undefined, range = 'week', forceReload = false) {
  try {
    const store = yield select();
    const catMap = store.basic_filtering.catMapping;
    const catV2Map = store.basic_filtering.catV2Mapping;
    const catV3Map = store.basic_filtering.catV3Mapping;
    let moduleAgg = module;
    let cat = {};
    let combinedCats = {};
    if (!catMap.loaded || forceReload) {
      const catsResult = yield call(Api.getData, {
        page: 'get_basic_policies',
      });
      // cat needs to contiain both v2 and v3
      // for dashboard display
      cat = {
        categories: catsResult['categories'],
        supercategories: catsResult['supercategories'],
      };
      combinedCats = {
        categories: {
          ...catsResult['catsv3']['categories'],
        },
        supercategories: {
          ...catsResult['catsv3']['supercategories'],
        },
      };
      yield put(
        BasicFilterTypes.storeLoadedCats(
          cat['categories'],
          cat['supercategories'],
          catsResult['catsv3'],
          catsResult['wcs_version']
        )
      );
    } else {
      cat = {
        categories: catMap['categories'],
        supercategories: catMap['supercategories'],
      };
      combinedCats = {
        categories: {
          ...catV2Map['categories'],
          ...catV3Map['categories'],
        },
        supercategories: {
          ...catV2Map['supercategories'],
          ...catV3Map['supercategories'],
        },
      };
    }

    if (['threatCount', 'threatCounts', 'threatLogs'].indexOf(module) !== -1) {
      moduleAgg = 'agent_threats';
    }

    const result = yield call(Api.getData, {
      page: 'dashboard',
      range,
      account_id: store.account.selected,
      modules: moduleAgg,
      time_zone: store.account.time_zone,
    });
    yield put(
      Types.getATPDataSuccess({
        ...result,
        wcs_cats: cat,
        combined_cats: combinedCats,
        module,
      })
    );
    yield put(
      Types.updateModuleDone({
        module,
      })
    );
    yield put(Types.getTlsVersionDataSuccess(result));
    yield put(Types.getProcessDataSuccess(result));
    yield put(Types.getProtocolDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getATPDataFailure(e.error));
  }
}

function* changeTime() {
  yield delay(500);
  yield put(Types.dashboardLoading());
  yield fetchLogs();
}

function* initData() {
  const store = yield select();
  const account_id = store.account.selected;
  yield fetchLogs(undefined, 'week', true);

  const result = yield call(Api.accounts.metrics, account_id);
  window.Intercom('update', {
    num_devices: result['devices'],
    directory_configured: result['directory_configured'],
    dns_configured: result['dns_configured'],
  });
}

function* updateModule(action) {
  yield fetchLogs(action.module, action.timeRange);
}

function* downloadCsv() {
  try {
    const store = yield select();
    const params = {
      account_id: store.account.selected,
      job_type: 'dashboard_csv',
      range: store.dashboard.csv.range,
      timezone: store.account.timezone,
    };

    const accountID = store.account.selected;
    const result = yield call(Api.csv.generate, accountID, params);
    const jobID = result.job_id;

    let i = 0;
    while (i++ < 30) {
      const result = yield call(Api.csv.checkJob, accountID, jobID);

      if (result.status === 'PENDING') {
        yield delay(1000 * (i * 2));
      } else {
        yield call(
          Api.csv.download,
          result.presignedURL,
          'BCS Top Endpoints by Threats.csv'
        );
        break;
      }
    }

    yield put(Types.downloadCsvSuccess());
  } catch (e) {
    yield put(AppTypes.error(translate('errors.downloadFailed')));
    yield put(Types.downloadCsvFailure(e));
  }
}

function* updateTlsVersionTimeframe() {
  try {
    const store = yield select();
    yield put(Types.tlsVersionLoading());
    const result = yield call(Api.getData, {
      page: 'breakdown_type',
      account_id: store.account.selected,
      range: store.dashboard.tlsRange,
      breakdown: 'ssl_server_version',
    });
    if (result === null) {
      yield put(Types.getTlsVersionDataFailure('Unable to get count'));
    }
    yield put(Types.getTlsVersionDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(translate('errors.loadingData.tls')));
    yield put(Types.getTlsVersionDataFailure(e.error));
  }
}

function* updateProcessTimeframe() {
  try {
    const store = yield select();
    yield put(Types.processLoading());
    const result = yield call(Api.getData, {
      page: 'breakdown_type',
      account_id: store.account.selected,
      range: store.dashboard.processRange,
      breakdown: 'process',
    });

    yield put(Types.getProcessDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(translate('errors.loadingData.process')));
    yield put(Types.getProcessDataFailure(e.error));
  }
}

function* updateProtocolTimeframe() {
  try {
    const store = yield select();
    yield put(Types.protocolLoading());
    const result = yield call(Api.getData, {
      page: 'breakdown_type',
      account_id: store.account.selected,
      range: store.dashboard.protocolRange,
      breakdown: 'web_protocol',
    });

    yield put(Types.getProtocolDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(translate('errors.loadingData.protocol')));
    yield put(Types.getProtocolDataFailure(e.error));
  }
}

export function* dashboardReducerFlow() {
  yield takeEvery(Types.INIT_DATA, initData);
  yield takeLatest(Types.CHANGE_TIME, changeTime);
  yield takeLatest(Types.DASHBOARD_UPDATE_MODULE, updateModule);
  yield takeLatest(
    Types.DASHBOARD_UPDATE_TLS_VERSION_TIMEFRAME,
    updateTlsVersionTimeframe
  );
  yield takeLatest(
    Types.DASHBOARD_UPDATE_PROCESS_TIMEFRAME,
    updateProcessTimeframe
  );
  yield takeLatest(
    Types.DASHBOARD_UPDATE_PROTOCOL_TIMEFRAME,
    updateProtocolTimeframe
  );
  yield takeLatest(Types.DASHBOARD_DOWNLOAD_CSV, downloadCsv);
}
