import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import classNames from 'classnames';
import { MenuArrowIcon } from '../icons';

const styles = theme => ({
  root: {
    background: theme.palette.background.paper,
  },
  label: {
    fontSize: 14,
    paddingRight: 12,
    paddingLeft: 12,
    lineHeight: '18px',
  },
  menu: {
    paddingRight: '4px',
    paddingLeft: '12px',
    paddingTop: 0,
    paddingBottom: 0,
    borderTop: '1px solid #DDDDDD',
    cursor: 'pointer',
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  menuIcon: {
    display: 'inline-block',
    float: 'left',
    marginLeft: '-4px',
    marginRight: '8px',
  },
  disabled: {
    cursor: 'default',
    background: '#DDD',
  },
  menuRow: {
    display: 'flex',
    alignItems: 'center',
  },
});

class TimeSelect extends React.Component {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClickListItem = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (event, index) => {
    if (index !== this.props.selectedIndex) {
      this.props.onChange(index);
    }
    this.setState({ open: false });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      options,
      customClasses,
      staticLabel,
      icon,
      customStyle,
      selectedIndex,
      disabled,
      height = '32px',
    } = this.props;
    const rootClass = classNames(...customClasses, classes.root);
    const menuClass = disabled
      ? classNames(classes.menu, classes.disabled)
      : classNames(classes.menu);
    const alignArrow = classNames(classes.menuRow, customStyle);

    return (
      <div className={rootClass} style={{ height: height }}>
        <List disablePadding={true}>
          <ListItem
            dense={true}
            button={false}
            aria-haspopup="true"
            aria-controls="time-menu"
            onClick={disabled ? undefined : this.handleClickListItem}
            style={{ height: height }}
            className={menuClass}
          >
            <ListItemText
              disableTypography={true}
              style={{ height: height, lineHeight: height }}
              className={classes.label}
              primary={
                <span className={alignArrow}>
                  <span className={classes.menuIcon}>{icon}</span>
                  {staticLabel || options[selectedIndex].label}
                  <MenuArrowIcon />
                </span>
              }
            />
          </ListItem>
        </List>
        <Menu
          data-test-id="timeSelection"
          id="time-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {options.map((option, index) =>
            option.hide ? (
              ''
            ) : (
              <MenuItem
                data-test-id={`option-${index}`}
                key={option.key}
                className={classes.label}
                style={{ height: height }}
                selected={index === selectedIndex}
                onClick={event => this.handleMenuItemClick(event, index)}
              >
                {option.icon}
                {option.label}
              </MenuItem>
            )
          )}
        </Menu>
      </div>
    );
  }
}

TimeSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.array,
  selectedIndex: PropTypes.number.isRequired,
};

TimeSelect.defaultProps = {
  customClasses: [],
};
export default withStyles(styles)(TimeSelect);
