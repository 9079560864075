import { connect } from 'react-redux';
import ErrorDashboard from '../components/ErrorDashboard';
import { checkSubscription } from '../actions/dashboard';

const mapStateToProps = state => ({
  msp: state.account.msp,
});

const mapDispatchToProps = dispatch => ({
  initData: () => {
    dispatch(checkSubscription());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ErrorDashboard);
