import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';

import Dropzone from 'react-dropzone';

import Modal from '../modal/Modal';

const styles = theme => ({
  logo: {
    width: '140px',
    height: '70px',
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    width: '160px',
    height: '80px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  previewText: {
    marginBottom: '8px',
  },
  dropzone: {
    backgroundColor: theme.colors.background,
    border: '1px dashed rgba(0,0,0,0.3)',
    textAlign: 'center',
    padding: '24px',
  },
  activeDropzone: {
    backgroundColor: theme.colors.greyText,
  },
  dropzoneText: {
    fontSize: '18px',
    lineHeight: '32px',
    marginBottom: '24px',
  },
  orText: {
    fontSize: '14px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    marginBottom: '24px',
  },
  row: {
    marginBottom: '16px',
  },
  noPreview: {
    borderRadius: '2px',
    backgroundColor: theme.colors.background,
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '32px',
    color: 'rgba(0,0,0,0.3)',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },

  errors: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    textAlign: 'center',
  },
});

class SMBAccountSettings extends React.Component {
  constructor() {
    super();
    this.onDrop = this.onDrop.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
  }

  state = {
    logo: null,
  };

  componentWillUnmount() {
    const { logo } = this.state;
    if (logo) {
      URL.revokeObjectURL(logo.preview);
    }
  }

  onDrop(accepted, rejected) {
    if (rejected.length) {
      this.props.logoErrors([`Image is larger than 100K`]);
    } else {
      const logo = accepted[0];
      const image = new Image(); // eslint-disable-line
      const preview = URL.createObjectURL(logo);

      image.onload = () => {
        if (image.height <= 240 && image.width <= 240) {
          this.setState({
            logo: {
              file: logo,
              preview,
              height: image.height,
              width: image.width,
            },
            errors: [],
          });
          this.props.togglePreview();
        } else {
          this.props.logoErrors([
            `Image too large ${image.width}x${image.height}`,
          ]);
        }
      };
      image.src = preview;
    }
  }

  uploadLogo() {
    this.props.uploadLogo(this.state.logo);
  }

  render() {
    const {
      classes,
      show,
      closeDialog,
      processing,
      showPreview,
      uploadErrors,
    } = this.props;
    const { logo, errors } = this.state;
    const { onDrop, uploadLogo } = this;

    return (
      <Modal
        name="upload-logo"
        showModal={show}
        title={translate('components.smbAccountSettings.selectFile')}
        saveBtnText={translate('components.smbAccountSettings.upload')}
        disabled={!logo || !showPreview}
        processing={processing}
        closeDialog={closeDialog}
        buttonAction={uploadLogo}
      >
        <div className={classes.errors}>
          {[...uploadErrors].map(error => (
            <span>{error}</span>
          ))}
        </div>
        <div className={classes.row}>
          <div className={classes.infoText}>
            <Translate value="components.smbAccountSettings.imageDimension" />
          </div>
          <div className={classes.infoText}>
            <Translate value="components.smbAccountSettings.logoTypeRule" />
          </div>
          <div className={classes.infoText}>
            <Translate value="components.smbAccountSettings.logoSizeRule" />
          </div>
        </div>
        <div className={classes.row}>
          <Dropzone
            data-test-id="dropzone"
            className={classes.dropzone}
            activeClassName={classes.activeDropzone}
            disableClick
            accept="image/*"
            multiple={false}
            maxSize={100000}
            onDrop={onDrop}
          >
            {({ open }) => (
              <React.Fragment>
                <div className={classes.dropzoneText}>
                  <Translate value="components.smbAccountSettings.dropFiles" />
                </div>
                <div className={classes.orText}>
                  <Translate value="components.smbAccountSettings.or" />
                </div>
                <Button
                  id="select-file-btn"
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={() => open()}
                >
                  {translate('components.smbAccountSettings.selectFile')}
                </Button>
              </React.Fragment>
            )}
          </Dropzone>
        </div>
        <div data-test-id="preview-section">
          <div className={classNames(classes.infoText, classes.previewText)}>
            <Translate value="components.smbAccountSettings.preview" />
          </div>
          {logo && showPreview ? (
            <div>
              <img data-test-id="prview-logo" src={logo.preview} alt="" />
            </div>
          ) : (
            <div className={classes.noPreview}>
              <Translate value="components.smbAccountSettings.noImage" />
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  uploadLogo: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  uploadErrors: PropTypes.array,
};

SMBAccountSettings.defaultProps = {
  uploadErrors: [],
};

export default withStyles(styles)(SMBAccountSettings);
