import React, { Component } from 'react';
import { translate, Translate } from 'react-i18nify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
} from '../CudaDialog';
import { InfoIcon } from '../icons';
import CudaMenu from '../CudaMenu';
import CategoryDropdown from '../../containers/notifications/CategoryDropdown';

class CreateNotificationComponent extends Component {
  static getDerivedStateFromProps(nextProps) {
    const notificationType = nextProps.formInfo.type || 'blocked_category';
    const options = nextProps.formInfo.options || {};
    const selectedType = nextProps.options.findIndex(
      option => option.key === notificationType
    );
    const result = {
      selectedType: selectedType >= 0 ? selectedType : 1,
    };

    if (options.category >= 0) {
      return {
        ...result,
        selectedCat: options.category,
      };
    }

    return {
      ...result,
      selectedCat: -1,
    };
  }

  state = { email_subscribers: '', email_mod: false };

  handleCatChange = (selectedCat, cat) => {
    this.props.updateSecondaryOptions({
      category: cat.key,
    });
  };

  render() {
    const {
      classes,
      showModal,
      closeDialog,
      buttonAction,
      title,
      formInfo,
      disableDropdown,
      actionTitle,
      options,
    } = this.props;
    const { selectedCat, selectedType } = this.state;
    const { handleTypeChange, errors } = this.props;
    const { handleCatChange } = this;

    return (
      <Dialog
        open={showModal}
        maxWidth="md"
        onEscapeKeyDown={closeDialog}
        className={classes.dialog}
        data-test-id="alert-modal"
      >
        <CudaDialogTitle onClose={closeDialog}>
          <Translate value={`components.notifications.${title}`} />
        </CudaDialogTitle>
        <CudaDialogContent overflow="visible" loadingAnimation={false}>
          <div className={classes.dialogContent}>
            <CudaDialogRegularContent className={classes.dialogContent}>
              <Grid container direction="column" spacing={2}>
                {(errors.emails || errors.threshold) && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <div className={classes.errorMessage}>Invalid values</div>
                    </Grid>
                  </Grid>
                )}
                <Grid item className={classes.grid} xs={12}>
                  <div className={classes.label}>
                    <Translate value="components.notifications.notification_type" />
                  </div>
                  <CudaMenu
                    disabled={disableDropdown}
                    customClasses={[classes.menu]}
                    selectedIndex={selectedType}
                    options={options}
                    onChange={handleTypeChange}
                    dataTestID="alert-type"
                  />
                  <Tooltip
                    title={translate(
                      'components.notifications.notificationTypeHelp'
                    )}
                    placement="top"
                    data-test-id="alert-type-help"
                  >
                    <span className={classes.infoIcon}>
                      <InfoIcon color="#888888" />
                    </span>
                  </Tooltip>
                </Grid>
                {options[selectedType].key === 'blocked_category' && (
                  <Grid item className={classes.grid} xs={12}>
                    <div className={classes.label}>
                      <Translate value="components.notifications.category" />
                    </div>
                    <CategoryDropdown
                      customClasses={[classes.menu]}
                      category={selectedCat}
                      onChange={handleCatChange}
                      dataTestID="alert-category"
                    />
                  </Grid>
                )}
                <Grid item className={classes.grid} xs={12}>
                  <div className={classes.label}>
                    <Translate value="components.notifications.alertCondition" />
                  </div>
                  <TextField
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        error: classes.error,
                        root: classes.thresholdInput,
                      },
                    }}
                    data-test-id="alert-condition"
                    error={errors.threshold}
                    defaultValue={
                      formInfo.threshold || formInfo.threshold === 0
                        ? formInfo.threshold
                        : 1
                    }
                    inputRef={input => {
                      this.threshold = input;
                    }}
                  />
                  <span className={classes.conditionLabel}>
                    {options[selectedType].conditionLabel}
                  </span>
                </Grid>
                <Grid item className={classes.grid} xs={12}>
                  <div className={classes.sectionTitle}>
                    <Translate value="components.notifications.addRecipients" />
                  </div>
                </Grid>
                <Grid item className={classes.grid} xs={12}>
                  <div className={classes.label}>
                    <Translate value="components.notifications.email_recipients" />
                  </div>
                  <TextField
                    placeholder={translate(
                      'components.notifications.email_placeholder'
                    )}
                    rows={5}
                    multiline
                    data-test-id="alert-recipients"
                    defaultValue={
                      formInfo.email_subscribers
                        ? formInfo.email_subscribers.join('\n')
                        : ''
                    }
                    onChange={e => {
                      this.setState({
                        email_subscribers: e.target.value,
                        email_mod: true,
                      });
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        error: classes.error,
                        root: classes.settingInput,
                      },
                    }}
                    error={errors.emails}
                  />
                  <Tooltip
                    title={
                      <Translate
                        value="components.notifications.emailRecipientsHelp"
                        dangerousHTML
                      />
                    }
                    data-test-id="alert-recipients-help"
                    placement="top"
                  >
                    <span className={classes.infoIcon}>
                      <InfoIcon color="inherit" />
                    </span>
                  </Tooltip>
                </Grid>
                {/*
                <Grid item className={classes.grid}>
                  <div className={ classes.label }>
                    <Translate
                      value='components.notifications.slack_hook'
                    />
                  </div>
                  <Input
                    placeholder= { translate('components.notifications.slack_placeholder') }
                    className={ classes.settingInput }
                    disableUnderline={ true }
                    inputRef={ (input) => { this.slack_hook = input; } }
                  />
                  <span className={ classes.infoIcon }>
                    <InfoIcon color={'#888888'} />
                  </span>
                </Grid>
                */}
              </Grid>
            </CudaDialogRegularContent>
          </div>
        </CudaDialogContent>
        <DialogActions>
          <div>
            <ActionButton
              data-test-id="btn-alert-cancel"
              disabled={this.props.processing}
              color="secondary"
              onClick={closeDialog}
            >
              {translate('shared.cancel')}
            </ActionButton>
            <ActionButton
              data-test-id="btn-alert-create"
              disabled={
                this.props.processing ||
                (formInfo.email_subscribers === undefined &&
                  this.state.email_subscribers === '')
              }
              color="primary"
              onClick={() => {
                let email = this.state.email_subscribers;
                if (!this.state.email_mod && email === '') {
                  email = formInfo.email_subscribers.join('\n');
                }
                buttonAction(
                  options[selectedType].key,
                  email,
                  this.threshold.value,
                  JSON.stringify(this.props.formInfo.options || {}),
                  formInfo
                );
              }}
            >
              <Translate value={`shared.${actionTitle}`} />
            </ActionButton>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

const styles = theme => ({
  dialogContent: {
    width: '600px',
    fontSize: '14px',
    lineHeight: '32px',
    color: '#000000',
  },
  sectionTitle: {
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '19px',
    textTransform: 'uppercase',
  },
  errorMessage: {
    fontSize: '15px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    textAlign: 'center',
  },
  error: {
    borderColor: `${theme.statusColors.failure} !important`,
  },
  thresholdInput: {
    width: '60px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  menu: {
    width: '344px',
    display: 'inline-block',
  },
  label: {
    width: '150px',
    display: 'inline-block',
  },
  conditionLabel: {
    marginLeft: '8px',
  },
  settingInput: {
    width: '330px',
    fontSize: '14px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  infoIcon: {
    color: '#888888',
    display: 'inline-block',
    marginLeft: '16px',
    verticalAlign: 'sub',
  },
});

export default withStyles(styles, { name: 'CreateNotificationStyles' })(
  CreateNotificationComponent
);
