import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

import SectionTitle from './SectionTitle';
import CudaMenu from '../CudaMenu';
import TextField from '../TextField';

import { validIPaddress } from '../../lib/Utils';

class NetworkAddBar extends Component {
  state = {
    selectedCidr: 0,
    address: '',
    addressError: false,
  };

  onChangeAddress = event => {
    this.setState({
      address: event.target.value,
    });
  };

  onChangeCidr = selectedCidr => {
    this.setState({
      selectedCidr,
    });
  };

  addHandler = () => {
    if (validIPaddress(this.state.address)) {
      this.props.addHandler(
        `${this.state.address}/${this.options[this.state.selectedCidr].key}`
      );
      this.setState({
        address: '',
        addressError: false,
      });
      return;
    }

    this.setState({
      addressError: true,
    });
  };

  options = [
    {
      key: '32',
      label: '32',
    },
    {
      key: '31',
      label: '31',
    },
    {
      key: '30',
      label: '30',
    },
    {
      key: '29',
      label: '29',
    },
    {
      key: '28',
      label: '28',
    },
    {
      key: '27',
      label: '27',
    },
    {
      key: '26',
      label: '26',
    },
    {
      key: '25',
      label: '25',
    },
    {
      key: '24',
      label: '24',
    },
    {
      key: '23',
      label: '23',
    },
    {
      key: '22',
      label: '22',
    },
    {
      key: '21',
      label: '21',
    },
    {
      key: '20',
      label: '20',
    },
    {
      key: '19',
      label: '19',
    },
    {
      key: '18',
      label: '18',
    },
    {
      key: '17',
      label: '17',
    },
    {
      key: '16',
      label: '16',
    },
    {
      key: '15',
      label: '15',
    },
    {
      key: '14',
      label: '14',
    },
    {
      key: '13',
      label: '13',
    },
    {
      key: '12',
      label: '12',
    },
    {
      key: '11',
      label: '11',
    },
    {
      key: '10',
      label: '10',
    },
    {
      key: '9',
      label: '9',
    },
    {
      key: '8',
      label: '8',
    },
    {
      key: '7',
      label: '7',
    },
    {
      key: '6',
      label: '6',
    },
    {
      key: '5',
      label: '5',
    },
    {
      key: '4',
      label: '4',
    },
    {
      key: '3',
      label: '3',
    },
    {
      key: '2',
      label: '2',
    },
    {
      key: '1',
      label: '1',
    },
  ];

  render() {
    const { classes } = this.props;
    const { selectedCidr, address } = this.state;
    const { options } = this;

    return (
      <Grid container spacing={16} alignItems="flex-end">
        <Grid item xs={6} data-test-id="outboundAddressWrapper">
          <SectionTitle
            tooltipText={
              <Translate
                value="components.basicFiltering.outboundAddressHelp"
                dangerousHTML
              />
            }
            titleText={translate('components.basicFiltering.outboundAddress')}
            tooltipTestId="outboundAddressHelp"
          />
          <TextField
            data-test-id="network-input"
            autoFocus
            disableUnderline
            value={address}
            error={this.state.addressError}
            onChange={this.onChangeAddress}
            placeholder={translate(
              'components.basicFiltering.addOutboundPlaceholder'
            )}
          />
        </Grid>
        <Grid item xs={2} data-test-id="prefixBoxWrapper">
          <SectionTitle
            tooltipText={translate('components.basicFiltering.prefixHelp')}
            titleText={translate('components.basicFiltering.prefix')}
            tooltipTestId="prefixHelp"
          />
          <CudaMenu
            data-test-id="network-prefix-option"
            options={options}
            selectedIndex={selectedCidr}
            onChange={this.onChangeCidr}
          />
        </Grid>
        <Grid item xs={4}>
          <Button
            data-test-id="add-outbound-btn"
            variant="contained"
            color="secondary"
            className={classes.addButton}
            onClick={this.addHandler}
            disabled={!address}
          >
            {translate('components.basicFiltering.addOutbound')}
          </Button>
        </Grid>
      </Grid>
    );
  }
}
NetworkAddBar.propTypes = {
  classes: PropTypes.object.isRequired,
  addHandler: PropTypes.func.isRequired,
};

const styles = {
  addButton: {
    '&:disabled': {
      opacity: '0.5',
    },
    lineHeight: '18px',
    width: '100%',
    margin: 0,
    fontWeight: 400,
    padding: '7px 0',
  },
};

export default withStyles(styles, { name: 'NetworkAddBar' })(NetworkAddBar);
