import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';

import { WarningIcon } from './icons';

import Paper from './CudaPaper';

import ThreatGraph from '../containers/dashboard/ThreatGraph';
import ActivateForm from '../containers/dashboard/ActivateForm';

const styles = theme => ({
  paper: {
    padding: '20px',
    border: '1px solid #DDDDDD',
  },
  paperTitle: {
    padding: '20px 20px 0 20px',
  },
});

class ErrorDashboard extends Component {
  componentDidMount() {
    this.props.initData();
  }

  render() {
    const { classes, msp } = this.props;

    return (
      <>
        {!msp ? (
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} md={12} lg={6}>
                  <Paper elevation={0} className={classes.paper}>
                    <Grid container spacing={0}>
                      <Grid tem xs={12} md={12} lg={1}>
                        <WarningIcon width="50px" height="50px" large={true} />
                      </Grid>
                      <Grid tem xs={12} md={12} lg={11}>
                        <Translate value="components.dashboard.errorActivation" />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={12} lg={6}>
              <Paper elevation={0} className={classes.paper}>
                <Grid container spacing={0}>
                  <Grid item xs={12} md={12} lg={12}>
                    <ActivateForm />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={0}>
              <Grid item xs={12} md={12} lg={6}>
                <Paper elevation={0} className={classes.paper}>
                  <Grid container spacing={0}>
                    <Grid tem xs={12} md={12} lg={1}>
                      <WarningIcon width="50px" height="50px" large={true} />
                    </Grid>
                    <Grid tem xs={12} md={12} lg={11}>
                      <Translate value="components.dashboard.errorMspActivation" />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
    );
  }
}

ErrorDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'WFDashboard' })(ErrorDashboard);
