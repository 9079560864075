import { connect } from 'react-redux';
import Preferences from '../../components/account/Preferences';
import { changeTimezone } from '../../actions/account';

import { getTimezoneList } from '../../selectors/account';

const mapStateToProps = (state, ownProps) => ({
  time_zone: state.account.time_zone,
  saving: state.account.saving,
  timezones: getTimezoneList(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeTimezone: time_zone => {
    dispatch(changeTimezone(time_zone));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preferences);
