import { connect } from 'react-redux';
import Process from '../../components/dashboard/Process';
import { timeOptions } from '../../actions/dashboard';

const mapStateToProps = (state, ownProps) => ({
  options: timeOptions,
  selectedIndex: state.dashboard.processSelectIndex,
  data: state.dashboard.processData,
  processdata: state.dashboard.processLoading,
  loading: state.dashboard.processLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Process);
