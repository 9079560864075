import { connect } from 'react-redux';
import BlockedRequests from '../../components/dashboard/BlockedRequests';

const mapStateToProps = (state, ownProps) => {
  const current = state.dashboard.categories.blockedRequests;
  const prev = state.dashboard.categories.prevBlockedRequests;
  let trend = '-';
  let delta = 0;

  if (current && prev) {
    trend = ((current - prev) / prev) * 100;
    delta = current - prev;
  } else if (prev) {
    trend = -100;
  } else if (current) {
    trend = 'infinite';
    delta = current;
  }

  return {
    loading: state.dashboard.blocked_infoLoading,
    blockedRequests: current,
    trend,
    delta,
    hideTitle: 'hideTitle' in ownProps ? ownProps.hideTitle : false,
    inlineText: 'inlineText' in ownProps ? ownProps.inlineText : false,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(BlockedRequests);
