import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';

import EPSTableBody from '../table/TableBody';
import CudaTableTitle from '../CudaTableTitle';

class NetworkTable extends Component {
  state = {
    orderBy: 'address',
    order: 'asc',
  };

  sortHandler = field => {
    const orderBy = field;
    let order = 'desc';

    if (this.state.orderBy === field && this.state.order === 'desc') {
      order = 'asc';
    }

    this.props.sortHandler(orderBy, order);
    this.setState({ order, orderBy });
  };

  render() {
    const { classes, data } = this.props;
    const { orderBy, order } = this.state;

    return (
      <Table data-test-id="networkTable">
        <TableHead>
          <TableRow>
            <TableCell data-test-id="outboundColumn">
              <CudaTableTitle
                value="components.basicFiltering.outboundAddress"
                sort
                toggle={orderBy === 'address' ? order : 'none'}
                onClick={() => {
                  this.sortHandler('address');
                }}
              />
            </TableCell>
            <TableCell data-test-id="prefixColumn">
              <CudaTableTitle value="components.basicFiltering.prefix" />
            </TableCell>
            <TableCell data-test-id="removeColumn">
              <CudaTableTitle value="components.basicFiltering.remove" />
            </TableCell>
          </TableRow>
        </TableHead>
        <EPSTableBody
          loading={false}
          colCount={3}
          rowCount={data.length}
          lid="components.basicFiltering.noOutbound"
        >
          {data.map((network, i) => {
            const splitNetwork = network.split('/');

            return (
              <TableRow datat-test-id={`networkRow${i}`} key={network}>
                <TableCell className={classes.cell}>
                  {splitNetwork[0]}
                </TableCell>
                <TableCell className={classes.cell}>
                  {splitNetwork.length > 1 ? `/${splitNetwork[1]}` : '/32'}
                </TableCell>
                <TableCell>
                  <IconButton
                    id={`remove-btn${i}`}
                    aria-label="View"
                    aria-owns={null}
                    aria-haspopup="true"
                    color="inherit"
                    classes={{ colorInherit: classes.delete }}
                    onClick={() => this.props.deleteHandler(network)}
                  >
                    <DeleteIcon style={{ height: '24px', width: '24px' }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </EPSTableBody>
      </Table>
    );
  }
}
NetworkTable.propTypes = {
  classes: PropTypes.object.isRequired,
  sortHandler: PropTypes.func.isRequired,
  deleteHandler: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const styles = {
  cell: {
    fontSize: '14px',
  },
  delete: {
    color: '#DD1E31',
  },
};

export default withStyles(styles, { name: 'NetworkTable' })(NetworkTable);
