import { connect } from 'react-redux';
import BasicFilterChart from '../../components/reports/BasicFilterChart';

const mapStateToProps = (state, ownProps) => ({
  data: state.reports.graphData,
  loading: state.reports.reportProgress,
  width: '99%',
  textWidth: 250,
  selected: state.reports.reportSelect,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BasicFilterChart);
