import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate, Translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';

import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import moment from 'moment-timezone';
import EditModal from '../../containers/reports/EditModal';
import Paper from '../CudaPaper';

import Timestamp from '../../containers/Timestamp';
const ITEM_HEIGHT = 48;

const styles = theme => ({
  title: {
    margin: 0,
    backgroundColor: '#F7F7F7',
    height: '44px',
    fontSize: '24px',
    fontWeight: '300',
    lineHeight: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 16px 0 38px',
  },
  run: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  border: {
    height: '32px',
    width: '1px',
    backgroundColor: '#DDDDDD',
  },
  runBtn: {
    backgroundColor: 'transparent',
    color: '#5F5F5F',
    fontSize: '12px',
  },
  checkColumn: {
    width: '50px',
  },
  rowGroupTitle: {
    height: '32px',
  },
  cellGroupTitle: {
    fontSize: '13px',
    lineHeight: '15px',
    backgroundColor: '#F7F7F7',
    paddingLeft: '40px',
  },
  row: {
    height: '40px',
  },
  tableWrapper: {
    width: '100%',
    overflowX: 'auto',
  },
  cell: {
    height: '40px',
    paddingTop: '0px',
    paddingBottom: '0px',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  loading: {
    height: '25px',
    display: 'flex',
    alignItems: 'center',
  },
  sendingText: {
    marginLeft: '8px',
  },
});

const ScheduleTableGroup = ({ classes, group }) => {
  return (
    <TableRow className={classes.rowGroupTitle}>
      <TableCell className={classes.cellGroupTitle} colSpan={5}>
        {group}
      </TableCell>
    </TableRow>
  );
};

const ScheduleTableSubGroup = ({
  classes,
  loading,
  checked,
  report,
  onClick,
  name,
  recipients,
  lastSeen,
  handleClick,
  handleSelectReport,
  timezone,
  userOptions,
  groupOptions,
}) => {
  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.cell}>
        <Checkbox
          checked={checked}
          color="primary"
          onChange={() => {
            handleSelectReport(report);
          }}
        />
      </TableCell>
      <TableCell className={classes.cell}>
        {translate(`shared.reports.${name}`)}
      </TableCell>
      <TableCell className={classes.cell}>
        {recipients.replace(/\n/g, ', ')}
      </TableCell>
      <TableCell className={classes.cell}>
        {!loading && lastSeen && <Timestamp timestamp={lastSeen} />}
        {!loading && !lastSeen && '-'}
        {loading && (
          <div className={classes.loading}>
            <CircularProgress size={20} />
            <span className={classes.sendingText}>
              <Translate value="shared.sendingReport" />
            </span>
          </div>
        )}
      </TableCell>
      <TableCell className={classes.cell}>
        <IconButton
          aria-label="More"
          aria-owns={'long-menu'}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

const checkRowAdded = (report, reportQueue) => {
  for (let i = 0; i < reportQueue.length; i++) {
    if (report.id === reportQueue[i].id) {
      return true;
    }
  }

  return false;
};

const _constructGroup = (
  data,
  lastSeenPendingList,
  lastSeen,
  reportQueue,
  handleClick,
  handleSelectReport,
  timezone,
  classes
) => {
  let store = [];
  for (let group in data) {
    if (data.hasOwnProperty(group)) {
      store.push(
        <ScheduleTableGroup classes={classes} group={group} key={group} />
      );
      for (let row in data[group]) {
        let loading = false;
        for (let i = 0; i < lastSeenPendingList.length; i++) {
          if (lastSeenPendingList[i]['id'] === data[group][row]['id']) {
            loading = true;
            break;
          }
        }

        let editData = { ...data[group][row] };
        let now = moment.utc();
        let tzDB = now.tz(editData.timezone);
        tzDB = tzDB.hour(Number(editData.hour));
        tzDB = tzDB.isoWeekday(Number(editData.weekday));
        if (editData.frequency === 'monthly') {
          tzDB = tzDB.date(editData.day);
        }
        let tzNow = tzDB.tz(timezone);
        editData.hour = Number(tzNow.hour());
        editData.weekday = Number(tzNow.isoWeekday());
        editData.day = Number(tzNow.date());

        store.push(
          <ScheduleTableSubGroup
            checked={checkRowAdded(data[group][row], reportQueue)}
            report={data[group][row]}
            key={data[group][row]['id']}
            classes={classes}
            name={data[group][row]['report_type']}
            recipients={data[group][row]['recipients']}
            lastSeen={lastSeen[data[group][row]['id']]}
            loading={loading}
            timezone={timezone}
            handleClick={event => {
              handleClick(event, editData);
            }}
            handleSelectReport={handleSelectReport}
          />
        );
      }
    }
  }

  return store;
};

const ISODays = {
  '1': 'monday',
  '2': 'tuesday',
  '3': 'wednesday',
  '4': 'thursday',
  '5': 'friday',
  '6': 'saturday',
  '7': 'sunday',
};

const ScheduledTable = ({
  classes,
  reports,
  lastSeen,
  loading,
  anchorEl,
  handleClick,
  handleClose,
  clickEdit,
  deleteReport,
  timezone,
  handleSelectReport,
  runMultiReports,
  reportQueue,
  lastSeenPendingList,
  toggleAllSelectReport,
  reportOptions,
}) => {
  let data = {
    monthly: {},
    weekly: {},
    biweekly: {},
    daily: {},
    hourly: {},
  };
  let monthly = [];
  let weekly = [];
  let biweekly = [];
  let daily = [];
  let hourly = [];

  // get the zone offsets for this time, in minutes

  // FIXME: Need to make sure to convert UTC time to local timezone
  let key;
  reports.forEach(r => {
    // Convert and set time based on settings in DB
    // Then convert to current timezone
    let now = moment.utc();
    let tzDB = now.tz(r.timezone);
    tzDB = tzDB.hour(Number(r.hour));
    tzDB = tzDB.isoWeekday(Number(r.weekday));
    if (r.frequency === 'monthly') {
      tzDB = tzDB.date(r.day);
    }
    let tzNow = tzDB.tz(timezone);
    let weekday = ISODays[tzNow.isoWeekday()];
    let hour = tzNow.hour();
    let day = tzNow.date();

    // Midnight 24 is stored as zero so convert for display
    if (hour === 0) {
      hour = 24;
    }

    if (r.frequency === 'monthly') {
      key = translate('components.reporting.monthlyHeader', {
        day: day,
        hour: hour,
        tz: timezone,
      });
    } else if (r.frequency === 'weekly') {
      key = translate('components.reporting.weeklyHeader', {
        weekday: translate(`shared.${weekday}`),
        hour: hour,
        tz: timezone,
      });
    } else if (r.frequency === 'biweekly') {
      key = translate('components.reporting.biweeklyHeader', {
        weekday: translate(`shared.${weekday}`),
        hour: hour,
        tz: timezone,
      });
    } else if (r.frequency === 'daily') {
      key = translate('components.reporting.dailyHeader', {
        weekday: translate(`shared.${weekday}`),
        hour: hour,
        tz: timezone,
      });
    } else if (r.frequency === 'hourly') {
      key = translate('components.reporting.hourlyHeader', {
        weekday: translate(`shared.${weekday}`),
        hour: hour,
        tz: timezone,
      });
    } else {
      // Put into error bucket??
    }

    if (data[r.frequency][key]) {
      data[r.frequency][key].push(r);
    } else {
      data[r.frequency][key] = [r];
    }
    key = '';
  });

  monthly = _constructGroup(
    data.monthly,
    lastSeenPendingList,
    lastSeen,
    reportQueue,
    handleClick,
    handleSelectReport,
    timezone,
    classes
  );
  weekly = _constructGroup(
    data.weekly,
    lastSeenPendingList,
    lastSeen,
    reportQueue,
    handleClick,
    handleSelectReport,
    timezone,
    classes
  );
  biweekly = _constructGroup(
    data.biweekly,
    lastSeenPendingList,
    lastSeen,
    reportQueue,
    handleClick,
    handleSelectReport,
    timezone,
    classes
  );
  daily = _constructGroup(
    data.daily,
    lastSeenPendingList,
    lastSeen,
    reportQueue,
    handleClick,
    handleSelectReport,
    timezone,
    classes
  );
  hourly = _constructGroup(
    data.hourly,
    lastSeenPendingList,
    lastSeen,
    reportQueue,
    handleClick,
    handleSelectReport,
    timezone,
    classes
  );

  return (
    <Paper>
      <div className={classes.title}>
        <div>Scheduled Reports</div>
        <div className={classes.run}>
          <div className={classes.border} />
          {
            <Button
              className={classes.runBtn}
              onClick={() => {
                runMultiReports();
              }}
              disabled={reportQueue.length === 0}
            >
              <Translate value="shared.runNow" />
            </Button>
          }
        </div>
      </div>
      <div className={classes.tableWrapper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.checkColumn}>
                <Checkbox
                  color="primary"
                  checked={reports.length === reportQueue.length}
                  indeterminate={
                    !!(
                      reportQueue.length &&
                      reportQueue.length !== reports.length
                    )
                  }
                  indeterminateIcon={
                    <IndeterminateCheckBoxIcon
                      data-test-id="reportIndeterminateIcon"
                      color="primary"
                    />
                  }
                  onChange={() => {
                    toggleAllSelectReport(reports);
                  }}
                />
              </TableCell>
              <TableCell>
                <Translate value="components.reporting.reportType" />
              </TableCell>
              <TableCell>
                <Translate value="components.reporting.recipients" />
              </TableCell>
              <TableCell>
                <Translate value="components.reporting.lastSent" />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell className={classes.noResults} colSpan="5">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : null}
            {!loading ? hourly : null}
            {!loading ? daily : null}
            {!loading ? weekly : null}
            {!loading ? biweekly : null}
            {!loading ? monthly : null}
          </TableBody>
        </Table>
      </div>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 200,
          },
        }}
      >
        <MenuItem
          key={'edit'}
          onClick={() => {
            clickEdit();
            handleClose();
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          key={'delete'}
          onClick={() => {
            deleteReport();
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <EditModal />
    </Paper>
  );
};

ScheduledTable.propTypes = {
  reports: PropTypes.array.isRequired,
  lastSeen: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  clickEdit: PropTypes.func.isRequired,
  deleteReport: PropTypes.func.isRequired,
  timezone: PropTypes.string.isRequired,
  runMultiReports: PropTypes.func.isRequired,
  toggleAllSelectReport: PropTypes.func.isRequired,
  reportQueue: PropTypes.array.isRequired,
  lastSeenPendingList: PropTypes.array.isRequired,
  reportOptions: PropTypes.array.isRequired,
};

export default withStyles(styles)(ScheduledTable);
