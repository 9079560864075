import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import { InfoIcon } from '../icons';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import Modal from '../modal/Modal';

const styles = theme => ({
  infoText: {
    paddingTop: '36px',
    width: '430px',
    fontSize: '16px',
    color: theme.colors.primaryText,
  },
  dialogRight: {
    backgroundColor: '#FFFFFF',
    flexGrow: 1,
  },
  dialogRightContent: {
    fontSize: '16px',
  },
  row: {
    display: 'flex',
    lineHeight: '32px',
  },
  rowHeader: {
    width: '140px',
    marginRight: '10px',
  },
  rowContent: {
    fontSize: '16px',
    fontWeight: 600,
  },
});

class ATPLogsReportFalseThreat extends React.Component {
  constructor() {
    super();
    this.reportFalseThreat = this.reportFalseThreat.bind(this);
  }

  state = {
    syslog: null,
  };

  componentWillUnmount() {
    const { syslog } = this.state;
  }

  reportFalseThreat() {
    this.props.reportFalseThreat(this.state.atplogs);
  }

  render() {
    const { classes, show, file, closeDialog } = this.props;
    const { syslogErrors } = this.state;
    const { reportFalseThreat } = this;

    return (
      <Modal
        name="report-false-thrat"
        showModal={show}
        title={translate('components.atpLogs.reportThreatTitle')}
        saveBtnText={translate('components.atpLogs.report')}
        closeDialog={closeDialog}
        buttonAction={reportFalseThreat}
      >
        <div className={classes.dialogRight}>
          <div className={classes.rightBox}>
            <div className={classes.dialogRightContent}>
              <div className={classes.row}>
                <div className={classes.rowHeader}>
                  <Translate value="components.atpLogs.fileName" />
                </div>
                <div className={classes.rowContent}>{file}</div>
              </div>
            </div>
          </div>
          <Translate
            className={classes.infoText}
            value="components.atpLogs.reportThreatBody"
            tag="div"
          />
        </div>
      </Modal>
    );
  }
}

ATPLogsReportFalseThreat.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  reportFalseThreat: PropTypes.func.isRequired,
  removeConfigErrors: PropTypes.array,
};

ATPLogsReportFalseThreat.defaultProps = {
  removeConfigErrors: [],
};

export default withStyles(styles)(ATPLogsReportFalseThreat);
