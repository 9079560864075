import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { translate } from 'react-i18nify';
import RadioSelector from '../../components/common/RadioSelector';
import { changeTemplate } from '../../actions/block_page_settings';
import { getChangedFields } from '../../selectors/block_page_settings';

const mapStateToProps = state => ({
  value: Object.prototype.hasOwnProperty.call(
    state.blockPageSettings.changes,
    'template'
  )
    ? state.blockPageSettings.changes.template
    : state.blockPageSettings.original.template,
  label: translate('blockPage.template.label'),
  disabled: Boolean(
    getChangedFields(state).filter(field => field !== 'template').length
  ),
  options: [
    {
      value: 'default',
      label: translate('blockPage.template.default'),
    },
    {
      value: 'custom',
      label: translate('blockPage.template.custom'),
    },
  ],
  tooltipText: translate('shared.unsaved'),
});

const mapDispatchToProps = dispatch => ({
  handleChange: e => {
    dispatch(changeTemplate(e.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RadioSelector));
