import { connect } from 'react-redux';
import NetworkNameBar from '../../components/basic_filtering/NetworkNameBar';
import { updateLocationName } from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  value: state.basic_filtering.locationConfig.name,
});

const mapDispatchToProps = dispatch => ({
  updateValue: event => {
    dispatch(updateLocationName(event.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetworkNameBar);
