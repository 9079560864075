import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import HeaderButton from './HeaderButton';

class ColumnChooser extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      loading,
      icon,
      value,
      columnTypes,
      columns,
      toggleColumn,
      ...others
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.wrapper}>
        <HeaderButton
          loading={loading}
          icon={icon}
          value={value}
          clickAction={this.handleClick}
          className={anchorEl ? classes.button : null}
          {...others}
        />
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          getContentAnchorEl={null}
          disableAutoFocusItem
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={classes.menuHeader}>Customize Table Columns</div>
          {columnTypes.map(n => (
            <MenuItem className={classes.menuOptions} key={n}>
              <FormControlLabel
                data-test-id="formControlCheckbox"
                key={n}
                classes={{
                  root: classes.formRoot,
                  label: classes.formLabel,
                }}
                control={
                  <Checkbox
                    id={`column-${n}`}
                    color="primary"
                    checked={columns[n].selected}
                    value={n}
                    className={classes.checkbox}
                    onChange={(event, checked) => toggleColumn(n, checked)}
                  />
                }
                label={<span data-test-id="formLabel">{columns[n].label}</span>}
              />
            </MenuItem>
          ))}
        </Menu>
      </div>
    );
  }
}

ColumnChooser.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  clickAction: PropTypes.func,
  value: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
};

ColumnChooser.defaultProps = {
  loading: false,
};

const styles = theme => ({
  menuHeader: {
    pointerEvents: 'none',
    padding: '8px 16px',
  },
  wrapper: {
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  button: {
    backgroundColor: '#FFFFFF',
  },
  logButtons: {
    minHeight: '46px',
    cursor: 'pointer',
    color: theme.colors.greyText,
  },
});

export default withStyles(styles, { name: 'ColumnChooser' })(ColumnChooser);
