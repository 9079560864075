import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import ActivateAgreement from '../../components/activate/ActivateAgreement';
import { linkPurchase, clearInfo } from '../../actions/activate';

const mapStateToProps = state => ({
  processing: state.activate.processing,
});

const mapDispatchToProps = dispatch => ({
  linkPurchase: history => {
    dispatch(linkPurchase(history));
  },
  clearInfo: () => {
    dispatch(clearInfo());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActivateAgreement));
