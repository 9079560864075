import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import { InfoIcon } from '../icons';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';

import AccountSettingsRadio from './AccountSettingsRadio';
import SettingsItem from '../settings/SettingsItem';
import Dropdown from '../common/Dropdown';

import Modal from '../modal/Modal';
import {
  CudaDialogContent,
  CudaDialogTitle,
  CudaDialogRegularContent,
  ActionButton,
} from '../CudaDialog';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import * as Env from '../../lib/Env';

const styles = theme => ({
  dialogContent: {
    width: '600px',
    fontSize: '14px',
    lineHeight: '32px',
    color: '#000000',
  },
  syslog: {
    width: '140px',
    height: '70px',
  },
  syslogTitleText: {
    fontSize: '21px',
    lineHeight: '31px',
    color: theme.colors.primaryText,
  },
  syslogWrapper: {
    border: '1px solid #DDDDDD',
    width: '160px',
    height: '80px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    width: '242px',
    display: 'inline-block',
  },
  input: {
    border: '1px solid #DDDDDD',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.06)',
    borderRadius: '2px',
    paddingLeft: '3px',
    paddingRight: '3px',
    fontSize: '14px',
    '& > input': {
      lineHeight: '20px',
      padding: '5px ',
    },
  },
  addressInput: {
    margin: '2px',
    fontSize: '14px',
    width: '133px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  portInput: {
    margin: '2px',
    fontSize: '14px',
    width: '75px',
    border: '1px solid #DDDDDD',
    paddingLeft: '6px',
    paddingRight: '6px',
  },
  row: {
    marginBottom: '16px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
  errors: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    textAlign: 'center',
  },
  infoIcon: {
    color: '#888888',
    display: 'inline-block',
    marginLeft: '16px',
    verticalAlign: 'sub',
  },
});
class SMBAccountSettings extends React.Component {
  constructor() {
    super();
    this.saveSyslogConfig = this.saveSyslogConfig.bind(this);
  }

  state = {
    syslog: null,
  };

  componentWillUnmount() {
    const { syslog } = this.state;
  }

  saveSyslogConfig() {
    this.props.saveSyslogConfig(this.state.syslog);
  }

  render() {
    const {
      classes,
      closeDialog,
      configErrors,
      processingSyslog,
      show,
      syslogConfig,
      toggleSyslogEnabled,
      updateSyslogAddress,
      updateSyslogPort,
      selectSyslogType,
      syslogType,
      toggleSyslogConnect,
      updateSyslog,
      saving,
      helperTextAddress,
      helperTextPort,
      accountId,
    } = this.props;
    const { syslogErrors } = this.state;
    const { saveSyslogConfig } = this;
    let demo_accounts = Env.demo_accounts;

    return (
      <Modal
        name="syslog-config"
        showModal={show}
        title={translate('components.syslog.configTitle')}
        saveBtnText={translate('shared.Save')}
        processing={processingSyslog}
        closeDialog={closeDialog}
        disabled={!saving}
        buttonAction={saveSyslogConfig}
      >
        <Grid container direction="column" spacing={16}>
          <Grid item className={classes.grid} xs={12}>
            <SettingsItem
              processing={processingSyslog}
              text={
                <Translate
                  value="components.syslog.serverEnable"
                  className={classes.infoText}
                />
              }
              toggled={syslogConfig.enabled}
              onToggle={toggleSyslogEnabled}
              padding={false}
              labels={['components.shared.on', 'components.shared.off']}
            />
          </Grid>
          <Grid item className={classes.grid} xs={12}>
            <div className={classes.infoText}>
              <Translate value="components.syslog.serverAddrPort" />
            </div>
            <TextField
              processing={processingSyslog}
              data-test-id="syslog-address"
              helperText={helperTextAddress}
              error={!!helperTextAddress}
              value={syslogConfig.address}
              name="address"
              disabled={!syslogConfig.enabled}
              InputProps={{
                disableUnderline: true,
                error: false,
                classes: {
                  root: classNames(classes.addressInput),
                },
              }}
              onChange={updateSyslogAddress}
            />
            <TextField
              processing={processingSyslog}
              data-test-id="syslog-port"
              value={syslogConfig.port}
              error={!!helperTextPort}
              helperText={helperTextPort}
              name="port"
              disabled={!syslogConfig.enabled}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classNames(classes.portInput),
                },
              }}
              onChange={updateSyslogPort}
            />
            <Tooltip
              title={translate('components.syslog.serverAddrPortHelp')}
              placement="top"
              data-test-id="syslog-ip-help"
            >
              <span className={classes.infoIcon}>
                <InfoIcon color="#888888" />
              </span>
            </Tooltip>
          </Grid>
          <Grid item className={classes.grid} xs={12}>
            {0 ? (
              <AccountSettingsRadio
                disabled={!syslogConfig.enabled}
                tooltipText={translate('components.syslog.serverConnHelp')}
                text={translate('components.syslog.serverConn')}
                value={syslogConfig.connection.value}
                radioValues={['false', 'true']}
                handleChange={() => toggleSyslogConnect(syslogConfig.conn)}
                labels={[
                  'components.syslogEncrypt.tcp',
                  'components.syslogEcnrpyt.ssl',
                ]}
              />
            ) : null}
          </Grid>
        </Grid>
      </Modal>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  saveSyslogConfig: PropTypes.func.isRequired,
  processingSyslog: PropTypes.bool.isRequired,
  configErrors: PropTypes.array,
};

SMBAccountSettings.defaultProps = {
  configErrors: [],
};

export default withStyles(styles)(SMBAccountSettings);
