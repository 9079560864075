import React from 'react';
import ReactTooltip from 'react-tooltip';
import { withStyles } from '@material-ui/core/styles';

const tooltipStyleSheet = {
  tooltipWrapper: {
    '& > div': {
      opacity: '1 !important',
      padding: '6px 9px',
      fontSize: 12,
    },
  },
  tooltipBox: {
    backgroundColor: 'rgba(0,0,0,0.7)',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
    maxWidth: 280,
    borderRadius: 2,
  },
  tooltipText: {
    color: '#ffffff',
  },
};

const TooltipComponent = ({
  classes,
  id,
  children,
  effect = 'solid',
  ...props
}) => {
  return (
    <div data-test-id={`tooltip-${id}`} className={classes.tooltipWrapper}>
      <ReactTooltip
        className={classes.tooltipBox}
        id={id}
        type="dark"
        effect={effect}
        {...props}
      >
        <div {...props} className={classes.tooltipText}>
          {children}
        </div>
      </ReactTooltip>
    </div>
  );
};

export default withStyles(tooltipStyleSheet, { name: 'TooltipComponent' })(
  TooltipComponent
);
