import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

const NoResultsComponent = ({ classes, colSpan, lid }) => (
  <TableRow>
    <TableCell className={classes.noResults} colSpan={colSpan}>
      <Translate value={lid} />
    </TableCell>
  </TableRow>
);
NoResultsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  colSpan: PropTypes.number.isRequired,
  lid: PropTypes.string.isRequired,
};

const styles = {
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
  },
};

export default withStyles(styles, { name: 'NoResultsStyles' })(
  NoResultsComponent
);
