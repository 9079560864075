import { connect } from 'react-redux';
import AccountSelection from '../../components/shared_policies/AccountSelection';

import { getActiveAvailableAccounts } from '../../selectors/account';
import { onChangeAccountSelection } from '../../actions/shared_policies';

const mapStateToProps = (state, ownProps) => {
  let accounts = [];
  let activeAccounts = getActiveAvailableAccounts(state);

  for (let i = 0; i < activeAccounts.length; i++) {
    accounts.push({
      type: 'smb_account',
      label: activeAccounts[i]['name'],
      value: activeAccounts[i]['account_id'],
    });
  }
  return {
    inputValue: state.shared_policies.accountSelections,
    options: accounts,
  };
};

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(onChangeAccountSelection(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSelection);
