import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

import Grid from '@material-ui/core/Grid';

import TextField from '../TextField';

const EditLocalUser = ({ classes, user, updateName }) => (
  <React.Fragment>
    <Translate
      value="userAuthentication.local.editText"
      className={classes.text}
      tag="div"
    />
    <Grid container spacing={8}>
      <Grid item xs={10} md={6}>
        <Translate
          value="userAuthentication.local.userID"
          className={classes.inputTitle}
          tag="div"
        />
        <TextField disableUnderline disabled value={user.email} />
      </Grid>
      <Grid item xs={10} md={6}>
        <Translate
          value="userAuthentication.local.userName"
          className={classes.inputTitle}
          tag="div"
        />
        <TextField disableUnderline value={user.name} onChange={updateName} />
      </Grid>
    </Grid>
  </React.Fragment>
);

EditLocalUser.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  updateName: PropTypes.func.isRequired,
};

const styles = theme => ({
  text: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  inputTitle: {
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    color: 'rgba(0, 0, 0, 0.5)',
    textTransform: 'uppercase',
  },
});

export default withStyles(styles, { name: 'EditLocalUser' })(EditLocalUser);
