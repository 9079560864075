import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { translate, Translate } from 'react-i18nify';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';

const styles = theme => ({
  border: {
    borderLeft: '1px solid #DDDDDD',
  },
  container: {
    display: 'flex',
  },
  btnWrapper: {
    display: 'flex',
  },
  text: {
    display: 'flex',
    alignItems: 'center',
  },
});

const DownloadOptions = ({
  classes,
  timezone,
  accountId,
  accountName,
  reportType,
  range,
  downloadCsv,
  downloadPdf,
  pdfDownloading,
  csvDownloading,
}) => (
  <div className={classes.container}>
    <div className={classes.btnWrapper}>
      <Button
        data-test-id="pdfDownload"
        variant="text"
        onClick={() => {
          downloadPdf(
            reportType,
            range,
            timezone,
            accountName,
            translate(`shared.reports.${reportType}`),
            translate(`shared.ranges.${range}`)
          );
        }}
      >
        {pdfDownloading ? (
          <CircularProgress />
        ) : (
          <div className={classes.text}>
            {' '}
            <GetAppIcon /> <Translate value="components.reporting.pdfReport" />{' '}
          </div>
        )}
      </Button>
      <div className={classes.border} />
    </div>
    <div className={classes.btnWrapper}>
      <Button
        data-test-id="csvDownload"
        variant="text"
        onClick={() => {
          downloadCsv(reportType, range);
        }}
      >
        {csvDownloading ? (
          <CircularProgress />
        ) : (
          <div className={classes.text}>
            {' '}
            <GetAppIcon /> <Translate value="components.reporting.csvReport" />{' '}
          </div>
        )}
      </Button>
      <div className={classes.border} />
    </div>
  </div>
);

DownloadOptions.propTypes = {};

DownloadOptions.defaultProps = {};
export default withStyles(styles)(DownloadOptions);
