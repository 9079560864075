import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';

import LinkIcon from '@material-ui/icons/Link';
import GetAppIcon from '@material-ui/icons/GetApp';

import { GreyWindowsIcon } from '../icons';
import { CSV_URL, DDNS_DOWNLOAD } from '../../lib/Env';
import Auth from '../../lib/Auth';

import { Mixpanel } from '../../lib/Mixpanel';

const styles = theme => ({
  wrapper: {
    padding: '25px',
  },
  title: {
    color: 'rgba(0, 0, 0, 0.7)',
    fontSize: '22px',
    lineHeight: '25px',
    fontWeight: 300,
    marginBottom: '16px',
  },
  instructionTitle: {
    color: 'rgba(0, 0, 0, 0.9)',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '28px',
  },
  instructionStep: {
    color: 'rgba(0, 0, 0, 0.9)',
    fontSize: '14px',
    lineHeight: '24px',
    marginLeft: '16px',
  },
  lastStep: {
    marginBottom: '16px',
  },
  downloadTable: {
    borderCollapse: 'collapse',
    width: '100%',
    marginBottom: '50px',
  },
  downloadTableRow: {
    border: '1px solid #E0E0E0',
  },
  downloadTableCell: {
    padding: '0 16px',
    fontSize: '14px',
    lineHeight: '40px',
    color: '#000000',
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    color: theme.colors.link,
    textDecoration: 'none',
    backgroundColor: 'transparent',
    textTransform: 'uppercase',
    fontSize: '14px',
    padding: 0,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  helpLink: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  icon: {
    color: '#777777',
    marginRight: '10px',
  },
});

const DynamicIPUpdater = ({
  classes,
  account_id,
  locationId,
  version,
  demo,
}) => {
  const lastStepClass = classNames(classes.instructionStep, classes.lastStep);

  return (
    <div className={classes.wrapper} data-test-id="dynamic-ip-updater-wrapper">
      <Translate
        value="components.basicFiltering.installationInstructions"
        tag="div"
        className={classes.title}
      />
      <Translate
        value="components.basicFiltering.installerTitle"
        tag="div"
        className={classes.instructionTitle}
      />
      <div className={classes.instructionStep}>
        1.&nbsp;
        <Translate value="components.basicFiltering.localDownload" />
      </div>
      <div className={classes.instructionStep}>
        2.&nbsp;
        <Translate value="components.basicFiltering.localRun" />
      </div>
      <div className={lastStepClass}>
        3.&nbsp;
        <Translate value="components.basicFiltering.localKey" />
      </div>
      <Translate
        value="components.basicFiltering.rmmTitle"
        tag="div"
        className={classes.instructionTitle}
      />
      <div className={classes.instructionStep}>
        1.&nbsp;
        <Translate value="components.basicFiltering.rmmDownload" />
      </div>
      <div className={lastStepClass}>
        2.&nbsp;
        <Translate
          value="components.basicFiltering.rmmRun"
          dangerousHTML
          linkClass={classes.helpLink}
        />
      </div>
      <table className={classes.downloadTable}>
        <tbody>
          <tr className={classes.downloadTableRow}>
            <td>
              <span className={classes.downloadTableCell}>
                <GreyWindowsIcon
                  style={{ color: '#777777', marginRight: '10px' }}
                />
                {translate('components.basicFiltering.windowsLink')}
              </span>
            </td>
            <td>
              <span className={classes.downloadTableCell}>
                <GetAppIcon
                  classes={{ colorPrimary: classes.icon }}
                  color="primary"
                />
                <Button
                  data-test-id="dynamic-ip-updater-download-installer"
                  disabled={demo}
                  onClick={() => {
                    Mixpanel.track(
                      'DNS Filtering / Dynamic IP Updater Dialog / Download',
                      {}
                    );
                    window.open(DDNS_DOWNLOAD, '_blank');
                  }}
                  className={classes.link}
                >
                  {translate('components.basicFiltering.downloadInstaller')}
                </Button>
              </span>
            </td>
          </tr>
          <tr className={classes.downloadTableRow}>
            <td>
              <span className={classes.downloadTableCell}>
                <LinkIcon
                  classes={{ colorPrimary: classes.icon }}
                  color="primary"
                />
                {translate('components.basicFiltering.installerKey')}
              </span>
            </td>
            <td>
              <span className={classes.downloadTableCell}>
                <GetAppIcon
                  classes={{ colorPrimary: classes.icon }}
                  color="primary"
                />
                <Button
                  data-test-id="dynamic-ip-updater-download-key"
                  disabled={demo}
                  onClick={() => {
                    Mixpanel.track(
                      'DNS Filtering / Dynamic IP Updater Dialog / Download Key',
                      {}
                    );
                    window.open(
                      `${CSV_URL}?page=installer_key&auth=${Auth.get_token()}&account_id=${account_id}&location_id=${locationId}&version=${version}`,
                      '_blank'
                    );
                  }}
                  className={classes.link}
                >
                  {translate('components.basicFiltering.downloadKey')}
                </Button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

DynamicIPUpdater.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'DynamicIPUpdater' })(
  DynamicIPUpdater
);
