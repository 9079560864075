import React from 'react';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input/Input';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { SearchIcon } from '../icons';

const cudaActionBarSheet = theme => ({
  wrapper: {
    minHeight: '44px',
    backgroundColor: '#F7F7F7',
    border: '1px solid #DDDDDD',
    margin: 0,
    width: '100%',
  },
  input_wrapper: {
    marginLeft: 0,
    marginRight: 25,
    width: '100%',
    fontSize: 14,
  },
  search_bar_wrapper: {
    display: 'flex',
  },
  search_bar: {
    display: 'flex',
    justifyContent: 'Flex-start',
    alignItems: 'center',
    borderRadius: '2px 0 0 2px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #DDDDDD',
    boxSizing: 'border-box',
    width: '490px',
    paddingLeft: '10px',
  },
  icon: {
    margin: '2px 25px 0 19px',
  },
  btn: {
    '&:hover': {
      opacity: '0.80',
    },
    '&:active': {
      opacity: '1',
    },
    cursor: 'pointer',
    backgroundColor: '#7F7F7F',
    display: 'flex',
    alignItems: 'center',
    width: '32px',
    justifyContent: 'center',
    borderRadius: '0px 2px 2px 0px',
    '& svg > path': {
      fill: 'white',
    },
  },
  btnWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sharedBtn: {
    height: '33px',
    margin: 0,
    backgroundColor: '#7F7F7F',
    color: '#FFFFFF',
  },
});

const ActionBar = ({ classes, openModal, value, onQueryChange, loading }) => {
  return (
    <Grid
      container
      spacing={2}
      className={classes.wrapper}
      data-test-id="cuda-action-bar"
    >
      <Grid item xs={12} md={8} lg={8}>
        <div className={classes.search_bar_wrapper}>
          <div className={classes.search_bar}>
            <Input
              placeholder="Search policy name or linked account"
              className={classes.input_wrapper}
              onChange={e => {
                onQueryChange(e.target.value);
              }}
              disabled={false}
              disableUnderline={true}
              value={value}
            />
          </div>
          <div className={classes.btn}>
            <SearchIcon />
          </div>
        </div>
      </Grid>
      <Grid item xs={12} md={4} lg={4} className={classes.btnWrapper}>
        <Button
          variant="contained"
          className={classes.sharedBtn}
          onClick={openModal}
          disabled={loading}
        >
          <Translate value="components.mspSharedAtp.addSharedThreatPolicy" />
        </Button>
      </Grid>
    </Grid>
  );
};

export default withStyles(cudaActionBarSheet, { name: 'ActionBar' })(ActionBar);
