import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const styles = {
  container: {
    alignItems: 'center',
    padding: '24px 0 16px 0',
    color: '#5F5F5F',
  },
  title: {
    fontSize: '32px',
    lineHeight: '38px',
    display: 'inline-block',
    color: 'rgba(0,0,0,0.9)',
    paddingLeft: '16px',
    fontWeight: '300',
  },
  text: {
    marginTop: '16px',
    fontSize: '14px',
    lineHeight: '28px',
    color: 'rgba(0,0,0,0.9)',
  },
  infoIcon: {
    width: '15px',
  },
  help: {
    marginLeft: '24px',
  },
};

const SectionHeader = ({
  classes,
  icon,
  title,
  tooltipText,
  children,
  options,
  help,
}) => {
  return (
    <Grid
      container
      justify={'space-between'}
      spacing={0}
      className={classes.container}
    >
      <Grid item lg={6} data-test-id="sectionTitle">
        {icon}
        <span className={classes.title}>{title}</span>
      </Grid>
      {options ? <Grid item>{options}</Grid> : null}
      {children ? (
        <Grid item xs={12} className={classes.text}>
          {children}
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles, { name: 'SectionHeader' })(SectionHeader);
