import { connect } from 'react-redux';
import ThreatPolicy from '../../components/shared_policies/ThreatPolicy';

import {
  filterExclusions,
  updateSharedOptions,
  showExclusionsModal,
} from '../../actions/shared_policies';

const mapStateToProps = (state, ownProps) => {
  return {
    fields: {
      ...state.shared_policies.fields,
      ...state.shared_policies.changes,
    },
    customExclusion: state.shared_policies.customExclusion,
    exclusionList: state.shared_policies.exclusionList,
    query: '',
    index: 0,
  };
};

const mapDispatchToProps = dispatch => ({
  updateSetting: (name, value) => {
    dispatch(
      updateSharedOptions({
        [name]: value,
      })
    );
  },
  filterSelection: selection => {
    dispatch(filterExclusions(selection));
  },
  showExclusionsModal: () => {
    dispatch(showExclusionsModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatPolicy);
