import { connect } from 'react-redux';
import CategoryTable from '../../components/dashboard/CategoryTable';

const mapStateToProps = state => ({
  data: state.mspDashboard.blockedAccounts.data,
  loading: state.mspDashboard.blockedAccounts.loading,
  firstColumn: 'account',
});

export default connect(
  mapStateToProps,
  undefined
)(CategoryTable);
