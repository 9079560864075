import { connect } from 'react-redux';
import ReportDataTable from '../components/reports/PerUserReport';

const mapStateToProps = (state, ownProps) => ({
  data: state.reports.perUserReport,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDataTable);
