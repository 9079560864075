import React from 'react';
import PropTypes from 'prop-types';
import { translate, Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';

import SettingsItem from './SettingsItem';
import Paper from '../CudaPaper';

const styleSheet = {
  label: {
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
};

const Support = ({
  classes,
  processing,
  support_enabled,
  toggleSupport,
  saving,
}) => {
  return (
    <Paper
      title={translate('components.smbAccountSettings.supportAccess')}
      subTitle={translate('components.smbAccountSettings.supportAccessSub')}
      loading={processing}
      padding
    >
      <SettingsItem
        text={
          <Translate
            value="components.smbAccountSettings.supportAccess"
            className={classes.label}
          />
        }
        toggled={support_enabled}
        onToggle={toggleSupport}
        padding={false}
        disabled={saving}
        labels={['components.shared.on', 'components.shared.off']}
      />
    </Paper>
  );
};

Support.propTypes = {
  classes: PropTypes.object.isRequired,
  processing: PropTypes.bool.isRequired,
  support_enabled: PropTypes.bool.isRequired,
  toggleSupport: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
};

export default withStyles(styleSheet)(Support);
