import React from 'react';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  link: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  disabled: {
    color: 'rgba(0,0,0,0.4)',
  },
};

let Link = ({ classes, children, href, target = '_self' }) => {
  return (
    <a className={classes.link} href={href} target={target}>
      {children}
    </a>
  );
};
Link = withStyles(styles, { name: 'CudaLink' })(Link);
export { Link };

let ButtonLink = ({ classes, children, onClick = undefined }) => {
  return (
    <span>
      {onClick === undefined ? (
        <span className={classes.disabled}>{children}</span>
      ) : (
        <span
          className={classes.link}
          onClick={onClick}
          onKeyPress={onClick}
          tabIndex="0"
          role="button"
        >
          {children}
        </span>
      )}
    </span>
  );
};
ButtonLink = withStyles(styles, { name: 'ButtonLink' })(ButtonLink);
export { ButtonLink };

let RouterLink = ({ classes, children, disabled = false, ...other }) => {
  return (
    <span>
      {disabled ? (
        <span className={classes.disabled}>{children}</span>
      ) : (
        <NavLink className={classes.link} {...other}>
          {children}
        </NavLink>
      )}
    </span>
  );
};
RouterLink = withStyles(styles, { name: 'RouterLink' })(RouterLink);
export default RouterLink;
