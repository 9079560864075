import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router';
import ScheduleScan from '../../components/atp_policy/ScheduleScan';

import { openScheduleFullScan, runFullScan } from '../../actions/atp';
import moment from 'moment';

const mapStateToProps = (state, ownProps) => {
  let label = 'Disabled';
  if (state.atp.modal.enabled) {
    if (state.atp.runNowTime) {
      label = state.atp.runNowTime;
      label = moment(state.atp.runNowTime).format('dddd, MMMM Do YYYY, h:mm a');
    } else {
      label = 'Enabled';
    }
  }
  return {
    lastScan: state.atp.lastScan,
    scheduleLabel: label,
    scanTime: state.atp.scanTime,
    scanEnabled: state.atp.modal.enabled,
    loading: state.atp.modal.isUpdating,
    disabled: state.atp.shared_policy,
    wca: state.account.subscriptions.wca,
    features: state.account.features,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  openScheduleModal: () => {
    dispatch(openScheduleFullScan());
  },
  runScan: () => {
    dispatch(runFullScan());
  },
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScheduleScan)
);
