import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Select, { components } from 'react-select';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GroupIcon from '@material-ui/icons/Group';
import Chip from '@material-ui/core/Chip';
import { WebIcon } from '../icons';

const MultiValueContainer = props => {
  let icon = null;

  if (props.data.type === 'user') {
    icon = <PersonOutlineIcon />;
  } else if (props.data.type === 'group') {
    icon = <GroupIcon />;
  } else if (props.data.type === 'everyone') {
    icon = <WebIcon />;
  }

  return (
    <components.MultiValueContainer {...props}>
      {icon ? (
        <Chip
          avatar={
            <div
              style={{
                width: '14px',
                height: '20px',
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {icon}
            </div>
          }
          key={props.data.value}
          label={props.data.label}
        />
      ) : (
        <Chip key={props.data.value} label={props.data.label} />
      )}
    </components.MultiValueContainer>
  );
};

const Option = props => {
  let icon = null;

  if (props.data.type === 'user') {
    icon = <PersonOutlineIcon />;
  } else if (props.data.type === 'group') {
    icon = <GroupIcon />;
  } else if (props.data.type === 'everyone') {
    icon = <WebIcon />;
  }
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '5px' }}>{icon}</div>
        {props.children}
      </div>
    </components.Option>
  );
};

const AccountSelection = ({
  classes,
  options,
  inputValue,
  onChange,
  multi,
  disabled,
}) => (
  <Grid container spacing={16} alignItems="flex-end">
    <Grid item xs={12} data-test-id="ad-selection">
      <Select
        isMulti={multi}
        isDisabled={disabled}
        isClearable={false}
        name="ad-selection"
        placeholder={translate('components.advancedFiltering.adPlaceholder')}
        components={{ MultiValueContainer, Option }}
        styles={{
          multiValue: base => ({ ...base, backgroundColor: 'transparent' }),
        }}
        options={options}
        className="basic-multi-select"
        classNamePrefix="select"
        getValue={value => {}}
        value={inputValue}
        onChange={selectedOptions => {
          onChange(selectedOptions);
        }}
      />
    </Grid>
  </Grid>
);

AccountSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
};

AccountSelection.defaultProps = {
  disabled: false,
  multi: true,
};

const styles = theme => ({});

export default withStyles(styles, { name: 'AccountSelection' })(
  AccountSelection
);
