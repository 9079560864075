import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

import ActionLabel from './ActionLabel';
import CudaMenu from '../CudaMenu';
import BlockedRequests from '../../containers/dashboard/BlockedRequests';
import CategoryTable from '../../containers/dashboard/CategoryTable';
import SupercategoryTable from '../../containers/dashboard/SupercategoryTable';
import ViewLink from './ViewLink';

import { Mixpanel } from '../../lib/Mixpanel';

class CategoryStats extends Component {
  state = {
    display: 'supercategories',
    timeRangeIndex: 1,
  };

  onChangeTimerange = timeRangeIndex => {
    this.props.changeTimerange(this.options[timeRangeIndex].key);
    this.setState({ timeRangeIndex });
  };

  options = [
    {
      key: 'day',
      label: translate('shared.ranges.day'),
    },
    {
      key: 'week',
      label: translate('shared.ranges.week'),
    },
    {
      key: 'fortnight',
      label: translate('shared.ranges.fortnight'),
    },
  ];

  render() {
    const { display, timeRangeIndex } = this.state;
    const { classes, loading, displayBlockRequest } = this.props;
    const { options } = this;

    return (
      <Grid
        container
        spacing={0}
        className={classes.container}
        data-test-id="categoryStats"
      >
        <Grid item xs={12} data-test-id="categoryStatsButtonNav">
          <BottomNavigation
            className={classes.buttonWrapper}
            value={display}
            showLabels
            onChange={(event, value) => {
              this.setState({
                display: value,
              });
            }}
          >
            <BottomNavigationAction
              onClick={() => {
                Mixpanel.track('Dashboard / Blocked Super Category-Report', {});
              }}
              classes={{
                root: classes.buttonRoot,
                selected: classes.buttonSelected,
              }}
              label={
                <ActionLabel
                  label={translate(
                    'components.dashboard.blockedSupercategories'
                  )}
                  subtext={
                    display === 'supercategories'
                      ? options[timeRangeIndex].label
                      : ''
                  }
                />
              }
              value="supercategories"
              data-test-id="categoryStatsButtonNavSupercategories"
            />
            <BottomNavigationAction
              onClick={() => {
                Mixpanel.track('Dashboard / Blocked Super Category-Report', {});
              }}
              classes={{
                root: classes.buttonRoot,
                selected: classes.buttonSelected,
              }}
              label={
                <ActionLabel
                  label={translate('components.dashboard.topBlockedCategories')}
                  subtext={
                    display === 'categories'
                      ? options[timeRangeIndex].label
                      : ''
                  }
                />
              }
              value="categories"
              data-test-id="categoryStatsButtonNavCategories"
            />
          </BottomNavigation>
        </Grid>
        {displayBlockRequest && (
          <Grid item xs={12}>
            <BlockedRequests range={options[timeRangeIndex].key} />
          </Grid>
        )}
        <Grid item xs={12}>
          {display === 'categories' && (
            <CategoryTable
              style={{
                display: display === 'categories' ? 'block' : 'none',
                minHeight: '451px',
              }}
            />
          )}
          {display === 'supercategories' && (
            <SupercategoryTable
              style={{
                display: display === 'supercategories' ? 'block' : 'none',
                minHeight: '451px',
              }}
            />
          )}
        </Grid>
        <Grid item xs={7} data-test-id="categoryStatsDropdownWrapper">
          <CudaMenu
            data-test-id="categoryStatsDropdown"
            disabled={loading}
            customStyle={classes.timeMenu}
            options={options}
            selectedIndex={timeRangeIndex}
            onChange={value => {
              this.onChangeTimerange(value);
            }}
          />
        </Grid>
        <Grid item xs={5} data-test-id="categoryStatsViewLinkWrapper">
          <ViewLink
            to="/reports"
            value={translate('components.dashboard.viewReport')}
          />
        </Grid>
      </Grid>
    );
  }
}

CategoryStats.defaultProps = {
  displayBlockRequest: true,
};

CategoryStats.propTypes = {
  classes: PropTypes.object.isRequired,
  changeTimerange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  displayBlockRequest: PropTypes.bool,
};

const styles = {
  container: {
    backgroundColor: '#FFFFFF',
  },
  timeMenu: {
    border: 0,
    maxWidth: '200px',
  },
  buttonWrapper: {
    justifyContent: 'flex-start',
  },
  buttonRoot: {
    color: 'rgba(0, 0, 0, 0.5)',
    borderColor: '#FFFFFF',
    borderTop: '3px solid',
    '&:hover': {
      backgroundColor: '#E7E7E7',
      borderColor: '#E7E7E7',
    },
    '&$buttonSelected': {
      paddingTop: '8px',
    },
    '&>span': {
      display: 'inline-block',
    },
  },
  buttonSelected: {
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    borderColor: '#0088CE',
    '&:hover': {
      borderColor: '#0088CE',
    },
  },
};

export default withStyles(styles, { name: 'CategoryStats' })(CategoryStats);
