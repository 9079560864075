import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Tooltip from '@material-ui/core/Tooltip';

import { RadioButtonCheckIcon, RadioButtonUncheckIcon } from '../icons';

const RadioSelector = ({
  classes,
  value,
  label,
  handleChange,
  options,
  disabled,
  tooltipText,
}) => {
  const radio = (
    <FormControl className={classes.control} disabled={disabled}>
      {Boolean(label) && (
        <FormLabel classes={{ root: classes.label }}>{label}</FormLabel>
      )}
      <RadioGroup
        aria-label="radioOption"
        name="radioOption"
        className={classes.group}
        value={value}
        onChange={handleChange}
        row
      >
        {options.map((n, i) => (
          <FormControlLabel
            key={options[i].value}
            value={options[i].value}
            classes={{
              label: classes.radioLabel,
            }}
            control={
              <Radio
                checkedIcon={<RadioButtonCheckIcon />}
                icon={<RadioButtonUncheckIcon />}
                classes={{
                  root: classes.radio,
                }}
              />
            }
            label={options[i].label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );

  return tooltipText ? (
    <Tooltip
      disableFocusListener={!disabled}
      disableHoverListener={!disabled}
      disableTouchListener={!disabled}
      title={tooltipText}
    >
      {radio}
    </Tooltip>
  ) : (
    <React.Fragment>{radio}</React.Fragment>
  );
};

const styles = theme => ({
  control: {
    flexDirection: 'row',
    alignItems: 'center',
    '& > label': {
      marginRight: '30px',
    },
  },
  radio: {
    padding: '6px',
  },
  label: {
    fontSize: '16px',
    lineHeight: '32px',
    color: theme.colors.primaryText,
  },
  radioLabel: {
    fontSize: '14px',
    lineHeight: '32px',
    color: theme.colors.primaryText,
  },
});

RadioSelector.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  tooltipText: PropTypes.string,
};

RadioSelector.defaultProps = {
  options: [
    {
      value: true,
      label: 'Yes',
    },
    {
      value: false,
      label: 'No',
    },
  ],
  label: '',
  tooltipText: '',
  disabled: false,
};

export default withStyles(styles, { name: 'RadioSelector' })(RadioSelector);
