import { connect } from 'react-redux';
import Password from '../../components/account/Password';
import { changePassword } from '../../actions/account';

const mapStateToProps = (state, ownProps) => ({
  saving: state.account.saving,
  disabled: state.account.msp,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changePassword: newPassword => {
    dispatch(changePassword(newPassword));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Password);
