import { connect } from 'react-redux';
import WFLogTable from '../../components/wf_logs/WFLogTable';
import { updateSort, changePage } from '../../actions/wf_logs';

const mapStateToProps = state => ({
  loading: state.wfLogs.loading,
  logs: state.wfLogs.logs,
  logCount: state.wfLogs.logCount,
  sort: state.wfLogs.sort,
  page: state.wfLogs.page,
  rowsPerPage: state.wfLogs.rowsPerPage,
  columns: state.wfLogs.columns,
});

const mapDispatchToProps = dispatch => ({
  updateSort: sort => {
    dispatch(updateSort(sort));
  },
  changePage: (page, rowsPerPage) => {
    dispatch(changePage(page, rowsPerPage));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WFLogTable);
