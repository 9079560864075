import { connect } from 'react-redux';
import TlsVersion from '../../components/dashboard/TlsVersion';
import { timeOptions } from '../../actions/dashboard';

const mapStateToProps = (state, ownProps) => ({
  options: timeOptions,
  selectedIndex: state.dashboard.tlsSelectIndex,
  data: state.dashboard.tlsData,
  loading: state.dashboard.tlsLoading,
  isTls: true,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TlsVersion);
