import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

const RemoveFromGroup = ({ classes, data }) => (
  <React.Fragment>
    <Translate
      value="userAuthentication.local.removeFromGroupText"
      className={classes.text}
      tag="div"
      user={data.user.email}
      group={data.group.name}
    />
    <Translate
      value="userAuthentication.local.removeFromGroupSubText"
      className={classNames(classes.text, classes.subText)}
      tag="div"
    />
  </React.Fragment>
);

const styles = theme => ({
  text: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  subText: {
    fontWeight: 500,
    fontStyle: 'italic',
  },
});

export default withStyles(styles, { name: 'RemoveFromGroup' })(RemoveFromGroup);
