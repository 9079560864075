import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Editor from '../../components/block_page/Editor';
import { updateState } from '../../actions/block_page_settings';

const mapStateToProps = state => {
  const template = Object.prototype.hasOwnProperty.call(
    state.blockPageSettings.changes,
    'template'
  )
    ? state.blockPageSettings.changes.template
    : state.blockPageSettings.original.template;

  return {
    editorState: state.blockPageSettings.editorState,
    rte: template === 'default',
  };
};

const mapDispatchToProps = dispatch => ({
  updateState: editorState => {
    dispatch(updateState(editorState));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Editor));
