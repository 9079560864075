import { connect } from 'react-redux';
import RulesetRow from '../../components/advanced_filtering/RulesetRow';
import {
  reorderRulesets,
  submitRulesetsReorder,
  submitRulesetOrder,
  openEditRuleset,
} from '../../actions/advanced_filtering';

const mapStateToProps = state => ({
  processing: state.advanced_filtering.processing,
  default_action: state.advanced_filtering.policy_settings.default_action,
  features: state.account.features,
});

const mapDispatchToProps = dispatch => ({
  reorder: (dragIndex, hoverIndex) => {
    dispatch(reorderRulesets(dragIndex, hoverIndex));
  },
  openEditRuleset: (ad, ruleId) => {
    dispatch(openEditRuleset(ad, ruleId));
  },
  submitReorder: (id, hoverIndex, originalIndex, parentId) => {
    dispatch(submitRulesetsReorder(id, hoverIndex, originalIndex, parentId));
  },
  submitOrder: (id, originalIndex, index, rulesetId) => {
    dispatch(submitRulesetOrder(id, originalIndex, index, rulesetId));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesetRow);
