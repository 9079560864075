import React from 'react';
import { Translate } from 'react-i18nify';
import CudaLink from '../CudaLink';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ATPPolicyIcon, ATPPolicyWhiteIcon } from '../icons';

import { Mixpanel } from '../../lib/Mixpanel';

const cudaNavigatorSheet = theme => ({
  container: {
    paddingTop: '10px',
  },
  navigationText: {
    color: 'rgba(0,0,0,0.9)',
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '15px',
    textTransform: 'uppercase',
  },
  currentLink: {
    '& div': {
      color: '#ffffff',
    },
    '& > div': {
      backgroundColor: theme.colors.barracudaBlue,
    },
    '& path': {
      fill: '#ffffff',
    },
    '& polygon': {
      fill: '#ffffff',
    },
  },
  placeholderCurrentLink: {
    '& div': {
      color: '#ffffff',
    },
    '& > div': {
      backgroundColor: theme.colors.barracudaBlue,
    },
    '& g': {
      stroke: '#ffffff',
    },
    '& $whiteIcon': {
      display: 'inline',
    },
    '& $colorIcon': {
      display: 'none',
    },
  },
  linkDiv: {
    color: 'rgba(0,0,0,0.9)',
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    maxWidth: '220px',
    padding: '6px 5px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '15px',
    },
  },
  colorIcon: {
    display: 'inline',
  },
  whiteIcon: {
    display: 'none',
  },
});

const Navigator = ({ classes, match }) => {
  return (
    <Grid
      container
      className={classes.container}
      spacing={1}
      data-test-id="cuda-navigator-wrapper"
    >
      <Grid item xs={12}>
        <CudaLink
          id="atp-nav"
          to={`/shared_policies/threat_protection`}
          activeClassName={classes.placeholderCurrentLink}
          onClick={() => {
            Mixpanel.track('NAV / Shared Threat Policy', {});
          }}
        >
          <div className={classes.linkDiv}>
            <ATPPolicyIcon className={classes.colorIcon} />
            <ATPPolicyWhiteIcon className={classes.whiteIcon} />
            <Translate
              value="shared.sharedThreatPolicies"
              className={classes.navigationText}
            />
          </div>
        </CudaLink>
      </Grid>
    </Grid>
  );
};

export default withStyles(cudaNavigatorSheet, { name: 'Navigator' })(Navigator);
