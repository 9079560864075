import { connect } from 'react-redux';
import Header from '../../components/reports/Header';
import { translate } from 'react-i18nify';
import {
  getUserOptions,
  getGroupOptions,
  getLocationOptions,
} from '../../selectors/reports';

const mapStateToProps = (state, ownProps) => {
  const accounts = state.account.accounts;
  const selected = state.account.selected;

  let name = '';
  for (var i = 0; i < accounts.length; i++) {
    if (parseInt(accounts[i].id, 10) === parseInt(selected, 10)) {
      name = accounts[i].name;
      break;
    }
  }

  let translateTxt = 'shared.reports.' + state.reports.genReport;
  let reportName = '';
  if (state.reports.genReport === 'per-user-reports') {
    translateTxt = 'shared.reports.' + state.reports.genReport + '-title';
    const users = getUserOptions(state);
    for (var i = 0; i < users.length; i++) {
      if (users[i]['key'] === state.reports.idSelect) {
        reportName = users[i]['label'];
        break;
      }
    }
  } else if (state.reports.genReport === 'per-group-reports') {
    translateTxt = 'shared.reports.' + state.reports.genReport + '-title';
    const groups = getGroupOptions(state);

    for (var i = 0; i < groups.length; i++) {
      if (
        parseInt(groups[i]['group_id']) === parseInt(state.reports.idSelect)
      ) {
        reportName = groups[i]['label'];
        break;
      }
    }
  } else if (state.reports.genReport === 'location-reports') {
    translateTxt = 'shared.reports.' + state.reports.genReport + '-title';
    const locations = getLocationOptions(state);
    for (var i = 0; i < locations.length; i++) {
      if (locations[i]['key'] === state.reports.idSelect) {
        reportName = locations[i]['label'];
        break;
      }
    }
  }
  const title = translate(translateTxt, { name: reportName });

  return {
    title: title,
    subTitle: state.reports.timeLabel,
    accountName: name,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
