import { connect } from 'react-redux';
import SyslogRemoveModal from '../../components/smb_account_settings/SyslogRemoveModal';

import { removeSyslog } from '../../actions/smb_account_settings';

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  removeSyslogConfig: () => {
    dispatch(removeSyslog());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyslogRemoveModal);
