import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import { ExpandIcon, CollapseIcon } from '../icons';

const InstallerHeaderRow = ({
  classes,
  handleToggle,
  expanded,
  label,
  ...other
}) => (
  <TableRow className={classes.headerRow} {...other}>
    <TableCell className={classes.expandCell}>
      <IconButton
        data-test-id="expand-row"
        className={classes.expandButton}
        aria-label="Toggle current"
        onClick={handleToggle}
        disableRipple
      >
        {expanded ? <CollapseIcon /> : <ExpandIcon />}
      </IconButton>
    </TableCell>
    <TableCell colSpan={4}>{label}</TableCell>
  </TableRow>
);

const styles = {
  headerRow: {
    backgroundColor: '#DDDDDD',
    height: '32px',
    boxShadow: '0 -1px 0 0 #DDDDDD, 0 1px 0 0 #E0E0E0',
    '& > td': {
      padding: '4px',
      '&:first-child': {
        paddingLeft: '24px',
        paddingRight: '0px',
      },
      '&:last-child': {
        paddingRight: '24px',
      },
    },
  },
  headerLabel: {
    fontSize: '12px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  expandButton: {
    padding: 0,
    '& svg': {
      height: 24,
      width: 24,
      cursor: 'pointer',
    },
  },
  expandCell: {
    width: 24,
  },
};

InstallerHeaderRow.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'InstallerHeaderRow' })(
  InstallerHeaderRow
);
