import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Navigator from '../../components/shared_policies/Navigator';

const mapStateToProps = state => ({
  subscriptions: state.account.subscriptions,
  msp: state.account.msp,
});

export default withRouter(
  connect(
    mapStateToProps,
    undefined
  )(Navigator)
);
