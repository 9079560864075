import { connect } from 'react-redux';
import DeleteGroup from '../../components/user_authentication/DeleteGroup';

const mapStateToProps = state => ({
  group: state.userAuthentication.modal.data,
});

export default connect(
  mapStateToProps,
  undefined
)(DeleteGroup);
