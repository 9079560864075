import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Authenticator from '../Authenticator';
import { translate } from 'react-i18nify';
import {
  updateSubscriptions,
  storeSubscriptions,
  retrieveAccount,
  storeUserName,
  storeSerials,
} from '../actions/account';
import { displayError } from '../actions/login';

import { storeDecodedSSOToken } from '../actions/activate';
import { showSessionModal } from '../actions/account';
import {
  storeSessionInfo,
  SIGNUP_USER_LOGIN_FAILURE,
  TOGGLE_LOGIN,
} from '../actions/signup';

const mapDispatchToProps = dispatch => ({
  updateSubscriptions: subscriptions => {
    dispatch(updateSubscriptions(subscriptions));
  },
  storeSubscriptions: (subs, accountSubs) => {
    dispatch(storeSubscriptions(subs, accountSubs));
  },
  storeSerials: serials => {
    dispatch(storeSerials(serials));
  },
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
  storeUserName: userName => {
    dispatch(storeUserName(userName));
  },
  storeDecodedSSOToken: (dtoken, provisioned_accounts) => {
    const re = /Partner\s*$/g;
    const msp = dtoken.accounts.some(a => a.type && a.type.match(re));
    const services = dtoken.services.reduce(
      (obj, account) => ({
        ...obj,
        ...(account.service_id === 17 && { [account.account_id]: true }),
      }),
      {}
    );
    const accounts = dtoken.accounts.filter(
      account =>
        !provisioned_accounts.includes(Number(account.account_id)) &&
        !(account.type && account.type.match(re))
    );
    dispatch(storeDecodedSSOToken(dtoken, provisioned_accounts));
    if (msp) {
      dispatch({ type: TOGGLE_LOGIN });
      dispatch({
        type: SIGNUP_USER_LOGIN_FAILURE,
        error: translate('signup.mspError'),
      });
    } else if (!accounts.length) {
      dispatch({ type: TOGGLE_LOGIN });
      dispatch({
        type: SIGNUP_USER_LOGIN_FAILURE,
        error: 'No eligible accounts',
      });
    } else {
      dispatch(storeSessionInfo(dtoken, accounts, msp));
    }
  },
  errorMessage: (code, message) => {
    dispatch(displayError(code, message));
  },
  showSessionModal: () => {
    dispatch(showSessionModal());
  },
});

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps
  )(Authenticator)
);
