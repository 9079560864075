import { connect } from 'react-redux';
import UploadModal from '../../components/smb_account_settings/UploadModal';

import { togglePreview, logoErrors } from '../../actions/smb_account_settings';

const mapStateToProps = state => ({
  showPreview: state.smbAccountSettings.showPreview,
  uploadErrors: state.smbAccountSettings.logoErrors,
});

const mapDispatchToProps = dispatch => ({
  togglePreview: () => {
    dispatch(togglePreview(true));
  },
  logoErrors: errors => {
    dispatch(logoErrors(errors));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadModal);
