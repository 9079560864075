import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

class CheckedCategoryComponent extends Component {
  static getDerivedStateFromProps(nextProps) {
    const categories = nextProps.categories.map(cat => parseInt(cat.value, 10));
    const selected = nextProps.categories
      .filter(
        cat =>
          nextProps.selectedCategories.indexOf(parseInt(cat.value, 10)) !== -1
      )
      .map(cat => parseInt(cat.value, 10));

    return {
      categories,
      selected,
    };
  }

  constructor(props) {
    super(props);
    this.handleAllClick = this.handleAllClick.bind(this);
  }

  state = {
    selected: [],
  };

  handleAllClick = (event, checked) => {
    let added = [];
    let removed = [];

    if (checked) {
      added = this.state.categories.filter(
        cat => !this.state.selected.some(selected => cat === selected)
      );
    } else {
      removed = this.state.selected;
    }

    this.props.changeSelection(added, removed);
  };

  handleClick = (event, checked, cat) => {
    const added = [];
    const removed = [];

    if (checked) {
      added.push(cat);
    } else {
      removed.push(cat);
    }

    this.props.changeSelection(added, removed);
  };

  render() {
    const { classes, categories, topLevel, className } = this.props;
    const { selected } = this.state;

    return (
      <div className={className}>
        <div className={classes.labelWrapper}>
          <FormControlLabel
            classes={{
              root: classes.titleRoot,
              label: classes.formLabel,
            }}
            control={
              <Checkbox
                id={`checkbox-${topLevel.value}`}
                color="primary"
                checked={selected.length === categories.length}
                indeterminate={
                  !!(selected.length && selected.length !== categories.length)
                }
                indeterminateIcon={
                  <IndeterminateCheckBoxIcon
                    data-test-id="indeterminateIcon"
                    color="primary"
                  />
                }
                value={topLevel.value}
                className={classes.checkbox}
                onChange={this.handleAllClick}
              />
            }
            label={translate(`components.basicFiltering.${topLevel.label}`)}
          />
        </div>
        <FormGroup>
          {categories.map(n => (
            <FormControlLabel
              data-test-id="formControlCheckbox"
              key={n.value}
              classes={{
                root: classes.formRoot,
                label: classes.formLabel,
              }}
              control={
                <Checkbox
                  id={`checkbox-${n.value.toString()}`}
                  color="primary"
                  checked={selected.indexOf(parseInt(n.value, 10)) !== -1}
                  value={n.value.toString()}
                  className={classes.checkbox}
                  onChange={(event, checked) =>
                    this.handleClick(event, checked, parseInt(n.value, 10))
                  }
                />
              }
              label={<span data-test-id="formLabel">{n.label}</span>}
            />
          ))}
        </FormGroup>
      </div>
    );
  }
}

CheckedCategoryComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  topLevel: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.number),
  changeSelection: PropTypes.func.isRequired,
};
CheckedCategoryComponent.defaultProps = {
  selectedCategories: [],
};

const styles = {
  labelWrapper: {
    textTransform: 'uppercase',
    '& span': {
      fontWeight: 'bold',
    },
    borderBottom: '1px solid #DDDDDD',
    width: '100%',
  },
  formLabel: {
    marginLeft: '10px',
  },
  titleRoot: {
    margin: 0,
    '& > span': {
      fontSize: '1rem',
      lineHeight: '2rem',
    },
  },
  formRoot: {
    margin: 0,
    '& > span': {
      fontSize: '0.75rem',
      lineHeight: '1.5rem',
    },
  },
  checkbox: {
    height: '16px',
    width: '16px',
  },
};

export default withStyles(styles, { name: 'NoResultsStyles' })(
  CheckedCategoryComponent
);
