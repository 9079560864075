import { connect } from 'react-redux';
import BarGraph from '../../components/dashboard/BarGraph';
import { updateModule } from '../../actions/dashboard';

const mapStateToProps = state => ({
  graphData: state.dashboard.threatCounts,
  loading: state.dashboard.threatCountsLoading,
});

const mapDispatchToProps = dispatch => ({
  changeTimerange: timeRange => {
    dispatch(updateModule('threatCounts', timeRange));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BarGraph);
