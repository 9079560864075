import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

const DeleteGroup = ({ classes, group }) => (
  <React.Fragment>
    <Translate
      value="userAuthentication.local.deleteGroupText"
      className={classes.text}
      tag="div"
      group={group.name}
    />
    <Translate
      value="userAuthentication.local.deleteGroupSubText"
      className={classNames(classes.text, classes.subText)}
      tag="div"
    />
  </React.Fragment>
);

const styles = theme => ({
  text: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  subText: {
    fontWeight: 500,
    fontStyle: 'italic',
  },
});

export default withStyles(styles, { name: 'DeleteGroup' })(DeleteGroup);
