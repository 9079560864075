import React from 'react';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress';

import SectionHeader from './SectionHeader';
import Paper from './CudaPaper';
import { SettingsIcon } from './icons';

import GridColumn from './common/GridColumn';

import StatusBar from '../containers/exempt_policies/StatusBar';
import SettingsItem from './settings/SettingsItem';

import ProcessExclusion from '../containers/ProcessExclusion';
import NetworkExclusion from '../containers/NetworkExclusion';

class ExemptPolicies extends React.Component {
  componentDidMount = () => {
    this.props.initData();
  };

  render() {
    const {
      classes,
      msp,
      wca,
      processing,
      fields,
      toggleField,
      saving,
      features,
    } = this.props;

    return (
      <DocumentTitle
        title={translate('components.exemptPolicies.title')}
        data-test-id="exempt-policies-title"
      >
        <React.Fragment>
          <SectionHeader
            icon={<SettingsIcon style={{ color: '#5F5F5F' }} />}
            title={translate('components.exemptPolicies.title')}
          />
          <Grid
            data-test-id="exempt-policies-wrapper"
            spacing={2}
            container
            alignItems="flex-start"
          >
            <Grid item xs={12} className={classes.logoBoxWrapper}>
              <Grid
                className={classes.settingsWrapper}
                spacing={2}
                container
                alignItems="flex-start"
              >
                <Grid item xs={12}>
                  <Paper
                    title={translate('components.exemptPolicies.microsoft')}
                    subTitle={translate(
                      'components.exemptPolicies.microsoftSub'
                    )}
                    loading={processing}
                    padding
                  >
                    <SettingsItem
                      text={
                        <Translate
                          value="shared.enable"
                          className={classes.label}
                        />
                      }
                      toggled={fields.o365}
                      onToggle={e => {
                        toggleField('o365', e.target.checked);
                      }}
                      padding={false}
                      disabled={saving}
                      labels={['components.shared.on', 'components.shared.off']}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Paper
                    title={translate(
                      'components.exemptPolicies.processExemptTitle'
                    )}
                    subTitle={translate(
                      'components.exemptPolicies.processExemptSubTitle'
                    )}
                    loading={processing}
                    padding
                  >
                    <div className={classes.processExclusionsHeader}>
                      {translate('components.exemptPolicies.processExempt')}
                    </div>
                    <div className={classes.processExclusionsWrapper}>
                      <ProcessExclusion />
                    </div>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Paper
                    title={translate(
                      'components.exemptPolicies.networkExemptTitle'
                    )}
                    subTitle={translate(
                      'components.exemptPolicies.networkExemptSubtitle'
                    )}
                    loading={processing}
                    padding
                  >
                    <div className={classes.processExclusionsHeader}>
                      {translate('components.exemptPolicies.networkExempt')}
                    </div>
                    <div className={classes.processExclusionsWrapper}>
                      <NetworkExclusion />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <StatusBar />
        </React.Fragment>
      </DocumentTitle>
    );
  }
}

ExemptPolicies.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
  toggleField: PropTypes.func.isRequired,
  msp: PropTypes.bool.isRequired,
  wca: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
};

const styles = theme => ({
  processExclusionsWrapper: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  processExclusionsHeader: {
    margin: '10px 0px 10px 0px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.5)',
    fontFamily: 'Roboto',
    fontSize: '14px',
  },
  settingsWrapper: {
    marginTop: '5px !important',
  },
  logoBoxWrapper: {
    marginTop: '-7px !important',
  },
  subText: {
    color: theme.colors.secondaryText,
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '150px',
  },
  label: {
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '16px',
    marginBottom: '4px',
  },
  row: {
    display: 'flex',
    marginBottom: '16px',
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    minHeight: '100px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    '& > img': {
      maxWidth: '240px',
      maxHeight: '240px',
    },
  },
});

export default withStyles(styles, { name: 'ExemptPolicies' })(ExemptPolicies);
