import { connect } from 'react-redux';
import AddPolicy from '../../components/advanced_filtering/AddPolicy';

import { TYPE_OPTIONS } from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  const typeIndex = state.advanced_filtering.ruleModal.selectedTypeIndex;

  return {
    display: TYPE_OPTIONS[typeIndex]['key'],
    disabledAD: state.advanced_filtering.addRuleModal.disabled,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(AddPolicy);
