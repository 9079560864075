import { connect } from 'react-redux';
import NetworkAddBar from '../../components/basic_filtering/NetworkAddBar';
import { updateNetworks } from '../../actions/basic_filtering';

const mapDispatchToProps = dispatch => ({
  addHandler: address => {
    dispatch(updateNetworks([address], []));
  },
});

export default connect(
  undefined,
  mapDispatchToProps
)(NetworkAddBar);
