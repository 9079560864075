import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import CudaNavigator from '../components/CudaNavigator';

const mapStateToProps = state => ({
  subscriptions: state.account.subscriptions,
  msp: state.account.msp,
});

export default withRouter(
  connect(
    mapStateToProps,
    undefined
  )(CudaNavigator)
);
