import React, { Component } from 'react';
import { translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import DocumentTitle from 'react-document-title';

import SectionHeader from '../components/SectionHeader';
import { GaugeIcon } from '../components/icons';

import TopThreatChart from '../containers/overview/TopThreatChart';
import ThreatPieChart from '../containers/overview/ThreatPieChart';
import ThreatDetectedChart from '../containers/overview/ThreatDetectedChart';
import TopBlockedAccounts from '../containers/overview/TopBlockedAccounts';
import Paper from './CudaPaper';

const dashboardStyle = {};

class MSPDashboardComponent extends Component {
  componentDidMount() {
    this.props.initData();
  }

  render() {
    const { classes, subscriptions } = this.props;

    return (
      <DocumentTitle title={translate('components.dashboard.overviewTitle')}>
        <div>
          <SectionHeader
            icon={<GaugeIcon />}
            title={translate('components.dashboard.overview')}
            tooltipText="Tooltip Text"
          />
          <Grid container spacing={2}>
            {subscriptions.dns && (
              <Grid item xs={3}>
                <Paper data-test-id="topBlockedAccounts">
                  <TopBlockedAccounts />
                </Paper>
              </Grid>
            )}
            {subscriptions.atp && (
              <Grid item xs={9} data-test-id="topThreatChart">
                <TopThreatChart />
              </Grid>
            )}
            {subscriptions.atp && (
              <Grid
                item
                xs={3}
                className={classes.statsColumn}
                data-test-id="threatPieChart"
              >
                <ThreatPieChart />
              </Grid>
            )}
            {subscriptions.atp && (
              <Grid item xs={9} data-test-id="threatDetectedChart">
                <ThreatDetectedChart />
              </Grid>
            )}
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

export default withStyles(dashboardStyle, { name: 'MSPDashboard' })(
  MSPDashboardComponent
);
