import { connect } from 'react-redux';
import NotificationModal from '../../components/notifications/NotificationModal';
import {
  create,
  changeType,
  updateSecondaryOptions,
} from '../../actions/notifications';

const mapStateToProps = state => ({
  typeIndex: state.notifications.typeSelectIndex,
  title: 'add',
  actionTitle: 'add',
  formInfo: state.notifications.editData,
  processing: state.notifications.isProcessing,
  errors: state.notifications.errors,
});

const mapDispatchToProps = dispatch => ({
  buttonAction: (type, emails, threshold, options = '') => {
    dispatch(
      create({
        added_emails: emails ? emails.split(/[\s,]+/) : [],
        type,
        threshold,
        options,
      })
    );
  },
  handleTypeChange: (index, type) => {
    dispatch(changeType(type.key));
  },
  updateSecondaryOptions: options => {
    dispatch(updateSecondaryOptions(options));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationModal);
