export const INIT_LOGS = 'INIT_LOGS';
export const GET_ATP_LOGS_SUCCESS = 'GET_ATP_LOGS_SUCCESS';
export const GET_ATP_LOGS_FAILURE = 'GET_ATP_LOGS_FAILURE';
export const TOGGLE_FILTER_TYPE = 'TOGGLE_FILTER_TYPE';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const UPDATE_ROWS = 'UPDATE_ROWS';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREV_PAGE = 'PREV_PAGE';
export const UPDATE_RANGE = 'UPDATE_RANGE';
export const UPDATE_SORT = 'UPDATE_LOGS_SORT';
export const ATP_LOGS_LOADING = 'ATP_LOGS_LOADING';
export const ATP_LOG_RESET = 'ATP_LOG_RESET';
export const ATP_LOG_FIRST_PAGE = 'ATP_LOG_FIRST_PAGE';
export const ATP_LOG_LAST_PAGE = 'ATP_LOG_LAST_PAGE';

export const ATP_LOGS_DOWNLOAD_PDF = 'ATP_LOGS_DOWNLOAD_PDF';
export const ATP_LOGS_DOWNLOAD_PDF_SUCCESS = 'ATP_LOGS_DOWNLOAD_PDF_SUCCESS';
export const ATP_LOGS_DOWNLOAD_PDF_FAILURE = 'ATP_LOGS_DOWNLOAD_PDF_FAILURE';

export const ATP_LOGS_DOWNLOAD_CSV = 'ATP_LOGS_DOWNLOAD_CSV';
export const ATP_LOGS_DOWNLOAD_CSV_SUCCESS = 'ATP_LOGS_DOWNLOAD_CSV_SUCCESS';
export const ATP_LOGS_DOWNLOAD_CSV_FAILURE = 'ATP_LOGS_DOWNLOAD_CSV_FAILURE';

export const ATP_LOGS_OPEN_REPORT_THREAT_MODAL =
  'ATP_LOGS_OPEN_REPORT_THREAT_MODAL';
export const ATP_LOGS_CLOSE_REPORT_THREAT_MODAL =
  'ATP_LOGS_CLOSE_REPORT_THREAT_MODAL';
export const ATP_LOGS_REPORT_FALSE_THREAT = 'ATP_LOGS_REPORT_THREAT';
export const ATP_LOGS_REPORT_FALSE_THREAT_SUCCESS =
  'ATP_LOGS_REPORT_FALSE_THREAT_SUCCESS';
export const ATP_LOGS_REPORT_FALSE_THREAT_FAILURE =
  'ATP_LOGS_REPORT_THREAT_FAILURE';

export const downloadPdf = cuid => ({
  type: ATP_LOGS_DOWNLOAD_PDF,
  cuid,
});

export const downloadPdfSuccess = () => ({
  type: ATP_LOGS_DOWNLOAD_PDF_SUCCESS,
});

export const downloadPdfFailure = error => ({
  type: ATP_LOGS_DOWNLOAD_PDF_FAILURE,
  error,
});

export const downloadCsv = range => ({
  type: ATP_LOGS_DOWNLOAD_CSV,
  range,
});

export const downloadCsvSuccess = () => ({
  type: ATP_LOGS_DOWNLOAD_CSV_SUCCESS,
});

export const downloadCsvFailure = error => ({
  type: ATP_LOGS_DOWNLOAD_CSV_FAILURE,
  error,
});

export const updateRange = (range, index) => ({
  type: UPDATE_RANGE,
  range,
  index,
});

export const openReportThreatModal = (filename, cuid) => ({
  type: ATP_LOGS_OPEN_REPORT_THREAT_MODAL,
  filename,
  cuid,
});

export const closeReportThreatModal = () => ({
  type: ATP_LOGS_CLOSE_REPORT_THREAT_MODAL,
});

export const reportFalseThreat = cuid => ({
  type: ATP_LOGS_REPORT_FALSE_THREAT,
  cuid,
});

export const reportFalseThreatSuccess = () => ({
  type: ATP_LOGS_REPORT_FALSE_THREAT_SUCCESS,
});

export const reportFalseThreatFailure = error => ({
  type: ATP_LOGS_REPORT_FALSE_THREAT_FAILURE,
  error,
});

export const resetPage = () => ({
  type: ATP_LOG_RESET,
});

export const firstPage = () => ({
  type: ATP_LOG_FIRST_PAGE,
});

export const nextPage = () => ({
  type: NEXT_PAGE,
});

export const prevPage = () => ({
  type: PREV_PAGE,
});

export const lastPage = () => ({
  type: ATP_LOG_LAST_PAGE,
});

export const updateRows = rows => ({
  type: UPDATE_ROWS,
  rows,
});

export const updateQuery = query => ({
  type: UPDATE_QUERY,
  query,
});

export const updateSort = (column, order) => ({
  type: UPDATE_SORT,
  column,
  order,
});

export const initLogs = () => ({
  type: INIT_LOGS,
});

export const logsLoading = () => ({
  type: ATP_LOGS_LOADING,
});

export const getATPLogSuccess = result => ({
  type: GET_ATP_LOGS_SUCCESS,
  result,
});

export const getATPLogFailure = error => ({
  type: GET_ATP_LOGS_FAILURE,
  error,
});
