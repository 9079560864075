import React, { Component } from 'react';
import { Translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ActionButton from './ActionButton';
import ErrorMessage from './ErrorMessage';

const styles = {
  formContainer: {
    width: '668px',
    height: '650px',
    padding: '0 0',
    backgroundColor: '#ffffff',
    margin: 0,
    marginTop: '50px',
  },
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    padding: '20px 20px 15px 20px',
    textAlign: 'center',
    borderBottom: '1px solid #dddddd',
  },
  text: {
    fontSize: '14px',
    lineHeight: '16px',
    height: '490px',
    overflow: 'auto',
    padding: '5px 24px',
    textAlign: 'left',
  },
  footer: {
    padding: '20px',
    backgroundColor: '#EEEEEE',
  },
  listHead: {
    listStyleType: 'none',
  },
  error: {
    fontSize: '13px',
  },
};

class LicenseAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reachedBottom: false,
    };
  }

  handleScroll = e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom) {
      this.setState({ reachedBottom: true });
    }
  };

  render() {
    const { classes, handleAgree, processing, history, error } = this.props;
    return (
      <form>
        <div>
          <Grid item container className={classes.formContainer}>
            <Grid
              item
              xs={12}
              className={classes.title}
              data-test-id="license-agreement-title"
            >
              <Translate value="components.activate.licenseAgreement" />
            </Grid>
            <Grid
              data-test-id="license-agreement-container"
              item
              xs={12}
              className={classes.text}
              onScroll={this.handleScroll}
            >
              <p>
                IF YOU LIVE IN THE UNITED STATES, THIS AGREEMENT CONTAINS A
                BINDING ARBITRATION CLAUSE AND CLASS ACTION WAIVER. IT AFFECTS
                YOUR RIGHTS ABOUT HOW TO RESOLVE ANY DISPUTE WITH BARRACUDA.
                PLEASE READ IT.
              </p>
              <p>
                PLEASE READ THIS SOFTWARE LICENSE AGREEMENT ("AGREEMENT")
                CAREFULLY BEFORE USING THE BARRACUDA NETWORKS SOFTWARE. BY USING
                THE BARRACUDA SOFTWARE YOU ("LICENSEE") ARE AGREEING TO BE BOUND
                BY THE TERMS OF THIS LICENSE. IF YOU DO NOT AGREE TO THE TERMS
                OF THIS LICENSE YOU MAY RETURN THE SOFTWARE OR HARDWARE
                CONTAINING THE SOFTWARE FOR A FULL REFUND TO YOUR PLACE OF
                PURCHASE.
              </p>
              <h3>Software License Agreement</h3>
              <li className={classes.listHead}>
                <p>
                  <strong>DEFINITIONS.</strong> The following capitalized terms
                  shall have the following meanings whenever used in this
                  Agreement.{' '}
                </p>
                <ul>
                  <li>
                    <p>
                      1.1. "Authorized User" means an employee or a contractor
                      of Licensee who is authorized to use the Licensor Software
                      provided hereunder.
                    </p>
                  </li>
                  <li>
                    <p>
                      1.2. "Documentation" means the Software's standard user
                      manual and any and all manuals, instructions and other
                      documents and materials that Barracuda provides or makes
                      available to Licensee in any form or medium which describe
                      the functionality, components, features or requirements of
                      the Software, including any aspect of the installation,
                      configuration, integration, operation, use, support or
                      maintenance thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      1.3. "Effective Date" This Agreement shall be effective
                      upon the earlier of installation or first use of the
                      Software.
                    </p>
                  </li>
                  <li>
                    <p>
                      1.4. "Intellectual Property Rights" means any and all
                      registered and unregistered rights granted, applied for or
                      otherwise now or hereafter in existence under or related
                      to any patent, copyright, trademark, trade secret,
                      database protection or other intellectual property rights
                      laws, and all similar or equivalent rights or forms of
                      protection, in any part of the world.
                    </p>
                  </li>
                  <li>
                    <p>
                      1.5. "Licensee Hardware" means hardware whether purchased
                      or provided to Licensee by Licensor on which the Software
                      is used.
                    </p>
                  </li>
                  <li>
                    <p>
                      1.6. "Permitted Use" means use of the Software by an
                      Authorized User for the benefit of Licensee in the
                      ordinary course of its internal business operations.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      1.7. "Software" means the software licensed in object code
                      format, together with any Updates provided to Licensee
                      pursuant to this Agreement.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      1.8. "Specifications" means Barracuda's standard
                      specifications for the Software set forth in its
                      Documentation on the Effective Date.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      1.9. "Subscription Term" means the length of time for
                      which the maintenance subscription is purchased. The
                      Subscription Term is listed on Barracuda's price list, or
                      quote.{' '}
                    </p>
                  </li>
                  <li>
                    <p>1.10. "Term" is defined in Section 10.1 below. </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      1.11. "Update" means minor enhancements and bug fixes to
                      the Software.
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>LICENSES &amp; DELIVERY.</strong>
                </p>
                <ul className={classes.listHead}>
                  <li>
                    <p>
                      {' '}
                      2.1. License. Barracuda hereby grants Licensee a
                      nonexclusive license to use the Software solely for
                      Licensee's internal business purposes, provided Licensee
                      complies with the restrictions set forth herein. Barracuda
                      reserves all rights not expressly granted to Licensee. The
                      rights granted are limited to the Software and do not
                      include any other patent or Intellectual Property Rights.
                      Barracuda retains ownership of the Software. If the
                      Software has been licensed for evaluation use only,
                      Licensee may not use the Software in a production
                      environment, or beyond the term of the demo license.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      2.2. Permitted Uses. This Agreement allows Licensee to use
                      the Software only on the single Barracuda labeled hardware
                      device on which the Software was delivered or on other
                      Licensee Hardware which meets the requirements set forth
                      in the Software specification. In addition, use of the
                      Software shall also be limited, as applicable and as set
                      forth in Barracuda's price list, quote, user
                      documentation, or web site, to a maximum number of (a)
                      seats (i.e. users with access to install Software); (b)
                      concurrent users, sessions, ports, and/or issued and
                      outstanding IP addresses; and/or (c) central processing
                      unit cycles or instructions per second. A limited license
                      allows Licensee to use the Software only for the licensed
                      number of instances and licensed sizes.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      2.3. Restrictions on Software Rights. Licensee shall not:
                      (a) modify, create derivative works from, distribute,
                      publicly display, publicly perform, or sublicense the
                      Software; (b) use the Software for service bureau or
                      time-sharing purposes or in any other way allow third
                      parties to exploit the Software; (c) reverse engineer,
                      decompile, disassemble, or otherwise attempt to derive any
                      of the Software's source code; (d) make copies of the
                      Software, other than one backup copy; (e) transfer, rent,
                      lease, lend, or sublicense the Software or allow a third
                      party to do so. Licensee may not otherwise transfer the
                      Software or any of its rights and/or obligations under
                      this Agreement; (f) utilize or run the Software on more
                      computers than the number of licenses that were purchased;
                      or (g) operate the Software in a fashion that exceeds the
                      capacity or capabilities that were purchased; (h) to
                      develop or disclose any benchmarks, measurements, or
                      reports on the Software.
                    </p>
                  </li>
                  <li>
                    <p>
                      2.4. Documentation. Licensee may reproduce the
                      Documentation as reasonably necessary to support internal
                      use of the Software.
                    </p>
                  </li>
                  <li>
                    <p>
                      2.5. Delivery. Barracuda shall provide the Software and
                      Documentation to Licensee, through a reasonable system of
                      electronic download or via other media, in its discretion.
                      Delivery occurs when the Software is made available to
                      Licensee.
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>MAINTENANCE.</strong>
                </p>
                <ul className={classes.listHead}>
                  <li>
                    <p>
                      3.1. Subscriptions. Software maintenance subscriptions may
                      be purchased separately or bundled into the price of the
                      license. Licensee is not entitled to maintenance
                      subscriptions if they have not paid for them in full.
                      "Subscription Term" refers to the length of time for which
                      the maintenance subscription is purchased. The
                      Subscription Term is listed on Barracuda's price list.
                      Software updates and subscription information provided by
                      Barracuda through Energize Updates or other software
                      maintenance services are necessary to continue access to
                      the functionality of the Software and for continued proper
                      operation of the Software. Licensee acknowledges that
                      failure to pay for ongoing subscriptions will limit the
                      functionality of Software and Licensee Hardware. Obtaining
                      Software updates on systems where no valid subscription
                      has been purchased or obtaining functionality where
                      subscription has not been purchased is strictly forbidden
                      and in violation of this Agreement. All initial
                      subscriptions commence at the time of activation and all
                      renewals commence at the expiration of the previous valid
                      subscription. Unless otherwise expressly provided in the
                      documentation, Licensee shall use the Energize Updates and
                      other subscriptions solely as embedded in, for execution
                      on, or where the applicable documentation permits
                      installation on non-Barracuda equipment for communication
                      with Barracuda equipment owned or leased by Licensee. All
                      subscriptions are non-transferrable. Barracuda makes no
                      warranty that Software will continue un-interrupted.
                      Subscriptions may be suspended or terminated without
                      notice by Barracuda for lack of full payment.
                    </p>
                  </li>
                  <li>
                    <p>
                      3.2. Subscription Term. The Subscription Term shall renew
                      automatically for a period of the same duration unless
                      Licensee gives written notice of its intent not to renew
                      15 days before the end of the current Subscription Term.
                      Barracuda will automatically bill Licensee unless notified
                      15 days before the renewal date.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      3.3. Updates. During each Subscription Term, Barracuda
                      shall provide Licensee with copies of all Updates, without
                      additional charge, in accordance with the then-current
                      service and subscription terms. Upon delivery to Licensee,
                      each Update will constitute an element of the Software and
                      will thereafter be subject to this Agreement's terms
                      regarding Software, including without limitation license
                      and warranty terms.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      3.4. Support. Telephone, email and other forms of support
                      will be provided to Licensee as part of the subscription.
                      The hours of support vary based on country and the type of
                      support subscription purchased. Barracuda's Energize
                      Updates typically include Basic support.
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>FEES & REIMBURSEMENT.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      4.1. Fees. Licensee shall pay Barracuda the fees set forth
                      on the then current price list or Barracuda quote.
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      4.2. Invoices. Payment against all invoices will be due
                      within 30 days thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      4.3. Fees for Renewal Terms. Barracuda may increase the
                      License Fee and/or Subscription Fee for each renewed Term
                      or Subscription Term, provided Barracuda gives Licensee
                      notice of such increase before the end of the applicable
                      current Term or Subscription Term.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      4.4. Billing Disputes. Licensee must notify Barracuda of
                      any billing problems or discrepancies within sixty (60)
                      days of the date of Barracuda's invoice. Licensee agrees
                      that failure to do so will waive its right to dispute such
                      problems or discrepancies.{' '}
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>IP & FEEDBACK.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      5.1. IP Rights in the Software. Barracuda retains all
                      right, title, and interest in and to the Documentation and
                      Software, including without limitation Updates, except to
                      the extent of the limited licenses specifically set forth
                      in Sections 2.1 (License), and 2.3 (Documentation).
                      Licensee recognizes that the Software and its components
                      are protected by copyright and other laws.
                    </p>
                  </li>
                  <li>
                    <p>
                      5.2. Trademarks. Certain portions of the product and names
                      used in this Agreement, the Software and the Documentation
                      may constitute trademarks of Barracuda. Licensee is not
                      authorized to use any such trademarks for any purpose.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      5.3. Feedback. Licensee hereby grants Barracuda a
                      perpetual, irrevocable, worldwide license to use any
                      Feedback (as defined below) Licensee communicates to
                      Barracuda during the Term, without compensation, without
                      any obligation to report on such use, and without any
                      other restriction. Barracuda's rights granted in the
                      previous sentence include, without limitation, the right
                      to exploit Feedback in any and every way, as well as the
                      right to grant sublicenses. Notwithstanding the provisions
                      of Article 6 (Confidential Information) below, Feedback
                      will not be considered Licensee's Confidential
                      Information. ("Feedback" refers to any suggestion or idea
                      for modifying any of Barracuda's products or services,
                      including without limitation all Intellectual Property
                      Rights in any such suggestion or idea).{' '}
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>CONFIDENTIAL INFORMATION.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      {' '}
                      6.1. Definition. "Confidential Information" refers to the
                      following information disclosed by one party ("Discloser")
                      to the other ("Recipient"): (a) any document Discloser
                      marks "Confidential"; (b) any information Discloser orally
                      designates as "Confidential" at the time of disclosure, or
                      which given the circumstances of the disclosure and the
                      nature of the information would reasonably be considered
                      confidential; (c) the non-public features and functions of
                      the Software, for which Barracuda is Discloser; and (d)
                      any other nonpublic, sensitive information disclosed by
                      Discloser. Notwithstanding the foregoing, Confidential
                      Information does not include information that: (i) is in
                      Recipient's possession at the time of disclosure; (ii) is
                      independently developed by Recipient without use of or
                      reference to Confidential Information; (iii) becomes known
                      publicly, before or after disclosure, other than as a
                      result of Recipient's improper action or inaction; or (iv)
                      is approved for release in writing by Discloser.
                    </p>
                  </li>
                  <li>
                    <p>
                      6.2. Nondisclosure. Recipient shall not use Confidential
                      Information for any purpose other than to facilitate the
                      transactions contemplated by this Agreement (the
                      "Purpose"). Recipient: (a) shall not disclose Confidential
                      Information to any employee or contractor of Recipient
                      unless such person needs access in order to facilitate the
                      Purpose and executes a nondisclosure agreement with
                      Recipient with terms no less restrictive than those of
                      this Article 6; and (b) shall not disclose Confidential
                      Information to any other third party without Discloser's
                      prior written consent. Without limiting the generality of
                      the foregoing, Recipient shall protect Confidential
                      Information with the same degree of care it uses to
                      protect its own confidential information of similar nature
                      and importance, but with no less than reasonable care.
                      Recipient shall promptly notify Discloser of any misuse or
                      misappropriation of Confidential Information that comes to
                      Recipient's attention. Notwithstanding the foregoing,
                      Recipient may disclose Confidential Information as
                      required by applicable law or by proper legal or
                      governmental authority. Recipient shall give Discloser
                      prompt notice of any such legal or governmental demand and
                      reasonably cooperate with Discloser in any effort to seek
                      a protective order or otherwise to contest such required
                      disclosure, at Discloser's expense.
                    </p>
                  </li>
                  <li>
                    <p>
                      6.3. Injunction. Recipient agrees that breach of this
                      Article 6 would cause Discloser irreparable injury, for
                      which monetary damages would not provide adequate
                      compensation, and that in addition to any other remedy,
                      Discloser will be entitled to seek injunctive relief
                      against such breach or threatened breach, without proving
                      actual damage or posting a bond or other security.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      6.4. Termination & Return. With respect to each item of
                      Confidential Information, the obligations of Section 6.2
                      above (Nondisclosure) will terminate 3 years after the
                      date of disclosure. Upon termination of this Agreement,
                      Recipient shall return all copies of Confidential
                      Information to Discloser or certify, in writing, the
                      destruction thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                      6.5. Retention of Rights. This Agreement does not transfer
                      ownership of Confidential Information or grant a license
                      thereto. Discloser will retain all right, title, and
                      interest in and to all Confidential Information.{' '}
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>SOFTWARE AUDIT.</strong> During the Term of this
                  Agreement and at any time during the five (5) years
                  thereafter, Barracuda may audit Licensee's use of Software on
                  30 days' advance written notice. Licensee shall cooperate with
                  the audit, including by providing access to any books,
                  computers, records, or other information that relate or may
                  relate to use of Software. Such audit shall not unreasonably
                  interfere with Licensee's business activities. If Barracuda
                  discovers unauthorized use, reproduction, distribution, or
                  other exploitation of the restrictions for the Software, and
                  such unauthorized use exceeds 5%, then Licensee shall
                  reimburse Barracuda for the reasonable cost of the audit, or
                  of the next audit in case of discovery without an audit, in
                  addition to such other rights and remedies as Barracuda may
                  have. Barracuda may not conduct an audit more than once per
                  year.
                </p>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>REPRESENTATIONS &amp; WARRANTIES.</strong>
                </p>
                <ul>
                  <li>
                    <p>8.1 From Barracuda.</p>
                    <ul>
                      <li>
                        <p>
                          (a) Barracuda represents and warrants that, during the
                          30 day period following Delivery, the Software will
                          perform materially as described in its Specifications.
                        </p>
                      </li>
                      <li>
                        <p>
                          (b) Barracuda represents that, to its knowledge, at
                          the time of Delivery, the Software is free of what are
                          commonly defined as viruses, worms, spyware, malware
                          and other malicious code that may potentially hamper
                          performance of the Software.{' '}
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <p>
                      8.2. From Both Parties. Each party represents and warrants
                      that it has the full right and authority to enter into,
                      execute, and perform its obligations under this Agreement
                      without further consent of any third party.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      8.3. Warranty Disclaimers. Except for the express
                      warranties in Sections 8.1 and 8.2 above, BARRACUDA MAKES
                      NO WARRANTIES, EITHER EXPRESS IMPLIED OR STATUTORY,
                      INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES AND/OR
                      CONDITIONS OF MERCHANTABILITY, OF SATISFACTORY QUALITY, OR
                      FITNESS FOR A PARTICULAR PURPOSE, OF ACCURACY, AND OF
                      NON-INFRINGEMENT OF THIRD PARTY RIGHTS. Barracuda does not
                      warrant that the Software will perform without error or
                      that it will run without interruption. Barracuda provides
                      no warranty regarding, and will have no responsibility
                      for, any claim arising out of: (a) a modification of the
                      Software made by anyone other than Barracuda, unless
                      Barracuda approves such modification in writing; or (b)
                      use of the Software in combination with any operating
                      system not authorized in the Specifications or
                      Documentation or with hardware or software specifically
                      forbidden by the Specifications or Documentation. LICENSEE
                      EXPRESSLY ACKNOWLEDGES AND AGREES THAT THE USE OF THE
                      SOFTWARE IS AT ITS OWN RISK AND THAT THE ENTIRE RISK AS TO
                      SATISFACTION, QUALITY, PERFORMANCE, AND ACCURACY IS WITH
                      LICENSEE. BARRACUDA DOES NOT WARRANT THE CONTINUED
                      OPERATION OF THE SOFTWARE, THAT THE PERFORMANCE WILL MEET
                      LICENSEE'S EXPECTATIONS, THAT THE FUNCTIONS WILL MEET
                      LICENSEE REQUIREMENTS, THAT THE OPERATION WILL BE ERROR
                      FREE OR CONTINUOUS, THAT CURRENT OR FUTURE VERSIONS OF ANY
                      OPERATING SYSTEM WILL BE SUPPORTED, OR THAT DEFECTS WILL
                      BE CORRECTED. NO ORAL OR WRITTEN INFORMATION GIVEN BY
                      BARRACUDA OR ANY AUTHORIZED BARRACUDA REPRESENTATIVE SHALL
                      CREATE A WARRANTY. BARRACUDA HAS NO LIABIILTY FOR LOSS OF
                      DATA, OR FOR ERRORS, FAILURES OR DAMAGE WHICH WERE CAUSED
                      BY IMPROPER OPERATION, USE OF UNSUITABLE RESOURCES OR
                      ABNORMAL OPERATING CONDITIONS (IN PARTICULAR DEVIATIONS
                      FROM THE INSTALLATION CONDITIONS). Barracuda does not
                      warrant that the Software or any equipment, system or
                      network on which the Software is used will be free of
                      vulnerability to intrusion or attack.
                    </p>
                    <p>
                      The Software is not intended for use with any high risk or
                      strict liability activity, including, without limitation,
                      air or space travel, nuclear facilities, technical
                      building or structural design, power plant design or
                      operation, life support or emergency medical operations or
                      uses, or any other uses in which failure could lead to
                      death, personal injury, or environmental damage, and
                      Licensor makes no warranty and shall have no liability
                      arising from any use of the Licensor Software in any high
                      risk or strict liability activities.
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>LIMITATION OF LIABILITY.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      9.1. Limit. BARRACUDA'S LIABILITY ARISING OUT OF OR
                      RELATED TO THIS AGREEMENT WILL BE AS SET FORTH IN THE
                      BARRACUDA NETWORKS PRODUCT TERMS AND CONDITIONS LOCATED AT{' '}
                      <a href="https://www.barracuda.com/company/legal/prd_trm">
                        {' '}
                        PRODUCT TERMS
                      </a>
                    </p>
                  </li>
                  <li>
                    <p>
                      9.2. Exclusion of Consequential Damages. TO THE EXTENT NOT
                      PROHIBITED BY LAW, IN NO EVENT WILL EITHER PARTY BE LIABLE
                      TO LICENSEE FOR PERSONAL INJURY OR ANY CONSEQUENTIAL,
                      INDIRECT, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES
                      WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
                      LOSS OF PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR
                      ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR
                      RELATED TO THIS AGREEMENT, REGARDLESS OF THE THEORY OF
                      LIABILITY AND EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF
                      THE POSSIBILITY OF DAMAGES.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      9.3. Clarifications & Disclaimers. THE LIABILITIES LIMITED
                      BY THIS ARTICLE 9 APPLY: (a) TO LIABILITY FOR NEGLIGENCE;
                      (b) REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT,
                      TORT, STRICT PRODUCT LIABILITY, OR OTHERWISE; (c) EVEN IF
                      BARRACUDA IS ADVISED IN ADVANCE OF THE POSSIBILITY OF THE
                      DAMAGES IN QUESTION AND EVEN IF SUCH DAMAGES WERE
                      FORESEEABLE; AND (d) EVEN IF LICENSEE'S REMEDIES FAIL OF
                      THEIR ESSENTIAL PURPOSE. If applicable law limits the
                      application of the provisions of this Article 9,
                      Barracuda's liability will be limited to the maximum
                      extent permissible. For the avoidance of doubt,
                      Barracuda's liability limits and other rights set forth in
                      this Article 9 apply likewise to Barracuda's affiliates,
                      licensors, suppliers, advertisers, agents, sponsors,
                      directors, officers, employees, consultants, and other
                      representatives.{' '}
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>TERM &amp; TERMINATION.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      10.1. Term. This Agreement shall be effective upon the
                      Effective Date and will end (a) upon the expiration of its
                      stated term; (b) upon Licensee's non-renewal of the
                      licenses; (c) upon Licensee's cancellation of the license;
                      or (d) when Licensor cancels or terminates the license,
                      pursuant to section 10.2, whichever occurs first. Any such
                      expiration, cancellation, discontinuation, or termination
                      are referred to hereafter as "termination. If the Software
                      is a time-based license, Licensee expressly acknowledges
                      that the Software will stop functioning at the time the
                      license expires unless renewed or extended as set forth in
                      this Agreement.
                    </p>
                  </li>
                  <li>
                    <p>
                      10.2. Termination for Cause. Either party may terminate
                      this Agreement for the other's material breach by written
                      notice, effective in 30 days unless the other party first
                      cures such breach.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      10.3. Effects of Termination. Upon termination of this
                      Agreement, Licensee shall cease all use of the Software
                      and delete, destroy, or return all copies of the Software
                      and Documentation in its possession or control. The
                      following provisions will survive termination or
                      expiration of this Agreement: (a) any obligation of
                      Licensee to pay fees incurred before termination; (b)
                      Articles and Sections 2.3 (Restrictions on Software
                      Rights) 5 (IP & Feedback), 6 (Confidential Information), 7
                      (Software Audit), 8.3 (Warranty Disclaimers), 9
                      (Limitation of Liability), 10 (Term and Termination), and
                      11 ("Collection of Data"); and (c) any other provision of
                      this Agreement that must survive to fulfill its essential
                      purpose.
                    </p>
                  </li>
                </ul>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>COLLECTION OF DATA.</strong> Licensee agrees to allow
                  Barracuda to collect information ("Statistics") from the
                  Software in order to fight spam, virus, and other threats as
                  well as optimize and monitor the Software. Information will be
                  collected electronically and automatically. Statistics
                  include, but are not limited to, the number of messages
                  processed, the number of messages that are categorized as
                  spam, the number of virus and types, IP addresses of the
                  largest spam senders, the number of emails classified for
                  Bayesian analysis, capacity and usage, websites not
                  categorized, fingerprints of emails, and other statistics.
                  Licensee data will be kept private and will only be reported
                  in the aggregate by Barracuda. Barracuda may transfer personal
                  information and data outside of the European Union for
                  purposes of providing software and subscription maintenance
                  services, and Licensee consents to such use and transfer of
                  personal information and data.
                </p>
              </li>
              <li className={classes.listHead}>
                <p>
                  <strong>MISCELLANEOUS.</strong>
                </p>
                <ul>
                  <li>
                    <p>
                      12.1. Independent Contractors. The parties are independent
                      contractors and will so represent themselves in all
                      regards. Neither party is the agent of the other, and
                      neither may make commitments on the other's behalf.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.2. Notices. Notices pursuant to this Agreement shall be
                      sent to the addresses below, or to such others as either
                      party may provide in writing. Such notices will be deemed
                      received at such addresses upon the earlier of (a) actual
                      receipt or (b) delivery in person, by fax with written
                      confirmation of receipt, or by certified mail return
                      receipt requested. For Barracuda: attn.: Legal Department,
                      Barracuda Networks, 3175 S. Winchester Blvd, Campbell, CA
                      95008.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      12.3. Force Majeure. No delay, failure, or default, other
                      than a failure to pay fees when due, will constitute a
                      breach of this Agreement to the extent caused by acts of
                      war, terrorism, hurricanes, earthquakes, other acts of God
                      or of nature, strikes or other labor disputes, riots or
                      other acts of civil disorder, embargoes, or other causes
                      beyond the performing party's reasonable control.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.4. Assignment & Successors. Licensee may not assign
                      this Agreement or any of its rights or obligations
                      hereunder without Barracuda's express written consent.
                      Except to the extent forbidden in this Section, this
                      Agreement will be binding upon and inure to the benefit of
                      the parties' respective successors and assigns.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.5. Severability. To the extent permitted by applicable
                      law, the parties hereby waive any provision of law that
                      would render any clause of this Agreement invalid or
                      otherwise unenforceable in any respect. In the event that
                      a provision of this Agreement is held to be invalid or
                      otherwise unenforceable, such provision will be
                      interpreted to fulfill its intended purpose to the maximum
                      extent permitted by applicable law, and the remaining
                      provisions of this Agreement will continue in full force
                      and effect.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.6. No Waiver. Neither party will be deemed to have
                      waived any of its rights under this Agreement by lapse of
                      time or by any statement or representation other than by
                      an authorized representative in an explicit written
                      waiver. No waiver of a breach of this Agreement will
                      constitute a waiver of any other breach of this Agreement.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.7. Government Restricted Rights. The Software is
                      provided with Restricted Rights. Use, duplication, or
                      disclosure for or by the government of the United States,
                      including without limitation any of its agencies or
                      instrumentalities, is subject to restrictions set forth,
                      as applicable: (i) in subparagraphs (a) through (d) of the
                      Commercial Computer Software-Restricted Rights clause at
                      FAR 52.227-19; or (ii) in similar clauses in other federal
                      regulations, including the NASA FAR supplement. The
                      contractor or manufacturer is Barracuda. Licensee shall
                      not remove or deface any restricted rights notice or other
                      legal notice appearing in the Software or on any packaging
                      or other media associated with the Software.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.8. Choice of Law & Jurisdiction: This Agreement will be
                      governed solely by the laws of the State of California
                      without reference to: (a) any conflicts of law principle
                      that would apply the substantive laws of another
                      jurisdiction to the parties' rights or duties; (b) the
                      1980 United Nations Convention on Contracts for the
                      International Sale of Goods; or (c) other international
                      laws. The parties consent to the personal and exclusive
                      jurisdiction of the federal and state courts of Santa
                      Clara, California.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.9. Dispute Resolution. IF YOU LIVE IN THE UNITED
                      STATES, THIS SECTION CONTAINS A BINDING ARBITRATION CLAUSE
                      AND CLASS ACTION WAIVER. IT AFFECTS YOUR RIGHTS ABOUT HOW
                      TO RESOLVE ANY DISPUTE WITH BARRACUDA. PLEASE READ IT.{' '}
                    </p>
                    <p>
                      The parties will attempt to resolve any claim, or dispute
                      or controversy (whether in contract, tort or otherwise)
                      against Barracuda, its agents, employees, successors,
                      assigns or affiliates (collectively for purposes of this
                      paragraph, "Barracuda") arising out of or relating to this
                      Agreement, Barracuda advertising, or any related purchase
                      (a "Dispute") through face to face negotiation with
                      persons fully authorized to resolve the Dispute or through
                      mediation utilizing a mutually agreeable mediator, rather
                      than through litigation. If the parties are unable to
                      resolve the Dispute through negotiation or mediation
                      within a reasonable time after written notice from one
                      party to the other that a Dispute exists, the Dispute will
                      be settled by binding arbitration in accordance with the
                      then current AAA Rules and the parties agree to share the
                      arbitration costs equally. The Arbitration will be
                      conducted before three (3) independent and impartial
                      arbitrators. Barracuda will appoint one (1) arbitrator and
                      the other party or parties will appoint one (1)
                      arbitrator. The two (2) appointed arbitrators will then
                      select a third arbitrator, who shall be the presiding
                      arbitrator. The arbitration hearing shall take place in
                      Cupertino, California and will be governed by the United
                      States Federal Arbitration Act to the exclusion of any
                      inconsistent state laws. The arbitrators shall base their
                      award on the terms of this Agreement, and will follow the
                      law and judicial precedents that a United States District
                      Judge sitting in the county of Santa Clara would apply to
                      the Dispute. The arbitrators shall render their award in
                      writing and will include the findings of fact and
                      conclusion of law upon which their award is based.
                      Judgment upon the arbitration award may be entered by any
                      court of competent jurisdiction. The existence or results
                      of any negotiation, mediation or arbitration will be
                      treated as confidential. Notwithstanding the foregoing,
                      either party will have the right to obtain from a court of
                      competent jurisdiction a temporary restraining order,
                      preliminary injunction or other equitable relief to
                      preserve the status quo or prevent irreparable harm,
                      although the merits of the underlying Dispute will be
                      resolved in accordance with this paragraph. THE PARTIES
                      AGREE TO ARBITRATE SOLELY ON AN INDIVIDUAL BASIS, AND THAT
                      THIS AGREEMENT DOES NOT PERMIT CLASS ARBITRATION OR ANY
                      CLAIMS BROUGHT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS
                      OR REPRESENTATIVE ARBITRATION PROCEEDING. THE ARBITRAL
                      TRIBUNAL MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S
                      CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER ANY FORM OF A
                      REPRESENTATIVE OR CLASS PROCEEDING.
                    </p>
                  </li>
                  <li>
                    <p>
                      {' '}
                      12.10. Construction. This Agreement will not be construed
                      in favor of or against either party by reason of
                      authorship.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.11. Changes. Barracuda reserves the right at any time
                      not to release, or to discontinue release of, any Software
                      or subscription and to alter prices, features,
                      specifications, capabilities, functions, licensing terms,
                      release dates, general availability or other
                      characteristics of any future releases of the Software or
                      subscriptions.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.12. Technology Export. Licensee shall not: (a) permit
                      any third party to access or use the Software in violation
                      of any U.S. law or regulation; or (b) export the Software
                      or otherwise remove it from the United States except with
                      the prior written consent of Barracuda and in compliance
                      with all applicable U.S. laws and regulations. Without
                      limiting the generality of the foregoing, Licensee shall
                      not permit any third party to access or use the Software
                      in, or export it to, a country subject to a United States
                      embargo (as of the Effective Date, Cuba, Iran, North
                      Korea, Sudan, and Syria); or (c) transfer or authorize the
                      transfer of the Software to a prohibited territory or
                      country or otherwise in violation of any applicable
                      restrictions or regulations. If Licensee is a United
                      States Government agency, the Software and documentation
                      qualify as "commercial items", as that term is defined at
                      Federal Acquisition Regulation ("FAR") (48 C.F.R.) 2.101,
                      consisting of "commercial computer software" and
                      "commercial computer software documentation" as such terms
                      are used in FAR 12.212. Consistent with FAR 12.212 and DoD
                      FAR Supp. 227.7202-1 through 227.7202-4, and
                      notwithstanding any other FAR or other contractual clause
                      to the contrary in any agreement into which this Agreement
                      may be incorporated, Government end user will acquire the
                      Software and documentation with only those rights set
                      forth in this Agreement. Use of either the Software or
                      documentation or both constitutes an agreement by the
                      Government that the Software and documentation are
                      "commercial computer software" and "commercial computer
                      software documentation", and constitutes acceptance of the
                      rights and restrictions herein.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.13. Entire Agreement. This Agreement sets forth the
                      entire agreement of the parties and supersedes all prior
                      or contemporaneous writings, negotiations, and discussions
                      with respect to its subject matter. Neither party has
                      relied upon any such prior or contemporaneous
                      communications.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.14. Execution in Counterparts. This Agreement may be
                      executed in one or more counterparts. Each counterpart
                      will be an original, but all such counterparts will
                      constitute a single instrument.{' '}
                    </p>
                  </li>
                  <li>
                    <p>
                      12.15. Amendment. This Agreement may not be amended except
                      through a written agreement by authorized representatives
                      of each party.
                    </p>
                  </li>
                  <li>
                    <p>
                      12.16. Open Source Licensing. Barracuda products may
                      include programs that are covered by the GNU General
                      Public License (GPL), The Lesser General Public License
                      (LGPL), or other Open Source license agreements, in
                      particular the Linux operating system. The Software does
                      not constitute an edited version or further development of
                      the operating system. These programs are copyrighted by
                      their authors or other parties, and the authors and
                      copyright holders disclaim any warranty for such programs.
                      Barracuda owns the copyright in other programs. In
                      addition, some Software which supports Bare Metal Disaster
                      Recovery of Microsoft Windows Vista and Microsoft Windows
                      2008 Operating Systems (DR6) contains and uses components
                      of the Microsoft Windows Pre-Installation Environment
                      (WINPE) with the following restrictions: (i) the WINPE
                      components in the DR6 product are licensed and not sold
                      and may only be used with the DR6 product; (ii) DR6 is
                      provided "as is"; (iii) Barracuda and its suppliers
                      reserve all rights not expressly granted; (iv) license to
                      use DR6 and the WINPE components is limited to use of the
                      product as a recovery utility program only and not for use
                      as a general purpose operating system; (v) Reverse
                      engineering, decompiling or disassembly of the WINPE
                      components, except to the extent expressly permitted by
                      applicable law, is prohibited; (vi) DR6 contains a
                      security feature from Microsoft that will automatically
                      reboot the system without warning after 24 hours of
                      continuous use; (vii) Barracuda alone will provide support
                      for customer issues involving DR6 and Microsoft, and its
                      Affiliates are released of all liability related to its
                      use and operation; and, (viii) DR6 is subject to U.S.
                      export jurisdiction.
                    </p>
                  </li>
                </ul>
              </li>
            </Grid>
            <Grid item container justify="flex-end" className={classes.footer}>
              <div>
                <ActionButton
                  data-test-id="btn-agree"
                  onClick={() => handleAgree(history)}
                  inProgress={processing}
                  inProgressText=""
                  disabled={!this.state.reachedBottom || processing}
                >
                  <Translate value="common.agree" />
                </ActionButton>
              </div>
              {error && <ErrorMessage error={error} />}
            </Grid>
          </Grid>
        </div>
      </form>
    );
  }
}

export default withStyles(styles, { name: 'LicenseAgreement' })(
  LicenseAgreement
);
