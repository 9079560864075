import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

const AccountFooter = ({ classes, accountID, serial }) => (
  <div className={classes.container}>
    <span className={classes.account}>
      {accountID && (
        <Translate value="components.footer.accountID" accountID={accountID} />
      )}
    </span>
    {serial && <Translate value="components.footer.serial" serial={serial} />}
  </div>
);

const styles = {
  container: {
    marginTop: '10px',
    background: '#EEEEEE',
    boxShadow: '0 -1px 0 0 #DDDDDD',
    textAlign: 'left',
    alignItems: 'center',
    padding: '0px 15px 0px 15px',
    fontSize: '12px',
    lineHeight: '24px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  account: {
    marginRight: '30px',
  },
};

AccountFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  accountID: PropTypes.any.isRequired,
  serial: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'AccountStyles' })(AccountFooter);
