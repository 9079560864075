import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import ADGroupSelection from '../../containers/advanced_filtering/ADGroupSelection';
import ActionSelection from '../../containers/advanced_filtering/ActionSelection';
import TypeSelection from '../../containers/advanced_filtering/TypeSelection';
import CategoryPolicy from '../../containers/advanced_filtering/CategoryPolicy';
import DomainInput from '../../containers/advanced_filtering/DomainInput';
import UrlInput from '../../containers/advanced_filtering/UrlInput';

const AddPolicy = ({ classes, display, disabledAD }) => (
  <Grid id="add-advance-policy" container spacing={0}>
    <Grid item xs={12} className={classes.levelContainer}>
      <ADGroupSelection disabled={disabledAD} />
    </Grid>
    <Grid item xs={12} className={classes.addContainer}>
      <ActionSelection data-test-id="action-selection" />
      <TypeSelection data-test-id="type-selection" />
      {display === 'categories' && <CategoryPolicy />}
      {display === 'domains' && <DomainInput />}
      {display === 'url' && <UrlInput />}
    </Grid>
  </Grid>
);

AddPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  display: PropTypes.string.isRequired,
  disabledAD: PropTypes.bool,
};

AddPolicy.defaultProps = {};

const styles = {
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'AddPolicy' })(AddPolicy);
