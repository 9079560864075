import * as Types from '../actions/app';

export default (
  state = {
    result: 'success', // success or failure
    error: {
      message: null,
    },
    success: {
      message: null,
    },
    show: false,
  },
  action
) => {
  switch (action.type) {
    case Types.APP_SUCCESS:
      return {
        ...state,
        result: 'success',
        error: {
          message: null,
        },
        success: {
          message: action.message,
        },
        show: true,
      };
    case Types.APP_ERROR:
      return {
        ...state,
        result: 'failure',
        error: {
          ...state.error,
          message: action.message,
        },
        success: {
          message: null,
        },
        show: true,
      };
    case Types.CLEAR_ERROR:
      return {
        ...state,
        error: {
          ...state.error,
          message: null,
        },
        show: false,
      };
    default:
      return state;
  }
};
