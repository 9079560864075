import { connect } from 'react-redux';
import Signup from '../../components/signup/Signup';
import { submitEmail, updateEmailAddress } from '../../actions/signup';

const mapStateToProps = state => ({
  page: state.signup.page,
  form: state.signup.userForm,
  email: state.signup.email,
});

const mapDispatchToProps = dispatch => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
