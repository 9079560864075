import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import GroupIcon from '@material-ui/icons/Group';
import Chip from '@material-ui/core/Chip';
import { WebIcon } from '../icons';

const MultiValueContainer = props => {
  let icon = null;

  if (props.data.type === 'user') {
    icon = <PersonOutlineIcon />;
  } else if (props.data.type === 'group') {
    icon = <GroupIcon />;
  } else if (props.data.type === 'everyone') {
    icon = <WebIcon />;
  }

  return (
    <components.MultiValueContainer {...props}>
      <Chip
        avatar={
          <div
            style={{
              width: '14px',
              height: '20px',
              marginLeft: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {icon}
          </div>
        }
        key={props.data.value}
        label={props.data.label}
      />
    </components.MultiValueContainer>
  );
};

const Option = props => {
  let icon = null;

  if (props.data.type === 'user') {
    icon = <PersonOutlineIcon />;
  } else if (props.data.type === 'group') {
    icon = <GroupIcon />;
  } else if (props.data.type === 'everyone') {
    icon = <WebIcon />;
  }
  return (
    <components.Option {...props}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: '5px' }}>{icon}</div>
        {props.children}
      </div>
    </components.Option>
  );
};

let ADTimeoutPtr;

const ADGroupSelection = ({
  classes,
  asyncCallback,
  activeDirectoryOptions,
  inputValue,
  onChange,
  multi,
  disabled,
}) => (
  <Grid container spacing={16} alignItems="flex-end">
    <Grid item xs={12} data-test-id="ad-selection">
      <AsyncSelect
        isMulti={multi}
        isDisabled={disabled}
        isClearable={false}
        name="ad-selection"
        placeholder={translate('components.advancedFiltering.adPlaceholder')}
        components={{ MultiValueContainer, Option }}
        styles={{
          multiValue: base => ({ ...base, backgroundColor: 'transparent' }),
        }}
        cacheOptions
        loadOptions={(input, callback) => {
          clearTimeout(ADTimeoutPtr);
          ADTimeoutPtr = setTimeout(() => {
            if (asyncCallback) {
              asyncCallback(input, callback);
              return;
            }

            callback(
              activeDirectoryOptions
                .filter(a =>
                  a.label
                    ? a.label.toLowerCase().includes(input.toLowerCase())
                    : false
                )
                .slice(0, 100)
            );
          }, 1000);
        }}
        defaultOptions={activeDirectoryOptions.slice(0, 100)}
        options={
          activeDirectoryOptions.length > 100
            ? activeDirectoryOptions.slice(0, 100)
            : activeDirectoryOptions
        }
        className="basic-multi-select"
        classNamePrefix="select"
        getValue={value => {}}
        value={inputValue}
        onChange={selectedOptions => {
          onChange(selectedOptions);
        }}
      />
    </Grid>
  </Grid>
);

ADGroupSelection.propTypes = {
  classes: PropTypes.object.isRequired,
  inputValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  activeDirectoryOptions: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  multi: PropTypes.bool,
};

ADGroupSelection.defaultProps = {
  disabled: false,
  multi: true,
};

const styles = theme => ({});

export default withStyles(styles, { name: 'ADGroupSelection' })(
  ADGroupSelection
);
