import { connect } from 'react-redux';
import CudaMenu from '../../components/CudaMenu';

const mapStateToProps = state => ({
  options: state.wfLogs.timeOptions,
  selectedIndex: state.wfLogs.timeSelectedIndex,
});

export default connect(
  mapStateToProps,
  undefined
)(CudaMenu);
