import { connect } from 'react-redux';
import AddLocalUsers from '../../components/user_authentication/AddLocalUsers';
import { updateModalData } from '../../actions/user_authentication';

import {
  getGroupList,
  getModalData,
} from '../../selectors/user_authentication';

const mapStateToProps = state => ({
  groups: getGroupList(state),
  data: getModalData(state),
  saving: state.userAuthentication.modal.processing,
});

const mapDispatchToProps = dispatch => ({
  updateUsers: e => {
    dispatch(updateModalData('users', e.target.value));
  },
  updateGroup: group => {
    dispatch(updateModalData('group', group));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddLocalUsers);
