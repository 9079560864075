export const AGENT_SETTINGS_GET_INFO = 'AGENT_SETTINGS_GET_INFO';
export const AGENT_SETTINGS_GET_INFO_SUCCESS =
  'AGENT_SETTINGS_GET_INFO_SUCCESS';
export const AGENT_SETTINGS_GET_INFO_FAILURE =
  'AGENT_SETTINGS_GET_INFO_FAILURE';
export const AGENT_SETTINGS_UPDATE_ACCOUNT_OPTION =
  'AGENT_SETTINGS_UPDATE_ACCOUNT_OPTION';
export const AGENT_SETTINGS_RESET = 'AGENT_SETTINGS_RESET';
export const AGENT_SETTINGS_SAVE = 'AGENT_SETTINGS_SAVE';
export const AGENT_SETTINGS_SAVE_SUCCESS = 'AGENT_SETTINGS_SAVE_SUCCESS';
export const AGENT_SETTINGS_SAVE_FAILURE = 'AGENT_SETTINGS_SAVE_FAILURE';

export const getAccountInfo = () => ({
  type: AGENT_SETTINGS_GET_INFO,
});

export const getAccountInfoSuccess = data => ({
  type: AGENT_SETTINGS_GET_INFO_SUCCESS,
  ...data,
});

export const getAccountInfoFailure = error => ({
  type: AGENT_SETTINGS_GET_INFO_FAILURE,
  error,
});

export const updateAccountOption = options => ({
  type: AGENT_SETTINGS_UPDATE_ACCOUNT_OPTION,
  options,
});

export const resetSettings = () => ({
  type: AGENT_SETTINGS_RESET,
});

export const saveSettings = () => ({
  type: AGENT_SETTINGS_SAVE,
});
export const saveSettingsSuccess = result => ({
  type: AGENT_SETTINGS_SAVE_SUCCESS,
  result,
});
export const saveSettingsFailure = errors => ({
  type: AGENT_SETTINGS_SAVE_FAILURE,
  errors,
});
