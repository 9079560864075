import { connect } from 'react-redux';
import ThreatPieChart from '../../components/overview/ThreatPieChart';
import { timeOptions } from '../../actions/mspdashboard';

const mapStateToProps = (state, ownProps) => ({
  options: timeOptions,
  selectedIndex: state.mspDashboard.fileTypeSelectIndex,
  data: state.mspDashboard.fileTypeData,
  loading: state.mspDashboard.fileTypeLoading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThreatPieChart);
