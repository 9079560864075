import { connect } from 'react-redux';
import CopyAccountModal from '../../components/shared_policies/CopyAccountModal';

import { Mixpanel } from '../../lib/Mixpanel';
import { closeCopyModal } from '../../actions/shared_policies';

import { getChangedFields } from '../../selectors/smb_account_settings';

const mapStateToProps = state => {
  let disabled = true;

  let changeAccount = false;
  if (state.shared_policies.editFields.updatedAccountSelections.length) {
    if (
      state.shared_policies.editFields.accountSelections.length !==
      state.shared_policies.editFields.updatedAccountSelections.length
    ) {
      changeAccount = true;
    } else {
      let a = state.shared_policies.editFields.accountSelections.map(
        x => x.value
      );
      let b = state.shared_policies.editFields.updatedAccountSelections.map(
        x => x.value
      );
      changeAccount = a.sort().toString() !== b.sort().toString();
    }
  }
  return {
    disabled: true,
    loading: state.shared_policies.copyEditModal.loading,
    showModal: state.shared_policies.copyEditModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {},
  closeDialog: () => {
    dispatch(closeCopyModal());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyAccountModal);
