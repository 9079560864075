import { connect } from 'react-redux';
import Dashboard from '../components/Dashboard';

const mapStateToProps = (state, ownProps) => ({
  subscriptions: state.account.subscriptions,
});

export default connect(
  mapStateToProps,
  undefined
)(Dashboard);
