import { connect } from 'react-redux';
import EditEveryonePolicy from '../../components/advanced_filtering/EditEveryonePolicy';

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  undefined
)(EditEveryonePolicy);
