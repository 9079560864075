import { connect } from 'react-redux';
import TopThreatChart from '../../components/overview/TopThreatChart';
import { onTopThreatChange } from '../../actions/mspdashboard';

const mapStateToProps = (state, ownProps) => ({
  selectedIndex: state.mspDashboard.topThreatsInfo.selectIndex,
  data: state.mspDashboard.topThreatsInfo.data,
  loading: state.mspDashboard.topThreatsInfo.loading,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: (range, index) => {
    dispatch(onTopThreatChange(range, index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopThreatChart);
