import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Translate, translate } from 'react-i18nify';
import Input from '@material-ui/core/Input/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { FolderIcon, FileIcon, MenuIcon, EditIcon, DeleteIcon } from '../icons';

const scanExclustionTableStyles = {
  typeItem: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '5px',
    },
  },
  menuItem: {
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
  menuItemRemove: {
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '22px',
      marginLeft: '-4px',
    },
    color: '#DD1E31',
  },
  contentRow: {
    width: '100%',
  },
};

class ScanExclusionTableRowComponent extends Component {
  state = {
    open: false,
    anchorEl: null,
  };

  render() {
    const {
      classes,
      type,
      name,
      removeAction,
      removeCustomExclusion,
      showEditPopUp,
    } = this.props;

    return (
      <TableRow>
        <TableCell>
          <span className={classes.typeItem}>
            {type === 'FILE' && <FileIcon color="#5F5F5F" />}
            {type === 'PATH' && <FolderIcon color="#5F5F5F" />}
            {type}
          </span>
        </TableCell>
        <TableCell className={classes.contentRow}>{name}</TableCell>
        <TableCell>
          <MenuIcon
            onClick={e => {
              this.setState({ open: true, anchorEl: e.currentTarget });
            }}
          />
          <Menu
            open={this.state.open}
            transitionDuration={50}
            anchorEl={this.state.anchorEl}
            onRequestClose={() => {
              this.setState({ open: false, anchorEl: null });
            }}
          >
            <MenuItem
              className={classes.menuItem}
              onClick={() => {
                showEditPopUp(removeAction, name);
                this.setState({ open: false, anchorEl: null });
              }}
            >
              <EditIcon />
              <Translate value="settings.webscraping.edit" />
            </MenuItem>
            <MenuItem
              className={classes.menuItemRemove}
              onClick={() => {
                removeCustomExclusion(removeAction, name);
                this.setState({ open: false, anchorEl: null });
              }}
            >
              <DeleteIcon color="#DD1E31" />
              <Translate value="settings.webscraping.remove" />
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>
    );
  }
}

export default withStyles(scanExclustionTableStyles, {
  name: 'ScanExclusionTableRow',
})(ScanExclusionTableRowComponent);
