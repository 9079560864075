import { connect } from 'react-redux';
import CategoryStats from '../../components/dashboard/CategoryStats';
import { updateModule } from '../../actions/dashboard';

const mapStateToProps = (state, ownProps) => ({
  selectedIndex: state.atp_shared.timeSelectIndex,
  loading: state.dashboard.blocked_infoLoading,
  displayBlockRequest:
    'displayBlockRequest' in ownProps ? ownProps.displayBlockRequest : true,
});

const mapDispatchToProps = dispatch => ({
  changeTimerange: timeRange => {
    dispatch(updateModule('blocked_info', timeRange));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryStats);
