import React from 'react';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  container: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: '#FFFFFF',
  },
  containerPadded: {
    padding: '20px 20px',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
    opacity: 0.7,
    zIndex: 5,
  },
  loadingAnimation: {
    alignSelf: 'center',
  },
  title: {
    fontSize: 24,
    lineHeight: '32px',
    color: theme.colors.primaryText,
    fontWeight: 300,
  },
  subTitle: {
    fontSize: 12,
    lineHeight: '16px',
    color: theme.colors.secondaryText,
    marginBottom: '20px',
    fontWeight: 'normal',
  },
});

const CudaPaper = ({
  classes,
  children,
  loading = false,
  loadingAnimationSize = 40,
  disabled,
  padding = false,
  title,
  subTitle,
  elevation = 2,
  className,
  titleClasses = [],
  ...props
}) => (
  <Paper
    className={classNames({
      [className]: true,
      [classes.container]: true,
      [classes.containerPadded]: padding,
    })}
    elevation={elevation}
    {...props}
  >
    {loading && (
      <div className={classes.overlay}>
        <div className={classes.loadingAnimation}>
          <CircularProgress size={loadingAnimationSize} />
        </div>
      </div>
    )}
    {disabled && <div className={classes.overlay} />}

    {title && (
      <div className={classNames(...titleClasses, classes.title)}>
        {title}
        {subTitle && <div className={classes.subTitle}>{subTitle}</div>}
      </div>
    )}

    {children}
  </Paper>
);

export default withStyles(styles, { name: 'CudaPaper' })(CudaPaper);
