import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';

import Modal from '../modal/Modal';

const styles = theme => ({
  logo: {
    width: '140px',
    height: '70px',
  },
  logoWrapper: {
    border: '1px solid #DDDDDD',
    width: '160px',
    height: '80px',
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  infoText: {
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  previewText: {
    marginBottom: '8px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
});

class SMBAccountSettings extends React.Component {
  constructor() {
    super();
  }

  render() {
    const {
      classes,
      show,
      closeDialog,
      processing,
      recategorizeDomainConfirm,
    } = this.props;

    return (
      <div>
        <Modal
          name="recategorize-modal"
          showModal={show}
          title={translate(
            'components.smbAccountSettings.recategorizeDomainConfirmModal'
          )}
          saveBtnText={translate(
            'components.smbAccountSettings.recategorizeDomainConfirmBtn'
          )}
          processing={processing}
          closeDialog={closeDialog}
          buttonAction={recategorizeDomainConfirm}
        >
          <div> Click on Confirm to submit your request. </div>
        </Modal>
      </div>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  recategorizeDomainConfirm: PropTypes.func.isRequired,
};

SMBAccountSettings.defaultProps = {
  selectedOption: PropTypes.string.isRequired,
};

export default withStyles(styles)(SMBAccountSettings);
