import React from 'react';
import PropTypes from 'prop-types';
import { translate, Translate } from 'react-i18nify';

import ReactGA from 'react-ga';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const styles = theme => ({
  tableTd: {
    paddingRight: '5px',
  },
  menuOptions: {
    color: '#5F5F5F',
    padding: '10px 25px',
    '& svg': {
      height: '20px',
      marginRight: '25px',
    },
  },
});

class MoreOption extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handlePdfClick = cuid => {
    const { downloadPdf } = this.props;
    ReactGA.event({
      category: 'ATPLogs',
      action: 'View Report',
    });
    downloadPdf(cuid);
    this.setState({ moreOpen: false, moreAnchorEl: null });
  };

  render() {
    const {
      classes,
      file,
      cuid,
      openReportThreatModal,
      features,
      wca,
    } = this.props;
    const { anchorEl } = this.state;

    return (
      <div className={classes.tableTd}>
        <IconButton
          data-test-id="more-icon"
          aria-label="More"
          aria-owns={null}
          aria-haspopup="true"
          onClick={this.handleClick}
          disabled={false}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          <MenuItem
            data-test-id="view-threat"
            className={classes.menuOptions}
            onClick={() => {
              this.handleClose();
              this.handlePdfClick({ cuid });
            }}
          >
            <Translate value="components.atpLogs.viewThreat" />
          </MenuItem>
          <MenuItem
            data-test-id="report-threat"
            className={classes.menuOptions}
            onClick={() => {
              this.handleClose();
              openReportThreatModal(file, cuid);
            }}
          >
            <Translate value="components.atpLogs.reportThreat" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

MoreOption.propTypes = {
  classes: PropTypes.object.isRequired,
  wca: PropTypes.bool.isRequired,
  features: PropTypes.array.isRequired,
};

export default withStyles(styles)(MoreOption);
