import { createSelector } from 'reselect';

const getLocations = state => state.wfLogs.locations;

const getSortedLocations = createSelector(
  [getLocations],
  locations => {
    const locs = [];
    Object.keys(locations).forEach(location => {
      locs.push({
        key: location,
        label: locations[location],
      });
    });

    return locs
      .map(loc => ({ label: loc.label, value: loc.key }))
      .sort((a, b) => (a.label || '').localeCompare(b.label));
  }
);

export { getSortedLocations };
