import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import SettingsItem from '../settings/SettingsItem';

import { CudaDialogTitle } from '../CudaDialog';
import CudaMenu from '../CudaMenu';

const ScheduleScanModal = ({
  classes,
  title,
  onClose,
  timeOptions,
  frequencyOptions,
  reportIndex,
  timeframeIndex,
  selectTimeframe,
  frequencyIndex,
  selectScanFrequency,
  selectScanHour,
  selectScanWeek,
  selectScanDay,
  onChangeRecipients,
  hourOptions,
  hourIndex,
  selectHour,
  weekOptions,
  weekIndex,
  selectWeek,
  dayOptions,
  dayIndex,
  selectDay,
  onSchedule,
  timezone,
  scheduleScanEnabled,
  toggleScheduleScan,
  ...other
}) => {
  let hourList = [];
  hourOptions.forEach(hour => {
    hourList.push({
      key: hour['key'],
      label: hour['label'] + ' (' + timezone + ')',
    });
  });

  return (
    <Dialog {...other} fullWidth={true} maxWidth="md">
      <CudaDialogTitle>{title}</CudaDialogTitle>
      <DialogContent>
        <Grid container spacing={2} className={classes.container}>
          <Grid item className={classes.frequencyWrapper} xs={12}>
            <SettingsItem
              processing={false}
              text={<Translate value="components.atp.enableScheduleScan" />}
              toggled={scheduleScanEnabled}
              onToggle={toggleScheduleScan}
              padding={false}
              labels={['components.shared.on', 'components.shared.off']}
            />
          </Grid>
          <Grid item xs={3}>
            <Translate value="components.reporting.frequency" />
          </Grid>
          <Grid item xs={9} className={classes.frequencyWrapper}>
            <CudaMenu
              options={frequencyOptions}
              onChange={selectScanFrequency}
              customClasses={[classes.flexMenu]}
              selectedIndex={frequencyIndex}
              disabled={!scheduleScanEnabled}
            />
            {frequencyOptions[frequencyIndex]['key'] === 'daily' ? (
              <CudaMenu
                options={hourList}
                onChange={selectScanHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
                disabled={!scheduleScanEnabled}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ? (
              <CudaMenu
                options={weekOptions}
                onChange={selectScanWeek}
                customClasses={[classes.flexMenu]}
                selectedIndex={weekIndex}
                disabled={!scheduleScanEnabled}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={dayOptions}
                onChange={selectScanDay}
                customClasses={[classes.flexMenu]}
                selectedIndex={dayIndex}
                disabled={!scheduleScanEnabled}
              />
            ) : null}
            {frequencyOptions[frequencyIndex]['key'] === 'weekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'biweekly' ||
            frequencyOptions[frequencyIndex]['key'] === 'monthly' ? (
              <CudaMenu
                options={hourList}
                onChange={selectScanHour}
                customClasses={[classes.flexMenu]}
                selectedIndex={hourIndex}
                disabled={!scheduleScanEnabled}
              />
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            color="secondary"
            variant="contained"
            onClick={onClose}
            className={classes.reportBtn}
            disabled={false}
          >
            <Translate value="shared.cancel" />
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              onSchedule();
            }}
            className={classes.reportBtn}
            disabled={false}
          >
            {scheduleScanEnabled ? (
              <Translate value="shared.schedule" />
            ) : (
              <Translate value="shared.save" />
            )}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

const styles = theme => ({
  container: {
    height: '300px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    width: '100%',
    margin: '0',
  },
  reportBtn: {
    height: '32px',
    lineHeight: '1px',
    margin: '0 16px 0 0',
    padding: '8px 21px',
    fontWeight: '200',
  },
  flexMenu: {
    flexGrow: 1,
    display: 'inline-flex',
    '& > ul': {
      width: '100%',
    },
  },
  frequencyWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& >div': {
      margin: '0 5px',
    },
    '& >div:first-child': {
      marginLeft: '0',
    },
    '& >div:last-child': {
      marginRight: '0',
    },
  },
});

ScheduleScanModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  timeOptions: PropTypes.array.isRequired,
  frequencyOptions: PropTypes.array.isRequired,
  timeframeIndex: PropTypes.number.isRequired,
  selectTimeframe: PropTypes.func.isRequired,
  frequencyIndex: PropTypes.number.isRequired,
  selectFrequency: PropTypes.func.isRequired,
  hourOptions: PropTypes.array.isRequired,
  selectHour: PropTypes.func.isRequired,
  hourIndex: PropTypes.number.isRequired,
  weekOptions: PropTypes.array.isRequired,
  selectWeek: PropTypes.func.isRequired,
  weekIndex: PropTypes.number.isRequired,
  dayOptions: PropTypes.array.isRequired,
  selectDay: PropTypes.func.isRequired,
  dayIndex: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  scheduleScanEnabled: PropTypes.bool.isRequired,
};

export default withStyles(styles)(ScheduleScanModal);
