import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Input from '@material-ui/core/Input/Input';
import { withStyles } from '@material-ui/core/styles';

const TextField = ({ classes, wrapperClass, icon, ...other }) => (
  <div className={classNames(classes.wrapper, wrapperClass)}>
    {icon}
    <Input {...other} />
  </div>
);

TextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  wrapper: {
    '& > svg': {
      marginRight: '10px',
    },
    '& > div > input[aria-invalid="true"]': {
      border: '1px solid red',
    },
    '& :focus': {
      border: '1px solid #a9a9a9',
    },
    '& > div': {
      border: '1px solid #DDDDDD',
      height: '32px',
      boxSizing: 'border-box',
      borderRadius: '2px',
      width: '100%',
    },
    '& > div > input': {
      fontSize: '14px',
      paddingLeft: '5px',
      boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
    },
  },
};

export default withStyles(styles, { name: 'TextField' })(TextField);
