import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const HelpTextComponent = ({ classes, children, customClasses = [] }) => (
  <div className={classNames(...customClasses, classes.helpText)}>
    {children}
  </div>
);

const styles = {
  helpText: {
    fontSize: '14px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,0.7)',
  },
};

export default withStyles(styles, { name: 'HelpTextStyles' })(
  HelpTextComponent
);
