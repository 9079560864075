import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'react-i18nify';
import classNames from 'classnames';

import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import ModuleLoading from './ModuleLoading';

import { UpArrow, DownArrow } from '../icons';

const BlockedRequests = ({
  classes,
  blockedRequests,
  trend,
  delta,
  range,
  loading,
  hideTitle,
  inlineText,
}) => {
  const trendText = `shared.trends.${range}`;
  const trendClass = classNames(
    classes.trendText,
    trend === '-' || trend === 'infinite' || trend >= 0
      ? classes.positive
      : classes.negative
  );

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      className={classes.wrapper}
      alignItems="center"
    >
      {loading ? <ModuleLoading /> : null}
      {!loading && !hideTitle ? (
        <Grid item xs={12} data-test-id="blockedRequestTitle">
          <Translate
            value="components.dashboard.blockedRequests"
            className={classes.titleText}
            tag="div"
          />
        </Grid>
      ) : null}
      {!loading ? (
        <Grid
          item
          className={classes.blockedText}
          xs={12}
          data-test-id="blockedText"
        >
          {blockedRequests.toLocaleString()}
        </Grid>
      ) : null}
      {!loading && inlineText ? (
        <Grid item className={trendClass} data-test-id="blockedTrend">
          {trend === '-' ? (
            <Translate value="components.dashboard.insufficientData" />
          ) : null}
          {trend === 'infinite' ? (
            <span className={classes.trendCell}>
              <UpArrow className={classes.icon} />
              {Math.abs(delta)}
            </span>
          ) : null}
          {trend !== '-' && trend !== 'infinite' ? (
            <span className={classes.trendCell}>
              {' '}
              {trend >= 0 ? (
                <UpArrow className={classes.icon} />
              ) : (
                <DownArrow className={classes.icon} />
              )}
              {Math.abs(delta)} &nbsp;
              {Math.abs(trend).toFixed(2)}
              {'%'}{' '}
            </span>
          ) : null}
          &nbsp;
          <Translate value={trendText} className={classes.subText} />
        </Grid>
      ) : null}
      {!loading && trend === '-' && !inlineText ? (
        <Grid
          item
          className={trendClass}
          data-test-id="blockedInsufficientData"
        >
          <Translate value="components.dashboard.insufficientData" />
        </Grid>
      ) : null}
      {!loading && trend === 'infinite' && !inlineText ? (
        <span className={trendClass}>
          <UpArrow className={classes.icon} />
          {Math.abs(delta)}
        </span>
      ) : null}
      {!loading && trend !== '-' && trend !== 'infinite' && !inlineText ? (
        <Grid item className={trendClass} data-test-id="blockedTrend2">
          {trend >= 0 ? (
            <UpArrow className={classes.icon} />
          ) : (
            <DownArrow className={classes.icon} />
          )}
          {Math.abs(trend).toFixed(2)}
          {'%'}
        </Grid>
      ) : null}
      {!loading && !inlineText ? (
        <Grid item data-test-id="blockedSubText">
          <Translate value={trendText} className={classes.subText} tag="div" />
        </Grid>
      ) : null}
    </Grid>
  );
};

BlockedRequests.defaultProps = {
  hideTitle: false,
  inlineText: false,
};

BlockedRequests.propTypes = {
  classes: PropTypes.object.isRequired,
  blockedRequests: PropTypes.number.isRequired,
  range: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  hideTitle: PropTypes.bool,
  inlineText: PropTypes.bool,
};

const styles = theme => ({
  trendCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  wrapper: {
    padding: '28px',
    position: 'relative',
  },
  icon: {
    height: '10px',
    width: '8px',
  },
  titleText: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#999999',
  },
  blockedText: {
    fontSize: '40px',
    lineHeight: '40px',
    color: '#000000',
    margin: '4px 0 24px 0',
    minHeight: '40px',
  },
  trendText: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 500,
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  positive: {
    color: theme.statusColors.failure,
  },
  negative: {
    color: theme.statusColors.success,
  },
  subText: {
    fontSize: '12px',
    lineHeight: '14px',
    color: 'rgba(0, 0, 0, 0.7)',
  },
});

export default withStyles(styles, { name: 'BlockedRequests' })(BlockedRequests);
