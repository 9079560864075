import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import DocumentTitle from 'react-document-title';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

import InstallerTable from '../containers/downloads/InstallerTable';
import Paper from './CudaPaper';
import SectionHeader from './SectionHeader';
import { DownloadIcon } from './icons';

class DeployAgent extends React.Component {
  componentDidMount = () => {
    const { initData } = this.props;

    initData();
  };

  render() {
    const {
      classes,
      downloadAccountConfig,
      downloadChromebookConfig,
      loading,
      downloading,
      features,
    } = this.props;

    return (
      <DocumentTitle title={translate('components.deploy.pageTitle')}>
        <div>
          <SectionHeader
            icon={<DownloadIcon />}
            title={translate('components.deploy.title')}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} lg={7} data-test-id="deploy-agent-wrapper">
              <Paper
                titleClasses={[classes.paperHeader]}
                title={translate('components.deploy.deployAgent')}
                subTitle={<Translate value="components.deploy.deployHelp" />}
                loading={loading}
              >
                <Button
                  data-test-id="agent-configuration-download"
                  color="primary"
                  className={classes.downloadButton}
                  type="text"
                  disabled={downloading}
                  onClick={() => {
                    downloadAccountConfig();
                  }}
                >
                  {downloading && (
                    <CircularProgress className={classes.spinner} size={18} />
                  )}
                  <DownloadIcon
                    className={classes.downloadIcon}
                    color="action"
                  />
                  <Translate value="components.deploy.accountConfiguration" />
                </Button>
                <Button
                  data-test-id="chromebook-configuration-download"
                  color="primary"
                  className={classes.downloadButton}
                  type="text"
                  disabled={downloading}
                  onClick={() => {
                    downloadChromebookConfig();
                  }}
                >
                  {downloading && (
                    <CircularProgress className={classes.spinner} size={18} />
                  )}
                  <DownloadIcon
                    className={classes.downloadIcon}
                    color="action"
                  />
                  <Translate value="components.deploy.chromebookConfiguration" />
                </Button>
                <InstallerTable />
              </Paper>
            </Grid>
            <Grid
              item
              xs={12}
              lg={5}
              data-test-id="deploy-instructions-wrapper"
            >
              <Paper
                padding
                title={
                  <span>
                    <Translate value="components.deploy.deployInstructions" />
                  </span>
                }
              >
                <div className={classes.deployHeader}>
                  <Translate value="components.deploy.installManually" />
                </div>
                <ol data-test-id="manual-steps-wrapper">
                  <li>
                    <Translate value="components.deploy.manualStepDownload" />
                  </li>
                  <li>
                    <Translate value="components.deploy.manualStepRun" />
                  </li>
                  <li>
                    <Translate value="components.deploy.manualStepLicense" />
                  </li>
                  <li>
                    <Translate value="components.deploy.manualStepWizard" />
                  </li>
                </ol>
                <div
                  className={classes.deployHeader}
                  data-test-id="install-rmm-wrapper"
                >
                  <Translate value="components.deploy.installRMM" />
                </div>
                <ol className={classes.rmmSteps} data-test-id="link-to-rmm">
                  <li>
                    <Translate
                      linkClass={classes.link}
                      value="components.deploy.rmmStepCampus"
                    />
                  </li>
                </ol>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

DeployAgent.propTypes = {
  downloadAccountConfig: PropTypes.func.isRequired,
  downloadChromebookConfig: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initData: PropTypes.func.isRequired,
  downloading: PropTypes.bool.isRequired,
};

const styles = {
  rmmSteps: {
    marginBottom: 0,
  },
  deployHeader: {
    padding: '20px 0px 0px 0px',
  },
  link: {
    color: '#0088CF',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  downloadButton: {
    padding: '5px 16px',
    fontWeight: 'normal',
    border: '1px solid #DDDDDD',
    borderRadius: '2px',
    marginBottom: '20px',
    marginLeft: '20px',
  },
  downloadIcon: {
    marginRight: '10px',
    height: 18,
  },
  spinner: {
    position: 'absolute',
  },
  paperHeader: {
    padding: '20px 20px 0 20px',
  },
};

export default withStyles(styles, { name: 'DeployAgent' })(DeployAgent);
