import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withRouter } from 'react-router';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';

import CudaTableTitle from '../CudaTableTitle';
import Paper from '../CudaPaper';
import BarChart from '../charts/Bar';
import PageReport from './PageReport';

const styles = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: '300',
    color: 'rgba(0,0,0,0.9)',
  },
  subtitle: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
  },
  graphWrapper: {
    padding: '20px',
    margin: '20px',
  },
  dataTableWrapper: {
    margin: '20px',
  },
  tableWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '75%',
    margin: '25px',
  },
});

const customReport = {
  agent_version: true,
  'blocked-categories': true,
  'blocked-supercategories': true,
};

class UserReport extends Component {
  render() {
    const { classes, data } = this.props;
    const blockedCategories = data.blockedCategories;
    const blockedSupercategories = data.blockedSupercategories;
    return (
      <div>
        <PageReport
          headers={['Categories', 'Requests']}
          data={blockedSupercategories}
          title={'Top Blocked Super Categories'}
        />
        <PageReport
          headers={['Name', 'Requests']}
          data={blockedCategories}
          title={'Top Blocked Categories'}
        />
      </div>
    );
  }
}

UserReport.propTypes = {};

export default withStyles(styles)(withRouter(UserReport));
