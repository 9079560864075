const WebStorage = {
  store(key, value) {
    localStorage.setItem(key, value);
  },
  get(key) {
    return localStorage.getItem(key);
  },
  remove(key) {
    return localStorage.removeItem(key);
  },
};

export default WebStorage;
