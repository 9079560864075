import { connect } from 'react-redux';
import { translate } from 'react-i18nify';
import ChipInput from '../components/ChipInput';
import { updateAccountOption } from '../actions/agent_settings';
import { getCopyStrLocalDomains } from '../selectors/agent_settings';

const mapStateToProps = (state, ownProps) => ({
  inputValue:
    state.agentSettings.changes.localDomains ||
    state.agentSettings.fields.localDomains,
  copyStr: getCopyStrLocalDomains(state),
  selectedIndex:
    state.agentSettings.changes.localDomainSelected ||
    state.agentSettings.fields.localDomainSelected,
  iconClass: ownProps.iconClass,
  icon: ownProps.icon,
});

const mapDispatchToProps = dispatch => ({
  filterInput: value => {
    return value.replace(/\/.*/, '');
  },
  onSelectIndex: index => {
    dispatch(
      updateAccountOption({
        localDomainSelected: index,
      })
    );
  },
  onChange: value => {
    dispatch(
      updateAccountOption({
        localDomains: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChipInput);
