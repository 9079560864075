import { connect } from 'react-redux';
import Login from '../components/Login';
import { withRouter } from 'react-router';
import { login } from '../actions/login';

const mapStateToProps = (state, ownProps) => ({
  login: state.login,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onClick: (username, password, history) => {
    return dispatch(login({ username: username, password: password })).then(
      response => {
        history.push('/route_view');
      },
      error => {}
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Login));
