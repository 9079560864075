import React from 'react';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

import ActionBar from '../../containers/shared_policies/ActionBar';
import CudaTableTitle from '../CudaTableTitle';

import {
  SHARED_THREAT_DELETE_MODAL_OPEN,
  SHARED_THREAT_EDIT_MODAL_OPEN,
} from '../../actions/shared_policies';

const cudaTableSheet = theme => ({
  table_content: {
    backgroundColor: '#FFFFFF',
  },
  empty_wrapper: {
    height: '200px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noResults: {
    color: 'rgba(0,0,0,0.5)',
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
});

const SharedTable = ({
  classes,
  match,
  policies,
  accounts,
  openModal,
  loading,
}) => {
  return (
    <Grid
      container
      className={classes.container}
      spacing={1}
      data-test-id="cuda-tabled-wrapper"
    >
      <Grid item xs={12}>
        <ActionBar />
        <div className={classes.table_content}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <CudaTableTitle
                    value={translate('components.mspSharedAtp.policyName')}
                    sort={true}
                    toggle={0}
                    onClick={() => {}}
                  />
                </TableCell>
                <TableCell>
                  <CudaTableTitle
                    value={translate('components.mspSharedAtp.lastUpdated')}
                    sort={true}
                    toggle={0}
                    onClick={() => {}}
                  />
                </TableCell>
                <TableCell style={{ width: '711px' }}>
                  <CudaTableTitle
                    value={translate('components.mspSharedAtp.accountPolicy')}
                    sort={true}
                    toggle={0}
                    onClick={() => {}}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell className={classes.noResults} colSpan={4}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!loading && policies.length
                ? policies.map(p => {
                    return (
                      <TableRow>
                        <TableCell>{p.name}</TableCell>
                        <TableCell />
                        <TableCell>
                          {p.accounts.map((a, i) => {
                            return (
                              <Chip
                                key={i}
                                label={
                                  Number(a) in accounts
                                    ? accounts[Number(a)]
                                    : a
                                }
                                style={{ marginRight: '5px' }}
                              />
                            );
                          })}
                        </TableCell>
                        <TableCell>
                          <EditIcon
                            color="inherit"
                            onClick={() => {
                              openModal(SHARED_THREAT_EDIT_MODAL_OPEN, p.id);
                            }}
                          />
                          <DeleteIcon
                            color="inherit"
                            onClick={() => {
                              openModal(SHARED_THREAT_DELETE_MODAL_OPEN, p.id);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null}
              {!loading && !policies.length && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <div className={classes.empty_wrapper}>
                      <div>
                        <Translate value="components.mspSharedAtp.managedThreats" />
                      </div>
                      <div>
                        <Link
                          href="#"
                          onClick={(event: React.SyntheticEvent) =>
                            event.preventDefault()
                          }
                        >
                          <Translate value="components.mspSharedAtp.addSharedThreatPolicy" />
                        </Link>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </Grid>
    </Grid>
  );
};

export default withStyles(cudaTableSheet, { name: 'SharedTable' })(SharedTable);
