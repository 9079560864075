import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Text } from 'recharts';
import moment from 'moment-timezone';

const styles = theme => ({});

const CustomizedAxisTick = ({
  x,
  y,
  stroke,
  payload,
  graphType,
  sparseTick = false,
  timezone = 'America/Los_Angeles',
}) => {
  let date = moment(payload.value);
  let value;

  if (graphType === 'day') {
    value = date.tz(timezone).format('hh:mm A');
  } else {
    value = date.tz(timezone).format('MMM DD');
  }

  if (sparseTick && payload.index % 2 === 1) {
    value = '';
  }

  return (
    <g transform={`translate(${x},${y})`}>
      <Text
        width={30}
        fontSize="12"
        x={0}
        y={0}
        dy={20}
        textAnchor="middle"
        fill="#606060"
      >
        {value}
      </Text>
    </g>
  );
};

CustomizedAxisTick.propTypes = {
  graphType: PropTypes.string,
};

export default withStyles(styles, { name: 'CustomizedAxisTick' })(
  CustomizedAxisTick
);
