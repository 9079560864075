import { call, put, select, takeEvery } from 'redux-saga/effects';
import { translate } from 'react-i18nify';
import Api from './Api';
import * as AppTypes from '../actions/app';
import * as Types from '../actions/quarantine';

function sortFiles(action, files, columns) {
  const order = action.order;
  let orderBy;
  let secondaryOrder;

  switch (action.orderBy) {
    case 'timestamp':
      orderBy = columns.get('quarantine_timestamp');
      break;
    case 'file':
      orderBy = columns.get('file_name');
      secondaryOrder = columns.get('file_path');
      break;
    case 'user':
      orderBy = columns.get('file_owner');
      break;
    case 'device':
      orderBy = columns.get('device_name');
      break;
    default:
      return files;
  }

  const quarantinedFiles =
    order === 'desc'
      ? files.sort((a, b) =>
          (b[orderBy] || '') < (a[orderBy] || '')
            ? -1
            : (b[orderBy] || '') === (a[orderBy] || '') &&
              secondaryOrder &&
              (b[secondaryOrder] || '') < (a[secondaryOrder] || '')
            ? -1
            : 1
        )
      : files.sort((a, b) =>
          (a[orderBy] || '') < (b[orderBy] || '')
            ? -1
            : (a[orderBy] || '') === (b[orderBy] || '') &&
              secondaryOrder &&
              (a[secondaryOrder] || '') < (b[secondaryOrder] || '')
            ? -1
            : 1
        );

  return quarantinedFiles;
}

function checkQuery(element, key, query) {
  const field = (element[key] || '').toLowerCase();

  return field.indexOf(query);
}

function filterData(files, query, columns) {
  let filtered = [];

  if (query) {
    files.forEach(function(element, index) {
      if (checkQuery(element, columns.get('file_path'), query) !== -1) {
        filtered.push(index);
        return;
      }
      if (checkQuery(element, columns.get('file_owner'), query) !== -1) {
        filtered.push(index);
        return;
      }
      if (checkQuery(element, columns.get('device_name'), query) !== -1) {
        filtered.push(index);
        return;
      }
    });
  }

  return filtered;
}

export default (
  state = {
    quarantinedFiles: [],
    query: '',
    selected: [],
    count: 0,
    filtered: [],
    isProcessing: false,
    order: 'desc',
    orderBy: 'timestamp',
    columns: new Map(),
    loading: false,
    cuid: '',
    index: -1,
  },
  action
) => {
  let filtered = [];

  switch (action.type) {
    case Types.QUARANTINE_DOWNLOAD_PDF:
      return {
        ...state,
        cuid: action.cuid,
        index: action.index,
      };
    case Types.QUARANTINE_DOWNLOAD_SUCCESS:
      return {
        ...state,
        cuid: '',
        index: -1,
      };
    case Types.QUARANTINE_DOWNLOAD_FAILURE:
      return {
        ...state,
        cuid: '',
      };
    case Types.QUARANTINE_ORDER_DATA:
      const quarantinedFiles = sortFiles(
        action,
        state.quarantinedFiles,
        state.columns
      );

      return {
        ...state,
        order: action.order,
        orderBy: action.orderBy,
        quarantinedFiles: quarantinedFiles,
      };
    case Types.QUARANTINE_FILTER_DATA:
      const query = action.query.toLowerCase();
      filtered = filterData(state.quarantinedFiles, query, state.columns);

      return {
        ...state,
        query: query,
        count: query ? filtered.length : state.quarantinedFiles.length,
        filtered: filtered,
      };
    case Types.QUARANTINE_INIT_DATA:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_QUARANTINED_FILES_SUCCESS:
      const result = sortFiles(
        {
          order: state.order,
          orderBy: state.orderBy,
        },
        action.result,
        action.columns
      );
      filtered = filterData(result, state.query, action.columns);
      return {
        ...state,
        columns: action.columns,
        count: state.query ? filtered.length : action.result.length,
        quarantinedFiles: result,
        filtered: filtered,
        loading: false,
      };
    default:
      return state;
  }
};

function* getQuarantine() {
  try {
    const store = yield select();
    let result = yield call(Api.quarantine.read, {
      time_range: store.atp_shared.range,
    });
    yield put(Types.getSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getFailure(e.error));
  }
}

function* restoreFiles(data) {
  try {
    const result = yield call(Api.quarantine.resolve, data.files);
    yield put(Types.restoreFilesSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.restoreFilesFailure(e.error));
  }
}

function* deleteFiles(data) {
  try {
    const result = yield call(Api.quarantine.resolve, data.files);
    yield put(Types.deleteFilesSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.deleteFilesFailure(e.error));
  }
}

function* bulkRestore(data) {
  try {
    const store = yield select();
    const fileIndexes = data.files;
    let files = [];
    let info;

    for (let i = 0; i < fileIndexes.length; ++i) {
      info = store.quarantine.quarantinedFiles[fileIndexes[i]];

      files.push({
        device_id: info.device_id,
        method: 'unquarantine',
        quarantine_uuid: info.quarantine_uuid,
      });
    }
    const result = yield call(Api.quarantine.resolve, files);
    yield put(Types.deleteFilesSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.deleteFilesFailure(e.error));
  }
}

function* bulkDelete(data) {
  try {
    const store = yield select();
    const fileIndexes = data.files;
    let files = [];
    let info;

    for (let i = 0; i < fileIndexes.length; ++i) {
      info = store.quarantine.quarantinedFiles[fileIndexes[i]];

      files.push({
        device_id: info.device_id,
        method: 'remove',
        quarantine_uuid: info.quarantine_uuid,
      });
    }
    const result = yield call(Api.quarantine.resolve, files);
    yield put(Types.deleteFilesSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.deleteFilesFailure(e.error));
  }
}

function* downloadPdf() {
  try {
    const store = yield select();
    const params = {
      cuid: store.quarantine.cuid,
      type: 'atp-report',
      account_id: store.account.selected,
    };

    yield call(Api.downloadReport, params);
    yield put(Types.downloadPdfSuccess());
  } catch (e) {
    yield put(AppTypes.error(translate('errors.downloadFailed')));
    yield put(Types.downloadPdfFailure(e));
  }
}

export function* quarantineReducerFlow() {
  yield takeEvery(Types.GET_QUARANTINED_FILES, getQuarantine);
  yield takeEvery(Types.QUARANTINE_INIT_DATA, getQuarantine);
  yield takeEvery(Types.QUARANTINE_RESTORE_FILES, restoreFiles);
  yield takeEvery(Types.QUARANTINE_DELETE_FILES, deleteFiles);
  yield takeEvery(Types.QUARANTINE_BULK_RESTORE_FILES, bulkRestore);
  yield takeEvery(Types.QUARANTINE_BULK_DELETE_FILES, bulkDelete);

  yield takeEvery(Types.QUARANTINE_RESTORE_FILES_SUCCESS, getQuarantine);
  yield takeEvery(Types.QUARANTINE_DELETE_FILES_SUCCESS, getQuarantine);

  yield takeEvery(Types.QUARANTINE_DOWNLOAD_PDF, downloadPdf);
}
