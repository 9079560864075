import React from 'react';
import PropTypes from 'prop-types';
import EditModal from '../modal/EditModal';
import NetworkPolicy from './NetworkPolicy';

const NetworkModal = ({
  title,
  showModal,
  closeDialog,
  buttonAction,
  disabled,
  hideNetwork,
}) => (
  <EditModal
    title={title}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={closeDialog}
    disabled={disabled}
  >
    <NetworkPolicy hideNetwork={hideNetwork} />
  </EditModal>
);
NetworkModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

NetworkModal.defaultProps = {
  hideNetwork: false,
  disabled: false,
};

export default NetworkModal;
