import { connect } from 'react-redux';
import TimeSelect from '../../components/overview/TimeSelection';
import { updateFileTypesTimeframe } from '../../actions/mspdashboard';

const options = [
  {
    key: 'day',
    label: 'Last 24 hours',
  },
  {
    key: 'week',
    label: 'Last 7 days',
  },
  {
    key: 'month',
    label: 'Last 30 days',
  },
];

const mapStateToProps = (state, ownProps) => ({
  options: options,
  selectedIndex: state.mspDashboard.fileTypeSelectIndex,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: index => {
    dispatch(updateFileTypesTimeframe(options[index].key, index));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeSelect);
