import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';
import { InfoIcon } from '../icons';
import Button from '@material-ui/core/Button';
//import Grid from '@material-ui/core/Grid';

import Modal from '../modal/Modal';
//import {
//  CudaDialogContent,
//  CudaDialogTitle,
//  CudaDialogRegularContent,
//  ActionButton,
//} from '../CudaDialog';
//import Dialog from '@material-ui/core/Dialog';
//import DialogActions from '@material-ui/core/DialogActions';

const styles = theme => ({
  infoText: {
    width: '430px',
    fontSize: '14px',
    lineHeight: '24px',
    color: theme.colors.primaryText,
  },
  row: {
    marginBottom: '16px',
  },
  button: {
    margin: 0,
    padding: '8px 20px',
  },
  errors: {
    fontSize: '14px',
    lineHeight: '16px',
    color: '#333333',
    backgroundColor: theme.statusColors.error,
    textAlign: 'center',
  },
});

class SMBAccountSettings extends React.Component {
  constructor() {
    super();
    this.removeSyslogConfig = this.removeSyslogConfig.bind(this);
  }

  state = {
    syslog: null,
  };

  componentWillUnmount() {
    const { syslog } = this.state;
  }

  removeSyslogConfig() {
    this.props.removeSyslogConfig(this.state.syslog);
  }

  render() {
    const {
      classes,
      show,
      removeSyslog,
      closeDialog,
      configErrors,
      processing,
    } = this.props;
    const { syslogErrors } = this.state;
    const { removeSyslogConfig } = this;

    return (
      <Modal
        name="remve-syslog-config"
        showModal={show}
        title={translate(
          'components.smbAccountSettings.syslogConfigConfirmRemoval'
        )}
        saveBtnText={translate(
          'components.smbAccountSettings.syslogConfigRemove'
        )}
        processing={processing}
        closeDialog={closeDialog}
        buttonAction={removeSyslogConfig}
      >
        <div className={classes.row}>
          <Translate
            className={classes.infoText}
            value="components.smbAccountSettings.syslogConfigConfirmMessage"
            tag="div"
          />
        </div>
      </Modal>
    );
  }
}

SMBAccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  removeSyslogConfig: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  removeConfigErrors: PropTypes.array,
};

SMBAccountSettings.defaultProps = {
  removeConfigErrors: [],
};

export default withStyles(styles)(SMBAccountSettings);
