import * as Types from '../actions/mspdashboard';
import * as AppTypes from '../actions/app';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Api from './Api';

export const mspDashboard = (
  state = {
    init: false,
    loading: false,
    topThreatsInfo: {
      selectIndex: 1,
      range: 'week',
      data: [],
      loading: false,
    },
    fileTypeLoading: false,
    fileTypeSelectIndex: 1,
    fileTypeRange: 'week',
    fileTypeData: [],
    fileDetectedLoading: false,
    threatDetectedSelect: 1,
    threatDetectedTypeSelect: 0,
    fileDetectedRange: 'week',
    threatDetectedType: 'summary', // summary, user, device
    areaData: [],
    threatDetectedSummary: 0,
    threatDetectedUsers: 0,
    threatDetectedDevices: 0,
    blockedAccounts: {
      loading: false,
      data: [],
    },
  },
  action
) => {
  switch (action.type) {
    case Types.TOP_THREAT_GRAPH_LOADING:
      return {
        ...state,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          loading: true,
        },
      };
    case Types.UPDATE_TOP_THREAT_GRAPH:
      return {
        ...state,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          selectIndex: action.index,
          range: action.range,
        },
      };
    case Types.GET_MSP_TOP_THREAT_DATA_SUCCESS:
      return {
        ...state,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          data: action.result,
          loading: false,
        },
      };
    case Types.GET_MSP_TOP_THREAT_DATA_FAILURE:
      return {
        ...state,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          loading: false,
        },
      };
    case Types.MSP_THREAT_DETECTED_TYPE_CHANGE:
      return {
        ...state,
        threatDetectedTypeSelect: action.index,
        threatDetectedType: action.threat,
      };
    case Types.MSP_TOP_BLOCKED_ACCOUNTS_LOADING:
      return {
        ...state,
        blockedAccounts: {
          ...state.blockedAccounts,
          loading: true,
        },
      };
    case Types.MSP_GET_TOP_BLOCKED_ACCOUNTS_SUCCESS:
      return {
        ...state,
        blockedAccounts: {
          ...state.blockedAccounts,
          data: action.topBlocked,
          loading: false,
        },
      };
    case Types.MSP_GET_TOP_BLOCKED_ACCOUNTS_FAILURE:
      return {
        ...state,
        blockedAccounts: {
          ...state.blockedAccounts,
          loading: false,
        },
      };
    case Types.MSP_THREAT_DETECTED_LOADING:
      return {
        ...state,
        fileDetectedLoading: true,
      };
    case Types.MSP_THREAT_TYPE_LOADING:
      return {
        ...state,
        fileTypeLoading: true,
      };
    case Types.GET_MSP_THREAT_DETECTED_DATA_SUCCESS:
      return {
        ...state,
        fileDetectedLoading: false,
        areaData: action.result.data,
        threatDetectedSummary: action.result.summary,
        threatDetectedUsers: action.result.users,
        threatDetectedDevices: action.result.devices,
      };
    case Types.GET_MSP_THREAT_DETECTED_DATA_FAILURE:
      return {
        ...state,
        fileDetectedLoading: false,
      };
    case Types.GET_MSP_THREAT_TYPE_DATA_SUCCESS:
      return {
        ...state,
        fileTypeLoading: false,
        fileTypeData: [
          ...action.result.aggregations.threatCount.buckets,
          {
            key: 'other',
            doc_count:
              action.result.aggregations.noType.doc_count +
              action.result.aggregations.threatCount.sum_other_doc_count,
          },
        ],
      };
    case Types.GET_MSP_THREAT_TYPE_DATA_FAILURE:
      return {
        ...state,
        fileTypeLoading: false,
      };
    case Types.UPDATE_THREAT_DETECTED_GRAPH:
      return {
        ...state,
        threatDetectedSelect: action.index,
        fileDetectedRange: action.range,
      };
    case Types.UPDATE_MSP_FILE_TYPES_TIMEFRAME:
      return {
        ...state,
        fileTypeSelectIndex: action.index,
        fileTypeRange: action.range,
      };
    case Types.GET_MSP_DATA_SUCCESS:
      return {
        ...state,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          loading: false,
          data: action.topThreats,
        },
        blockedAccounts: {
          ...state.blockedAccounts,
          loading: false,
          data: action.blockedAccounts,
        },
        init: true,
        loading: false,
        fileTypeLoading: false,
        fileDetectedLoading: false,
        fileTypeData: [
          ...action.result.threat_type.aggregations.threatCount.buckets,
          {
            key: 'other',
            doc_count:
              action.result.threat_type.aggregations.noType.doc_count +
              action.result.threat_type.aggregations.threatCount
                .sum_other_doc_count,
          },
        ],
        areaData: action.result.threat_detected.data,
        threatDetectedSummary: action.result.threat_detected.summary,
        threatDetectedUsers: action.result.threat_detected.users,
        threatDetectedDevices: action.result.threat_detected.devices,
      };
    case Types.GET_MSP_DATA_FAILURE:
      return {
        ...state,
        init: true,
        loading: false,
        fileTypeLoading: false,
        fileDetectedLoading: false,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          loading: false,
        },
      };

    case Types.MSP_LOADING:
      return {
        ...state,
        loading: true,
        fileTypeLoading: true,
        fileDetectedLoading: true,
        topThreatsInfo: {
          ...state.topThreatsInfo,
          loading: true,
        },
        blockedAccounts: {
          ...state.blockedAccounts,
          loading: true,
        },
      };
    default:
      return state;
  }
};

function* initData() {
  try {
    const store = yield select();
    yield put(Types.mspLoading());
    const result = yield call(Api.getData, {
      page: 'msp_dashboard',
      range: 'week',
      fileTypeTime: store.mspDashboard.fileTypeRange,
    });
    yield put(Types.getMSPDataSuccess(result, store.account.accounts));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getMSPDataFailure(e.error));
  }
}

function* updateTopThreatGraph() {
  try {
    const store = yield select();
    yield put(Types.topThreatTypeLoading());
    const result = yield call(Api.getData, {
      page: 'top_threat_graph',
      range: store.mspDashboard.topThreatsInfo.range,
    });
    yield put(Types.getTopThreatDataSuccess(result, store.account.accounts));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getTopThreatDataFailure(e.error));
  }
}

function* updateThreatGraph() {
  try {
    const store = yield select();
    yield put(Types.threatTypeLoading());
    const result = yield call(Api.getData, {
      page: 'threat_graph',
      range: store.mspDashboard.fileTypeRange,
    });
    yield put(Types.getThreatTypeDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getThreatTypeDataFailure(e.error));
  }
}

function* updateTopBlockedAccounts(action) {
  try {
    const store = yield select();
    yield put(Types.topBlockedAccountsLoading());
    const result = yield call(Api.getData, {
      page: 'top_blocked_accounts',
      range: action.range,
    });
    yield put(
      Types.getTopBlockedAccountsSuccess(result, store.account.accounts)
    );
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getTopBlockedAccountsFailure(e.error));
  }
}

function* updateThreatDetectedGraph() {
  try {
    const store = yield select();
    yield put(Types.threatDetectedLoading());
    const result = yield call(Api.getData, {
      page: 'threat_detected',
      range: store.mspDashboard.fileDetectedRange,
      type: store.mspDashboard.threatDetectedType,
    });
    yield put(Types.getThreatDetectedDataSuccess(result));
  } catch (e) {
    yield put(AppTypes.error(e.message));
    yield put(Types.getThreatDetectedDataFailure(e.error));
  }
}

export function* mspDashboardReducerFlow() {
  yield takeEvery(Types.INIT_DATA, initData);
  yield takeLatest(Types.UPDATE_TOP_THREAT_GRAPH, updateTopThreatGraph);
  yield takeLatest(Types.UPDATE_MSP_FILE_TYPES_TIMEFRAME, updateThreatGraph);
  yield takeLatest(
    Types.UPDATE_THREAT_DETECTED_GRAPH,
    updateThreatDetectedGraph
  );
  yield takeLatest(
    Types.MSP_THREAT_DETECTED_TYPE_CHANGE,
    updateThreatDetectedGraph
  );
  yield takeLatest(
    Types.MSP_GET_TOP_BLOCKED_ACCOUNTS,
    updateTopBlockedAccounts
  );
}
