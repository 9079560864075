import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Translate, translate } from 'react-i18nify';

import Paper from './CudaPaper';

import Status from '../containers/dashboard/CustomerStatus';
import ThreatTable from '../containers/dashboard/ThreatTable';
import ThreatGraph from '../containers/dashboard/ThreatGraph';
import CategoryStats from '../containers/dashboard/CategoryStats';
import SubscriptionModule from '../containers/overview/SubscriptionModule';
import LicenseModule from '../containers/overview/LicenseModule';
import Protocol from '../containers/dashboard/Protocol';
import TlsVersion from '../containers/dashboard/TlsVersion';
import Process from '../containers/dashboard/Process';

const styles = theme => ({
  paper: {
    // height: 270,
    paddingBottom: 0,
    border: '1px solid #DDDDDD',
  },
  paperTitle: {
    padding: '20px 20px 0 20px',
  },
  menu: {
    width: '150px',
  },
  button: {
    float: 'right',
  },
});

class ComboDashboardComponent extends Component {
  componentDidMount() {
    this.props.initData();
  }

  render() {
    const { classes, getSubscription, msp, features } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={9}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12} lg={3} data-test-id="threat-summary">
              <Paper
                elevation={0}
                titleClasses={[classes.paperTitle]}
                className={classes.paper}
                title={translate('components.dashboard.threatSummaryHeader')}
              >
                <Status />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={9} data-test-id="threat-block-graph">
              <Paper
                elevation={0}
                titleClasses={[classes.paperTitle]}
                className={classes.paper}
                title={translate('components.dashboard.threatGraphHeader')}
              >
                <ThreatGraph />
              </Paper>
            </Grid>
            <Grid item xs={12} md={12} lg={4} data-test-id="encrypt-summary">
              <Protocol />
            </Grid>
            <Grid item xs={12} md={12} lg={4} data-test-id="tls-summary">
              <TlsVersion />
            </Grid>
            <Grid item xs={12} md={12} lg={4} data-test-id="process-summary">
              <Process />
            </Grid>
            <Grid item xs={12} data-test-id="threat-table">
              <Paper
                elevation={0}
                titleClasses={[classes.paperTitle]}
                className={classes.paper}
                title={
                  <span>
                    <Translate value="components.dashboard.recentThreatGraphHeader" />
                  </span>
                }
              >
                <ThreatTable />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Grid container spacing={2}>
            {!msp && (
              <Grid item xs={12} md={12} lg={12}>
                <SubscriptionModule initData={getSubscription} />
              </Grid>
            )}
            {!msp && (
              <Grid item xs={12} md={12} lg={12}>
                <LicenseModule initData={getSubscription} />
              </Grid>
            )}
            <Grid item xs={12} md={12} lg={12}>
              <Paper className={classes.paper} elevation={0}>
                <CategoryStats />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

ComboDashboardComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  initData: PropTypes.func.isRequired,
  getSubscription: PropTypes.func.isRequired,
};

export default withStyles(styles, { name: 'Dashboard' })(
  ComboDashboardComponent
);
