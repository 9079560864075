import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { Editor, RichUtils, Modifier, EditorState } from 'draft-js';

import IconButton from '@material-ui/core/IconButton';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
import UnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import NumberIcon from '@material-ui/icons/FormatListNumbered';
import BulletIcon from '@material-ui/icons/FormatListBulleted';

class BlockPageEditor extends React.Component {
  constructor(props) {
    super(props);
    this.focus = () => this.ref.current.focus();
    this.ref = React.createRef();
  }

  toggleInlineStyle = (e, style) => {
    const { editorState, updateState } = this.props;
    e.preventDefault();
    updateState(RichUtils.toggleInlineStyle(editorState, style));
  };

  toggleCodeBlock = e => {
    const { editorState, updateState } = this.props;
    e.preventDefault();
    updateState(RichUtils.toggleCode(editorState));
  };

  toggleBlockType = (e, block) => {
    const { editorState, updateState } = this.props;
    e.preventDefault();
    updateState(RichUtils.toggleBlockType(editorState, block));
  };

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.props.updateState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  handleTab = e => {
    const { editorState, updateState } = this.props;
    e.preventDefault();

    updateState(
      EditorState.push(
        editorState,
        Modifier.replaceText(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          '    '
        )
      )
    );
  };

  render() {
    const { classes, editorState, updateState, rte } = this.props;
    const {
      toggleInlineStyle,
      toggleBlockType,
      toggleCodeBlock,
      handleTab,
    } = this;

    return (
      <div className={classes.content} onClick={this.focus}>
        {rte && (
          <React.Fragment>
            <IconButton
              onMouseDown={e => toggleCodeBlock(e, 'BOLD')}
              name="BOLD"
              className={classes.button}
              aria-label="Bold"
            >
              <BoldIcon />
            </IconButton>
            <IconButton
              onMouseDown={e => toggleInlineStyle(e, 'ITALIC')}
              name="ITALIC"
              className={classes.button}
              aria-label="Italic"
            >
              <ItalicIcon />
            </IconButton>
            <IconButton
              onMouseDown={e => toggleInlineStyle(e, 'UNDERLINE')}
              name="UNDERLINE"
              className={classes.button}
              aria-label="Underline"
            >
              <UnderlinedIcon />
            </IconButton>
            {/*
              <IconButton className={classes.button} aria-label="Bold">
                <LinkIcon />
              </IconButton>
              */}
            <IconButton
              onMouseDown={e => toggleBlockType(e, 'ordered-list-item')}
              className={classes.button}
              aria-label="Ordered List"
            >
              <NumberIcon />
            </IconButton>
            <IconButton
              onMouseDown={e => toggleBlockType(e, 'unordered-list-item')}
              className={classes.button}
              aria-label="Unordered List"
            >
              <BulletIcon />
            </IconButton>
          </React.Fragment>
        )}
        <div className={classes.editorWrapper}>
          <Editor
            editorState={editorState}
            handleKeyCommand={this.handleKeyCommand}
            onChange={updateState}
            ref={this.ref}
            onTab={handleTab}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  button: {
    padding: '5px',
    color: '#191919',
  },
  editorWrapper: {
    cursor: 'text',
    marginTop: '5px',
    '& .DraftEditor-root': {
      border: '1px solid #DDDDDD',
      borderRadius: '2px',
      boxShadow: 'inset 0 1px 2px 0 rgba(0,0,0,0.2)',
      minHeight: '400px',
    },
    '& .public-DraftEditor-content': {
      padding: '5px',
    },
  },
};

BlockPageEditor.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles, { name: 'BlockPageEditor' })(BlockPageEditor);
