import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const ModuleLoading = ({ classes }) => (
  <div className={classes.center} data-test-id="moduleLoading">
    <CircularProgress />
  </div>
);
ModuleLoading.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = {
  center: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
  },
};

export default withStyles(styles, { name: 'ModuleLoading' })(ModuleLoading);
