import { connect } from 'react-redux';
import BlockedRequests from '../../components/dashboard/BlockedRequests';

const mapStateToProps = (state, ownProps) => {
  const blockedCats = state.dashboard.dayCategories.blockedCategories;
  let trend = '-';
  let current = 0;
  let delta = 0;
  blockedCats.forEach(cat => {
    if (cat.key === 80 || cat.key === 402) {
      trend = cat.trend;
      current = cat.count;
      delta = cat.delta;
    }
  });

  return {
    loading: state.dashboard.blocked_infoLoading,
    blockedRequests: current,
    trend,
    delta,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(BlockedRequests);
