import { connect } from 'react-redux';
import AddPolicyModal from '../../components/advanced_filtering/AddPolicyModal';

import {
  TYPE_OPTIONS,
  addRule,
  closeAddRule,
} from '../../actions/advanced_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => {
  const typeIndex = state.advanced_filtering.ruleModal.selectedTypeIndex;
  const ad = state.advanced_filtering.ruleModal.adGroup.length;
  let disabled = true;

  if (TYPE_OPTIONS[typeIndex].key === 'categories') {
    disabled = !state.advanced_filtering.ruleModal.categories.length || !ad;
  } else if (TYPE_OPTIONS[typeIndex].key === 'domains') {
    disabled = !state.advanced_filtering.ruleModal.domains.length || !ad;
  } else if (TYPE_OPTIONS[typeIndex].key === 'url') {
    disabled = !state.advanced_filtering.ruleModal.urls.length || !ad;
  }

  return {
    disabled,
    loading: state.advanced_filtering.addRuleModal.loading,
    showModal: state.advanced_filtering.addRuleModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: () => {
    Mixpanel.track('Advanced Filtering / Add Rule Dialog / Create', {});
    dispatch(addRule());
  },
  closeDialog: () => {
    Mixpanel.track('Advanced Filtering / Add Rule Dialog / Cancel', {});
    dispatch(closeAddRule());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPolicyModal);
