import { connect } from 'react-redux';
import CopyAccount from '../../components/shared_policies/CopyAccount';

import { updateSharedOptions } from '../../actions/shared_policies';
import { getAccountNames } from '../../selectors/account';

const mapStateToProps = (state, ownProps) => {
  return {
    display: false,
    accounts: state.shared_policies.copyEditModal.accounts,
    accountsMap: getAccountNames(state),
  };
};

const mapDispatchToProps = dispatch => ({
  updateSetting: (name, value) => {
    dispatch(
      updateSharedOptions({
        [name]: value,
      })
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CopyAccount);
