// API Calls
export const QUARANTINE_DOWNLOAD_PDF = 'QUARANTINE_DOWNLOAD_PDF';
export const QUARANTINE_DOWNLOAD_SUCCESS = 'QUARANTINE_DOWNLOAD_SUCCESS';
export const QUARANTINE_DOWNLOAD_FAILURE = 'QUARANTINE_DOWNLOAD_FAILURE';

export const QUARANTINE_INIT_DATA = 'QUARANTINE_INIT_DATA';
export const GET_QUARANTINED_FILES = 'GET_QUARANTINED_FILES';
export const GET_QUARANTINED_FILES_SUCCESS = 'GET_QUARANTINED_FILES_SUCCESS';
export const GET_QUARANTINED_FILES_FAILURE = 'GET_QUARANTINED_FILES_FAILURE';

export const QUARANTINE_RESTORE_FILES = 'QUARANTINE_RESTORE_FILES';
export const QUARANTINE_RESTORE_FILES_SUCCESS =
  'QUARANTINE_RESTORE_FILES_SUCCESS';
export const QUARANTINE_RESTORE_FILES_FAILURE =
  'QUARANTINE_RESTORE_FILES_FAILURE';

export const QUARANTINE_BULK_RESTORE_FILES = 'QUARANTINE_BULK_RESTORE_FILES';
export const QUARANTINE_BULK_RESTORE_FILES_SUCCESS =
  'QUARANTINE_BULK_RESTORE_FILES_SUCCESS';
export const QUARANTINE_BULK_RESTORE_FILES_FAILURE =
  'QUARANTINE_BULK_RESTORE_FILES_FAILURE';

export const QUARANTINE_DELETE_FILES = 'QUARANTINE_DELETE_FILES';
export const QUARANTINE_DELETE_FILES_SUCCESS =
  'QUARANTINE_DELETE_FILES_SUCCESS';
export const QUARANTINE_DELETE_FILES_FAILURE =
  'QUARANTINE_DELETE_FILES_FAILURE';

export const QUARANTINE_BULK_DELETE_FILES = 'QUARANTINE_BULK_DELETE_FILES';
export const QUARANTINE_BULK_DELETE_FILES_SUCCESS =
  'QUARANTINE_BULK_DELETE_FILES_SUCCESS';
export const QUARANTINE_BULK_DELETE_FILES_FAILURE =
  'QUARANTINE_BULK_DELETE_FILES_FAILURE';

export const QUARANTINE_FILTER_DATA = 'QUARANTINE_FILTER_DATA';

export const QUARANTINE_ORDER_DATA = 'QUARANTINE_ORDER_DATA';

export const initData = () => ({
  type: QUARANTINE_INIT_DATA,
});

export const downloadPdf = (cuid, index) => ({
  type: QUARANTINE_DOWNLOAD_PDF,
  cuid,
  index,
});

export const downloadPdfSuccess = () => ({
  type: QUARANTINE_DOWNLOAD_SUCCESS,
});

export const downloadPdfFailure = e => ({
  type: QUARANTINE_DOWNLOAD_FAILURE,
  error: e,
});

export const get = () => ({
  type: GET_QUARANTINED_FILES,
});
export const getSuccess = result => {
  let columns = new Map();
  for (let i = 0; i < (result.columns || []).length; ++i) {
    columns.set(result.columns[i], i);
  }
  return {
    type: GET_QUARANTINED_FILES_SUCCESS,
    result: result.rows,
    columns,
  };
};
export const getFailure = result => ({
  type: GET_QUARANTINED_FILES_FAILURE,
  result,
});

export const restoreFiles = files => ({
  type: QUARANTINE_RESTORE_FILES,
  files,
});
export const restoreFilesSuccess = result => ({
  type: QUARANTINE_RESTORE_FILES_SUCCESS,
  result,
});
export const restoreFilesFailure = result => ({
  type: QUARANTINE_RESTORE_FILES_FAILURE,
  result,
});

export const bulkRestoreFiles = files => ({
  type: QUARANTINE_BULK_RESTORE_FILES,
  files,
});
export const bulkRestoreFilesSuccess = result => ({
  type: QUARANTINE_BULK_RESTORE_FILES_SUCCESS,
  result,
});
export const bulkRestoreFilesFailure = result => ({
  type: QUARANTINE_BULK_RESTORE_FILES_FAILURE,
  result,
});

export const deleteFiles = files => ({
  type: QUARANTINE_DELETE_FILES,
  files,
});
export const deleteFilesSuccess = result => ({
  type: QUARANTINE_DELETE_FILES_SUCCESS,
  result,
});
export const deleteFilesFailure = result => ({
  type: QUARANTINE_DELETE_FILES_FAILURE,
  result,
});

export const bulkDeleteFiles = files => ({
  type: QUARANTINE_BULK_DELETE_FILES,
  files,
});
export const bulkDeleteFilesSuccess = result => ({
  type: QUARANTINE_BULK_DELETE_FILES_SUCCESS,
  result,
});
export const bulkDeleteFilesFailure = result => ({
  type: QUARANTINE_BULK_DELETE_FILES_FAILURE,
  result,
});

export const orderData = (orderBy, order) => ({
  type: QUARANTINE_ORDER_DATA,
  orderBy,
  order,
});

export const filterData = query => ({
  type: QUARANTINE_FILTER_DATA,
  query,
});
