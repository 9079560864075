// API Calls
export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_SUCCESS = 'CREATE_NOTIFICATION_SUCCESS';
export const CREATE_NOTIFICATION_FAILURE = 'CREATE_NOTIFICATION_FAILURE';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_SUCCESS = 'UPDATE_NOTIFICATION_SUCCESS';
export const UPDATE_NOTIFICATION_FAILURE = 'UPDATE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const SUSPEND_NOTIFICATION = 'SUSPEND_NOTIFICATION';
export const SUSPEND_NOTIFICATION_SUCCESS = 'SUSPEND_NOTIFICATION_SUCCESS';
export const SUSPEND_NOTIFICATION_FAILURE = 'SUSPEND_NOTIFICATION_FAILURE';

export const CHANGE_NOTIFICATION_TYPE = 'CHANGE_NOTIFICATION_TYPE';

export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const START_NOTIFICATION_CREATE = 'START_NOTIFICATION_CREATE';

export const NOTIFICATION_HIDE_MODAL = 'NOTIFICATION_HIDE_MODAL';
export const NOTIFICATION_UPDATE_OPTIONS = 'NOTIFICATION_UPDATE_OPTIONS';

export const NOTIFICATION_UPDATE_ERRORS = 'NOTIFICATION_UPDATE_ERRORS';

export const updateErrors = errors => ({
  type: NOTIFICATION_UPDATE_ERRORS,
  errors,
});

export const changeType = notificationType => ({
  type: CHANGE_NOTIFICATION_TYPE,
  notificationType,
});

export const updateSecondaryOptions = options => ({
  type: NOTIFICATION_UPDATE_OPTIONS,
  options,
});

export const create = notification => ({
  type: CREATE_NOTIFICATION,
  notification,
});

export const createSuccess = result => ({
  type: CREATE_NOTIFICATION_SUCCESS,
  result,
});
export const createFailure = result => ({
  type: CREATE_NOTIFICATION_FAILURE,
  result,
});

export const get = () => ({
  type: GET_NOTIFICATIONS,
});
export const getSuccess = result => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  result: {
    notifications: result.notifications.sort((a, b) => a.id - b.id),
  },
});
export const getFailure = result => ({
  type: GET_NOTIFICATIONS_FAILURE,
  result,
});

export const update = notification => ({
  type: UPDATE_NOTIFICATION,
  notification,
});
export const updateSuccess = result => ({
  type: UPDATE_NOTIFICATION_SUCCESS,
  result,
});
export const updateFailure = result => ({
  type: UPDATE_NOTIFICATION_FAILURE,
  result,
});

export const remove = notification => ({
  type: DELETE_NOTIFICATION,
  notification,
});
export const removeSuccess = result => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  result,
});
export const removeFailure = result => ({
  type: DELETE_NOTIFICATION_FAILURE,
  result,
});

export const suspend = notification => ({
  type: SUSPEND_NOTIFICATION,
  notification,
});
export const suspendSuccess = result => ({
  type: SUSPEND_NOTIFICATION_SUCCESS,
  result,
});
export const suspendFailure = result => ({
  type: SUSPEND_NOTIFICATION_FAILURE,
  result,
});

export const edit = notification => ({
  type: EDIT_NOTIFICATION,
  notification,
});

export const startCreate = () => ({
  type: START_NOTIFICATION_CREATE,
});

export const hideModal = () => ({
  type: NOTIFICATION_HIDE_MODAL,
});
