import { connect } from 'react-redux';
import BlockPageSettings from '../components/BlockPageSettings';
import {
  showPreview,
  fetchInfo,
  resetDefault,
} from '../actions/block_page_settings';
import { getChangedFields } from '../selectors/block_page_settings';

const mapStateToProps = state => ({
  loading: state.blockPageSettings.loading || state.blockPageSettings.saving,
  changed: Boolean(getChangedFields(state).length),
  template: Object.prototype.hasOwnProperty.call(
    state.blockPageSettings.changes,
    'template'
  )
    ? state.blockPageSettings.changes.template
    : state.blockPageSettings.original.template,
});

const mapDispatchToProps = dispatch => ({
  fetchInfo: () => dispatch(fetchInfo()),
  showPreview: () => dispatch(showPreview()),
  resetDefault: () => dispatch(resetDefault()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockPageSettings);
