import React from 'react';
import { Translate, translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { InfoIcon, SuccessIcon } from '../icons';

const atpDisableNoticeSheet = theme => ({
  wrapper: {
    display: 'flex',
    height: '25px',
    color: '#FFF',
    backgroundColor: '#425160',
    alignItems: 'center',
    padding: '15px',
  },
  warning: {
    marginLeft: '15px',
    font: 'Roboto Medium',
    size: '16',
    lineHeight: '24px',
  },
  button: {
    marginLeft: 'auto',
    marginRight: '0px',
  },
});

const ATPDisableNotice = ({ classes }) => {
  return (
    <div className={classes.wrapper}>
      <InfoIcon color="#FFFFFF" />
      <div className={classes.warning}>
        <Translate value="components.atp.sharedWarning" />
      </div>
      <div className={classes.button}>
        <Button
          variant="outlined"
          style={{ color: '#FFF', borderColor: '#FFF' }}
        >
          <Translate value="components.atp.editSharedPolicy" />
        </Button>
      </div>
    </div>
  );
};

export default withStyles(atpDisableNoticeSheet, { name: 'ATPDisableNotice' })(
  ATPDisableNotice
);
