import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';

import { SHARED_THREAT_DELETE_MODAL_CLOSE } from '../../actions/shared_policies';

const AddSharedThreatPolicyModal = ({
  showModal,
  closeDialog,
  buttonAction,
  disabled,
}) => (
  <EditModal
    title={translate('components.mspSharedAtp.deleteSharedThreatPolicy')}
    showModal={showModal}
    buttonAction={buttonAction}
    closeDialog={() => {
      closeDialog(SHARED_THREAT_DELETE_MODAL_CLOSE);
    }}
    saveBtnText={translate('shared.continue')}
    disabled={disabled}
  >
    <div style={{ margin: '15px' }}>
      <Translate value="components.mspSharedAtp.deleteWarning" />
    </div>
  </EditModal>
);

AddSharedThreatPolicyModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

AddSharedThreatPolicyModal.defaultProps = {
  disabled: false,
};

export default AddSharedThreatPolicyModal;
