import React, { Component } from 'react';
import { connect } from 'react-redux';
import Reports from '../components/Reports';
import { getScheduleReports, clearRunReport } from '../actions/reports';

const mapStateToProps = (state, ownProps) => ({
  loading: state.reports.reportProgress,
  report: state.reports.report,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getScheduleReports: () => {
    dispatch(getScheduleReports());
  },
  clearRunReport: () => {
    dispatch(clearRunReport());
  },
});

class ReportsWrapper extends Component {
  componentDidMount() {
    const { getScheduleReports, clearRunReport } = this.props;
    getScheduleReports();
    clearRunReport();
  }

  render() {
    return <Reports {...this.props} />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsWrapper);
