import { connect } from 'react-redux';
import SearchBar from '../../components/SearchBar';
import { updateQuery } from '../../actions/user_authentication';

const mapStateToProps = state => ({
  value: state.userAuthentication.query,
});

const mapDispatchToProps = dispatch => ({
  onChange: value => {
    dispatch(updateQuery(value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
