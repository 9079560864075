import React, { Component } from 'react';
import DocumentTitle from 'react-document-title';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { translate } from 'react-i18nify';
import PropTypes from 'prop-types';
import { BellIcon } from './icons';
import SectionHeader from './SectionHeader';
import Paper from './CudaPaper';

import NotificationsTable from '../containers/notifications/NotificationsTable';
import CreateDialog from '../containers/notifications/CreateNotification';
import EditDialog from '../containers/notifications/EditNotification';

class NotificationsComponent extends Component {
  state = {
    formInfo: {},
  };

  componentDidMount = () => {
    this.props.initData();
  };

  showEdit = info => {
    this.setState({
      formInfo: info,
    });
  };

  options = [
    {
      key: 'basic-filtering',
      label: translate('components.notifications.basicFiltering'),
      type: 'label',
    },
    {
      key: 'blocked_category',
      label: translate('components.notifications.blockedCategory'),
      conditionLabel: translate('components.notifications.requests24Hours'),
    },
    {
      key: 'command_control',
      label: translate('components.notifications.commandControl'),
      conditionLabel: translate('components.notifications.requests24Hours'),
    },
    {
      key: 'advanced-threat-filtering',
      label: translate('components.notifications.advancedThreat'),
      type: 'label',
    },
    {
      key: 'device_installed',
      label: translate('components.notifications.device_installed'),
      conditionLabel: translate('components.notifications.agentsInstalled'),
    },
    {
      key: 'threat_detected',
      label: translate('components.notifications.threat_detected'),
      conditionLabel: translate('components.notifications.threats24Hours'),
    },
  ];

  render() {
    const { classes, showCreate, options } = this.props;

    return (
      <DocumentTitle title={translate('components.notifications.page_title')}>
        <div>
          <SectionHeader
            data-test-id="notification-title"
            icon={<BellIcon />}
            title={translate('components.notifications.title')}
            options={
              <Button
                color="secondary"
                variant="contained"
                className={classes.button}
                data-test-id="add-alert"
                onClick={() => {
                  showCreate();
                }}
              >
                {translate('components.notifications.create')}
              </Button>
            }
            help
          />
          <Grid
            id="notifications"
            container
            justify="space-between"
            spacing={2}
          >
            <CreateDialog
              showModal={this.props.showCreateModal}
              closeDialog={this.props.hideModal}
              options={options}
            />
            <EditDialog
              showModal={this.props.showEditModal}
              formInfo={this.state.formInfo}
              closeDialog={this.props.hideModal}
              options={options}
            />
            <Grid item xs={12}>
              <Paper className={classes.root}>
                <NotificationsTable editAction={this.showEdit} />
              </Paper>
            </Grid>
          </Grid>
        </div>
      </DocumentTitle>
    );
  }
}

NotificationsComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  showCreate: PropTypes.func.isRequired,
  initData: PropTypes.func.isRequired,
  showCreateModal: PropTypes.bool.isRequired,
  showEditModal: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
};

const styles = theme => ({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  button: {
    margin: 0,
    float: 'right',
  },
});

export default withStyles(styles)(NotificationsComponent);
