import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';

import PaginatorActions from './PaginatorActions';

const SeparatePaginator = ({
  classes,
  rowsPerPageOptions,
  rowsPerPage,
  page,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}) => (
  <Table classes={{ root: classes.table }}>
    <TableHead>
      <TableRow
        classes={{ head: classes.tableRow }}
        data-test-id="pagination-wrapper"
      >
        <TablePagination
          classes={{
            toolbar: classes.toolbar,
            root: classes.paginateRoot,
          }}
          colSpan={1}
          count={count}
          rowsPerPageOptions={rowsPerPageOptions}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          SelectProps={{
            'data-test-id': 'selections-wrapper',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={PaginatorActions}
        />
      </TableRow>
    </TableHead>
  </Table>
);
SeparatePaginator.propTypes = {
  classes: PropTypes.object.isRequired,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPage: PropTypes.number,
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};
SeparatePaginator.defaultProps = {
  rowsPerPageOptions: [20, 50, 100],
  rowsPerPage: 50,
};

const styles = {
  table: {},
  tableRow: {
    height: '46px',
  },
  toolbar: {
    height: '46px',
    minHeight: '46px',
  },
  paginateRoot: {
    border: 0,
  },
};

export default withStyles(styles, { name: 'SeparatePaginator' })(
  SeparatePaginator
);
