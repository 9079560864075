import { connect } from 'react-redux';
import StatusBar from '../../components/StatusBar';

const mapStateToProps = (state, ownProps) => {
  const result = state.account.updateResult;
  const lid = state.account.saving
    ? 'updating'
    : state.account.fieldsUpdated.join('') +
      'Update' +
      result.charAt(0).toUpperCase() +
      result.slice(1);

  return {
    messageLid: `components.account.${lid}`,
    result: state.account.updateResult,
    saving: state.account.saving,
    show: state.account.saving || state.account.showResult,
  };
};

export default connect(
  mapStateToProps,
  undefined
)(StatusBar);
