import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import { translate } from 'react-i18nify';
import Api from './Api';
import * as AppTypes from '../actions/app';
import * as Types from '../actions/basic_filtering';
import { Mixpanel } from '../lib/Mixpanel';

const defaultConfig = {
  highlightedLocation: '',
  categoryConfig: {
    id: '',
    name: '',
    selected: 'medium',
    editSelected: 'custom',
    categories: [],
  },
  catV3Mapping: {
    categories: {},
    supercategories: {},
  },
  locationConfig: {
    id: '',
    name: '',
    exception_policy_id: '',
    category_policy_id: '',
    addresses: [],
    origAddresses: [],
  },
  exceptionConfig: {
    error: null,
    id: '',
    name: '',
    selected: 'custom',
    editSelected: 'custom',
    domains: [],
    allowDomains: [],
    allowDomainsStr: '',
    allowSelectedIndex: 1,
    denyDomains: [],
    denySelectedIndex: 1,
    denyDomainsStr: '',
  },
};

export default (
  state = {
    query: '',
    loading: false,
    showAddModal: false,
    showDynamicIPModal: false,
    selectedLocation: '',
    ipType: 'dynamic',
    catMapping: {
      loaded: false,
      categories: {},
      supercategories: {},
      wcsVersion: '3.0',
    },
    catV3Mapping: {
      ...defaultConfig.catV3Mapping,
    },
    categoryConfig: {
      ...defaultConfig.categoryConfig,
    },
    customPolicies: [],
    categoryLevels: {
      high: [],
      medium: [],
      low: [],
      custom: [],
    },
    superCategories: [],
    locationConfig: {
      ...defaultConfig.locationConfig,
    },
    exceptionConfig: {
      ...defaultConfig.exceptionConfig,
    },
    policies: [],
    checkLocationIds: [],
  },
  action
) => {
  let query;
  let allowDomains, denyDomains;
  switch (action.type) {
    case Types.BASIC_FILTER_SELECT_ALLOW_INDEX:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          allowSelectedIndex: action.index,
        },
      };
    case Types.BASIC_FILTER_CHANGE_ALLOW_DOMAINS:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          allowDomains: action.value,
          allowSelectedIndex: action.value.length,
        },
      };
    case Types.BASIC_FILTER_SELECT_DENY_INDEX:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          denySelectedIndex: action.index,
        },
      };
    case Types.BASIC_FILTER_CHANGE_DENY_DOMAINS:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          denyDomains: action.value,
          denySelectedIndex: action.value.length,
        },
      };
    case Types.STORE_LOADED_CATS:
      defaultConfig.categoryConfig.categories = action.levels.medium;
      return {
        ...state,
        catMapping: {
          loaded: true,
          categories: action.categories,
          supercategories: action.supercategories,
          wcsVersion: action.version,
        },
        catV3Mapping: {
          categories: action.v3.categories,
          supercategories: action.v3.supercategories,
        },
        categoryConfig: {
          ...state.categoryConfig,
          categories: action.levels.medium,
        },
      };
    case Types.HIGHLIGHT_LOCATION:
      return {
        ...state,
        highlightedLocation: action.rule,
      };
    case Types.BASIC_FILTER_SET_ALL_NOT_SEEN:
      let updatePolicies = [...state.policies];
      for (let i in updatePolicies) {
        if (updatePolicies[i]['lastSeen'] === null) {
          updatePolicies[i]['lastSeen'] = Types.NO_TRAFFIC_SEEN;
        }
      }
      return {
        ...state,
        policies: updatePolicies,
      };
    case Types.BASIC_FILTER_CHECK_TRAFFIC_SUCCESS:
      if (action.result.length > 0) {
        let updatePolicies = [...state.policies];
        for (let location_index in action.result) {
          let l = action.result[location_index];
          for (let i in updatePolicies) {
            if (updatePolicies[i]['origAddresses'][0]['id'] === l['id']) {
              updatePolicies[i]['lastSeen'] = l['lastSeen'];
              break;
            }
          }
        }
        return {
          ...state,
          policies: updatePolicies,
        };
      }

      return {
        ...state,
      };
    case Types.BASIC_FILTER_CHECK_TRAFFIC:
      return {
        ...state,
        checkLocationIds: action.locationIds,
      };
    case Types.BASIC_FILTER_RESET_CONFIG:
      return {
        ...state,
        ipType: 'dynamic',
        categoryConfig: {
          ...defaultConfig.categoryConfig,
        },
        locationConfig: {
          ...defaultConfig.locationConfig,
        },
        exceptionConfig: {
          ...defaultConfig.exceptionConfig,
        },
      };
    case Types.LOAD_BASIC_POLICIES:
      return {
        ...state,
        superCategories: action.superCatArr,
        categoryLevels: action.levels,
        categoryConfig: {
          ...state.categoryConfig,
          categories: action.levels.medium,
        },
      };
    case Types.BASIC_FILTERING_UPDATE_SEARCH:
      return {
        ...state,
        query: action.value,
      };
    case Types.BASIC_FILTERING_DUPLICATE_DOMAIN_ERROR:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          error: 'errors.dupDomain',
        },
      };
    case Types.BASIC_FILTERING_BAD_DOMAIN_ERROR:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          error: 'errors.badValueDomain',
        },
      };
    case Types.BASIC_FILTERING_SET_EXCEPTIONS:
      allowDomains = [];
      denyDomains = [];
      if (action.domains && action.domains.length > 0) {
        for (let i = 0; i < action.domains.length; i++) {
          if (action.domains[i]['action'] === 'allow') {
            allowDomains.push(action.domains[i]['domain']);
          } else if (action.domains[i]['action'] === 'block') {
            denyDomains.push(action.domains[i]['domain']);
          }
        }
      }
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          domains: action.domains,
          allowDomains: allowDomains,
          allowSelectedIndex: allowDomains.length,
          denyDomains: denyDomains,
          denySelectedIndex: denyDomains.length,
          selected: action.selected,
        },
      };
    case Types.BASIC_FILTERING_UPDATE_IP_TYPE:
      return {
        ...state,
        ipType: action.ipType,
      };
    case Types.BASIC_FILTERING_UPDATE_EXCEPTIONS:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          error: null,
          domains: [
            ...state.exceptionConfig.domains.filter(
              domain => action.removed.indexOf(domain) === -1
            ),
            ...action.added,
          ],
          selected: action.selected,
        },
      };
    case Types.BASIC_FILTERING_ORDER_EXCEPTIONS:
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          domains:
            action.order === 'asc'
              ? [...state.exceptionConfig.domains].sort(
                  (a, b) => (a.domain > b.domain) - (a.domain < b.domain)
                )
              : [...state.exceptionConfig.domains].sort(
                  (a, b) => (a.domain < b.domain) - (a.domain > b.domain)
                ),
        },
      };
    case Types.BASIC_FILTERING_FILTER_EXCEPTIONS:
      query = action.query.toLowerCase();

      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          domains: state.exceptionConfig.domains.map(domain => ({
            ...domain,
            hide:
              (action.action !== 'all' &&
                action.action.toLowerCase() !== domain.action) ||
              (action.query &&
                domain.domain.toLowerCase().indexOf(query) === -1),
          })),
        },
      };
    case Types.BASIC_FILTERING_SET_CATEGORIES:
      return {
        ...state,
        categoryConfig: {
          ...state.categoryConfig,
          categories: action.categories,
          selected: action.selected,
        },
      };
    case Types.BASIC_FILTERING_UPDATE_SELECTED_CATEGORIES:
      return {
        ...state,
        categoryConfig: {
          ...state.categoryConfig,
          selected: action.selected,
          categories: [
            ...state.categoryConfig.categories.filter(
              cat => action.removed.indexOf(cat) === -1
            ),
            ...action.added,
          ],
        },
      };
    case Types.BASIC_FILTERING_LOADING:
      return {
        ...state,
        loading: true,
      };
    case Types.GET_BASIC_FILTERING_SUCCESS:
      return {
        ...state,
        loading: false,
        policies: action.policies,
        customPolicies: action.customPolicies,
      };
    case Types.GET_BASIC_FILTERING_ERROR:
      return {
        ...state,
        loading: false,
      };
    case Types.BASIC_FILTERING_SORT_NETWORKS:
      return {
        ...state,
        locationConfig: {
          ...state.locationConfig,
          addresses:
            action.order === 'asc'
              ? [...state.locationConfig.addresses].sort(
                  (a, b) => (a > b) - (a < b)
                )
              : [...state.locationConfig.addresses].sort(
                  (a, b) => (a < b) - (a > b)
                ),
        },
      };
    case Types.BASIC_FILTERING_UPDATE_NETWORKS:
      return {
        ...state,
        locationConfig: {
          ...state.locationConfig,
          addresses: [
            ...state.locationConfig.addresses.filter(
              address => action.removed.indexOf(address) === -1
            ),
            ...action.added,
          ],
        },
      };
    case Types.BASIC_FILTERING_UPDATE_LOCATION_NAME:
      return {
        ...state,
        locationConfig: {
          ...state.locationConfig,
          name: action.name,
        },
      };
    case Types.NETWORK_POLICY_CREATED:
      return {
        ...state,
        showDynamicIPModal: action.ipType === 'dynamic',
        showAddModal: false,
        selectedLocation: action.locationId,
      };
    case Types.OPEN_ADD_NETWORK_MODAL:
      return {
        ...state,
        showAddModal: true,
      };
    case Types.CLOSE_ADD_NETWORK_MODAL:
      return {
        ...state,
        showAddModal: false,
      };
    case Types.DYNAMIC_IP_SHOW_MODAL:
      return {
        ...state,
        showDynamicIPModal: true,
        selectedLocation: action.id,
      };
    case Types.DYNAMIC_IP_HIDE_MODAL:
      return {
        ...state,
        showDynamicIPModal: false,
      };
    case Types.OPEN_CATEGORY_MODAL:
      return {
        ...state,
        categoryConfig: {
          ...action.config,
          selected: action.id || 'custom',
          editSelected: action.id || 'custom',
        },
      };
    case Types.OPEN_EXCEPTION_MODAL:
      allowDomains = action.config.domains
        .filter(d => d.action == 'allow')
        .map(d => d.domain);
      denyDomains = action.config.domains
        .filter(d => d.action == 'block')
        .map(d => d.domain);
      return {
        ...state,
        exceptionConfig: {
          ...state.exceptionConfig,
          ...action.config,
          allowDomains: allowDomains,
          allowSelectedIndex: allowDomains.length,
          allowDomainsStr: allowDomains.join(', '),
          denyDomains: denyDomains,
          denySelectedIndex: denyDomains.length,
          denyDomainStr: denyDomains.join(', '),
          selected: action.id || 'custom',
          editSelected: action.id || 'custom',
        },
      };
    case Types.OPEN_LOCATION_MODAL:
    case Types.REMOVE_LOCATION_POLICY:
      return {
        ...state,
        locationConfig: {
          ...action.config,
          ipType: action.config.origLocation.locations.length
            ? action.config.origLocation.locations[0].ip_type
            : 'static',
        },
      };
    default:
      return state;
  }
};

function* createLocationPolicy() {
  try {
    const store = yield select();
    const ipType = store.basic_filtering.ipType;
    const domains = [
      ...store.basic_filtering.exceptionConfig.allowDomains.map(d => {
        return { domain: d, action: 'allow' };
      }),
      ...store.basic_filtering.exceptionConfig.denyDomains.map(d => {
        return { domain: d, action: 'block' };
      }),
    ];
    yield put(Types.basicFilteringLoading());

    const result = yield call(Api.locationPolicy.create, {
      name: store.basic_filtering.locationConfig.name,
      addresses: store.basic_filtering.locationConfig.addresses,
      categories: store.basic_filtering.categoryConfig.categories,
      domains: domains,
      ip_type: ipType,
    });

    yield put(Types.getAllLocationPolicy());

    yield put(Types.networkCreated(result.location_id, ipType));
  } catch (e) {
    const { error } = e;
    yield put(Types.getBasicFilteringFailure(error));
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status == 500) {
        return yield put(AppTypes.error(translate('errors.500')));
      }
      yield put(AppTypes.error(error.response.data.error));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      yield put(AppTypes.error(translate('errors.503')));
    } else {
      // Something happened in setting up the request that triggered an Error
      yield put(AppTypes.error(translate('errors.500')));
    }
  }
}

function* editCategoryPolicy() {
  try {
    const store = yield select();
    yield put(Types.basicFilteringLoading());
    yield call(Api.locationPolicy.replace, {
      ...store.basic_filtering.categoryConfig,
      action: 'category',
    });
    yield put(Types.getAllLocationPolicy());
  } catch (e) {
    const { error } = e;
    yield put(Types.getBasicFilteringFailure(error));
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status == 500) {
        return yield put(AppTypes.error(translate('errors.500')));
      }
      yield put(AppTypes.error(error.response.data.error));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      yield put(AppTypes.error(translate('errors.503')));
    } else {
      // Something happened in setting up the request that triggered an Error
      yield put(AppTypes.error(translate('errors.500')));
    }
  }
}

function* editExceptionPolicy() {
  try {
    const store = yield select();
    yield put(Types.basicFilteringLoading());
    const domains = [
      ...store.basic_filtering.exceptionConfig.allowDomains.map(d => {
        return { domain: d, action: 'allow' };
      }),
      ...store.basic_filtering.exceptionConfig.denyDomains.map(d => {
        return { domain: d, action: 'block' };
      }),
    ];
    yield call(Api.locationPolicy.replace, {
      ...store.basic_filtering.exceptionConfig,
      domains,
      action: 'exception',
    });
    yield put(Types.getAllLocationPolicy());
  } catch (e) {
    const { error } = e;
    yield put(Types.getBasicFilteringFailure(error));
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      if (error.response.status == 500) {
        return yield put(AppTypes.error(translate('errors.500')));
      }
      yield put(AppTypes.error(error.response.data.error));
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      yield put(AppTypes.error(translate('errors.503')));
    } else {
      // Something happened in setting up the request that triggered an Error
      yield put(AppTypes.error(translate('errors.500')));
    }
  }
}

function* editLocationPolicy() {
  try {
    const store = yield select();
    yield put(Types.basicFilteringLoading());
    yield call(Api.locationPolicy.replace, {
      ...store.basic_filtering.locationConfig,
      action: 'location',
    });
    yield put(Types.getAllLocationPolicy());
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* removeLocationPolicy() {
  try {
    const store = yield select();
    yield put(Types.basicFilteringLoading());
    yield call(Api.locationPolicy.replace, {
      ...store.basic_filtering.locationConfig,
      action: 'remove',
    });
    yield put(Types.getAllLocationPolicy());
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* getAllLocationPolicy() {
  try {
    const store = yield select();
    yield put(Types.basicFilteringLoading());
    const catMap = store.basic_filtering.catMapping;
    let cat = {};
    if (!catMap.loaded) {
      const catsResult = yield call(Api.getData, {
        page: 'get_basic_policies',
      });
      cat = {
        categories: catsResult['categories'],
        supercategories: catsResult['supercategories'],
      };
      yield put(
        Types.storeLoadedCats(
          cat['categories'],
          cat['supercategories'],
          catsResult['catsv3'],
          catsResult['wcs_version']
        )
      );
    } else {
      cat = {
        categories: catMap['categories'],
        supercategories: catMap['supercategories'],
      };
    }

    yield put(
      Types.initBasicPolicies(cat['categories'], cat['supercategories'])
    );
    const result = yield call(Api.locationPolicy.read, {});
    yield put(
      Types.getBasicFilteringSuccess(
        result,
        store.basic_filtering.query,
        store.account.time_zone,
        store.basic_filtering.policySortIndex,
        store.basic_filtering.policySortDirection
      )
    );
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* checkLocation() {
  try {
    const store = yield select();
    const result = yield call(Api.getData, {
      page: 'get_location_traffic',
      account_id: store.account.selected,
      location_ids: store.basic_filtering.checkLocationIds.join(','),
    });
    yield put(Types.checkLocationSuccess(result));
  } catch (error) {
    yield put(AppTypes.error(error.message));
    yield put(Types.getBasicFilteringFailure(error.error));
  }
}

function* trackSearch() {
  yield delay(100);
  Mixpanel.track('DNS Filtering / Search', {});
}

export function* basicFilteringReducerFlow() {
  yield takeEvery(Types.BASIC_FILTERING_GET_ALL_POLICY, getAllLocationPolicy);
  yield takeEvery(Types.CREATE_LOCATION_POLICY, createLocationPolicy);
  yield takeEvery(Types.EDIT_CATEGORY_POLICY, editCategoryPolicy);
  yield takeEvery(Types.EDIT_EXCEPTION_POLICY, editExceptionPolicy);
  yield takeEvery(Types.EDIT_LOCATION_POLICY, editLocationPolicy);
  yield takeEvery(Types.REMOVE_LOCATION_POLICY, removeLocationPolicy);
  yield takeEvery(Types.BASIC_FILTER_CHECK_TRAFFIC, checkLocation);
  yield takeLatest(Types.BASIC_FILTERING_UPDATE_SEARCH, trackSearch);
}
