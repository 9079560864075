import { connect } from 'react-redux';
import CategoryPolicy from '../../components/basic_filtering/CategoryPolicy';
import {
  updatedSelectedCategories,
  setSelectedCategories,
} from '../../actions/basic_filtering';

import { Mixpanel } from '../../lib/Mixpanel';

const mapStateToProps = state => ({
  selected: state.basic_filtering.categoryConfig.selected,
  editSelected: state.basic_filtering.categoryConfig.editSelected,
  superCategories: state.basic_filtering.superCategories,
  selectedCategories: state.basic_filtering.categoryConfig.categories,
  levels: state.basic_filtering.categoryLevels,
  customPolicies: state.basic_filtering.customPolicies,
  wcsVersion: state.basic_filtering.catMapping.wcsVersion,
});

const mapDispatchToProps = dispatch => ({
  updateCategories: (selected, added, removed) => {
    dispatch(updatedSelectedCategories(selected, added, removed));
  },
  setCategories: (selected, categories) => {
    if (selected === 'custom') {
      Mixpanel.track(
        'DNS Filtering / Add Location Dialog / Step 1-Category Policy Custom',
        {}
      );
    } else if (selected === 'low') {
      Mixpanel.track(
        'DNS Filtering / Add Location Dialog / Step 1-Category Policy Low',
        {}
      );
    } else if (selected === 'medium') {
      Mixpanel.track(
        'DNS Filtering / Add Location Dialog / Step 1-Category Policy Medium',
        {}
      );
    } else if (selected === 'high') {
      Mixpanel.track(
        'DNS Filtering / Add Location Dialog / Step 1-Category Policy High',
        {}
      );
    }
    dispatch(setSelectedCategories(selected, categories));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryPolicy);
