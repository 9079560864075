import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

const colors = {
  blue: '#0088CE',
};

const Theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: blue,
  },
  colors: {
    barracudaBlue: colors.blue,
    link: colors.blue,
    blueAction: colors.blue,
    greyText: '#5F5F5F',
    background: '#F7F7F7',
    tableBorder: '#EEEEEE',
    highlightYellow: '#FCF8E3',
    primaryText: '#191919',
    secondaryText: '#7F7F7F',
  },
  statusColors: {
    failure: '#DD1E31',
    success: '#5BA71B',
    error: '#FFDDE0',
    warning: '#EE8C00',
  },
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1366,
      xl: 1920,
    },
  },
  overrides: {
    MuiBottomNavigation: {
      root: {
        height: 'auto',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(0,0,0,0.8)',
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.5)',
        borderRadius: '2px',
        maxWidth: '300px',
        textTransform: 'none',
        textAlign: 'left',
        fontSize: '12px !important',
        lineHeight: '16px',
        fontWeight: 'normal',
        whiteSpace: 'normal',
        overflowWrap: 'break-word',
      },
    },
    MuiFormLabel: {
      root: {
        '&$error': {
          color: '#DD1E31',
        },
      },
      error: {},
    },
    MuiPaper: {
      elevation2: {
        boxShadow:
          '0 2px 5px 0 rgba(0,0,0,0.26), 0 2px 10px 0 rgba(0,0,0,0.16)',
      },
    },
    MuiMenuItem: {
      root: {
        paddingTop: '2px',
        paddingBottom: '2px',
        lineHeight: '20px',
        whiteSpace: 'normal',
        fontSize: '16px',
      },
    },
    MuiTableRow: {
      root: {
        height: 55,
      },
    },
    MuiTableCell: {
      root: {
        padding: '4px 24px 4px 24px',
      },
    },
    MuiInput: {
      input: {
        color: '#555555',
        '&::-webkit-input-placeholder': {
          color: 'rgba(0,0,0,0.38)',
        },
      },
      root: {
        '&$disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.blue,
      },
    },
    MuiButton: {
      root: {
        lineHeight: '16px',
        fontSize: '14px',
        fontWeight: 500,
        borderRadius: '2px',
        minHeight: 32,
        margin: 0,
      },
      contained: {
        minWidth: 100,
        minHeight: 32,
        margin: 5,
        padding: '12px 20px',
      },
      containedPrimary: {
        backgroundColor: colors.blue,
        '&:hover': {
          backgroundColor: '#007AB8',
        },
      },
      containedSecondary: {
        backgroundColor: '#7F7F7F',
        '&:hover': {
          backgroundColor: '#6D6D6D',
        },
      },
      textPrimary: {
        color: colors.blue,
        fontWeight: 'normal',
      },
    },
    MuiDialog: {
      paperWidthXs: {
        maxWidth: 500,
      },
      paperWidthMd: {
        maxWidth: 750,
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#0088CF',
        padding: '24px 30px 21px 27px',
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: 'auto',
        padding: 0,
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        padding: 14,
        backgroundColor: '#eeeeee',
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#DDDDDD',
      },
    },
    MuiSvgIcon: {
      root: {
        height: 20,
        width: 20,
      },
      colorAction: {
        color: colors.blue,
      },
    },
    MuiSwitchLabel: {
      labelText: {
        fontSize: '13px',
      },
    },
  },
});

export default Theme;
