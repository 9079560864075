import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const HeaderButton = ({
  classes,
  loading,
  clickAction,
  icon,
  value,
  ...others
}) => (
  <Button
    classes={{
      root: classes.logButtons,
      label: classes.label,
    }}
    variant="text"
    disabled={loading}
    onClick={clickAction}
    {...others}
  >
    {icon}
    {loading && (
      <CircularProgress
        data-test-id="btn-loading"
        style={{ position: 'absolute' }}
      />
    )}
    {value}
  </Button>
);

HeaderButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  clickAction: PropTypes.func.isRequired,
  value: PropTypes.element.isRequired,
  icon: PropTypes.element.isRequired,
};

HeaderButton.defaultProps = {
  loading: false,
};

const styles = theme => ({
  logButtons: {
    minHeight: '46px',
    cursor: 'pointer',
    color: theme.colors.greyText,
    fontSize: '12px',
    lineHeight: '14px',
  },
  label: {
    '& > svg': {
      marginRight: '10px',
    },
  },
});

export default withStyles(styles, { name: 'HeaderButton' })(HeaderButton);
