import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ErrorIcon from '@material-ui/icons/Error';

const ErrorMessage = ({ classes, error, ...other }) => (
  <Grid item className={classes.error} {...other}>
    <ErrorIcon className={classes.errorIcon} />
    <span className={classes.errorText}>{error}</span>
  </Grid>
);

const styles = theme => ({
  error: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '-10px',
    backgroundColor: '#FFDDE0',
    padding: '6px',
    width: '100%',
  },
  errorText: {
    color: '#333333',
    lineHeight: '15px',
    fontSize: '13px',
    marginLeft: '10px',
  },
  errorIcon: {
    color: theme.statusColors.failure,
  },
});

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string.isRequired,
};

export default withStyles(styles, { name: 'ErrorMessage' })(ErrorMessage);
