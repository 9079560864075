import { connect } from 'react-redux';
import EditLocalGroup from '../../components/user_authentication/EditLocalGroup';
import { updateModalData } from '../../actions/user_authentication';

const mapStateToProps = state => ({
  group: {
    ...state.userAuthentication.modal.data,
    ...state.userAuthentication.modal.changes,
  },
});

const mapDispatchToProps = dispatch => ({
  updateName: e => {
    dispatch(updateModalData('name', e.target.value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocalGroup);
