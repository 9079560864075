import { connect } from 'react-redux';

import {
  retrieveAccount,
  selectAccount,
  getAccountInstallers,
} from '../actions/account';
import { initData } from '../actions/mspdashboard';

import MSPDashboard from '../components/MSPDashboard';

const mapStateToProps = (state, ownProps) => ({
  subscriptions: state.account.subscriptions,
  account: state.account,
  msp: state.mspDashboard,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAccountInstallers: account => {
    dispatch(getAccountInstallers(account));
  },
  selectAccount: (id, name) => {
    dispatch(selectAccount(id, name));
  },
  initData: () => {
    dispatch(initData());
  },
  retrieveAccount: (email, account, accounts, services, user_id, time_zone) => {
    dispatch(
      retrieveAccount(email, account, accounts, services, user_id, time_zone)
    );
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSPDashboard);
