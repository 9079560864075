import { connect } from 'react-redux';
import EditTimeConstraintModal from '../../components/advanced_filtering/EditTimeConstraintModal';

import {
  closeTimeConstraint,
  saveTimeConstraint,
} from '../../actions/advanced_filtering';

const mapStateToProps = (state, ownProps) => {
  return {
    disabled: false,
    loading: state.advanced_filtering.editTimeConstraintModal.loading,
    showModal: state.advanced_filtering.editTimeConstraintModal.show,
  };
};

const mapDispatchToProps = dispatch => ({
  buttonAction: rule => {
    dispatch(saveTimeConstraint());
  },
  closeDialog: () => {
    dispatch(closeTimeConstraint());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditTimeConstraintModal);
