import React from 'react';
import { Translate } from 'react-i18nify';
import CudaLink from './CudaLink';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  PieChartIcon,
  AuditLogIcon,
  ATPPolicyIcon,
  ATPPolicyWhiteIcon,
  BellIcon,
  LogIcon,
  ReportIcon,
  DeviceIcon,
  QuarantineIcon,
  QuarantineWhiteIcon,
  BasicFilterIcon,
  DownloadIcon,
  WebLogIcon,
  AdvancedFilteringIcon,
  SettingsIcon,
  BlockpageIcon,
  PeopleIcon,
} from './icons';

import { Mixpanel } from '../lib/Mixpanel';

const cudaNavigatorSheet = theme => ({
  container: {
    paddingTop: '10px',
  },
  navigationText: {
    color: 'rgba(0,0,0,0.9)',
    fontSize: '12px',
    lineHeight: '20px',
    marginLeft: '15px',
    textTransform: 'uppercase',
  },
  currentLink: {
    '& div': {
      color: '#ffffff',
    },
    '& > div': {
      backgroundColor: theme.colors.barracudaBlue,
    },
    '& path': {
      fill: '#ffffff',
    },
    '& polygon': {
      fill: '#ffffff',
    },
  },
  placeholderCurrentLink: {
    '& div': {
      color: '#ffffff',
    },
    '& > div': {
      backgroundColor: theme.colors.barracudaBlue,
    },
    '& g': {
      stroke: '#ffffff',
    },
    '& $whiteIcon': {
      display: 'inline',
    },
    '& $colorIcon': {
      display: 'none',
    },
  },
  linkDiv: {
    color: 'rgba(0,0,0,0.9)',
    fontSize: '12px',
    lineHeight: '20px',
    textTransform: 'uppercase',
    maxWidth: '220px',
    padding: '6px 5px',
    borderRadius: '2px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '15px',
    },
  },
  colorIcon: {
    display: 'inline',
  },
  whiteIcon: {
    display: 'none',
  },
});

const CudaNavigator = ({ classes, match, subscriptions, msp }) => {
  return (
    <Grid
      container
      className={classes.container}
      spacing={1}
      data-test-id="cuda-navigator-wrapper"
    >
      <Grid item xs={12}>
        <CudaLink
          id="dashboard-nav"
          to={`/dashboard`}
          activeClassName={classes.currentLink}
          onClick={() => {
            Mixpanel.track('NAV / Dashboard', {});
          }}
        >
          <div className={classes.linkDiv}>
            <PieChartIcon />
            <Translate
              value="components.dashboard.navtext"
              className={classes.navigationText}
            />
          </div>
        </CudaLink>
      </Grid>
      {subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="basic-filtering-nav"
            to={`/basic_filtering`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / DNS Filtering', {});
            }}
          >
            <div className={classes.linkDiv}>
              <BasicFilterIcon />
              <Translate
                value="components.basicFiltering.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.wca && (
        <Grid item xs={12}>
          <CudaLink
            id="advanced-filtering-nav"
            to={`/advanced_filtering`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Advanced Filtering', {});
            }}
          >
            <div className={classes.linkDiv}>
              <AdvancedFilteringIcon />
              <Translate
                value="components.advancedFiltering.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {(subscriptions.dns || subscriptions.wca) && (
        <Grid item xs={12}>
          <CudaLink
            id="basic-filtering-logs"
            to={`/web_filtering_logs`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Web Filtering Logs', {});
            }}
          >
            <div className={classes.linkDiv}>
              <WebLogIcon />
              <Translate
                value="components.wfLogs.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.atp && (
        <Grid item xs={12}>
          <CudaLink
            id="atp-nav"
            to={`/atp`}
            activeClassName={classes.placeholderCurrentLink}
            onClick={() => {
              Mixpanel.track('NAV / Threat Policy', {});
            }}
          >
            <div className={classes.linkDiv}>
              <ATPPolicyIcon className={classes.colorIcon} />
              <ATPPolicyWhiteIcon className={classes.whiteIcon} />
              <Translate
                value="components.atp.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.atp && (
        <Grid item xs={12}>
          <CudaLink
            id="quarantine-nav"
            to={`/quarantine`}
            activeClassName={classes.placeholderCurrentLink}
            onClick={() => {
              Mixpanel.track('NAV / Quarantine', {});
            }}
          >
            <div className={classes.linkDiv}>
              <QuarantineWhiteIcon className={classes.whiteIcon} />
              <QuarantineIcon className={classes.colorIcon} />
              <Translate
                value="components.quarantine.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.atp && (
        <Grid item xs={12}>
          <CudaLink
            id="atp-log-nav"
            to={`/atp_logs`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Threat Logs', {});
            }}
          >
            <div className={classes.linkDiv}>
              <LogIcon />
              <Translate
                value="components.atpLogs.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="report-nav"
            to={`/reports`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Reports', {});
            }}
          >
            <div className={classes.linkDiv}>
              <ReportIcon />
              <Translate
                value="components.reporting.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {(subscriptions.atp || subscriptions.wca) && (
        <Grid item xs={12}>
          <CudaLink
            id="device-nav"
            to={`/devices`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Endpoints', {});
            }}
          >
            <div className={classes.linkDiv}>
              <DeviceIcon />
              <Translate
                value="components.devices.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="notification-nav"
            to={`/notifications`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Alerts', {});
            }}
          >
            <div className={classes.linkDiv}>
              <BellIcon />
              <Translate
                value="components.notifications.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {(subscriptions.atp || subscriptions.wca) && (
        <Grid item xs={12}>
          <CudaLink
            id="device-nav"
            to={`/deploy`}
            activeClassName={classes.currentLink}
            style={{ color: 'rgb(95, 95, 95)' }}
            onClick={() => {
              Mixpanel.track('NAV / Downloads', {});
            }}
          >
            <div className={classes.linkDiv}>
              <DownloadIcon />
              <Translate
                value="components.deploy.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.wca && (
        <Grid item xs={12}>
          <CudaLink
            id="users"
            to="/users"
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Users', {});
            }}
          >
            <div className={classes.linkDiv}>
              <PeopleIcon secondaryColor="#0088CE" />
              <Translate
                value="userAuthentication.title"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {!msp && subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="account-audit-log-nav"
            to={`/account_audit_logs`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Audit Log', {});
            }}
          >
            <div className={classes.linkDiv}>
              <AuditLogIcon />
              <Translate
                value="components.dashboard.auditLog"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="smb-account-nav"
            to={`/customization`}
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Account Settings', {});
            }}
          >
            <div className={classes.linkDiv}>
              <SettingsIcon style={{ color: '#5F5F5F' }} />
              <Translate
                value="components.smbAccountSettings.title"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.dns && (
        <Grid item xs={12}>
          <CudaLink
            id="block-page-settings"
            to="/block_page_settings"
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Block Page', {});
            }}
          >
            <div className={classes.linkDiv}>
              <BlockpageIcon />
              <Translate
                value="blockPage.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.wca && (
        <Grid item xs={12}>
          <CudaLink
            id="agent-settings"
            to="/agent_settings"
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Software Agent Settings', {});
            }}
          >
            <div className={classes.linkDiv}>
              <SettingsIcon style={{ color: '#5F5F5F' }} />
              <Translate
                value="components.agentSettings.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
      {subscriptions.wca && (
        <Grid item xs={12}>
          <CudaLink
            id="exempt-policies"
            to="/exempt_policies"
            activeClassName={classes.currentLink}
            onClick={() => {
              Mixpanel.track('NAV / Exemption Policies', {});
            }}
          >
            <div className={classes.linkDiv}>
              <SettingsIcon style={{ color: '#5F5F5F' }} />
              <Translate
                value="components.exemptPolicies.navtext"
                className={classes.navigationText}
              />
            </div>
          </CudaLink>
        </Grid>
      )}
    </Grid>
  );
};

export default withStyles(cudaNavigatorSheet, { name: 'CudaNavigator' })(
  CudaNavigator
);
