import React from 'react';
import PropTypes from 'prop-types';
import { Translate, translate } from 'react-i18nify';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { LinkingIcon, SerialIcon, AccountIcon } from '../icons';
import TextField from '../TextField';
import Auth from '../../lib/Auth';
import CudaMenu from '../CudaMenu';

const styles = {
  title: {
    fontSize: '24px',
    lineHeight: '28px',
    fontWeight: '300',
    marginBottom: '20px',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '300',
  },
  formDiv: {
    padding: 8,
    width: '400px',
  },
  actionButton: {
    width: '100%',
  },
  signOutButton: {
    backgroundColor: 'transparent',
    color: '#000',
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '&:disabled': {
      backgroundColor: 'transparent',
      color: 'grey',
    },
  },
  errorText: {
    visibility: 'hidden',
    marginLeft: '30px',
    marginTop: '2px',
  },
  errorState: {
    visibility: 'visible',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginLeft: '10px',
      width: '100%',
    },
  },
  formWrapper: {
    height: '48px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  progress: {
    color: 'rgba(0,0,0,0.7)',
  },
  progressContainer: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  progressText: {
    display: 'inline-block',
    marginLeft: '8px',
    verticalAlign: 'top',
    lineHeight: '24px',
    textTransform: 'uppercase',
    color: 'rgba(0,0,0,0.7)',
  },
};

class ActivateForm extends React.Component {
  constructor(props) {
    super(props);
    this.logOut = this.logOut.bind(this);
    this.saveInfo = this.saveInfo.bind(this);
    this.onChangeSerial = this.onChangeInput.bind(this, 'serial');
    this.onChangeCode = this.onChangeInput.bind(this, 'code');
  }

  state = {
    selectedAccount: 0,
    errors: {},
  };

  onChangeInput = (field, event) => {
    if (!event.target.value) {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: true,
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: false,
        },
      });
    }
  };

  onChangeAccount = selectedAccount => {
    this.setState({
      selectedAccount,
    });
  };

  logOut() {
    this.props.deleteSession(Auth.get_token());
    Auth.logout();
  }

  saveInfo(accountId, serial, code) {
    if (accountId && serial && code) {
      this.props.saveInfo(accountId, serial, code);
    }
    this.setState({
      errors: {
        ...this.state.errors,
        serial: !serial,
        code: !code,
      },
    });
  }

  render() {
    const { classes, accounts, processing } = this.props;
    const { selectedAccount, errors } = this.state;
    const { logOut, saveInfo } = this;
    const menuAccounts = accounts.map(account => ({
      key: account.account_id,
      label: account.account_name,
    }));

    return (
      <Grid container justify="center" spacing={2}>
        <Grid item className={classes.title}>
          <Translate value="components.activate.formTitle" />
        </Grid>
        <Grid item className={classes.subTitle}>
          <Translate value="components.activate.formSubTitle" />
        </Grid>
        <div>
          <div className={classes.formDiv}>
            <Grid container alignItems="center" justify="center" spacing={2}>
              <Grid item xs={10} className={classes.formWrapper}>
                <FormControl fullWidth>
                  <div className={classes.inputWrapper}>
                    <AccountIcon />
                    <CudaMenu
                      data-test-id="activate-account-dropdown"
                      options={menuAccounts}
                      selectedIndex={selectedAccount}
                      onChange={this.onChangeAccount}
                    />
                  </div>
                </FormControl>
              </Grid>
              <Grid item xs={10} className={classes.formWrapper}>
                <FormControl fullWidth error={errors.serial}>
                  <div className={classes.inputWrapper}>
                    <SerialIcon />
                    <TextField
                      id="serial-number"
                      autoFocus
                      disableUnderline
                      style={{
                        backgroundColor: '#FFFFFF',
                      }}
                      inputRef={input => {
                        this.serial = input;
                      }}
                      placeholder="Serial Number"
                      onChange={this.onChangeSerial}
                    />
                  </div>
                  <FormHelperText
                    className={classes.errorText}
                    id="serial-number-text"
                    classes={{
                      error: classes.errorState,
                    }}
                  >
                    Required
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={10} className={classes.formWrapper}>
                <FormControl fullWidth error={errors.code}>
                  <div className={classes.inputWrapper}>
                    <LinkingIcon />
                    <TextField
                      id="linking-code"
                      disableUnderline
                      style={{
                        backgroundColor: '#FFFFFF',
                      }}
                      inputRef={input => {
                        this.code = input;
                      }}
                      placeholder="Linking Code"
                      onChange={this.onChangeCode}
                    />
                  </div>
                  <FormHelperText
                    className={classes.errorText}
                    id="linking-code-text"
                    classes={{
                      error: classes.errorState,
                    }}
                  >
                    Required
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={10} className={classes.buttonWrapper}>
                {processing ? (
                  <div className={classes.progressContainer}>
                    <CircularProgress className={classes.progress} size={2} />
                    <Typography type="body1" className={classes.progressText}>
                      {translate('components.activate.linking')}
                    </Typography>
                  </div>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      saveInfo(
                        menuAccounts[selectedAccount].key,
                        this.serial.value,
                        this.code.value
                      );
                    }}
                    className={classes.actionButton}
                  >
                    <Translate value="common.submit" />
                  </Button>
                )}
              </Grid>
              <Grid item xs={10} className={classes.buttonWrapper}>
                <Button
                  color="primary"
                  disabled={processing}
                  className={classes.signOutButton}
                  onClick={() => {
                    logOut();
                  }}
                >
                  <Translate value="shared.signOut" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    );
  }
}

ActivateForm.propTypes = {
  classes: PropTypes.object.isRequired,
  deleteSession: PropTypes.func.isRequired,
  saveInfo: PropTypes.func.isRequired,
  processing: PropTypes.bool.isRequired,
  accounts: PropTypes.array.isRequired,
};

export default withStyles(styles, { name: 'ActivateForm' })(ActivateForm);
