import { call, put, select, takeEvery, all, delay } from 'redux-saga/effects';
import { translate } from 'react-i18nify';

import { getAccountId } from '../selectors/account';
import * as Types from '../actions/smb_account_settings';
import Api from './Api';

import {
  validIPaddress,
  invalidDomainCharacter,
  validHostname,
} from '../lib/Utils';
import { getSMBSettings } from '../selectors/smb_account_settings';
import * as AppTypes from '../actions/app';
import { SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG } from '../actions/smb_account_settings';
import * as Env from '../lib/Env';

const update = require('immutability-helper');

const hasOwn = (object, field) =>
  Object.prototype.hasOwnProperty.call(object, field);

export default (
  state = {
    showUpload: false,
    showPreview: false,
    showSyslog: false,
    showSyslogRemove: false,
    connStatus: 'Error',
    processing: false,
    customLogo: '',
    logo: '',
    logoErrors: [],
    saving: false,
    savingSyslog: false,
    errors: [],
    fields: {
      support_enabled: true,
    },
    changes: {},
    syslogConnect: [
      {
        value: 'ssl',
        label: translate('SSL'),
      },
      {
        value: 'tcp',
        label: translate('TCP'),
      },
    ],
    syslogType: [
      {
        value: 'syslogng',
        label: translate('Syslog-NG'),
      },
    ],
    syslogConfig: {
      address: '',
      enabled: false,
      connection: {
        prevValue: false,
        value: true,
      },
      connectionState: {
        prevValue: true,
        value: true,
      },
      port: 6514,
      server: 'syslogng',
      conn: 'connection',
    },
    syslogExists: false,
    syslogChanges: {},
    syslogErrors: [],
    helperTextAddress: '',
    helperTextPort: '',
    domain: '',
    category_name: '',
    domainVal: '',
    categories: [],
    selectedOption: '',
    showRecategorizeDomain: false,
  },
  action
) => {
  switch (action.type) {
    case Types.SMB_ACCOUNT_TOGGLE_UPLOAD:
      return {
        ...state,
        showUpload: action.show,
        showPreview: false,
        logoErrors: [],
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO:
      return {
        ...state,
        processing: true,
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO_SUCCESS:
      return {
        ...state,
        showUpload: false,
        logoErrors: [],
      };
    case Types.SMB_ACCOUNT_CHANGE_LOGO_SUCCESS:
      return {
        ...state,
        processing: false,
        logo: `${state.customLogo}?${Date.now()}`,
      };
    case Types.SMB_ACCOUNT_LOGO_ERROR:
      return {
        ...state,
        logoErrors: action.errors,
      };
    case Types.SMB_ACCOUNT_UPLOAD_LOGO_FAILURE:
      return {
        ...state,
        processing: false,
        logoErrors: ['Failed to upload'],
      };
    case Types.SMB_ACCOUNT_TOGGLE_SYSLOG:
      return {
        ...state,
        syslogChanges: {
          ...state.syslogConfig,
        },
        showSyslog: action.show,
        syslogErrors: [],
      };
    case Types.SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG:
      return {
        ...state,
        syslogConfig: {
          ...state.syslogConfig,
        },
        helperTextAddress: '',
        helperTextPort: '',
        showSyslog: true,
        syslogErrors: [],
        processingSyslog: false,
      };
    case Types.SMB_ACCOUNT_TOGGLE_SYSLOG_CONFIG:
      return {
        ...state,
        syslogConfig: {
          ...state.syslogConfig,
        },
        helperTextAddress: '',
        helperTextPort: '',
        showSyslog: true,
        syslogErrors: [],
        processingSyslog: true,
      };
    case Types.SMB_ACCOUNT_TOGGLE_SYSLOG_REMOVE:
      return {
        ...state,
        helperTextAddress: '',
        helperTextPort: '',
        showSyslogRemove: action.show,
        syslogRemoveErrors: [],
      };
    case Types.SMB_ACCOUNT_GET_SYSLOG_SUCCESS:
      const {
        syslogIp,
        syslogPort,
        syslogConnect,
        syslogServer,
        syslogEnabled,
        syslogExists,
      } = action;

      return {
        ...state,
        syslogIp: syslogExists ? syslogIp : '',
        syslogConfig: {
          ...state.syslogConfig,
          address: syslogExists ? syslogIp : '',
          port: syslogExists ? syslogPort : 6514,
          connection: {
            value:
              syslogConnect === 'ssl' || syslogConnect === '' ? true : false,
          },
          server: syslogServer,
          enabled: syslogEnabled,
        },
        helperTextAddress: '',
        helperTextPort: '',
        syslogErrors: [],
        processingSyslog: false,
      };
    case Types.SMB_ACCOUNT_DEMO_ACCOUNT_GET_SYSLOG_SUCCESS:
      return {
        ...state,
        syslogIp: '192.168.1.1',
        syslogConfig: {
          ...state.syslogConfig,
          address: '192.168.1.1',
          port: 6514,
          connection: {
            value:
              syslogConnect === 'ssl' || syslogConnect === '' ? true : false,
          },
          server: syslogServer,
          enabled: true,
        },
        helperTextAddress: '',
        helperTextPort: '',
        syslogErrors: [],
        processingSyslog: false,
      };

    case Types.SMB_ACCOUNT_SYSLOG_TOGGLE_CONNECTION:
      const { syslogConfig } = state;

      return {
        ...state,
        syslogConfig: {
          ...state.syslogConfig,
          connection: {
            value: !syslogConfig.connection.value,
          },
        },
      };
    case Types.SMB_ACCOUNT_CLOSE_SYSLOG_CONFIG:
      return {
        ...state,
        showSyslog: false,
        syslogErrors: [''],
      };
    case Types.SMB_ACCOUNT_SYSLOG_ERROR:
      return {
        ...state,
        processing: false,
        showSyslog: false,
        logErrors: ['Failed to config syslog'],
      };
    case Types.SMB_ACCOUNT_TOGGLE_PREVIEW:
      return {
        ...state,
        showPreview: action.show,
      };
    case Types.SMB_ACCOUNT_GET_INFO:
      return {
        ...state,
        processing: true,
      };
    case Types.SMB_ACCOUNT_GET_INFO_SUCCESS: {
      const {
        logo,
        support_enabled,
        syslogExists,
        syslogIp,
        syslogLastSync,
        syslogLastFail,
        syslogEnabled,
        lastSync,
        isInactive,
      } = action;

      const exists = syslogIp ? true : false;
      const warn =
        (!syslogEnabled && syslogIp !== '') ||
        syslogLastFail === syslogLastSync ||
        isInactive
          ? true
          : false;
      const bad = syslogLastFail > syslogLastSync ? true : false;
      const good = !bad;

      return {
        ...state,
        processing: false,
        customLogo: logo.custom,
        fields: {
          ...state.fields,
          support_enabled,
        },
        syslogEnabled: syslogEnabled,
        syslogIp: syslogIp,
        syslogExists: exists,
        syslogSyncGood: warn ? 0 : good,
        syslogSyncError: warn ? 0 : bad,
        syslogSyncWarn: warn,
        syslogLastSync: lastSync,
        logo: logo.current ? `${logo.current}?${Date.now()}` : '',
      };
    }
    case Types.SMB_ACCOUNT_UPDATE_ACCOUNT_OPTION: {
      const { options } = action;
      const { fields, changes } = state;
      const merged = {
        ...changes,
        ...options,
      };

      Object.keys(options).forEach(key => {
        if (merged[key] === fields[key]) {
          delete merged[key];
        }
      });

      return update(state, {
        changes: {
          $set: merged,
        },
        errors: {
          $set: [],
        },
      });
    }
    case Types.SMB_ACCOUNT_RESET:
      return update(state, {
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS:
      return {
        ...state,
        processing: true,
        syslogSaving: true,
        showSyslog: false,
      };
    case Types.SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_SUCCESS:
      const { syslogChanges } = state;
      let newIp = syslogChanges.address
        ? syslogChanges.address
        : state.syslogIp;

      return {
        ...state,
        syslogIp: newIp,
        syslogExists: true,
        syslogSyncGood: false,
        syslogSyncError: false,
        syslogSyncWarn: true,
        processing: false,
        showSyslog: false,
        helperTextAddress: '',
        helperTextPort: '',
      };
    case Types.SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS_FAILURE:
      return {
        ...state,
        syslogSyncGood: false,
        syslogSyncError: false,
        syslogSyncWarn: true,
        processing: false,
        syslogSaving: false,
        showSyslog: true,
        helperTextPort: action.port ? action.msg : '',
        helperTextAddress: action.address ? action.msg : '',
        errors: action.errors || ['Failed to save syslog settings'],
      };
    case Types.SMB_ACCOUNT_SAVE:
      return {
        ...state,
        saving: true,
      };
    case Types.SMB_ACCOUNT_SAVE_SUCCESS:
      return update(state, {
        saving: { $set: false },
        fields: {
          $merge: state.changes,
        },
        changes: {
          $set: {},
        },
        errors: {
          $set: [],
        },
      });
    case Types.SMB_ACCOUNT_SAVE_FAILURE:
      return {
        ...state,
        saving: false,
        errors: action.errors || ['Failed to update settings'],
      };
    case Types.SMB_ACCOUNT_GET_INFO_FAILURE:
      return {
        ...state,
        processing: false,
      };
    case Types.SMB_ACCOUNT_UPDATE_SYSLOG_CONFIG: {
      const { options } = action;
      const { syslogConfig, syslogChanges } = state;
      const merged = {
        ...syslogChanges,
        ...options,
      };

      Object.keys(options).forEach(key => {
        if (merged[key] === syslogConfig[key]) {
          delete merged[key];
        }
      });

      return update(state, {
        syslogChanges: {
          $set: merged,
        },
        savingSyslog: { $set: Boolean(Object.keys(syslogChanges).length) },
        syslogErrors: {
          $set: [],
        },
      });
    }
    case Types.SMB_ACCOUNT_SYSLOG_REMOVE: {
      const { options } = action;
      const merged = {
        ...options,
      };
      return {
        ...state,
        processing: true,
        address: '',
        port: '',
        syslogChanges: {},
      };
    }
    case Types.SMB_ACCOUNT_SYSLOG_REMOVE_SUCCESS: {
      return {
        ...state,
        syslogConfig: {
          ...state.syslogConfig,
          address: '',
          port: 6514,
          enabled: false,
        },
        syslogChanges: {},
        showSyslogRemove: false,
        processing: false,
        syslogIp: '',
        syslogExists: false,
        errors: [],
        changes: [],
      };
    }
    case Types.SMB_ACCOUNT_SYSLOG_REMOVE_FAILURE: {
      return {
        ...state,
        processing: false,
        showSyslogRemove: false,
        errors: action.errors || ['Failed to remove syslog settings'],
      };
    }

    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY:
      return {
        ...state,
        domain: action.domain,
      };
    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY_SUCCESS:
      return {
        ...state,
        category_name: action.category_name,
      };

    case Types.SMB_ACCOUNT_UPDATE_DOMAIN:
      const { field } = action;
      return {
        ...state,
        domain: field['domain'],
        category_name: '',
      };

    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY_FAILURE:
      return {
        ...state,
      };

    case Types.SMB_ACCOUNT_UPDATE_RECATEGORIZE_DOMAIN:
      const { myfield } = action;
      return {
        ...state,
        domainVal: myfield['domainVal'],
      };

    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST:
      return {
        ...state,
        domainVal: action.domainVal,
      };
    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        selectedOption: action.categories.length ? action.categories[0] : '',
      };

    case Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY_LIST_FAILURE:
      return {
        ...state,
      };

    case Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_HANDLE_CHANGE:
      return {
        ...state,
        selectedOption: action.value,
      };

    case Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_MODAL:
      return {
        ...state,
        showRecategorizeDomain: action.show,
      };

    case Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_CONFIRM:
      return {
        ...state,
        processing: true,
        showRecategorizeDomain: false,
      };

    case Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_SUCCESS:
    case Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_FAILURE:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

function* uploadLogo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.upload, accountId, action);

    yield put(Types.uploadLogoSuccess(result));
    yield delay(2000);
    yield put(Types.changeLogo(result));
  } catch (e) {
    yield put(Types.uploadLogoFailure(e));
  }
}

function* checkLogoChange(action) {
  const state = yield select();
  const { etag } = action;
  const { customLogo } = state.smbAccountSettings;

  try {
    const result = yield call(Api.accounts.logo, `${customLogo}?${Date.now()}`);
    if (etag === result) {
      yield put(Types.changeLogoSuccess());
    } else {
      yield delay(2000);
      yield put(Types.changeLogo(etag));
    }
  } catch (e) {
    yield delay(2000);
    yield put(Types.changeLogo(etag));
  }
}

function* getInfo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.read, accountId);
    yield put(Types.getAccountInfoSuccess(result));
    const catsResult = yield call(Api.getData, {
      page: 'wcs_categories_read',
      version: '3.0',
    });
    var cats = catsResult['categories'];
    var categories = [];
    for (const [key, value] of Object.entries(cats)) {
      categories.push(value['display']);
    }
    categories.sort(function(a, b) {
      return a.toLowerCase().localeCompare(b.toLowerCase());
    });
    yield put(Types.getDomainCategoryListSuccess(categories));
  } catch (e) {
    yield put(Types.getAccountInfoFailure(e));
  }
}

function* saveSetttings(action) {
  try {
    const [accountId, smbSettings] = yield all([
      select(getAccountId),
      select(getSMBSettings),
    ]);
    const { changes, fields } = smbSettings;
    const values = {
      ...fields,
      ...changes,
    };
    let updates = {};

    if (hasOwn(changes, 'support_enabled')) {
      updates.support_enabled = changes.support_enabled;
    }

    const result = yield call(Api.accounts.update, accountId, updates);
    yield put(Types.saveSettingsSuccess(result));
  } catch (e) {
    yield put(Types.saveSettingsFailure([e.error.request.responseText]));
  }
}

function* saveSyslogSettings(action) {
  try {
    const [accountId, smbSettings] = yield all([
      select(getAccountId),
      select(getSMBSettings),
    ]);
    const { syslogChanges, syslogConfig, syslogExists } = smbSettings;
    const values = {
      ...syslogConfig,
      ...syslogChanges,
    };
    let updates = {};
    let demo_accounts = Env.demo_accounts;
    if (demo_accounts.indexOf(accountId.toString()) > -1) {
      syslogChanges.address = '192.168.1.1';
    }
    const privateIpRegex = /(^127\.)|(^10\.)|(^172\.1[6-9]\.)|(^172\.2[0-9]\.)|(^172\.3[0-1]\.)|(^192\.168\.)/;
    updates.create = !syslogExists;
    if (!syslogExists) {
      updates.address = syslogConfig.address;
      updates.port = syslogConfig.port ? syslogConfig.port : 6514;
      updates.connection = syslogConfig.connection.value ? 'ssl' : 'ssl';
      updates.server = syslogConfig.server ? syslogConfig.server : 'syslogng';
      updates.enabled = syslogConfig.enabled ? syslogConfig.enabled : false;
    }

    let error = {};
    if (hasOwn(syslogChanges, 'address')) {
      updates.address = syslogChanges.address;
      if (syslogChanges.address.match(/^(\d+)\.(\d+)\.(\d+)\.(\d+)$/)) {
        if (privateIpRegex.test(syslogChanges.address)) {
          error.address = true;
          error.msg = translate('components.syslog.badValueSyslogIp');
          yield put(Types.saveSyslogSettingsFailure(error));
          return;
        }
        if (!validIPaddress(syslogChanges.address)) {
          error.address = true;
          error.msg = translate('components.syslog.invalidIp');
          yield put(Types.saveSyslogSettingsFailure(error));
          return;
        }
      } else {
        if (invalidDomainCharacter(syslogChanges.address)) {
          error.address = true;
          error.msg = translate('components.syslog.invalidHostnameChar');
          yield put(Types.saveSyslogSettingsFailure(error));
          return;
        }
        if (!validHostname(syslogChanges.address)) {
          error.address = true;
          error.msg = translate('components.syslog.invalidHostname');
          yield put(Types.saveSyslogSettingsFailure(error));
          return;
        }
      }
    }

    if (hasOwn(syslogChanges, 'port')) {
      if (!syslogChanges.port.match(/^\d+$/)) {
        error.port = true;
        error.msg = translate('components.syslog.invalidPort');
        yield put(Types.saveSyslogSettingsFailure(error));
        return;
      }
      if (syslogChanges.port < 1 || syslogChanges.port > 65535) {
        error.port = true;
        error.msg = translate('components.syslog.invalidPortRange');
        yield put(Types.saveSyslogSettingsFailure(error));
        return;
      }
      updates.port = syslogChanges.port;
    }
    if (hasOwn(syslogChanges, 'connection')) {
      updates.connection = 'ssl'; //syslogChanges.connection.value ? 'ssl' : 'tcp';
    }
    if (hasOwn(syslogChanges, 'enabled')) {
      updates.enabled = syslogChanges.enabled;
    }
    if (hasOwn(syslogChanges, 'server')) {
      updates.server = syslogChanges.server;
    }

    const result = yield call(
      Api.accounts.syslogConfig.update,
      accountId,
      updates
    );
    yield delay(2000);
    yield put(Types.saveSyslogSettingsSuccess(result));
  } catch (e) {
    yield put(Types.saveSyslogSettingsFailure[e.error.request.responseText]());
  }
}

function* getSyslogInfo(action) {
  try {
    const accountId = yield select(getAccountId);
    const result = yield call(Api.accounts.syslogConfig.read, accountId);
    yield delay(2000);
    yield put(Types.getSyslogInfoSuccess(result));
  } catch (e) {
    yield put(Types.getSyslogInfoFailure(e));
  }
}

function* getSyslogDemoAccountInfo(action) {
  try {
    yield put(Types.getSyslogInfoForDemoAccountSuccess());
  } catch (e) {
    yield put(Types.getSyslogInfoFailure(e));
  }
}
function* removeSyslog(action) {
  try {
    const accountId = yield select(getAccountId);
    let remove = {};

    remove.create = false;
    remove.address = '';
    remove.port = '';
    remove.enabled = false;

    const result = yield call(
      Api.accounts.syslogConfig.delete,
      accountId,
      remove
    );
    yield put(Types.removeSyslogSuccess(result));
  } catch (e) {
    yield put(Types.removeSyslogFailure(e));
  }
}

function* toggleConnection() {
  try {
    yield put(Types.toggleConnectionSuccess('connection'));
  } catch (e) {
    yield put(Types.toggleConnectionFailure('connection'));
  }
}

function* getDomainCategory(action) {
  try {
    const accountId = yield select(getAccountId);
    const domain = action['domain'];

    const result = yield call(Api.getData, {
      account_id: accountId,
      domain: domain,
      supercat: 'true',
      page: 'get_domain_lookup',
    });
    var category_ids = result['categories'];

    const catsResult = yield call(Api.getData, {
      page: 'wcs_categories_read',
    });
    var categories = catsResult['categories'];
    var category_name = '';

    for (var i = 0; i < category_ids.length; i++) {
      let cat_id = category_ids[i].toString();
      if (cat_id in categories) {
        let cat = categories[cat_id];
        category_name += cat['display'] + ',';
        if (category_name == 'Uncategorized') {
          category_name += 'Not Found' + ',';
        }
      }
    }
    category_name = category_name.substring(0, category_name.length - 1);
    yield put(Types.getDomainCategorySuccess(category_name));
  } catch (e) {
    yield put(Types.getDomainCategoryFailure(e));
  }
}

function* getDomainCategoryList(action) {
  try {
    const catsResult = yield call(Api.getData, {
      page: 'wcs_categories_read',
    });
    var cats = catsResult['categories'];
    var categories = [];
    for (const [key, value] of Object.entries(cats)) {
      categories.push(value['display']);
    }
    yield put(Types.getDomainCategoryListSuccess(categories));
  } catch (e) {
    yield put(Types.getDomainCategoryListFailure(e));
  }
}

function* recategorizeDomainConfirm(action) {
  try {
    const accountId = yield select(getAccountId);
    const store = yield select();
    const domain = store.smbAccountSettings.domainVal;
    const selectedOption = store.smbAccountSettings.selectedOption;
    const params = {
      method: 'recategorize_domain',
      params: {
        accountId: accountId,
        domain: domain,
        selectedOption: selectedOption,
      },
    };
    const result = yield call(Api.recategorizeDomain, params);
    yield delay(2000);
    yield put(Types.recategorizeDomainSuccess(result));
  } catch (e) {
    yield put(Types.recategorizeDomainFailure(e));
  }
}

function* recategorizeDomainSuccess(action) {
  yield put(
    AppTypes.success(
      translate('components.smbAccountSettings.recategorizeDomainSuccess')
    )
  );
}

export function* SMBAccountsReducerFlow() {
  yield takeEvery(Types.SMB_ACCOUNT_UPLOAD_LOGO, uploadLogo);
  yield takeEvery(Types.SMB_ACCOUNT_GET_INFO, getInfo);
  yield takeEvery(Types.SMB_ACCOUNT_CHANGE_LOGO, checkLogoChange);
  yield takeEvery(Types.SMB_ACCOUNT_SAVE, saveSetttings);
  yield takeEvery(Types.SMB_ACCOUNT_TOGGLE_SYSLOG_CONFIG, getSyslogInfo);
  yield takeEvery(
    Types.SMB_ACCOUNT_DEMO_TOGGLE_SYSLOG_CONFIG,
    getSyslogDemoAccountInfo
  );
  yield takeEvery(Types.SMB_ACCOUNT_SYSLOG_REMOVE, removeSyslog);
  yield takeEvery(Types.SMB_ACCOUNT_SAVE_SYSLOG_SETTINGS, saveSyslogSettings);
  yield takeEvery(Types.SMB_ACCOUNT_GET_DOMAIN_CATEGORY, getDomainCategory);
  yield takeEvery(
    Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_CONFIRM,
    recategorizeDomainConfirm
  );
  yield takeEvery(
    Types.SMB_ACCOUNT_RECATEGORIZE_DOMAIN_SUCCESS,
    recategorizeDomainSuccess
  );
}
