import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import NetworkTable from '../../containers/basic_filtering/NetworkTable';
import NetworkAddBar from '../../containers/basic_filtering/NetworkAddBar';
import NetworkNameBar from '../../containers/basic_filtering/NetworkNameBar';

const NetworkPolicy = ({ classes, hideName, hideNetwork }) => (
  <Grid id="network-policy" container spacing={0}>
    {!hideName && (
      <Grid item xs={12} className={classes.levelContainer}>
        <NetworkNameBar />
      </Grid>
    )}
    {!hideNetwork && (
      <Grid item xs={12} className={classes.addContainer}>
        <NetworkAddBar />
      </Grid>
    )}
    {!hideNetwork && (
      <Grid item xs={12} className={classes.catContainer}>
        <div className={classes.tableWrapper}>
          <NetworkTable />
        </div>
      </Grid>
    )}
  </Grid>
);

NetworkPolicy.propTypes = {
  classes: PropTypes.object.isRequired,
  hideName: PropTypes.bool,
  hideNetwork: PropTypes.bool,
};

NetworkPolicy.defaultProps = {
  hideName: false,
  hideNetwork: false,
};

const styles = {
  tableWrapper: {
    borderLeft: '1px solid #DDDDDD',
    borderTop: '1px solid #DDDDDD',
    borderRight: '1px solid #DDDDDD',
    marginBottom: '24px',
  },
  catContainer: {
    padding: '24px 24px 0 24px',
  },
  addContainer: {
    padding: '24px 24px 0 24px',
  },
  levelContainer: {
    padding: '24px 24px 16px 24px',
    backgroundColor: '#F7F7F7',
    borderBottom: '1px solid #DDDDDD',
  },
};

export default withStyles(styles, { name: 'NetworkPolicy' })(NetworkPolicy);
