import { connect } from 'react-redux';
import ExceptionPolicy from '../../components/basic_filtering/ExceptionPolicy';
import { updateExceptions, setExceptions } from '../../actions/basic_filtering';

const mapStateToProps = state => ({
  customPolicies: state.basic_filtering.customPolicies,
  domains: state.basic_filtering.exceptionConfig.domains,
  allowedDomainsStr: state.basic_filtering.exceptionConfig.allowDomains,
  deniedDomainsStr: state.basic_filtering.exceptionConfig.denyDomains,
  selected: state.basic_filtering.exceptionConfig.selected,
  editSelected: state.basic_filtering.exceptionConfig.editSelected,
});

const mapDispatchToProps = dispatch => ({
  updateExceptions: (added, removed, selected) => {
    dispatch(updateExceptions(added, removed, selected));
  },
  setExceptions: (domains, selected) => {
    dispatch(setExceptions(domains, selected));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExceptionPolicy);
