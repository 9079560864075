import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18nify';
import EditModal from '../modal/EditModal';
import DynamicIPUpdater from '../../containers/basic_filtering/DynamicIPUpdater';

import { Mixpanel } from '../../lib/Mixpanel';

const DynameicIPModal = ({ title, showModal, closeDialog }) => (
  <EditModal
    title={title}
    showModal={showModal}
    closeBtnText={translate('shared.close')}
    closeDialog={() => {
      Mixpanel.track('DNS Filtering / Dynamic IP Updater Dialog / Close	', {});
      closeDialog();
    }}
  >
    <DynamicIPUpdater />
  </EditModal>
);

DynameicIPModal.propTypes = {
  title: PropTypes.string.isRequired,
  showModal: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};

export default DynameicIPModal;
