import { createSelector } from 'reselect';

const getChanges = state => state.agentSettings.changes;
const getAgentSettings = state => state.agentSettings;

const getChangedFields = createSelector(
  [getChanges],
  changes => Object.keys(changes)
);

const getCopyStrLocalDomains = createSelector(
  [getAgentSettings],
  settings => {
    if (settings.changes.localDomains) {
      return settings.changes.localDomains.join(',').toString();
    }

    return settings.fields.localDomains.join(',').toString();
  }
);

export { getChangedFields, getAgentSettings, getCopyStrLocalDomains };
