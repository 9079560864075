import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Translate } from 'react-i18nify';

import TextField from '../TextField';

const EditLocalGroup = ({ classes, group, updateName }) => (
  <React.Fragment>
    <Translate
      value="userAuthentication.local.renameGroupText"
      className={classes.text}
      tag="div"
    />
    <TextField
      className={classes.textField}
      disableUnderline
      value={group.name}
      onChange={updateName}
    />
  </React.Fragment>
);

const styles = theme => ({
  text: {
    marginBottom: '24px',
    color: theme.colors.primaryText,
    fontSize: '14px',
    lineHeight: '24px',
  },
  textField: {
    width: '100%',
    marginBottom: '24px',
  },
});

export default withStyles(styles, { name: 'EditLocalGroup' })(EditLocalGroup);
