import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { translate } from 'react-i18nify';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CudaMenu from '../CudaMenu';
import ModuleLoading from './ModuleLoading';

class StatusComponent extends Component {
  state = {
    timeRangeIndex: 1,
  };

  onChangeTimerange = timeRangeIndex => {
    this.props.changeTimerange(this.options[timeRangeIndex].key);
    this.setState({ timeRangeIndex });
  };

  options = [
    {
      key: 'day',
      label: translate('shared.ranges.day'),
    },
    {
      key: 'week',
      label: translate('shared.ranges.week'),
    },
    {
      key: 'month',
      label: translate('shared.ranges.month'),
    },
  ];

  render() {
    const { classes, threatCount, textColor, loading } = this.props;
    const { timeRangeIndex } = this.state;
    const { options } = this;

    return (
      <Grid container spacing={0} direction="column">
        <Grid item xs={12} className={classes.wrapper}>
          {loading ? <ModuleLoading /> : null}
          {!loading ? (
            <Typography type="caption">
              {options[timeRangeIndex].label}
            </Typography>
          ) : null}
          {!loading ? (
            <div style={{ textAlign: 'center', marginTop: '40px' }}>
              <Typography
                style={{ color: textColor }}
                className={classes.threatCount}
                type="display1"
              >
                {threatCount}
              </Typography>
              <Typography style={{ color: textColor }} type="caption">
                {translate('components.dashboard.statusSubtext')}
              </Typography>
            </div>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <CudaMenu
            disabled={loading}
            customStyle={classes.timeMenu}
            options={options}
            selectedIndex={timeRangeIndex}
            onChange={value => {
              this.onChangeTimerange(value);
            }}
          />
        </Grid>
      </Grid>
    );
  }
}
StatusComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  threatCount: PropTypes.number.isRequired,
  textColor: PropTypes.string.isRequired,
  changeTimerange: PropTypes.func.isRequired,
};

const styles = {
  wrapper: {
    padding: '0 20px 20px 20px',
    borderBottom: '1px solid #DDDDDD',
    height: '228px',
    flex: 'none',
  },
  threatCount: {
    fontSize: '40px',
    fontWeight: 500,
  },
  timeMenu: {
    border: 0,
  },
};

export default withStyles(styles, { name: 'StatusStyles' })(StatusComponent);
